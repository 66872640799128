import React, { useEffect, useState } from "react";

// Components for page structure
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import InstaFeeds from "../components/InstaFeeds";
import NavigationBar from "../components/NavigationBar";
import Whatsapp from "../components/Whatsapp";

// Data for HeroSection
import { CancellationPolicyHeroSection } from "../data/Home/dataStorage";

// Asset for footer background
import expedition from "../assets/home/hero/expedition.webp";

// Animation library (if actively used)
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import UpcomingTours from "../components/UpcomingTours";
import UpcomingToursIcon from "../components/UpcomingToursIcon";
import { FaFacebookSquare, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const CancellationPolicy = () => {
  useEffect(() => {
    // Initialize AOS library for animations
    AOS.init();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Helmet>
        <title>White Expedition - Cancellation Policy</title>
        <meta
          name="description"
          content="Plan with clarity! White Expedition's Cancellation Policy outlines our cancellation terms and procedures. Be informed before you book your climb."
        />
      </Helmet>

      {/* Hero Section with Carousel Data from WhoWeAreHeroSection */}
      <HeroSection carouselData={CancellationPolicyHeroSection} />

      {/* Navigation Bar */}
      <section className="center">
        <NavigationBar />
      </section>

      {/* Cancellation Policy Section  */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start text-primary font-bold margin">
                Cancellation Policy
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                In case you cancel 30 days before the start of the expedition,
                we will issue you a voucher for the same amount, valid for one
                year on any of our similar expeditions.If you wish to get a
                refund, we’ll pay 90% of the amount paid by you.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                If cancellation is done between 30 days to 10 days before the
                start, then 50% refund or 75% carry forward to a similar
                expedition.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                If you cancel less than 10 days before the start of an
                expedition/activity, we’ll refund 10% or you’ll get a voucher
                for 50% carry forward to a similar expedition.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                In cases when we have to cancel the expedition/trek/tour before
                the beginning of it, due to natural calamities (floods, storms,
                earthquakes or anything else) or reasons beyond our control
                (political or government order), we’ll issue you a voucher valid
                for one year.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                In case, we have to cancel an event after the start of the
                expedition/trek/tour due to any reasons natural (floods, storms,
                earthquakes or anything else) or man-made (political or
                government order), no refund/voucher will be issued.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                If any climber/trekker/tourist decides to leave the activity of
                choice mid-way, no refund will be initiated.
              </h1>
            </div>
          </div>
        </div>
      </section>

      {/* Instagram Section  */}
      <section className="center bg-black">
        {/* Container for Instagram feed section */}
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Section newHeader (remove commented-out animations) */}
            <h1 className="newHeader col-start-1 col-span-12 text-center">
              Photo Gallery
            </h1>

            {/* Instagram Username Link */}
            {/* <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="newL3SubHeader text-primary">@white.expedition</h1>
            </a> */}
            <div className="col-span-12 flex justify-center items-center space-x-3">
              <a
                href="https://www.facebook.com/share/19E5pajnVe/?mibextid=wwXIfr"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our facebook page
                </h1>
              </a>
              <a
                href="https://www.twitter.com/@WhiteExped79673"
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareXTwitter
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our twitter page
                </h1>
              </a>
              <a
                href="https://www.instagram.com/white.expedition?igsh=dHZiNXZwOGtyMTN0"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our instagram page
                </h1>
              </a>
              <a
                href="https://www.youtube.com/@White.Expeditions"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="text-primary cursor-pointer" size={25} />
                <h1 className="invisible hidden">
                  Click here to see our youtube page
                </h1>
              </a>
            </div>
            {/* Instagram Feed (replace with your actual Instagram feed component) */}
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
              {/* Comment: Replace InstaFeeds with your actual Instagram feed component */}
            </div>
          </div>
        </div>
      </section>

      {/* Footer section with background image */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        {/* Dark overlay on top of background image */}
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>

        {/* Container for footer content */}
        <div className="container relative z-20">
          <div className="grid grid-cols-12 gap-2">
            {/* Footer component (replace with your actual footer component) */}
            <div className="col-start-1 col-span-12">
              <Footer />
              {/* Comment: Replace Footer with your actual footer component */}
            </div>
          </div>
        </div>
      </section>

      {/* WhatsApp integration (replace with your implementation) */}
      <Whatsapp />
      {/* Comment: Replace Whatsapp with your actual WhatsApp integration */}

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </>
  );
};

export default CancellationPolicy;
