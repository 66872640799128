import React, { useState, useEffect, useRef } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// Components
import expedition from "../assets/home/hero/expedition.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import HeroSection from "../components/HeroSection";
import NavigationBar from "../components/NavigationBar";
import InstaFeeds from "../components/InstaFeeds";
import Footer from "../components/Footer";
import Whatsapp from "../components/Whatsapp";
import { adventureToursHeroSection } from "../data/Home/dataStorage";
import UpcomingTours from "../components/UpcomingTours";
import UpcomingToursIcon from "../components/UpcomingToursIcon";
import pdf from "../assets/Spiti_4x4.pdf";
import { FaFacebookSquare, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const WinterSpiti = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className="relative">
      <Helmet>
        <title>
          White Expedition - {}
          Winter Spiti Overlanding
        </title>
        <meta
          name="description"
          content={`Conquer winter spiti overlanding with White Expedition! Embark on an unforgettable guided climb to the majestic winter spiti overlanding in Himachal Pradesh.`}
        />
      </Helmet>

      {/* Hero Section  */}
      <HeroSection carouselData={adventureToursHeroSection} />

      {/* Navigation bar Section  */}
      <section className="center">
        <NavigationBar />
      </section>

      {/* Expedition Basic Information  */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-primary text-center">
              Winter Spiti Overlanding
            </h1>
            <a
              href={pdf}
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 sm:col-start-4 sm:col-span-6 lg:col-start-5 lg:col-span-4"
            >
              <h1 className="newContent text-center margin px-4 py-2 bg-black text-primary rounded-md hover:cursor-pointer">
                View the detailed document
              </h1>
            </a>
          </div>
        </div>
      </section>

      {/* Instagram Section */}
      <section className="center  bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2 ">
            <h1 className="newHeader col-start-1 col-span-12 text-center">
              Photo Gallery
            </h1>
            {/* <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="newL3SubHeader  text-primary">
                @white.expedition
              </h1>
            </a> */}
            <div className="col-span-12 flex justify-center items-center space-x-3">
              <a
                href="https://www.facebook.com/share/19E5pajnVe/?mibextid=wwXIfr"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our facebook page
                </h1>
              </a>
              <a
                href="https://www.twitter.com/@WhiteExped79673"
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareXTwitter
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our twitter page
                </h1>
              </a>
              <a
                href="https://www.instagram.com/white.expedition?igsh=dHZiNXZwOGtyMTN0"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our instagram page
                </h1>
              </a>
              <a
                href="https://www.youtube.com/@White.Expeditions"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="text-primary cursor-pointer" size={25} />
                <h1 className="invisible hidden">
                  Click here to see our youtube page
                </h1>
              </a>
            </div>
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
            </div>
          </div>
        </div>
      </section>

      {/* Footer section */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>
        <div className="center">
          <div className="container relative z-20">
            <div className="grid grid-cols-12 gap-2">
              <div className="col-start-1 col-span-12">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Whatsapp />

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      {/* <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      /> */}
    </div>
  );
};

export default WinterSpiti;
