import React, { useEffect, useState } from "react";

// Components for page structure
import NavigationBar from "../components/NavigationBar";
import HeroSection from "../components/HeroSection";
import Whatsapp from "../components/Whatsapp";
import InstaFeeds from "../components/InstaFeeds";
import Footer from "../components/Footer";

// Data for HeroSection
import { PlanYourTripHeroSection } from "../data/Home/dataStorage";

// Assets
import expedition from "../assets/home/hero/expedition.webp";

// Library for smooth scroll animations
import AOS from "aos";
import "aos/dist/aos.css"; // AOS stylesheet import
import { Helmet } from "react-helmet";
import UpcomingTours from "../components/UpcomingTours";
import UpcomingToursIcon from "../components/UpcomingToursIcon";
import { FaFacebookSquare, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const PlanYourTrip = () => {
  useEffect(() => {
    // Initialize AOS library for animations
    AOS.init();
  }, []);

  // Initial form data state
  const initialFormData = {
    fullName: "",
    email: "",
    phoneNumber: "",
    tripType: "",
    country: "",
    selectedTrip: "",
    message: "", // Optional message field (not required)
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isDisabled, setIsDisabled] = useState(true);

  // Function to handle form field changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    // Required fields for form validation
    const requiredFields = [
      "fullName",
      "email",
      "phoneNumber",
      "tripType",
      "country",
      "selectedTrip",
    ];

    // Check if all required fields are filled
    const isAllRequiredFilled = requiredFields.every(
      (field) => formData[field] !== ""
    );

    // Update button disabled state based on validation
    setIsDisabled(!isAllRequiredFilled);
  };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted:", formData);

    // Replace with your backend form submission logic here

    // Clear form data after successful submission
    setFormData(initialFormData);
  };

  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Helmet>
        <title>White Expedition - Plan Your Trip</title>
        <meta
          name="description"
          content="Plan your Himachal climb with experts! White Expedition's 'Plan Your Trip' page guides you through planning your dream climb in Himachal Pradesh. Let's make it happen!"
        />
      </Helmet>

      {/* Hero section with "Plan Your Trip" theme */}
      <HeroSection carouselData={PlanYourTripHeroSection} />

      {/* 
        Comment: NavigationBar should ideally be placed before the HeroSection 
        to ensure it's displayed at the very top of the page for navigation. 
        Consider moving it above the HeroSection.
      */}
      <section className="center">
        <NavigationBar /> {/* Navigation bar */}
      </section>

      {/* Plan Your Trip Section  */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader text-primary col-start-1 col-span-12">
              Plan your Trip
            </h1>
            <form className="col-start-1 col-span-12">
              <div className="grid grid-cols-12 gap-2">
                {/* Full Name */}
                <div className="col-start-1 col-span-12 lg:col-start-1 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Full Name :</span>
                    <input
                      required
                      type="text"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div>
                {/* Email Address */}
                <div className="col-start-1 col-span-12 lg:col-start-7 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Email Address :</span>
                    <input
                      required
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div>
                {/* Phone Number */}
                <div className="col-start-1 col-span-12 lg:col-start-1 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Phone Number :</span>
                    <input
                      required
                      type="text"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div>
                {/* Select Trip Type */}
                <div className="col-start-1 col-span-12 lg:col-start-7 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Select Trip Type :</span>
                    <select
                      required
                      name="tripType"
                      value={formData.tripType}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    >
                      <option value="">-- Please Select --</option>
                      <option value="expedition">Expedition</option>
                      <option value="pure_adrenaline">Pure Adrenaline</option>
                      <option value="trekking">Trekking</option>
                      <option value="wildlife_photography">
                        Wildlife Photography
                      </option>
                      <option value="adventure_tour">Adventure Tours</option>
                    </select>
                  </div>
                </div>
                {/* Country */}
                <div className="col-start-1 col-span-12 lg:col-start-1 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Country :</span>
                    <input
                      required
                      type="text"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div>
                <div className="col-start-1 col-span-12 lg:col-start-7 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Select Trip :</span>
                    <select
                      required
                      name="selectedTrip"
                      value={formData.selectedTrip}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    >
                      <option value="">
                        -- Please Select Trip Type First --
                      </option>
                      {/* Populate options based on selected tripType (optional) */}
                      {formData.tripType === "expedition" && (
                        <>
                          <option value="friendship_peak">
                            Friendship Peak
                          </option>
                          <option value="yunam_peak">Yunam Peak</option>
                          <option value="hanuman_tibba">Hanuman Tibba</option>
                          <option value="deo_tibba">Deo Tibba</option>
                          <option value="shitidhar">Shitidhar</option>
                          <option value="ladakhi">Ladakhi</option>
                          <option value="lady_of_keylong">
                            Lady of Keylong
                          </option>
                          <option value="kangyatse_1">Kangyatse 1</option>
                          <option value="kangyatse_2">Kangyatse 2</option>
                          <option value="ut_kangri">Ut Kangri</option>
                          <option value="mentok_kangri">Mentok Kangri</option>
                          <option value="chau_chau_kang_nilda">
                            Chau Chau Kang Nilda
                          </option>
                          <option value="leo_purgyil">Leo Purgyil</option>
                          <option value="manirang">Manirang</option>
                          <option value="kanamo">Kanamo</option>
                          <option value="nun">Nun</option>
                          <option value="kun">Kun</option>
                          <option value="kiagar_ri">Kiagar Ri</option>
                          <option value="spangnak_ri">Spangnak Ri</option>
                          <option value="p6250">P6250</option>
                          <option value="chamaer_kangri">Chamaer Kangri</option>
                          <option value="lungser_kangri">Lungser Kangri</option>
                          <option value="lagpo">Lagpo</option>
                          <option value="chalung">Chalung</option>
                          <option value="shukule">Shukule</option>
                          <option value="cb_13">CB-13</option>
                          <option value="menthosa">Menthosa</option>
                        </>
                      )}
                      {formData.tripType === "pure_adrenaline" && (
                        <>
                          <option value="papsura">Papsura</option>
                          <option value="reo_purgyil">Reo Purgyil</option>
                          <option value="indrasan">Indrasan</option>
                          <option value="makar_beh">Makar Beh</option>
                        </>
                      )}
                      {formData.tripType === "trekking" && (
                        <>
                          <option value="pin_parvati_pass_trek">
                            Pin Parvati Pass Trek
                          </option>
                          <option value="parang_la_trek">Parang La Trek</option>
                          <option value="auden_col_trek">
                            Auden's Col Trek
                          </option>
                          <option value="kalindi_khal_trek">
                            Kalindi Khal Trek
                          </option>
                          <option value="kang_la_trek">Kang La Trek</option>
                          <option value="goecha_la_trek">Goecha La Trek</option>
                          <option value="bara_bangal_trek">
                            Bara Bangal Trek
                          </option>
                          <option value="leh_lamayuru_trek">
                            Leh Lamayuru Trek
                          </option>
                          <option value="markha_valley_trek">
                            Markha Valley Trek
                          </option>
                          <option value="spituk_to_matho_trek">
                            Spituk To Matho Trek
                          </option>
                          <option value="padum_to_darcha_trek">
                            Padum To Darcha Trek
                          </option>
                          <option value="leh_to_tso_morori_trek">
                            Leh To Tso Morori Trek
                          </option>
                          <option value="nubra_valley_trek">
                            Nubra Valley Trek
                          </option>
                        </>
                      )}
                      {formData.tripType === "wildlife_photography" && (
                        <>
                          <option value="snow_leopard_winter_spiti">
                            Snow Leopard Winter Spiti
                          </option>
                        </>
                      )}
                      {formData.tripType === "adventure_tour" && (
                        <>
                          <option value="spiti_valley_overlanding">
                            Spiti Valley Overlanding
                          </option>
                          <option value="ladakh_overlanding">
                            ladakh Overlanding
                          </option>
                          <option value="zanskar_overlanding">
                            Zanskar Overlanding
                          </option>
                          <option value="sach_pas_kishtwar_overlanding">
                            Sach Pas Kishtwar Overlanding
                          </option>
                        </>
                      )}
                    </select>
                  </div>
                </div>
                {/* Review */}
                <div className="col-start-1 col-span-12 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Message :</span>
                    <textarea
                      type="text"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                      rows={4}
                    />
                  </div>
                </div>
                {/* Submit */}
                <div className="col-start-1 col-span-12 mt-4">
                  <div className="flex flex-col">
                    <button
                      className={`bg-primary p-2 newL3SubHeader font-bold text-black hover:cursor-pointer rounded-md hover:outline-primary ${
                        isDisabled ? "disabled opacity-50" : ""
                      }`}
                      disabled={isDisabled}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      {/* Instagram Section  */}
      <section className="center bg-black">
        {/* Container for Instagram feed section */}
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Section newHeader (remove commented-out animations) */}
            <h1 className="newHeader col-start-1 col-span-12 text-center">
              Photo Gallery
            </h1>

            {/* Instagram Username Link */}
            {/* <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="newL3SubHeader text-primary">@white.expedition</h1>
            </a> */}
            <div className="col-span-12 flex justify-center items-center space-x-3">
              <a
                href="https://www.facebook.com/share/19E5pajnVe/?mibextid=wwXIfr"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our facebook page
                </h1>
              </a>
              <a
                href="https://www.twitter.com/@WhiteExped79673"
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareXTwitter
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our twitter page
                </h1>
              </a>
              <a
                href="https://www.instagram.com/white.expedition?igsh=dHZiNXZwOGtyMTN0"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our instagram page
                </h1>
              </a>
              <a
                href="https://www.youtube.com/@White.Expeditions"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="text-primary cursor-pointer" size={25} />
                <h1 className="invisible hidden">
                  Click here to see our youtube page
                </h1>
              </a>
            </div>
            {/* Instagram Feed (replace with your actual Instagram feed component) */}
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
              {/* Comment: Replace InstaFeeds with your actual Instagram feed component */}
            </div>
          </div>
        </div>
      </section>

      {/* Footer section with background image */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        {/* Dark overlay on top of background image */}
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>

        {/* Container for footer content */}
        <div className="container relative z-20">
          <div className="grid grid-cols-12 gap-2">
            {/* Footer component (replace with your actual footer component) */}
            <div className="col-start-1 col-span-12">
              <Footer />
              {/* Comment: Replace Footer with your actual footer component */}
            </div>
          </div>
        </div>
      </section>

      {/* WhatsApp integration (replace with your implementation) */}
      <Whatsapp />
      {/* Comment: Replace Whatsapp with your actual WhatsApp integration */}

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </>
  );
};

export default PlanYourTrip;
