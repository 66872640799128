import React from "react";
import { IoCloseCircle } from "react-icons/io5";
import { FaCircleCheck } from "react-icons/fa6";

const Popup = ({ error, success, title, content, onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full z-[100] bg-black bg-opacity-60">
      <div className="center h-full">
        <div className="container">
          <div className="grid grid-cols-12 gap-2 h-full">
            <div className="relative col-start-1 col-span-12 sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-4 lg:col-span-6 xl:col-start-5 xl:col-span-4 bg-white rounded-xl h-60 md:h-[280px] xl:h-[320px] 2xl:h-[330px] flex flex-col items-center overflow-hidden">
              {error && (
                <div className="w-full h-[25%] bg-red-500 flex justify-center items-center ">
                  <IoCloseCircle
                    size={40}
                    className="text-white xl:hidden xl:invisible"
                  />
                  <IoCloseCircle
                    size={50}
                    className="text-white xl:block xl:visible"
                  />
                </div>
              )}
              {success && (
                <div className="w-full h-[25%] bg-green-500 flex justify-center items-center ">
                  <FaCircleCheck
                    size={40}
                    className="text-white xl:hidden xl:invisible"
                  />
                  <FaCircleCheck
                    size={50}
                    className="text-white xl:block xl:visible"
                  />
                </div>
              )}
              <div className="w-full h-[55%] flex flex-col justify-center items-center px-2">
                <h1 className="newL3SubHeader text-black text-center">
                  {title}
                </h1>
                <h1 className="newL2Content text-center px-2">{content}</h1>
              </div>
              <div className="w-full h-[20%] flex flex-col justify-center items-center">
                <button
                  className="px-6 py-2 bg-primary rounded-lg"
                  onClick={onClose}
                >
                  <h1 className="tagline text-black">Close</h1>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
