import React, { useRef, useEffect } from "react";

const ExpeditionSection = ({ title, expeditions, highlightDatesLogic }) => {
  const scrollRefs = useRef([]); // Array of refs for each scrollable date container

  useEffect(() => {
    if (!scrollRefs.current) return;

    const scrollSpeed = 1; // Pixels per scroll step
    const scrollInterval = 100; // Milliseconds per step
    const directions = Array(scrollRefs.current.length).fill("forward"); // Track scrolling direction

    const scrollIntervals = scrollRefs.current.map((scrollRef, index) => {
      if (!scrollRef) return null;

      return setInterval(() => {
        const container = scrollRef;

        if (directions[index] === "forward") {
          container.scrollLeft += scrollSpeed;

          // Use Math.ceil to ensure accurate comparisons
          if (
            Math.ceil(container.scrollLeft) >=
            container.scrollWidth - container.clientWidth
          ) {
            directions[index] = "backward";
          }
        } else {
          container.scrollLeft -= scrollSpeed;

          // Use Math.floor to ensure accurate comparisons
          if (Math.floor(container.scrollLeft) <= 0) {
            directions[index] = "forward";
          }
        }
      }, scrollInterval);
    });

    // Cleanup intervals on unmount
    return () => {
      scrollIntervals.forEach((interval) => {
        if (interval) clearInterval(interval);
      });
    };
  }, []);

  return (
    <section className="center bg-black">
      <div className="container">
        <div className="grid grid-cols-12 gap-4">
          <h1 className="newHeader col-start-1 col-span-12 text-primary text-center">
            {title}
          </h1>

          {expeditions.map((expedition, index) => {
            const highlightedDates = highlightDatesLogic(expedition);

            return (
              <div
                key={index}
                className="col-span-12 sm:col-span-6 lg:col-span-4 h-fit bg-white flex flex-col overflow-hidden rounded-lg margin"
              >
                {/* Expedition Image */}
                <div className="h-[250px] w-full">
                  <img
                    src={expedition.image}
                    alt={expedition.title}
                    className="h-full w-full object-cover"
                    loading="lazy" // Lazy loading for the image
                  />
                </div>

                {/* Expedition Details */}
                <div className="px-4 w-full h-[40%] py-4">
                  <h1 className="newSubHeader md:newL2SubHeader lg:newL3SubHeader font-bold text-black md:text-black lg:text-black">
                    {expedition.title}
                  </h1>

                  <h1 className="newL2Content">{expedition.name}</h1>

                  {/* Dates Section */}
                  <div
                    ref={(el) => (scrollRefs.current[index] = el)} // Assign the ref
                    className="bg-gray-100 w-fit max-w-full h-fit mt-2 px-2 py-1 lg:py-2 rounded-md flex gap-1 overflow-x-auto no-scrollbar"
                  >
                    {highlightedDates.map((date, dateIndex) => (
                      <div
                        key={dateIndex}
                        className={`min-w-12 h-14 rounded-md flex flex-none flex-col justify-center items-center ${
                          date.isHighlighted ? "bg-primary" : ""
                        }`}
                      >
                        <h1 className="text-md font-bold">{date.day}</h1>
                        <h1 className="text-sm">{date.month}</h1>
                      </div>
                    ))}
                  </div>

                  {/* Action Buttons */}
                  <div className="mt-2 md:mt-3 lg:mt-4 w-full flex justify-between gap-4">
                    <a
                      href={`https://wa.me/918894412884?text=I%20want%20to%20book%20${expedition.title}`}
                      className="bg-primary flex-1 px-4 py-2 lg:py-1 lg:newContent rounded-md text-center newL3SubHeader  cursor-pointer flex items-center justify-center text-black md:text-black lg:text-black"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Book Now
                    </a>
                    <a
                      href={expedition.link}
                      className="border-2 border-primary flex-1 px-4 py-2 lg:py-1 lg:newContent rounded-md text-center newL3SubHeader cursor-pointer flex items-center justify-center text-black md:text-black lg:text-black"
                    >
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ExpeditionSection;
