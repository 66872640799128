import React, { useEffect, useRef, useState } from "react";

// Components
import HeroSection from "../components/HeroSection";
import NavigationBar from "../components/NavigationBar";
import InstaFeeds from "../components/InstaFeeds";
import Footer from "../components/Footer";
import Whatsapp from "../components/Whatsapp"; // Assuming this renders a WhatsApp button

// Data
import { GetInTouchHeroSection } from "../data/Home/dataStorage";

// Assets
import expedition from "../assets/home/hero/expedition.webp";

// Icons
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaLocationDot, FaSquareXTwitter } from "react-icons/fa6";

// Animation library
import AOS from "aos";
import "aos/dist/aos.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import Popup from "../components/Popup";
import { Helmet } from "react-helmet";
import UpcomingTours from "../components/UpcomingTours";
import UpcomingToursIcon from "../components/UpcomingToursIcon";
import { FaFacebookSquare, FaInstagram, FaYoutube } from "react-icons/fa";

const GetInTouch = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    tripType: "",
    country: "",
    selectedTrip: "",
    message: "",
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const requiredFields = [
      "fullName",
      "email",
      "phoneNumber",
      "tripType",
      "country",
      "selectedTrip",
      "message",
    ];

    // Check if all required fields are filled (excluding message)
    const isAllRequiredFilled = requiredFields.every(
      (field) => formData[field] !== ""
    );

    setIsDisabled(!isAllRequiredFilled); // Enable button only if all required fields are filled
  }, [formData]);

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  // Function to toggle popup visibility
  const toggleErrorPopup = () => setShowErrorPopup(false);
  const toggleSuccessPopup = () => setShowSuccessPopup(false);

  // Get currently log in user

  const [activeUser, setActiveUser] = useState("");

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(
      auth,
      (currentUser) => {
        if (currentUser) {
          // User is logged in
          setActiveUser(currentUser);
        } else {
          // User is not logged in (or error occurred)
          setActiveUser(null);
        }
      },
      []
    );

    // Cleanup function to prevent memory leaks
    return () => unsubscribe();
  }, []);

  const formRef = useRef(null);

  async function handleSubmit(event) {
    event.preventDefault();
    console.log("Form submitted:", formData);
    // Submit form data to your backend here

    if (activeUser != null) {
      try {
        const db = getFirestore();

        await addDoc(collection(db, "getInTouch"), {
          ...formData,
        });

        setShowSuccessPopup(true);
        // Clear form fields after successful submission
        setFormData({
          fullName: "",
          email: "",
          phoneNumber: "",
          tripType: "",
          country: "",
          selectedTrip: "",
          message: "",
        });
      } catch (error) {
        console.error("Error saving review details:", error);
      }
    } else {
      setShowErrorPopup(true);
    }
    sendDataToGoogleSheet();
  }

  const sendDataToGoogleSheet = () => {
    if (activeUser != null) {
      fetch(
        "https://script.google.com/macros/s/AKfycbx7oC9J5EG_6wz3cmn2VbzHMUXcqbhkHTcd1cWirnn_cA5C7aU7HxQRsZikjYyoGQZ8/exec",
        {
          method: "POST",
          body: new FormData(formRef.current),
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          alert(data.msg);
        })
        .catch((err) => console.log(err));
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Helmet>
        <title>White Expedition - Get In Touch</title>
        <meta
          name="description"
          content="Ready to climb your dream peak? Contact White Expedition today! Get in touch to inquire about climbs, dates, and booking your adventure."
        />
      </Helmet>

      {/* Hero Section  */}
      <HeroSection carouselData={GetInTouchHeroSection} />

      {/* Navigation bar Section  */}
      <section className="center">
        <NavigationBar />
      </section>

      {/* About Company Section */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newContent col-start-1 col-span-12 text-center margin">
              At White Expedition, we prioritize your experience throughout your
              entire adventure. We are dedicated to providing exceptional
              service, ensuring you have all the support you need before,
              during, and after your trip. Our highly responsive customer
              support team is readily available 24/7 to address any questions or
              concerns you may have. Whether a quick phone call or a convenient
              text message, we'll ensure prompt and professional assistance to
              keep your travel plans worry-free.
            </h1>
          </div>
        </div>
      </section>

      {/* Contact Details Section */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-start-1 col-span-12 sm:col-start-1 sm:col-span-10 lg:col-start-1 lg:col-span-6">
              <h1 className="newHeader text-primary col-start-1 col-span-12 sm:col-start-2 sm:col-span-10 md:col-start-1 md:col-span-6">
                White Expedition
              </h1>
              <div className="col-start-1 col-span-12 sm:col-start-2 sm:col-span-10 md:col-start-1 md:col-span-6 flex justify-start items-center space-x-2 lg:space-x-3 mt-2">
                <IoCall size={25} className="text-primary" />
                <div className="flex flex-col space-y-1">
                  <h1 className="newL3SubHeader font-bold">Call us</h1>
                  <h1 className="newContent text-white">+91 8580659518</h1>
                </div>
              </div>
              <div className="col-start-1 col-span-12 sm:col-start-2 sm:col-span-10 md:col-start-1 md:col-span-6 flex justify-start items-center space-x-2 lg:space-x-3 mt-2">
                <MdEmail size={25} className="text-primary" />
                <div className="flex flex-col space-y-1">
                  <h1 className="newL3SubHeader font-bold">Email us</h1>
                  <h1 className="newContent text-white text-wrap">
                    whiteexpeditionoffice@gmail.com
                  </h1>
                </div>
              </div>
              <div className="col-start-1 col-span-12 sm:col-start-2 sm:col-span-10 md:col-start-1 md:col-span-6 flex justify-start items-center space-x-2 lg:space-x-3 mt-2">
                <FaLocationDot size={25} className="text-primary" />
                <div className="flex flex-col space-y-1">
                  <h1 className="newL3SubHeader font-bold">Reach to us</h1>
                  <h1 className="newContent text-white">
                    VPO Junga Shimla, 171218
                  </h1>
                </div>
              </div>
            </div>

            {/* Talk to export  */}
            <div className=" col-start-1 col-span-12 sm:col-start-1 sm:col-span-10 lg:col-start-7 lg:col-span-6 margin lg:m-0">
              <h1 className="newHeader text-primary col-start-1 col-span-12 sm:col-start-2 sm:col-span-10 md:col-start-7 md:col-span-6">
                Talk to Experts
              </h1>
              <div className="col-start-1 col-span-12 sm:col-start-2 sm:col-span-10 md:col-start-7 md:col-span-6 flex justify-start items-center space-x-2 lg:space-x-3 mt-2">
                <IoCall size={25} className="text-primary" />
                <div className="flex flex-col space-y-1">
                  <h1 className="newL3SubHeader font-bold">
                    Ricky Mountaineer
                  </h1>
                  <h1 className="newContent text-white">+91 8894412884</h1>
                </div>
              </div>
              <div className="col-start-1 col-span-12 sm:col-start-2 sm:col-span-10 md:col-start-7 md:col-span-6 flex justify-start items-center space-x-2 lg:space-x-3 mt-2">
                <IoCall size={25} className="text-primary" />
                <div className="flex flex-col space-y-1">
                  <h1 className="newL3SubHeader font-bold">Nikita Thakur</h1>
                  <h1 className="newContent text-white">+91 7807360003</h1>
                </div>
              </div>
              <div className="col-start-1 col-span-12 sm:col-start-2 sm:col-span-10 md:col-start-7 md:col-span-6 flex justify-start items-center space-x-2 lg:space-x-3 mt-2">
                <IoCall size={25} className="text-primary" />
                <div className="flex flex-col space-y-1">
                  <h1 className="newL3SubHeader font-bold">Shubham </h1>
                  <h1 className="newContent text-white">+91 9878136133</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Get In Touch Form Section  */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-center margin">
              Get in Touch
            </h1>
            <form ref={formRef} className="col-start-1 col-span-12">
              <div className="grid grid-cols-12 gap-2">
                {/* Full Name */}
                <div className="col-start-1 col-span-12 lg:col-start-1 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Full Name :</span>
                    <input
                      required
                      type="text"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div>
                {/* Email Address */}
                <div className="col-start-1 col-span-12 lg:col-start-7 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Email Address :</span>
                    <input
                      required
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div>
                {/* Phone Number */}
                <div className="col-start-1 col-span-12 lg:col-start-1 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Phone Number :</span>
                    <input
                      required
                      type="text"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div>
                {/* Select Trip Type */}
                <div className="col-start-1 col-span-12 lg:col-start-7 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Select Trip Type :</span>
                    <select
                      required
                      name="tripType"
                      value={formData.tripType}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    >
                      <option value="">-- Please Select --</option>
                      <option value="expedition">Expedition</option>
                      <option value="pure_adrenaline">Pure Adrenaline</option>
                      <option value="trekking">Trekking</option>
                      <option value="wildlife_photography">
                        Wildlife Photography
                      </option>
                      <option value="adventure_tour">Adventure Tours</option>
                    </select>
                  </div>
                </div>
                {/* Country */}
                <div className="col-start-1 col-span-12 lg:col-start-1 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Country :</span>
                    <input
                      required
                      type="text"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div>
                <div className="col-start-1 col-span-12 lg:col-start-7 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Select Trip :</span>
                    <select
                      required
                      name="selectedTrip"
                      value={formData.selectedTrip}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    >
                      <option value="">
                        -- Please Select Trip Type First --
                      </option>
                      {/* Populate options based on selected tripType (optional) */}
                      {formData.tripType === "expedition" && (
                        <>
                          <option value="Friendship Peak">
                            Friendship Peak
                          </option>
                          <option value="Yunam Peak">Yunam Peak</option>
                          <option value="Hanuman Tibba">Hanuman Tibba</option>
                          <option value="Deo Tibba">Deo Tibba</option>
                          <option value="Shitidhar">Shitidhar</option>
                          <option value="Ladakhi">Ladakhi</option>
                          <option value="Lady Of Keylong">
                            Lady of Keylong
                          </option>
                          <option value="Kangyatse 1">Kangyatse 1</option>
                          <option value="Kangyatse 2">Kangyatse 2</option>
                          <option value="Ut Kangri">Ut Kangri</option>
                          <option value="Mentok Kangri">Mentok Kangri</option>
                          <option value="Chau Chau Kang Nilda">
                            Chau Chau Kang Nilda
                          </option>
                          <option value="Leo Purgyil">Leo Purgyil</option>
                          <option value="Manirang">Manirang</option>
                          <option value="Kanamo">Kanamo</option>
                          <option value="Nun">Nun</option>
                          <option value="Kun">Kun</option>
                          <option value="Kiagar Ri">Kiagar Ri</option>
                          <option value="Spangnak Ri">Spangnak Ri</option>
                          <option value="P6250">P6250</option>
                          <option value="Chamaer Kangri">Chamaer Kangri</option>
                          <option value="Lungser Kangri">Lungser Kangri</option>
                          <option value="Lagpo">Lagpo</option>
                          <option value="Chalung">Chalung</option>
                          <option value="Dhukule">Shukule</option>
                          <option value="Cb_13">CB-13</option>
                          <option value="Menthosa">Menthosa</option>
                        </>
                      )}
                      {formData.tripType === "pure_adrenaline" && (
                        <>
                          <option value="Papsura">Papsura</option>
                          <option value="Reo Purgyil">Reo Purgyil</option>
                          <option value="Indrasan">Indrasan</option>
                          <option value="Makar Beh">Makar Beh</option>
                        </>
                      )}
                      {formData.tripType === "trekking" && (
                        <>
                          <option value="Pin Parvati Pass Trek">
                            Pin Parvati Pass Trek
                          </option>
                          <option value="Parang La Trek">Parang La Trek</option>
                          <option value="Auden's Col Trek">
                            Auden's Col Trek
                          </option>
                          <option value="Kalindi Khal Trek">
                            Kalindi Khal Trek
                          </option>
                          <option value="Kang La Trek">Kang La Trek</option>
                          <option value="Goecha La Trek">Goecha La Trek</option>
                          <option value="Bara Bangal Trek">
                            Bara Bangal Trek
                          </option>
                          <option value="Leh Lamayuru Trek">
                            Leh Lamayuru Trek
                          </option>
                          <option value="Markha Valley Trek">
                            Markha Valley Trek
                          </option>
                          <option value="Spituk To Matho Trek">
                            Spituk To Matho Trek
                          </option>
                          <option value="Padum To Darcha Trek">
                            Padum To Darcha Trek
                          </option>
                          <option value="Leh To Tso Morori Trek">
                            Leh To Tso Morori Trek
                          </option>
                          <option value="Nubra Valley Trek">
                            Nubra Valley Trek
                          </option>
                        </>
                      )}
                      {formData.tripType === "wildlife_photography" && (
                        <>
                          <option value="Snow Leopard Winter Spiti">
                            Snow Leopard Winter Spiti
                          </option>
                        </>
                      )}
                      {formData.tripType === "adventure_tour" && (
                        <>
                          <option value="Spiti Valley Overlanding">
                            Spiti Valley Overlanding
                          </option>
                          <option value="Ladakh Overlanding">
                            ladakh Overlanding
                          </option>
                          <option value="Zanskar Overlanding">
                            Zanskar Overlanding
                          </option>
                          <option value="Sach Pas Kishtwar Overlanding">
                            Sach Pas Kishtwar Overlanding
                          </option>
                        </>
                      )}
                    </select>
                  </div>
                </div>
                {/* Review */}
                <div className="col-start-1 col-span-12 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Message :</span>
                    <textarea
                      type="text"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                      rows={4}
                    />
                  </div>
                </div>
                {/* Submit */}
                <div className="col-start-1 col-span-12 mt-4">
                  <div className="flex flex-col">
                    <button
                      className={`bg-primary p-2 newL3SubHeader font-bold text-black hover:cursor-pointer rounded-md hover:outline-primary ${
                        isDisabled
                          ? "disabled opacity-50 hover:cursor-not-allowed"
                          : ""
                      }`}
                      disabled={isDisabled}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      {/* Map Section */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-primary text-center">
              White Expedition
            </h1>
            <div className="col-start-1 col-span-12 aspect-video rounded-lg overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2417.5643419963153!2d77.1921256095162!3d31.027579136484572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sVPO%20Junga%20Shimla%2C%20171218!5e0!3m2!1sen!2sin!4v1710601311674!5m2!1sen!2sin"
                className="w-full h-full border-2 border-black"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="White Expedition Office Location"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      {/* Instagram Section */}
      <section className="center  bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2 ">
            <h1
              className="newHeader col-start-1 col-span-12 text-center"
              //
              //
            >
              Photo Gallery
            </h1>
            {/* <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="newL3SubHeader  text-primary">
                @white.expedition
              </h1>
            </a> */}
            <div className="col-span-12 flex justify-center items-center space-x-3">
              <a
                href="https://www.facebook.com/share/19E5pajnVe/?mibextid=wwXIfr"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our facebook page
                </h1>
              </a>
              <a
                href="https://www.twitter.com/@WhiteExped79673"
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareXTwitter
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our twitter page
                </h1>
              </a>
              <a
                href="https://www.instagram.com/white.expedition?igsh=dHZiNXZwOGtyMTN0"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our instagram page
                </h1>
              </a>
              <a
                href="https://www.youtube.com/@White.Expeditions"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="text-primary cursor-pointer" size={25} />
                <h1 className="invisible hidden">
                  Click here to see our youtube page
                </h1>
              </a>
            </div>
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
            </div>
          </div>
        </div>
      </section>

      {/* Footer section */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>
        <div className="center">
          <div className="container relative z-20">
            <div className="grid grid-cols-12 gap-2">
              <div className="col-start-1 col-span-12">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* WhatsApp Section */}
      <Whatsapp />

      {showErrorPopup && (
        <Popup
          error={true}
          success={false}
          title={"Sign in Required"}
          content={
            "Sign in to your account to proceed. To access this feature, please sign in to your account."
          }
          onClose={toggleErrorPopup}
        />
      )}

      {showSuccessPopup && (
        <Popup
          error={false}
          success={true}
          title={"Review Submitted"}
          content={
            "Thank you for your review! It has been submitted successfully."
          }
          onClose={toggleSuccessPopup}
        />
      )}

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </>
  );
};

export default GetInTouch;
