import React from "react";
import { GiBarracksTent } from "react-icons/gi";

const UpcomingToursIcon = (props) => {
  return (
    <>
      <div
        className="fixed w-16 h-16 bottom-24 left-5 z-40 bg-primary p-2 rounded-full shadow-md  cursor-pointer flex justify-center items-center hidden"
        onClick={props.open}
      >
        <GiBarracksTent size={30} className="text-black" />
      </div>
    </>
  );
};

export default UpcomingToursIcon;
