import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, addDoc, collection } from "firebase/firestore";

// Components
import HeroSection from "../../components/HeroSection";
import NavigationBar from "../../components/NavigationBar";
import InstaFeeds from "../../components/InstaFeeds";
import Footer from "../../components/Footer";
import Popup from "../../components/Popup";
import Whatsapp from "../../components/Whatsapp";

import { FaLocationCrosshairs, FaSquareXTwitter } from "react-icons/fa6";
import { GiDuration } from "react-icons/gi";
import { IoIosSpeedometer } from "react-icons/io";
import { PiMountainsFill } from "react-icons/pi";
import {
  FaCloudSunRain,
  FaFacebookSquare,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaCircleInfo } from "react-icons/fa6";

import { individualExpedition, thingstocarray } from "./dataStorage";
import expedition from "../../assets/home/hero/expedition.webp";

import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import UpcomingTours from "../../components/UpcomingTours";
import UpcomingToursIcon from "../../components/UpcomingToursIcon";

const IndividualExpedition = () => {
  const navigate = useNavigate();
  const { individualExpeditionType } = useParams();

  const FilterIndividualExpedition = individualExpedition.find(
    (exp) => exp.individualExpeditionType === individualExpeditionType
  );

  const [thingsToKnow, setThingsToKnow] = useState({
    whatsIncluded: true,
    whatsExcluded: false,
    thingsToCarry: false,
  });

  const handleItemClick = (item) => {
    setThingsToKnow((prevThingsToKnow) => ({
      ...prevThingsToKnow,
      whatsIncluded: false,
      whatsExcluded: false,
      thingsToCarry: false,
      [item]: true,
    }));
  };

  const oddExpedition = FilterIndividualExpedition.expeditionDate.filter(
    (item) => item.id % 2 === 1
  );
  const evenExpedition = FilterIndividualExpedition.expeditionDate.filter(
    (item) => item.id % 2 === 0
  );
  const firstArray = FilterIndividualExpedition.expeditionDate.filter(
    (_, index) => (index + 1) % 3 === 1
  );
  const secondArray = FilterIndividualExpedition.expeditionDate.filter(
    (_, index) => (index + 1) % 3 === 2
  );
  const thirdArray = FilterIndividualExpedition.expeditionDate.filter(
    (_, index) => (index + 1) % 3 === 0
  );

  useEffect(() => {
    AOS.init();
  }, []);

  const [formData, setFormData] = useState({
    fullName: "",
    ratingStar: "",
    selectedTrip: "",
    message: "",
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const requiredFields = [
      "fullName",
      "email",
      "ratingStar",
      "tripType",
      "country",
      "selectedTrip",
      "message",
    ];

    console.log(formData);

    // Check if all required fields are filled (excluding message)
    const isAllRequiredFilled = requiredFields.every(
      (field) => formData[field] !== ""
    );

    setIsDisabled(!isAllRequiredFilled); // Enable button only if all required fields are filled
  }, [formData]);

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  // Function to toggle popup visibility
  const toggleErrorPopup = () => setShowErrorPopup(false);
  const toggleSuccessPopup = () => setShowSuccessPopup(false);

  // Get currently log in user

  const [activeUser, setActiveUser] = useState("");

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(
      auth,
      (currentUser) => {
        if (currentUser) {
          // User is logged in
          setActiveUser(currentUser);
        } else {
          // User is not logged in (or error occurred)
          setActiveUser(null);
        }
      },
      []
    );

    // Cleanup function to prevent memory leaks
    return () => unsubscribe();
  }, []);

  const formRef = useRef(null);

  async function handleSubmit(event) {
    event.preventDefault();
    console.log("Form submitted:", formData);
    // Submit form data to your backend here

    try {
      const db = getFirestore();

      await addDoc(collection(db, "reviews"), {
        ...formData,
      });

      setShowSuccessPopup(true);

      console.log(formData);

      // Clear form fields after successful submission
      setFormData({
        fullName: "",
        email: "",
        ratingStar: "",
        tripType: "",
        country: "",
        selectedTrip: "",
        message: "",
      });
    } catch (error) {
      console.error("Error saving review details:", error);
    }

    // if (activeUser != null) {
    //   try {
    //     const db = getFirestore();

    //     await addDoc(collection(db, "reviews"), {
    //       ...formData,
    //     });

    //     setShowSuccessPopup(true);

    //     console.log(formData);

    //     // Clear form fields after successful submission
    //     setFormData({
    //       fullName: "",
    //       email: "",
    //       ratingStar: "",
    //       tripType: "",
    //       country: "",
    //       selectedTrip: "",
    //       message: "",
    //     });
    //   } catch (error) {
    //     console.error("Error saving review details:", error);
    //   }
    // } else {
    //   setShowErrorPopup(true);
    // }
    sendDataToGoogleSheet();
  }

  const sendDataToGoogleSheet = () => {
    if (activeUser != null) {
      fetch(
        "https://script.google.com/macros/s/AKfycbx7tlmyPC58iTj-AjQwcI0HWQCWAf2MfNQ-FpdFzlTw1iLlMwdlLNKGhlxhA1Eds35G/exec",
        {
          method: "POST",
          body: new FormData(formRef.current),
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log(data);
          alert(data.msg);
        })
        .catch((err) => console.log(err));
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  const highlightDatesLogic = (expedition) => {
    const today = new Date();

    const updatedDates = expedition.expeditionDate.map((date) => {
      const fullDate = new Date(
        today.getFullYear(),
        new Date(`${date.month} 1, 2000`).getMonth(),
        parseInt(date.day, 10)
      );

      // If the date is in the past (considering year), move it to next year
      if (fullDate < today) {
        fullDate.setFullYear(today.getFullYear() + 1);
      }

      return { ...date, fullDate };
    });

    const firstUpcomingDate = updatedDates
      .filter((date) => date.fullDate >= today)
      .sort((a, b) => a.fullDate - b.fullDate)[0];

    return updatedDates.map((date) => ({
      ...date,
      isHighlighted:
        date.fullDate.getTime() === firstUpcomingDate?.fullDate.getTime(),
    }));
  };

  return (
    <div className="relative">
      <Helmet>
        <title>
          White Expedition - {}
          {FilterIndividualExpedition.individualExpeditionName}
        </title>
        <meta
          name="description"
          content={`Conquer ${FilterIndividualExpedition.individualExpeditionName} with White Expedition! Embark on an unforgettable guided climb to the majestic ${FilterIndividualExpedition.individualExpeditionName} in Himachal Pradesh.`}
        />
      </Helmet>

      {/* Hero Section  */}
      <HeroSection carouselData={FilterIndividualExpedition.carouselData} />

      {/* Navigation bar Section  */}
      <section className="center">
        <NavigationBar />
      </section>

      {/* Expedition Basic Information  */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-primary text-center">
              {FilterIndividualExpedition.individualExpeditionName}
            </h1>
            <h1 className="newContent col-start-1 col-span-12 text-center margin">
              {FilterIndividualExpedition.mainExpeditionContent}
            </h1>
          </div>
        </div>
      </section>

      {/* Expedition Neccessary Section  */}
      <section className="center bg-black">
        <div className="container ">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-start-1 col-span-6 sm:col-start-1 sm:col-span-4 xl:col-start-2 xl:col-span-2 flex flex-col justify-start items-center margin ">
              <FaLocationCrosshairs className="text-primary " size={60} />
              <h1 className="newL3SubHeader text-center text-primary">
                Location
              </h1>
              <h1 className="newL3SubHeader text-center">
                {FilterIndividualExpedition.location}
              </h1>
            </div>
            <div className="col-start-7 col-span-6 sm:col-start-5 sm:col-span-4 xl:col-start-4 xl:col-span-2 flex flex-col justify-start items-center margin">
              <GiDuration className="text-primary " size={60} />
              <h1 className="newL3SubHeader text-center text-primary">
                Duration
              </h1>
              <h1 className="newL3SubHeader text-center">
                {FilterIndividualExpedition.duration}
              </h1>
            </div>
            <div className="col-start-1 col-span-6 sm:col-start-9 sm:col-span-4 xl:col-start-6 xl:col-span-2 flex flex-col justify-start items-center margin">
              <IoIosSpeedometer className="text-primary " size={60} />
              <h1 className="newL3SubHeader text-center text-primary">Grade</h1>
              <h1 className="newL3SubHeader text-center">
                {FilterIndividualExpedition.grade}
              </h1>
            </div>
            <div className="col-start-7 col-span-6 sm:col-start-3 sm:col-span-4 xl:col-start-8 xl:col-span-2 flex flex-col justify-start items-center margin">
              <PiMountainsFill className="text-primary " size={60} />
              <h1 className="newL3SubHeader text-center text-primary">
                Max Altitude
              </h1>
              <h1 className="newL3SubHeader text-center">
                {FilterIndividualExpedition.maxAltitude}
              </h1>
            </div>
            <div className="col-start-4 col-span-6 sm:col-start-7 sm:col-span-4 xl:col-start-10 xl:col-span-2 flex flex-col justify-start items-center margin">
              <FaCloudSunRain className="text-primary " size={60} />
              <h1 className="newL3SubHeader text-center text-primary">
                Season
              </h1>
              <h1 className="newL3SubHeader text-center">
                {FilterIndividualExpedition.bestSeason}
              </h1>
            </div>
            <div className="col-start-1 col-span-12 flex justify-center items-center margin">
              <h1 className="newContent text-primary text-center">
                {FilterIndividualExpedition.experienceRequired}
              </h1>
            </div>
          </div>
        </div>
      </section>

      {/* Expedition Experience Section  */}
      {/* <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newContent col-start-1 col-span-12 text-center margin">
              {FilterIndividualExpedition.aboutExpeditionExperienced}
            </h1>
          </div>
        </div>
      </section> */}

      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-center text-primary">
              Why With Us?
            </h1>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center font-bold margin">
                Expertise and Experience
              </h1>
              <h1 className="newL2Content text-center mt-1">
                Our team comprises seasoned mountaineers, adventure leaders, and
                filmmakers who have explored the most challenging terrains. From
                unclimbed peaks to iconic summits, we’ve achieved milestones
                that reflect our unmatched expertise.
              </h1>
            </div>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center font-bold margin">
                Personalized Adventures
              </h1>
              <h1 className="newL2Content text-center mt-1">
                Every expedition is thoughtfully planned to ensure it aligns
                with your dreams and abilities. Whether you’re a beginner or a
                seasoned climber, our itineraries are crafted to offer the right
                mix of challenge, safety, and fulfillment.
              </h1>
            </div>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center font-bold margin">
                Safety First, Always
              </h1>
              <h1 className="newL2Content text-center mt-1">
                We prioritize your safety above all. With certified guides,
                medical assistance, and top-notch equipment, you can focus on
                the journey while we ensure your well-being.
              </h1>
            </div>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center font-bold margin">
                Local Knowledge, Global Standards
              </h1>
              <h1 className="newL2Content text-center mt-1">
                Our deep connection with the Himalayas and our team’s local
                expertise give us an edge. We combine this with international
                standards of planning, professionalism, and service to offer you
                the best of both worlds.
              </h1>
            </div>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center font-bold margin">
                Sustainable Adventure
              </h1>
              <h1 className="newL2Content text-center mt-1">
                We believe in leaving no trace. Our expeditions are designed to
                minimize environmental impact and support local communities,
                ensuring your adventure is meaningful and eco-friendly.
              </h1>
            </div>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center font-bold margin">
                Unforgettable Experiences
              </h1>
              <h1 className="newL2Content text-center mt-1">
                From breathtaking views and thrilling climbs to stories shared
                around a campfire, we promise memories that last a lifetime.
                With us, it’s more than just an expedition—it’s an experience
                that transforms.
              </h1>
            </div>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-start-5 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center font-bold margin">
                Recognized Excellence
              </h1>
              <h1 className="newL2Content text-center mt-1">
                We’ve been part of record-breaking climbs, pioneering
                expeditions, and successful group adventures. Our track record
                speaks for itself, and our clients’ stories of satisfaction say
                it all.
              </h1>
            </div>
          </div>
        </div>
      </section>

      {/* Expedition Itinerary  */}
      <section className="center bg-black">
        <div className="container ">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-center margin text-primary">
              Expedition Itinerary
            </h1>
            <div className="col-start-1 relative col-span-12 margin">
              {/* Line Html  */}
              <div className="absolute w-[25%] h-full border-r-2 border-textColor bg-transparent"></div>
              <div className="absolute w-[75%] h-full border-l-2 border-textColor right-0 bg-transparent"></div>

              {/* Map function starts here  */}
              {FilterIndividualExpedition.itineraryData.map(
                (dayData, index) => (
                  <>
                    {/* Days, Icon & Title  */}
                    <div className="w-auto h-auto mb-2" key={index}>
                      <div className="h-auto min-h-7 sm:min-h-8 md:min-h-10 lg:min-h-12 xl:min-h-14 flex">
                        <div className="w-[20%] h-full  flex justify-center items-start">
                          <div className="flex flex-col sm:flex-row sm:space-x-1 sm:mt-1 md:mt-1.5 justify-center items-center text-center">
                            <h1 className="newContent text-center font-bold text-primary">
                              Day
                            </h1>
                            <h1 className="newContent text-center font-bold text-primary">
                              {dayData.day}
                            </h1>
                          </div>
                        </div>
                        <div className="w-[10%] relative h-full flex">
                          <div className="w-1/2 h-full border-r border-textColor"></div>
                          <div className="w-1/2 h-full border-l border-textColor "></div>
                          <div className="absolute w-full h-full z-10  bg-transparent flex justify-center items-start">
                            <div className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12  bg-primary flex justify-center items-center rounded-full">
                              <div className="w-4 h-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 bg-white rounded-full"></div>
                            </div>
                          </div>
                        </div>
                        <div className="w-[70%] h-full flex items-start flex-wrap px-2">
                          <h1 className="newL3SubHeader mt-0.5 sm:mt-1 md:mt-1.5 font-bold text-primary">
                            {dayData.title}
                          </h1>
                        </div>
                      </div>
                    </div>

                    {dayData.details.map((detail, detailIndex) => (
                      <>
                        {/* sub Itinerary map start here  */}
                        <div className="w-auto h-auto mb-2" key={detailIndex}>
                          <div className="h-auto min-h-7 sm:min-h-8 md:min-h-10 lg:min-h-12 xl:min-h-14 flex">
                            <div className="w-[20%] h-full  flex justify-center items-start">
                              <div className="flex flex-col sm:flex-row sm:space-x-1 sm:mt-1 md:mt-1.5 justify-center items-center text-center">
                                {/* <h1 className="newContent text-center font-bold">Day</h1>
                      <h1 className="newContent text-center font-bold">1-2</h1> */}
                              </div>
                            </div>
                            <div className="w-[10%] relative h-full flex">
                              <div className="w-1/2 h-full border-r border-textColor"></div>
                              <div className="w-1/2 h-full border-l border-textColor "></div>
                              <div className="absolute w-full h-full z-10  bg-transparent flex justify-center items-start">
                                <div className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 bg-transparent flex justify-center items-center rounded-full">
                                  <div className="w-4 h-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 bg-primary rounded-full"></div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[70%] h-full flex items-start flex-wrap px-2">
                              <h1 className="newL3SubHeader mt-0.5 sm:mt-1 md:mt-1.5 ">
                                {detail}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                )
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Expedition Things to Know Section  */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-center margin">
              Things to know
            </h1>
            <div
              className={`col-start-1 col-span-4 border border-textColor h-10 xl:h-12 hover:cursor-pointer flex justify-center items-center rounded-md hover:bg-black group ${
                thingsToKnow.whatsIncluded ? "bg-black" : "bg-transparent"
              } margin `}
              onClick={() => handleItemClick("whatsIncluded")}
            >
              <h1
                className={`newContent text-center group-hover:text-primary ${
                  thingsToKnow.whatsIncluded ? "text-primary" : "text-textColor"
                }`}
              >
                Inclusion
              </h1>
            </div>
            <div
              className={`col-start-5 col-span-4 border border-textColor h-10 xl:h-12 hover:cursor-pointer flex justify-center items-center rounded-md hover:bg-black group ${
                thingsToKnow.whatsExcluded ? "bg-black" : "bg-transparent"
              } margin`}
              onClick={() => handleItemClick("whatsExcluded")}
            >
              <h1
                className={`newContent text-center group-hover:text-primary ${
                  thingsToKnow.whatsExcluded ? "text-primary" : "text-textColor"
                }`}
              >
                Exclusion
              </h1>
            </div>
            <div
              className={`col-start-9 col-span-4 border border-textColor h-10 xl:h-12 hover:cursor-pointer flex justify-center items-center rounded-md hover:bg-black group ${
                thingsToKnow.thingsToCarry ? "bg-black" : "bg-transparent"
              } margin`}
              onClick={() => handleItemClick("thingsToCarry")}
            >
              <h1
                className={`newContent text-center group-hover:text-primary ${
                  thingsToKnow.thingsToCarry ? "text-primary" : "text-textColor"
                }`}
              >
                Carry
              </h1>
            </div>

            {thingsToKnow.whatsIncluded && (
              <>
                {/* Inclusion  */}
                <div className="col-start-1 relative col-span-12 margin">
                  {/* Line Html  */}
                  <div className="absolute w-[10%] h-full border-r-2 border-textColor bg-transparent"></div>
                  <div className="absolute w-[90%] h-full border-l-2 border-textColor right-0 bg-transparent"></div>

                  {/* Map function starts here  */}
                  {FilterIndividualExpedition.inclusion.map(
                    (Include, index) => (
                      <>
                        {/* Days, Icon & Title  */}
                        <div className="w-auto h-auto mb-2" key={index}>
                          <div className="h-auto min-h-7 sm:min-h-8 md:min-h-10 lg:min-h-12 xl:min-h-14 flex">
                            <div className="w-[5%] h-full  flex justify-center items-start">
                              {/* <div className="flex flex-col sm:flex-row sm:space-x-1 sm:mt-1 md:mt-1.5 justify-center items-center text-center">
                            <h1 className="newContent text-center font-bold text-primary">
                              Day
                            </h1>
                            <h1 className="newContent text-center font-bold text-primary">
                              {dayData.day}
                            </h1>
                          </div> */}
                            </div>
                            <div className="w-[10%] relative h-full flex">
                              <div className="w-1/2 h-full border-r border-textColor"></div>
                              <div className="w-1/2 h-full border-l border-textColor "></div>
                              <div className="absolute w-full h-full z-10  bg-transparent flex justify-center items-start">
                                <div className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12  bg-green-500 flex justify-center items-center rounded-full">
                                  <FaCircleCheck className="w-4 h-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 text-white rounded-full"></FaCircleCheck>
                                </div>
                              </div>
                            </div>
                            <div className="w-[85%] h-full flex items-start flex-wrap px-2">
                              <h1 className="newL3SubHeader mt-0.5 sm:mt-1 md:mt-1.5 font-bold text-green-500">
                                {Include.sectionTitle}
                              </h1>
                            </div>
                          </div>
                        </div>

                        {Include.content.map((detail, detailIndex) => (
                          <>
                            {/* sub Itinerary map start here  */}
                            <div
                              className="w-auto h-auto mb-2"
                              key={detailIndex}
                            >
                              <div className="h-auto min-h-7 sm:min-h-8 md:min-h-10 lg:min-h-12 xl:min-h-14 flex">
                                <div className="w-[5%] h-full  flex justify-center items-start">
                                  <div className="flex flex-col sm:flex-row sm:space-x-1 sm:mt-1 md:mt-1.5 justify-center items-center text-center"></div>
                                </div>
                                <div className="w-[10%] relative h-full flex">
                                  <div className="w-1/2 h-full border-r border-textColor"></div>
                                  <div className="w-1/2 h-full border-l border-textColor "></div>
                                  <div className="absolute w-full h-full z-10  bg-transparent flex justify-center items-start">
                                    <div className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 bg-transparent flex justify-center items-center rounded-full">
                                      <div className="w-4 h-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 bg-[#1e1e1e] rounded-full"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-[85%] h-full flex items-start flex-wrap px-2">
                                  <h1 className="newL3SubHeader mt-0.5 sm:mt-1 md:mt-1.5 ">
                                    {detail}
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    )
                  )}
                </div>
              </>
            )}

            {thingsToKnow.whatsExcluded && (
              <>
                {/* Exclusion  */}
                <div className="col-start-1 relative col-span-12 margin">
                  {/* Line Html  */}
                  <div className="absolute w-[10%] h-full border-r-2 border-textColor bg-transparent"></div>
                  <div className="absolute w-[90%] h-full border-l-2 border-textColor right-0 bg-transparent"></div>

                  {/* Map function starts here  */}
                  {FilterIndividualExpedition.exclusion.map(
                    (Include, index) => (
                      <>
                        {/* Days, Icon & Title  */}
                        <div className="w-auto h-auto mb-2" key={index}>
                          <div className="h-auto min-h-7 sm:min-h-8 md:min-h-10 lg:min-h-12 xl:min-h-14 flex">
                            <div className="w-[5%] h-full  flex justify-center items-start">
                              {/* <div className="flex flex-col sm:flex-row sm:space-x-1 sm:mt-1 md:mt-1.5 justify-center items-center text-center">
                            <h1 className="newContent text-center font-bold text-primary">
                              Day
                            </h1>
                            <h1 className="newContent text-center font-bold text-primary">
                              {dayData.day}
                            </h1>
                          </div> */}
                            </div>
                            <div className="w-[10%] relative h-full flex">
                              <div className="w-1/2 h-full border-r border-textColor"></div>
                              <div className="w-1/2 h-full border-l border-textColor "></div>
                              <div className="absolute w-full h-full z-10  bg-transparent flex justify-center items-start">
                                <div className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12  bg-red-500 flex justify-center items-center rounded-full">
                                  <AiFillCloseCircle className="w-4 h-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 text-white rounded-full"></AiFillCloseCircle>
                                </div>
                              </div>
                            </div>
                            <div className="w-[85%] h-full flex items-start flex-wrap px-2">
                              <h1 className="newL3SubHeader mt-0.5 sm:mt-1 md:mt-1.5 font-bold text-red-500">
                                {Include.sectionTitle}
                              </h1>
                            </div>
                          </div>
                        </div>

                        {Include.content.map((detail, detailIndex) => (
                          <>
                            {/* sub Itinerary map start here  */}
                            <div
                              className="w-auto h-auto mb-2"
                              key={detailIndex}
                            >
                              <div className="h-auto min-h-7 sm:min-h-8 md:min-h-10 lg:min-h-12 xl:min-h-14 flex">
                                <div className="w-[5%] h-full  flex justify-center items-start">
                                  <div className="flex flex-col sm:flex-row sm:space-x-1 sm:mt-1 md:mt-1.5 justify-center items-center text-center"></div>
                                </div>
                                <div className="w-[10%] relative h-full flex">
                                  <div className="w-1/2 h-full border-r border-textColor"></div>
                                  <div className="w-1/2 h-full border-l border-textColor "></div>
                                  <div className="absolute w-full h-full z-10  bg-transparent flex justify-center items-start">
                                    <div className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 bg-transparent flex justify-center items-center rounded-full">
                                      <div className="w-4 h-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 bg-[#1e1e1e] rounded-full"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-[85%] h-full flex items-start flex-wrap px-2">
                                  <h1 className="newL3SubHeader mt-0.5 sm:mt-1 md:mt-1.5 ">
                                    {detail}
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    )
                  )}
                </div>
              </>
            )}

            {thingsToKnow.thingsToCarry && (
              <>
                {/* Things to carry  */}
                <div className="col-start-1 relative col-span-12 margin">
                  {/* Line Html  */}
                  <div className="absolute w-[10%] h-full border-r-2 border-textColor bg-transparent"></div>
                  <div className="absolute w-[90%] h-full border-l-2 border-textColor right-0 bg-transparent"></div>

                  {/* Map function starts here  */}
                  {thingstocarray.map((Include, index) => (
                    <>
                      {/* Days, Icon & Title  */}
                      <div className="w-auto h-auto mb-2" key={index}>
                        <div className="h-auto min-h-7 sm:min-h-8 md:min-h-10 lg:min-h-12 xl:min-h-14 flex">
                          <div className="w-[5%] h-full  flex justify-center items-start">
                            {/* <div className="flex flex-col sm:flex-row sm:space-x-1 sm:mt-1 md:mt-1.5 justify-center items-center text-center">
                            <h1 className="newContent text-center font-bold text-primary">
                              Day
                            </h1>
                            <h1 className="newContent text-center font-bold text-primary">
                              {dayData.day}
                            </h1>
                          </div> */}
                          </div>
                          <div className="w-[10%] relative h-full flex">
                            <div className="w-1/2 h-full border-r border-textColor"></div>
                            <div className="w-1/2 h-full border-l border-textColor "></div>
                            <div className="absolute w-full h-full z-10  bg-transparent flex justify-center items-start">
                              <div className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12  bg-yellow-500 flex justify-center items-center rounded-full">
                                <FaCircleInfo className="w-4 h-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 text-white rounded-full"></FaCircleInfo>
                              </div>
                            </div>
                          </div>
                          <div className="w-[85%] h-full flex items-start flex-wrap px-2">
                            <h1 className="newL3SubHeader mt-0.5 sm:mt-1 md:mt-1.5 font-bold text-yellow-500">
                              {Include.sectionTitle}
                            </h1>
                          </div>
                        </div>
                      </div>

                      {Include.content.map((detail, detailIndex) => (
                        <>
                          {/* sub Itinerary map start here  */}
                          <div className="w-auto h-auto mb-2" key={detailIndex}>
                            <div className="h-auto min-h-7 sm:min-h-8 md:min-h-10 lg:min-h-12 xl:min-h-14 flex">
                              <div className="w-[5%] h-full  flex justify-center items-start">
                                <div className="flex flex-col sm:flex-row sm:space-x-1 sm:mt-1 md:mt-1.5 justify-center items-center text-center"></div>
                              </div>
                              <div className="w-[10%] relative h-full flex">
                                <div className="w-1/2 h-full border-r border-textColor"></div>
                                <div className="w-1/2 h-full border-l border-textColor "></div>
                                <div className="absolute w-full h-full z-10  bg-transparent flex justify-center items-start">
                                  <div className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 bg-transparent flex justify-center items-center rounded-full">
                                    <div className="w-4 h-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 bg-[#1e1e1e] rounded-full"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="w-[85%] h-full flex items-start flex-wrap px-2">
                                <h1 className="newL3SubHeader mt-0.5 sm:mt-1 md:mt-1.5 ">
                                  {detail}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      {/* Expedition Youtube Section */}
      <section className="center bg-black">
        <div className="container ">
          <div className="grid grid-cols-12 gap-2">
            {FilterIndividualExpedition.youtubeLink !== "" ? (
              <div className="col-start-1 col-span-12 aspect-video rounded-lg overflow-hidden">
                <iframe
                  className="w-full h-full"
                  src={FilterIndividualExpedition.youtubeLink}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            ) : (
              <div className="col-start-1 col-span-12 aspect-video flex justify-center items-center">
                <h1 className="newHeader col-start-1 col-span-12 text-center margin">
                  Stay tuned for the upcoming video.
                </h1>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Expedition Bookking Section */}
      <section className="center bg-gray-200">
        <div className="container">
          <div className="grid-cols-12 gap-2 grid">
            <h1 className="newHeader col-start-1 col-span-12 text-center margin">
              Book your spot
            </h1>
            <div className="col-span-12">
              <div className="flex justify-center items-center">
                <div className="bg-gray-100 flex w-fit max-w-full h-fit mt-2 px-2 py-1 lg:py-2 rounded-md gap-1 overflow-x-auto no-scrollbar">
                  {highlightDatesLogic(FilterIndividualExpedition).map(
                    (date, index) => (
                      <div
                        key={index}
                        className={`min-w-12 h-14 rounded-md flex flex-none flex-col justify-center items-center ${
                          date.isHighlighted ? "bg-primary" : ""
                        }`}
                      >
                        <h1 className="text-md font-bold">{date.day}</h1>
                        <h1 className="text-sm">{date.month}</h1>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <h1
              className="px-4 py-2 bg-primary newContent col-start-2 col-span-10 sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 xl:col-start-5 xl:col-span-4 margin rounded-lg flex justify-center items-center text-black hover:cursor-pointer font-bold"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/getInTouch");
              }}
            >
              BOOK YOUR SPOT
            </h1>
          </div>
        </div>
      </section>

      {/* Expedition Customization Section */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newL2SubHeader col-start-1 col-span-12 lg:col-start-1 lg:col-span-8 lg:flex lg:justify-center lg:items-center text-center">
              Want to <span className="text-primary lg:mx-2">Personalize</span>{" "}
              Your Own Adventure?
            </h1>
            <h1
              className="newContent col-start-3 col-span-8 sm:col-start-4 sm:col-span-6 lg:col-start-9 lg:col-span-4 2xl:col-start-9 2xl:col-span-3 flex justify-center items-center px-2 py-2 rounded-md text-black font-bold text-center bg-primary margin lg:m-0 hover:cursor-pointer"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/planYourTrip");
              }}
            >
              PERSONALIZE YOUR TRIP
            </h1>
          </div>
        </div>
      </section>

      {/* Add review Section */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-center margin">
              Add Your Review
            </h1>
            <form ref={formRef} className="col-start-1 col-span-12">
              <div className="grid grid-cols-12 gap-2">
                {/* Full Name */}
                <div className="col-start-1 col-span-12 lg:col-start-1 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Full Name :</span>
                    <input
                      required
                      type="text"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div>
                {/* Email Address */}
                {/* <div className="col-start-1 col-span-12 lg:col-start-7 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Email Address :</span>
                    <input
                      required
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div> */}
                {/* Star Rating */}
                <div className="col-start-1 col-span-12 lg:col-start-7 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Star Rating :</span>
                    <select
                      required
                      name="ratingStar"
                      value={formData.ratingStar}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 h-12 newContent rounded-md hover:outline-primary"
                    >
                      <option value="">-- Please Select --</option>
                      <option value="5">5</option>
                      <option value="4.5">4.5</option>
                      <option value="4">4</option>
                      <option value="3.5">3.5</option>
                      <option value="3">3</option>
                      <option value="2.5">2.5</option>
                      <option value="2">2</option>
                      <option value="1.5">1.5</option>
                      <option value="1">1</option>
                    </select>
                  </div>
                </div>
                {/* Select Trip Type */}
                {/* <div className="col-start-1 col-span-12 lg:col-start-7 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Select Trip Type :</span>
                    <select
                      required
                      name="tripType"
                      value={formData.tripType}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 h-12 newContent rounded-md hover:outline-primary"
                    >
                      <option value="">-- Please Select --</option>
                      <option value="expedition">Expedition</option>
                      <option value="pure_adrenaline">Pure Adrenaline</option>
                      <option value="trekking">Trekking</option>
                      <option value="wildlife_photography">
                        Wildlife Photography
                      </option>
                      <option value="adventure_tour">Adventure Tours</option>
                    </select>
                  </div>
                </div> */}
                {/* Country */}
                {/* <div className="col-start-1 col-span-12 lg:col-start-1 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Country :</span>
                    <input
                      required
                      type="text"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div> */}
                <div className="col-start-1 col-span-12 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Select Trip :</span>
                    <select
                      required
                      name="selectedTrip"
                      value={formData.selectedTrip}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 h-12 newContent rounded-md hover:outline-primary"
                    >
                      <option value="">
                        -- Please Select Trip Type First --
                      </option>
                      <option value="Winter Expedition Kiagar Ri">
                        Winter Expedition Kiagar Ri
                      </option>
                      <option value="Winter Spiti 4x4">Winter Spiti 4x4</option>
                      <option value="2x5000 (Firendship Peak & Shitidhar)">
                        2x5000 (Firendship Peak & Shitidhar)
                      </option>
                      <option value="2x6000 (Kangyatse 1 & Kangyatse 2)">
                        2x6000 (Kangyatse 1 & Kangyatse 2)
                      </option>
                      <option value="3x6000M (Kiager Ri, Spangnak Ri & Zes Ri)">
                        3x6000M (Kiager Ri, Spangnak Ri & Zes Ri)
                      </option>
                      <option value="Ski Down">Ski Down</option>
                      <option value="Ramjak">Ramjak</option>
                      <option value="Kangju Kangri">Kangju Kangri</option>
                      <option value="Friendship Peak">Friendship Peak</option>
                      <option value="Yunum Peak">Yunum Peak</option>
                      <option value="Hanuman Tibba">Hanuman Tibba</option>
                      <option value="Deo Tibba">Deo Tibba</option>
                      <option value="Shitidhar">Shitidhar</option>
                      <option value="Ladakhi">Ladakhi</option>
                      <option value="Lady Of Keylong">Lady Of Keylong</option>
                      <option value="Kangyatse 1">Kangyatse 1</option>
                      <option value="Kangyatse 2">Kangyatse 2</option>
                      <option value="Ut Kangri">Ut Kangri</option>
                      <option value="Mentok Kangri">Mentok Kangri</option>
                      <option value="Chau Chau Kang Nilda">
                        Chau Chau Kang Nilda
                      </option>
                      <option value="Leo Purgyil">Leo Purgyil</option>
                      <option value="Manirang">Manirang</option>
                      <option value="Kanamo">Kanamo</option>
                      <option value="Nun">Nun</option>
                      <option value="Kun">Kun</option>
                      <option value="Kiagar Ri">Kiagar Ri</option>
                      <option value="Spangnak Ri">Spangnak Ri</option>
                      <option value="P6250">P6250</option>
                      <option value="Chamaer Kangri">Chamaer Kangri</option>
                      <option value="Lungser Kangri">Lungser Kangri</option>
                      <option value="Lagpo">Lagpo</option>
                      <option value="Chalung">Chalung</option>
                      <option value="Shukule">Shukule</option>
                      <option value="CB 13">CB 13</option>
                      <option value="Menthosa">Menthosa</option>
                      <option value="Papsura">Papsura</option>
                      <option value="Reo Purgyil">Reo Purgyil</option>
                      <option value="Indrasan">Indrasan</option>
                      <option value="Makar Beh">Makar Beh</option>
                      <option value="Pin Parvati Pass Trek">
                        Pin Parvati Pass Trek
                      </option>
                      <option value="Parang La Trek">Parang La Trek</option>
                      <option value="Auden's Col Trek">Auden's Col Trek</option>
                      <option value="Kalindi Khal Trek">
                        Kalindi Khal Trek
                      </option>
                      <option value="Kang La Trek">Kang La Trek</option>
                      <option value="Goecha La Trek">Goecha La Trek</option>
                      <option value="Bara Bangal Trek">Bara Bangal Trek</option>
                      <option value="Leh Lamayuru Trek">
                        Leh Lamayuru Trek
                      </option>
                      <option value="Markha Valley Trek">
                        Markha Valley Trek
                      </option>
                      <option value="Spituk To Matho Trek">
                        Spituk To Matho Trek
                      </option>
                      <option value="Padum To Darcha Trek">
                        Padum To Darcha Trek
                      </option>
                      <option value="Leh To Tso Morori Trek">
                        Leh To Tso Morori Trek
                      </option>
                      <option value="Nubra Valley Trek">
                        Nubra Valley Trek
                      </option>
                      <option value="Snow Leopard Winter Spiti">
                        Snow Leopard Winter Spiti
                      </option>
                      <option value="Spiti Valley Overlanding">
                        Spiti Valley Overlanding
                      </option>
                      <option value="Ladakh Overlanding">
                        ladakh Overlanding
                      </option>
                      <option value="Zanskar Overlanding">
                        Zanskar Overlanding
                      </option>
                      <option value="Sach Pas Kishtwar Overlanding">
                        Sach Pas Kishtwar Overlanding
                      </option>
                      {/* Populate options based on selected tripType (optional) */}
                      {/* {formData.tripType === "expedition" && (
                        <>
                          <option value="Friendship Peak">
                            Friendship Peak
                          </option>
                          <option value="Yunum Peak">Yunum Peak</option>
                          <option value="Hanuman Tibba">Hanuman Tibba</option>
                          <option value="Deo Tibba">Deo Tibba</option>
                          <option value="Shitidhar">Shitidhar</option>
                          <option value="Ladakhi">Ladakhi</option>
                          <option value="Lady Of Keylong">
                            Lady Of Keylong
                          </option>
                          <option value="Kangyatse 1">Kangyatse 1</option>
                          <option value="Kangyatse 2">Kangyatse 2</option>
                          <option value="Ut Kangri">Ut Kangri</option>
                          <option value="Mentok Kangri">Mentok Kangri</option>
                          <option value="Chau Chau Kang Nilda">
                            Chau Chau Kang Nilda
                          </option>
                          <option value="Leo Purgyil">Leo Purgyil</option>
                          <option value="Manirang">Manirang</option>
                          <option value="Kanamo">Kanamo</option>
                          <option value="Nun">Nun</option>
                          <option value="Kun">Kun</option>
                          <option value="Kiagar Ri">Kiagar Ri</option>
                          <option value="Spangnak Ri">Spangnak Ri</option>
                          <option value="P6250">P6250</option>
                          <option value="Chamaer Kangri">Chamaer Kangri</option>
                          <option value="Lungser Kangri">Lungser Kangri</option>
                          <option value="Lagpo">Lagpo</option>
                          <option value="Chalung">Chalung</option>
                          <option value="Shukule">Shukule</option>
                          <option value="CB 13">CB 13</option>
                          <option value="Menthosa">Menthosa</option>
                        </>
                      )}
                      {formData.tripType === "pure_adrenaline" && (
                        <>
                          <option value="Papsura">Papsura</option>
                          <option value="Reo Purgyil">Reo Purgyil</option>
                          <option value="Indrasan">Indrasan</option>
                          <option value="Makar Beh">Makar Beh</option>
                        </>
                      )}
                      {formData.tripType === "trekking" && (
                        <>
                          <option value="Pin Parvati Pass Trek">
                            Pin Parvati Pass Trek
                          </option>
                          <option value="Parang La Trek">Parang La Trek</option>
                          <option value="Auden's Col Trek">
                            Auden's Col Trek
                          </option>
                          <option value="Kalindi Khal Trek">
                            Kalindi Khal Trek
                          </option>
                          <option value="Kang La Trek">Kang La Trek</option>
                          <option value="Goecha La Trek">Goecha La Trek</option>
                          <option value="Bara Bangal Trek">
                            Bara Bangal Trek
                          </option>
                          <option value="Leh Lamayuru Trek">
                            Leh Lamayuru Trek
                          </option>
                          <option value="Markha Valley Trek">
                            Markha Valley Trek
                          </option>
                          <option value="Spituk To Matho Trek">
                            Spituk To Matho Trek
                          </option>
                          <option value="Padum To Darcha Trek">
                            Padum To Darcha Trek
                          </option>
                          <option value="Leh To Tso Morori Trek">
                            Leh To Tso Morori Trek
                          </option>
                          <option value="Nubra Valley Trek">
                            Nubra Valley Trek
                          </option>
                        </>
                      )}
                      {formData.tripType === "wildlife_photography" && (
                        <>
                          <option value="Snow Leopard Winter Spiti">
                            Snow Leopard Winter Spiti
                          </option>
                        </>
                      )}
                      {formData.tripType === "adventure_tour" && (
                        <>
                          <option value="Spiti Valley Overlanding">
                            Spiti Valley Overlanding
                          </option>
                          <option value="Ladakh Overlanding">
                            ladakh Overlanding
                          </option>
                          <option value="Zanskar Overlanding">
                            Zanskar Overlanding
                          </option>
                          <option value="Sach Pas Kishtwar Overlanding">
                            Sach Pas Kishtwar Overlanding
                          </option>
                        </>
                      )} */}
                    </select>
                  </div>
                </div>
                {/* Review */}
                <div className="col-start-1 col-span-12 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Message :</span>
                    <textarea
                      type="text"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                      rows={4}
                    />
                  </div>
                </div>
                {/* Submit */}
                <div className="col-start-1 col-span-12 mt-4">
                  <div className="flex flex-col">
                    <button
                      className={`bg-primary p-2 newL3SubHeader font-bold text-black hover:cursor-pointer rounded-md hover:outline-primary ${
                        isDisabled
                          ? "disabled opacity-50 hover:cursor-not-allowed"
                          : ""
                      }`}
                      disabled={isDisabled}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      {/* Instagram Section */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2 ">
            <h1 className="newHeader col-start-1 col-span-12 text-center">
              Photo Gallery
            </h1>
            {/* <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="newL3SubHeader  text-primary">
                @white.expedition
              </h1>
            </a> */}
            <div className="col-span-12 flex justify-center items-center space-x-3">
              <a
                href="https://www.facebook.com/share/19E5pajnVe/?mibextid=wwXIfr"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our facebook page
                </h1>
              </a>
              <a
                href="https://www.twitter.com/@WhiteExped79673"
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareXTwitter
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our twitter page
                </h1>
              </a>
              <a
                href="https://www.instagram.com/white.expedition?igsh=dHZiNXZwOGtyMTN0"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our instagram page
                </h1>
              </a>
              <a
                href="https://www.youtube.com/@White.Expeditions"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="text-primary cursor-pointer" size={25} />
                <h1 className="invisible hidden">
                  Click here to see our youtube page
                </h1>
              </a>
            </div>
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
            </div>
          </div>
        </div>
      </section>

      {/* Footer section */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>
        <div className="center">
          <div className="container relative z-20">
            <div className="grid grid-cols-12 gap-2">
              <div className="col-start-1 col-span-12">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Whatsapp />

      {showErrorPopup && (
        <Popup
          error={true}
          success={false}
          title={"Sign in Required"}
          content={
            "Sign in to your account to proceed. To access this feature, please sign in to your account."
          }
          onClose={toggleErrorPopup}
        />
      )}

      {showSuccessPopup && (
        <Popup
          error={false}
          success={true}
          title={"Review Submitted"}
          content={
            "Thank you for your review! It has been submitted successfully."
          }
          onClose={toggleSuccessPopup}
        />
      )}

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </div>
  );
};

export default IndividualExpedition;
