import {
  expedition5000MHeroSection,
  expedition6000MHeroSection,
  expedition7000MHeroSection,
  expeditionPureAdrenalineHeroSection,
  expeditionTrekkingHeroSection,
} from "../../data/Home/dataStorage";

export const thingstocarray = [
  {
    sectionTitle: "Layered Clothing",
    content: [
      "Thermal innerwear: Base layers to keep you warm.",
      "Insulating layers: Fleece or down jackets for insulation.",
      "Waterproof and windproof outer shell: Protects against snow and wind.",
    ],
  },
  {
    sectionTitle: "Headgear",
    content: [
      "Insulated hat or balaclava: Covers the head and ears.",
      "Sunglasses with UV protection: Protects eyes from snow glare.",
    ],
  },
  {
    sectionTitle: "Hand Protection",
    content: [
      "Insulated gloves or mittens: Keep hands warm and dry.",
      "Hand warmers: Disposable heat packs for extra warmth.",
    ],
  },
  {
    sectionTitle: "Footwear",
    content: [
      "Insulated and waterproof boots: Provide warmth and protection.",
      "Gaiters: Cover the lower leg and prevent snow from entering the boots.",
      "Extra pairs of moisture-wicking socks: Keep feet dry and warm.",
    ],
  },
  {
    sectionTitle: "Backpack",
    content: [
      "Large, sturdy backpack: To carry all your essentials.",
      "Rain cover or waterproof liners: Protects gear from snow and moisture.",
      "Day bag (20-30 ltr)",
    ],
  },
  {
    sectionTitle: "Trekking Poles",
    content: [
      "Adjustable trekking poles: Assist in maintaining balance in snow-covered terrain.",
    ],
  },
  {
    sectionTitle: "Nutrition",
    content: [
      "High-energy snacks: Nuts, energy bars, and chocolate.",
      "Hydration system: Insulated water bottles or a hydration pack.",
    ],
  },
  {
    sectionTitle: "Emergency Equipment",
    content: ["Personal medicines if required."],
  },
  {
    sectionTitle: "Lighting",
    content: [
      "Headlamp or flashlight: Essential for visibility in the dark.",
      "Extra batteries: Ensure you have enough power.",
    ],
  },
  {
    sectionTitle: "Communication",
    content: [
      "Fully charged cell phone: Keep it in a warm place to preserve battery life.",
      "Portable charger: Extra power for electronic devices.",
    ],
  },
  {
    sectionTitle: "Personal Items",
    content: [
      "Sunscreen: Even in winter, UV rays can be intense at high altitudes.",
      "Lip balm: Prevents chapped lips in cold, dry conditions.",
      "Personal hygiene items: Toothbrush, toothpaste, etc.",
    ],
  },
  {
    sectionTitle: "Documentation & Cash",
    content: [
      "ID, permits, and emergency contact information.",
      "Remote areas don’t have ATMs, so please carry enough cash.",
    ],
  },
];

export const individualExpedition = [
  {
    individualExpeditionType: "friendshipPeak",
    individualExpeditionName: "Friendship Peak",
    carouselData: expedition5000MHeroSection,
    location: "Manali",
    duration: "7 Days",
    grade: "Beginners",
    maxAltitude: "5289 m or 17, 349 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Friendship Peak, a mountain in Manali, Himachal Pradesh stands tall at 5,289 meters. It's like a magnet for folks who love trekking. Though it’s a trekking peak, the duration to reach the top, the gradient, and the efforts required, make it a worthy first climb for those who wish to enter the world of climbing. Picture this: snow all around, stunning views, and glaciers showing off. So, if you're into trekking with awesome scenery and good vibes, Friendship Peak in the Himalayas is your kind of adventure – no fancy words needed. Just a solid mountain and a great time!",
    aboutExpeditionExperienced:
      "Climb Friendship Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali (elevation: 2,050 meters)",
          "Transfer to the accommodation",
          "Settle in and rest",
          "Evening briefing and gear check by the trek leader",
        ],
      },
      {
        day: "2",
        title: "Drive to Dhundi and trek to Lady Leg base camp.",
        details: [
          "Drive to Dhundi",
          "Trek to Lady leg (5-6 hours)",
          "Elevation gain 3800m",
        ],
      },
      {
        day: "3",
        title: "Short trek for acclimatisation",
        details: [
          "Acclimatisation and briefing",
          "Trekking time: Approximately 3-4 hours",
        ],
      },
      {
        day: "4",
        title: "Trek to Advanced Base Camp (ABC)",
        details: [
          "Trek to Advanced Base Camp (elevation: 4,300 meters)",
          "Elevation gain 4300m",
          "Enjoy scenic views",
        ],
      },
      {
        day: "5",
        title: "Summit Day - Friendship Peak",
        details: [
          "Early morning start for Friendship Peak summit (elevation: 5,289 meters)",
          "Summit and descend to ABC or Lady Leg based on conditions",
          "Trekking time: Approximately 10-12 hours",
        ],
      },
      {
        day: "6",
        title: "Reserve Day / Descend to Manali",
        details: [
          "Keep the day as a reserve for contingencies or gradual descent",
          "If the summit was on Day 5, descend to Manali",
          "Overnight stay in Manali or at a suitable location on the descent",
        ],
      },
      {
        day: "7",
        title: "Departure from Manali",
        details: [
          "Drive back to Manali",
          "Arrive in Manali and conclude the trek",
          "Depart from Manali with memories of the Friendship Peak expedition",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Accommodation in Manali on Day 1 and Day 6 (as applicable)",
          "Camping arrangements during the trek",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and hygienic meals throughout the expedition",
          "Breakfast, lunch, and dinner are provided during the expedition days",
          "Special arrangements for dietary preferences if communicated in advance",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: ["Transportation to and from climbing locations."],
      },
      {
        sectionTitle: "Guides and Support",
        content: [
          "Experienced trek leader and support staff",
          "Local guides familiar with the terrain",
          "Support staff for camping and logistics",
        ],
      },
      {
        sectionTitle: "Permits and Entrance Fees",
        content: [
          "All necessary permits and entry fees",
          "Permissions for climbing in the region",
          "Indian Mountaineering Foundation (IMF) registration",
        ],
      },
      {
        sectionTitle: "Safety and Medical Assistance",
        content: [
          "First aid kit and basic medical assistance",
          "Emergency evacuation plans in place",
          "Experienced guides trained in high-altitude rescue operations",
        ],
      },
      {
        sectionTitle: "Equipment",
        content: [
          "Tents, sleeping bags, and other camping equipment",
          "Safety equipment for the climb",
          "Personal gear check and assistance",
        ],
      },
      {
        sectionTitle: "Climbing Assistance",
        content: [
          "Guidance and support during the summit day",
          "Assistance with technical aspects of the climb",
        ],
      },
      {
        sectionTitle: "Documentation",
        content: [
          "Detailed itinerary and information before the climb",
          "Completion certificates for the participants",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking/climbing",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the climb organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the climb.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/csCpWrakV-M?si=dxCL8jIq4lOIOW0v",
    expeditionDate: [
      { day: "10", month: "MAY" },
      { day: "20", month: "MAY" },
      { day: "05", month: "JUN" },
      { day: "15", month: "JUN" },
      { day: "05", month: "SEP" },
      { day: "15", month: "SEP" },
      { day: "05", month: "OCT" },
      { day: "15", month: "OCT" },
    ],
  },
  {
    individualExpeditionType: "yunamPeak",
    individualExpeditionName: "Yunam Peak",
    carouselData: expedition6000MHeroSection,
    location: "Bharatpur, Himachal Pradesh",
    duration: "9 Days",
    grade: "Intermediate",
    maxAltitude: "6111 m or 20,013 ft",
    bestSeason: "June, July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Standing proudly at 6,100 meters, Yunam is a formidable yet captivating mountain in the Indian Himalayas. Nestled in the Lahaul region, this peak calls the adventure enthusiasts with its challenging ascent and breathtaking panoramic views. The climb to Yunam Peak is a test of both skill and endurance, rewarding climbers with glimpses of pristine glaciers and the rugged beauty of the surrounding landscape. 200 km north of Manali, on the way to Leh, this peak is almost in the middle of nowhere. The vast expanse of stunning and unforgiving Baralacha La (4320 m) is known for its notoriety when it comes to low oxygen levels. Low oxygen levels test the mettle of both, amateur and experienced climbers. This barren, windy, and isolated Yunam Peak is a worthy challenge to begin or establish yourself as a mountaineer.",
    aboutExpeditionExperienced:
      "Climb Yunam Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali",
          "Check into hotel",
          "Explore local surroundings and markets",
          "Enjoy scenic landscapes",
          "Acclimatize",
          "Evening meeting and briefing",
        ],
      },
      {
        day: "2",
        title: "Acclimatisation hike in Manali",
        details: [
          "Short hike to Lama dugh (4-5 hours)",
          "Elevation 2650m",
          "Enjoy scenic view",
        ],
      },
      {
        day: "3",
        title: "Manali to Bharatpur",
        details: [
          "63 km journey to Bharatpur Base Camp (3 hours)",
          "Cross Barlacha La (4230 m)",
          "Enjoy mesmerizing landscapes",
          "Arrive at base camp and spend the night",
        ],
      },
      {
        day: "4",
        title: "Acclimatisation day",
        details: [
          "load ferry to summit camp and back to base camp (5-6 hours)",
          "This process is crucial for adjusting to higher altitudes and ensuring a safer climb.",
        ],
      },
      {
        day: "5",
        title: "Climb and Set up Summit Camp (5300 m)",
        details: [
          "4-hour climb for summit camp",
          "Elevation gain 5300m",
          "Prepare for summit attempt the following night",
        ],
      },
      {
        day: "6",
        title: "Summit Day - Yunam Peak (6100 m)",
        details: [
          "Early morning ascent to Yunam Peak (around 2 am)",
          "Enjoy the sunrise from the top",
          "Summit the peak and descend (10-11 hours)",
          "Descend to Summit Camp/Base Camp (depending on conditions)",
        ],
      },
      {
        day: "7",
        title: "Bharatpur Base Camp to Manali Drive",
        details: [
          "6-7 hour drive back to Manali (200 km)",
          "Spend the night in a comfortable hotel",
        ],
      },
      {
        day: "8",
        title: "Departure from Manali",
        details: ["Bid farewell to Manali and conclude the expedition"],
      },
      {
        day: "Buffer",
        title: "Buffer Day for Summit",
        details: [
          "Flexibility for unexpected conditions or additional acclimatization",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Hotel accommodation in Manali on Day 1 and Day 7.",
          "Camp accommodation during the trek days.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and hygienic meals throughout the climb.",
          "Breakfast, lunch, and dinner are provided during the climbing days.",
          "Special arrangements for dietary preferences if communicated in advance.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: ["Transportation to and from climbing locations."],
      },
      {
        sectionTitle: "Guides and Support",
        content: [
          "Experienced climb leader and support staff.",
          "Local guides familiar with the terrain.",
          "Support staff for camping and logistics.",
        ],
      },
      {
        sectionTitle: "Permits and Entrance Fees",
        content: [
          "All necessary permits and entry fees.",
          "Permissions for climbing in the region.",
          "Indian Mountaineering Foundation (IMF) registration.",
        ],
      },
      {
        sectionTitle: "Safety and Medical Assistance",
        content: [
          "First aid kit and basic medical assistance.",
          "Emergency evacuation plans in place.",
          "Experienced guides trained in high-altitude rescue operations.",
        ],
      },
      {
        sectionTitle: "Equipment",
        content: [
          "Tents, sleeping bags, and other camping equipment.",
          "Safety equipment for the climb.",
          "Personal gear check and assistance.",
        ],
      },
      {
        sectionTitle: "Climbing Assistance",
        content: [
          "Guidance and support during the summit day.",
          "Assistance with technical aspects of the climb.",
        ],
      },
      {
        sectionTitle: "Documentation",
        content: [
          "Detailed itinerary and information before the climb.",
          "Completion certificates for the participants.",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies.",
          "Regular updates and briefings.",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking.",
          "Practices to minimize environmental impact.",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the climb organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/x8R0FbnCNks?si=SlakJB2gO2x6i88t",
    expeditionDate: [
      { day: "10", month: "JUN" },
      { day: "25", month: "JUN" },
      { day: "10", month: "JUL" },
      { day: "25", month: "JUL" },
      { day: "11", month: "AUG" },
      { day: "10", month: "SEP" },
      { day: "20", month: "OCT" },
      { day: "10", month: "OCT" },
    ],
  },
  {
    individualExpeditionType: "hanumanTibba",
    individualExpeditionName: "Hanuman Tibba",
    carouselData: expedition6000MHeroSection,
    location: "Manali, Himachal Pradesh",
    duration: "13 Days",
    grade: "Advanced",
    maxAltitude: "5892 M or 19330 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "Prior experience of 5000m/6000m climbs or multiple challenging treks",
    mainExpeditionContent:
      "Hanuman Tibba, the tallest peak of the Dhauladhar ranges is one of the most challenging peaks in the Manali region. Standing at an impressive 5,982 meters, it challenges mountaineers with its technical prowess and awe-inspiring altitude. Named after the legendary Hindu deity Hanuman, the peak demands both skill and determination to conquer its slopes. What makes it so challenging is the steep and infamous “Tentu Gully” loaded with rock fall sections that’ll get your adrenaline pumping like never before. The peak is a mix of rocky faces and icy ridges and the ascent promises a thrilling adventure for seasoned mountaineers. The summit rewards with a panoramic vista that stretches across the Himalayan range, a visual feast for those who brave its heights. Hanuman Tibba isn't just a peak; it's a calling for mountaineers seeking a blend of technical challenge and breathtaking views, making it a revered icon in the world of alpine pursuits.",
    aboutExpeditionExperienced:
      "Climb Hanuman Tibba with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "01",
        title: "Arrive in Manali (2050m)",
        details: [
          "Welcome to the scenic town of Manali. Arrive and settle in at the designated accommodation.",
          "Day dedicated to acclimatization and rest, crucial for adjusting to the altitude.",
          "Team briefing and completion of paperwork with the Indian Mountaineering Foundation (IMF) for necessary permits.",
        ],
      },
      {
        day: "02",
        title: "Solang Nala to Bakarthach (3000m)",
        details: [
          "Early morning departure for a picturesque drive to Dhundi, the starting point of the trek.",
          "Trek to Bakarthach, soaking in stunning mountain views with the iconic Hanuman Tibba in the backdrop.",
          "Enjoy the changing landscapes and the serenity of the trail.",
        ],
      },
      {
        day: "03",
        title: "Bakarthatch to Beas Kund (3600m)",
        details: [
          "Continue the trek towards Beas Kund Glacier, a journey filled with natural wonders.",
          "Reach the base of Tentu Pass, set against the awe-inspiring beauty of the Himalayan landscape.",
          "Camp at a strategic location, preparing for the challenges ahead.",
        ],
      },
      {
        day: "04",
        title: "Rest Day at Base Camp",
        details: [
          "A well-deserved complete rest day at Base Camp to recharge both physically and mentally.",
          "Technical gear distribution to ensure everyone is well-equipped for the upcoming ascent.",
          "General meeting to discuss the intricacies of the route and ensure everyone is on the same page.",
        ],
      },
      {
        day: "05",
        title: "Base Camp to Advanced Camp (4000m)",
        details: [
          "Embark on a challenging ascent with a steep snow slope leading to Advanced Camp.",
          "Halfway up the mountain, pitch tents in a strategic location for optimal acclimatization.",
        ],
      },
      {
        day: "06",
        title: "Advanced Camp to Camp 1 (4800m)",
        details: [
          "Face the challenging climb to Tentu Pass (5000m), a critical point in the expedition.",
          "Reach Camp 1 after overcoming the difficulties, marking significant progress.",
        ],
      },
      {
        day: "07",
        title: "Camp 1 to Summit Camp (5000m)",
        details: [
          "Traverse a flat glacier, arriving at the pivotal Summit Camp.",
          "Devote the day to preparations for the summit attempt, including rest and mental readiness.",
        ],
      },
      {
        day: "08",
        title: "Summit Attempt - Camp 1",
        details: [
          "Embark on the ultimate challenge with a midnight summit attempt.",
          "Revel in the breathtaking 360-degree panoramic views from the summit.",
          "Descend to Camp 1 after the summit.",
        ],
      },
      {
        day: "09",
        title: "Camp 1 to Advanced Camp",
        details: [
          "Return to Advanced Camp, allowing for much-needed rest and recovery.",
        ],
      },
      {
        day: "10",
        title: "Advanced Camp to Base Camp",
        details: [
          "Descend from Tentu Pass, appreciating the changing landscapes.",
          "Celebrate and feast at Base Camp.",
        ],
      },
      {
        day: "11",
        title: "Rest at Base Camp/ Buffer Day",
        details: [
          "Utilize this day as an extra day or buffer day, providing flexibility in case of weather-related issues during the summit.",
          "Complete rest at Base Camp to ensure everyone is in top condition for the journey ahead.",
        ],
      },
      {
        day: "12",
        title: "Base Camp to Manali",
        details: [
          "Trek down to Dhundi and drive back to Manali.",
          "Enjoy the feeling of reaching back to civilization after so many days.",
        ],
      },
      {
        day: "13",
        title: "Departure",
        details: [
          "Conclude the memorable expedition with a heartfelt farewell party in the vibrant town of Manali.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the Hanuman Tibba region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the Hanuman Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/SgiJGiRgFuQ?si=M5QIP7AafHwXj3qk",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "deoTibba",
    individualExpeditionName: "Deo Tibba",
    carouselData: expedition6000MHeroSection,
    location: "Manali, Himachal Pradesh",
    duration: "11+2 Days",
    grade: "Intermediate",
    maxAltitude: "6001 M or 19688 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "Prior experience of 5000m/6000m climbs or multiple challenging treks",
    mainExpeditionContent:
      "Mt. Deo Tibba, standing at 6,001 meters, is the second highest peak in the Pir Panjal range, located in Himachal Pradesh's Kullu valley. Locals believe it's a sacred gathering place for gods. Despite legends, the mountain is known for its unpredictable and extreme moods. The climb starts in Manali and is a serious mountaineering expedition with challenging features like vertical ice gullies and rocky sections. The journey to the base camp offers stunning landscapes, from meadows with colorful wildflowers to rocky terrain and snow-covered slopes.The climb includes navigating the challenging slope to Duhangan Col, known for its intimidating reputation. Mt. Deo Tibba's unique dome-shaped summit sets it apart, featuring a flat, plateau-like ice dome. The expedition demands technical skills, including using fixed ropes and mountaineering equipment for 60-70 degree gradients in vertical ice gullies. This expedition is for experienced trekkers with prior mountaineering knowledge, making it suitable for aspiring mountaineers aiming for heights beyond 6000 meters.",
    aboutExpeditionExperienced:
      "Climb Deo Tibba with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali",
        details: [
          "Reach Manali and check into your accommodation.",
          "Orientation session, equipment check, and final preparations for the trek.",
          "Overnight stay in Manali.",
        ],
      },
      {
        day: "2",
        title: "Manali – Road Head (2-hour drive) – Trek to Chika (10,100 ft)",
        details: [
          "Drive through scenic valleys to the trek starting point.",
          "Begin the trek to Chika, passing through dense forests and meadows.",
          "Overnight stay in tents at Chika.",
        ],
      },
      {
        day: "3",
        title: "Chika to Seri (12,800 ft)",
        details: [
          "Trek through lush greenery, crossing rivers and moraines.",
          "Reach Seri, a beautiful meadow surrounded by mountains.",
          "Overnight stay in tents at Seri.",
        ],
      },
      {
        day: "4",
        title: "Seri to Tenta Base Camp (14,200 ft)",
        details: [
          "Trek through boulder-strewn paths and alpine meadows.",
          "Reach Tenta Base Camp with stunning views of Deo Tibba and Indrasan peaks.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "5",
        title: "Rest and Acclimatization at Base Camp",
        details: [
          "Relax, acclimatize, and prepare for higher altitudes.",
          "Short hikes around the camp to aid acclimatization.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "6",
        title: "Base Camp to Summit Camp at Dhuangan Col (17,000 ft)",
        details: [
          "Trek through snowfields and challenging terrain to reach the summit camp.",
          "Set up camp at Dhuangan Col with incredible views of the surrounding peaks.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "7",
        title: "Rest and Acclimatization at Summit Camp",
        details: [
          "Rest day to recover and acclimatize at high altitude.",
          "Prepare for the summit push.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "8",
        title: "Summit Push (19,688 ft) and Return to Summit Camp",
        details: [
          "Start early for the summit push to Deo Tibba.",
          "Enjoy breathtaking views from the top and celebrate the climb.",
          "Descend back to Summit Camp.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "9",
        title: "Summit Camp to Base Camp",
        details: [
          "Trek back to Tenta Base Camp, retracing your steps.",
          "Relax and celebrate the successful summit.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "10",
        title: "Base Camp to Chika",
        details: [
          "Descend to Chika through scenic trails.",
          "Overnight stay in tents at Chika.",
        ],
      },
      {
        day: "11",
        title: "Chika to Road Head – Drive Back to Manali",
        details: [
          "Trek to the road head and drive back to Manali.",
          "Check into your accommodation and relax.",
        ],
      },
      {
        day: "Buffer Days (2 Days)",
        title: "Reserved for Unforeseen Delays",
        details: [
          "Reserved for unforeseen delays, such as bad weather or acclimatization issues.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the Deo Tibba expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Deo Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/KYbUv4NhYpw?si=bLBx88IRnAtj_0VP",
    expeditionDate: [
      { day: "10", month: "MAY" },
      { day: "15", month: "SEP" },
    ],
  },
  {
    individualExpeditionType: "shitidhar",
    individualExpeditionName: "Shitidhar",
    carouselData: expedition5000MHeroSection,
    location: "Manali, Himachal Pradesh",
    duration: "9 Days",
    grade: "Beginners",
    maxAltitude: "5250 M or 17224 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Explore the beginner-friendly world of mountaineering with Shitidhar Peak in Himachal Pradesh, India. This climb offers breathtaking panoramic views of Hanuman Tibba and the Dhauladhar and Pir Panjal Range. Trek through the scenic meadows of Bakarthach and Dhundi, with a backdrop of the Beas Kund Glacier. While fitness is crucial, basic knowledge of equipment like ice axes and crampons is required, and a short training will be provided before the technical ascent. Many trekkers with high-altitude experience choose Shitidhar as an introductory climb to mountaineering. Embark on this unforgettable adventure, filled with awe-inspiring landscapes and a sense of accomplishment at the summit. Gear up for an exciting journey to Mount Shitidhar, an ideal starting point for your mountaineering endeavors.",
    aboutExpeditionExperienced:
      "Climb Shitidhar with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali (2050m)",
        details: [
          "Arrive in Manali and check into the hotel.",
          "Briefing session about the expedition",
          "Gear check and preparation for the trek.",
          "Explore Manali or relax at leisure.",
          "Overnight Stay: Hotel in Manali.",
        ],
      },
      {
        day: "2",
        title:
          "Drive from Manali to Dhundi (3100m) and Trek to Lady Leg Camp (3700m)",
        details: [
          "Distance: 22 km drive + 4-5 km trek (~3-4 hours).",
          "Drive along the scenic Solang Valley to Dhundi.",
          "Begin the trek to Lady Leg Camp, crossing lush meadows and streams.",
          "Set up camp and enjoy views of Hanuman Tibba and Friendship Peak.",
          "Overnight Stay: Tents at Lady Leg Camp.",
        ],
      },
      {
        day: "3",
        title: "Acclimatization Walk around Lady Leg Camp",
        details: [
          "Explore the surrounding area for an acclimatization walk.",
          "Gain elevation to adapt to high altitude and return to camp.",
        ],
      },
      {
        day: "4",
        title: "Acclimatization & Load Ferry",
        details: [
          "Acclimatization activities.",
          "Load ferry to summit camp.",
          "Overnight stay at Lady Leg.",
        ],
      },
      {
        day: "5",
        title: "Lady Leg to Summit Camp (4,400 meters)",
        details: ["Trek to Summit Camp (2-3 hours)."],
      },
      {
        day: "6",
        title: "Summit Day - Shitidhar Peak (5,250 meters)",
        details: ["Trek to Shitidhar Peak and return to Lady Leg (8-9 hours)."],
      },
      {
        day: "7",
        title: "Lady Leg to Road-head, Drive to Manali",
        details: [
          "Trek down to Road-head.",
          "Drive back to Manali.",
          "Night stay in a hotel with Expedition Celebration Party!",
        ],
      },
      {
        day: "8",
        title: "Departure",
        details: [],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/fQRdBveRZVE?si=uO5LxRKgPPSHcA0L",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "ladakhi",
    individualExpeditionName: "Ladakhi",
    carouselData: expedition5000MHeroSection,
    location: "Manali, Himachal Pradesh",
    duration: "8+1 Days",
    grade: "Moderate",
    maxAltitude: "5577 m or 18300 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Ladakhi Peak, nestled in the Pir Panjal range of Himachal Pradesh, beckons adventure enthusiasts with its challenging trekking route. Named after the first Ladakhi climber, this moderate-level peak demands climbing experience, including the use of mountaineering equipment and traversing snow and ice. The journey begins with a 13 km drive to Solang Nallah, followed by a 2-hour trek to the picturesque meadow of Dhundi. From there, the route winds through green slopes to Bakar Thach, a shepherd encampment, offering a 2-3 hour scenic trek. Bakar Thach serves as the base for peaks like Shitidhar, Ladakhi, Friendship, Manali, and Hanuman Tibba. Continuing towards Beas Kund, a tiny religious lake, the base camp is established for peaks like Friendship, Ladakhi, and Hanuman Tibba. The ascent involves rock climbing, providing breathtaking views of Dhauladhar and Pir Panjal ranges, along with Indrasan and Deotibba peaks. After summiting Ladakhi Peak, the descent leads back to the base camp, followed by a return journey through Beas Kund, Dhundi, and Solang.",
    aboutExpeditionExperienced:
      "Climb Ladakhi with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali (2050m)",
        details: [
          "Arrive in Manali and meet the expedition team.",
          "Orientation and gear check.",
          "Rest and acclimatization.",
          "Overnight stay in Manali (hotel).",
        ],
      },
      {
        day: "2",
        title: "Manali to Dhundi and Trek to Bakarthach (3300m)",
        details: [
          "Drive from Manali to Dhundi (approx. 2 hours).",
          "Trek to Bakarthach through beautiful alpine meadows (4–5 hours).",
          "Set up camp and spend the night in tents.",
        ],
      },
      {
        day: "3",
        title: "Trek to Beas Kund Base Camp (3700m)",
        details: [
          "Trek to Beas Kund, a sacred glacial lake (4–5 hours).",
          "Explore the surroundings and acclimatize.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "4",
        title: "Trek to Advanced Camp (4200m)",
        details: [
          "Ascend to Advanced Camp (approx. 4200m).",
          "Technical briefing and equipment preparation.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "5",
        title: "Load Ferry to Summit Camp (4800m)",
        details: [
          "Carry loads and establish Summit Camp.",
          "Return to Advanced Camp for the night.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "6",
        title: "Move to Summit Camp (4800m)",
        details: [
          "Trek to Summit Camp with full gear.",
          "Final briefing and preparation for the summit attempt.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "7",
        title: "Summit Day (5300m)",
        details: [
          "Early morning push for Mt. Ladkahi Peak summit.",
          "Enjoy panoramic views of the Himalayas.",
          "Descend to Summit Camp.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "8",
        title: "Descend to Dhundi and Drive to Manali",
        details: [
          "Descend to Advanced Camp and further to Dhundi.",
          "Drive back to Manali.",
          "Celebrate the successful expedition.",
          "Overnight stay in Manali (hotel).",
        ],
      },
      {
        day: "Buffer Day",
        title: "Reserved for Unforeseen Delays",
        details: ["Reserved for unforeseen weather conditions or delays."],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "ladyOfKeylong",
    individualExpeditionName: "Lady Of Keylong",
    carouselData: expedition6000MHeroSection,
    location: "Lahaul, Himachal Pradesh",
    duration: "11 Days",
    grade: "Advanced",
    maxAltitude: "6061 M or 19886 ft",
    bestSeason: "June, July, August, September and October",
    experienceRequired:
      "Prior experience of 5000m/6000m climbs or multiple challenging treks",
    mainExpeditionContent:
      "The Lady of Keylong, a lesser-known peak in the challenging Lahaul and Spiti region, was named after British Lady Elashaingh during British rule. The Geological Survey of India also recognized it as the 'Lady of Keylong'. Despite its obscurity, the mountain's snow-covered terrain, interspersed with rocky patches, resembles a lady carrying a load.The climb is technically demanding, starting from Tinoo village near Keylong. The route involves campsites, strenuous terrain, severe weather, and high altitudes. After acclimatization, climbers face steep ascents, gullies with mixed terrain, glaciers, ice walls, and open crevasses. The final summit push, undertaken at night, involves traversing icy slopes, humps, and deep crevasses, reaching a height of approximately 6061m. The summit offers panoramic views of surrounding peaks and valleys. ",
    aboutExpeditionExperienced:
      "Climb Lady of Keylong with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali (2000m)",
        details: [
          "Arrive in Manali and check into your hotel.",
          "Team briefing, equipment check, and last-minute shopping.",
          "Overnight stay in Manali.",
        ],
      },
      {
        day: "2",
        title: "Manali to Tinno Village Base Camp (3 hours drive)",
        details: [
          "Drive through the scenic Lahaul Valley to Tinno Village (base camp).",
          "Set up the camp and enjoy the breathtaking views of the surrounding peaks.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "3",
        title: "Acclimatization and Rest Day",
        details: [
          "Explore the nearby areas for acclimatization.",
          "Short hikes to adjust to the altitude.",
          "Rest and prepare for the upcoming trek.",
        ],
      },
      {
        day: "4",
        title: "Trek to Camp 1 (3300m)",
        details: [
          "Start the trek on a steep and rocky path.",
          "Reach Camp 1 after a challenging ascent.",
          "Set up camp and rest.",
        ],
      },
      {
        day: "5",
        title: "Trek to Camp 2 (4200m)",
        details: [
          "Continue ascending towards Camp 2 through rugged terrain.",
          "Enjoy the spectacular views as you gain altitude.",
          "Overnight stay in tents at Camp 2.",
        ],
      },
      {
        day: "6",
        title: "Trek to Summit Camp (4800m)",
        details: [
          "Trek to the summit camp, crossing steep sections and rocky patches.",
          "Set up the camp and prepare for the summit attempt.",
        ],
      },
      {
        day: "7",
        title: "Rest and Preparation Day",
        details: [
          "Rest and hydrate at summit camp.",
          "Final preparations and briefing for the summit push.",
        ],
      },
      {
        day: "8",
        title: "Summit Day (6061M)",
        details: [
          "Early morning start for the summit push.",
          "Climb the Lady of Keylong peak and enjoy panoramic views from the top.",
          "Descend to Camp 2 for rest.",
        ],
      },
      {
        day: "9",
        title: "Camp 2 to Base Camp",
        details: [
          "Trek back to the base camp at Tinno Village.",
          "Celebrate the successful climb with the team.",
        ],
      },
      {
        day: "10",
        title: "Tinno Village to Manali (3 hours drive)",
        details: [
          "Pack up and drive back to Manali.",
          "End of the expedition.",
        ],
      },
      {
        day: "11",
        title: "Buffer Day 1",
        details: [
          "Reserved for contingencies like weather delays or additional acclimatization.",
        ],
      },
      {
        day: "12",
        title: "Buffer Day 2",
        details: [
          "Reserved for contingencies like weather delays or additional acclimatization.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "kangyatse1",
    individualExpeditionName: "Kangyatse 1",
    carouselData: expedition6000MHeroSection,
    location: "Markha Valley, Ladakh",
    duration: "13 Days",
    grade: "Intermediate",
    maxAltitude: "6401 m or 21,000 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple treks",
    mainExpeditionContent:
      "Kangyatse is a prominent mountain located in the Indian union territory of Ladakh. It is part of the Himalayas and specifically situated in the Markha Valley region. Kangyatse is a popular peak among mountaineers and trekkers due to its challenging ascent and stunning surrounding landscapes.Kangyatse has two main summits: Kangyatse I (6,401 meters or 21,000 feet) and Kangyatse II (6,250 meters or 20,505 feet). Climbers often aim to reach the higher Kangyatse I. The ascent involves technical climbing, and the trek to the base camp provides breathtaking views of the Himalayan range.Ladakh's harsh yet captivating terrain, with its high-altitude desert and snow-capped peaks, adds to the allure of Mount Kangyatse for adventure enthusiasts seeking a challenging climb and an immersive experience in the stunning landscapes of the region.",
    aboutExpeditionExperienced:
      "Climb Kangyatse 1 with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Leh (3500m)",
        details: [
          "Arrive in Leh and check into your hotel.",
          "Rest and hydrate to acclimatize to the high altitude.",
        ],
      },
      {
        day: "2",
        title: "Acclimatization Hike and Local Sightseeing",
        details: [
          "Take a short acclimatization hike in Leh.",
          "Visit landmarks such as Shanti Stupa, Leh Palace, and local monasteries.",
          "Overnight in Leh.",
        ],
      },
      {
        day: "3",
        title: "Drive from Leh to Skiu (3400m), then Trek to Markha (3700m)",
        details: [
          "Drive to Skiu village (2 hours).",
          "Begin your trek through the Markha Valley to Markha village.",
          "Trekking distance: 3–4 hours.",
          "Overnight in tents.",
        ],
      },
      {
        day: "4",
        title: "Trek from Markha to Hankar (4100m)",
        details: [
          "Pass through stunning landscapes, crossing rivers and small settlements.",
          "Trekking distance: 7–8 hours.",
          "Overnight in tents.",
        ],
      },
      {
        day: "5",
        title: "Trek from Hankar to Base Camp (5400m)",
        details: [
          "Steep ascent to Kang Yatse Base Camp.",
          "Trekking distance: 6–7 hours.",
          "Overnight in tents.",
        ],
      },
      {
        day: "6",
        title: "Rest and Acclimatization at Base Camp",
        details: [
          "Spend the day resting and acclimatizing at base camp.",
          "Conduct technical training and check climbing gear.",
        ],
      },
      {
        day: "7",
        title: "Move to Advance Camp (5600m)",
        details: [
          "A short but steep climb to advance camp.",
          "Trekking time: 3–4 hours.",
          "Overnight in tents.",
        ],
      },
      {
        day: "8",
        title: "Move to Summit Camp (5900m)",
        details: [
          "Move higher to summit camp, preparing for the final push.",
          "Trekking time: 4–5 hours.",
          "Overnight in tents.",
        ],
      },
      {
        day: "9",
        title: "Summit Day (6400m) and Return to Base Camp",
        details: [
          "Start the summit push around midnight.",
          "Challenging ascent with technical sections.",
          "Enjoy breathtaking views from the summit, including Karakoram and Zanskar ranges.",
          "Descend to base camp.",
          "Trekking time: 10–12 hours.",
        ],
      },
      {
        day: "10",
        title: "Rest Day at Base Camp",
        details: [
          "Recover after the summit attempt and celebrate the achievement.",
        ],
      },
      {
        day: "11",
        title:
          "Trek from Base Camp to Chokdo via Kongmaru La Pass (5200m), then Drive to Leh",
        details: [
          "Cross the Kongmaru La Pass, with stunning views of the surrounding peaks.",
          "Trekking time: 7–8 hours.",
          "Drive back to Leh.",
        ],
      },
      {
        day: "12–13",
        title: "Buffer Days",
        details: ["Reserved for weather or any unforeseen circumstances."],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/TrVP-533qdc?si=Mjkzm_8qPQt1QNZl",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "kangyatse2",
    individualExpeditionName: "Kangyatse 2",
    carouselData: expedition6000MHeroSection,
    location: "Markha Valley, Ladakh",
    duration: "11 Days",
    grade: "Intermediate",
    maxAltitude: "6250 m or 20,505 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple treks.",
    mainExpeditionContent:
      "Kang Yatse II, the Western Summit of Kang Yatse, is situated in Ladakh's Markha Valley. A stepping stone from trekking to semi-technical mountaineering, this Alpine PD-rated peak stands just above 20,000 feet, offering a feasible yet demanding climb. Revel in panoramic views of K2, Broad Peak, and the Gasherbrum Massif to the north, and Nun Kun and Zanskar peaks to the west.The trek winds through Thactungtse and Nimling pastures adorned with wildflowers, featuring Mani stones and Tigu lakes. Summit day entails grappling with moraines, loose rocks, and snow-covered crevasses, demanding roping up for safety. This expedition is a perfect blend of challenge and pleasure, ideal for mountaineers eager to test their skills on a semi-technical peak while savoring Ladakh's stunning scenery.",
    aboutExpeditionExperienced:
      "Climb Kangyatse 2 with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Leh",
        details: [
          "Arrive in Leh and check into the hotel.",
          "Rest and acclimatize to the high altitude (3500m).",
        ],
      },
      {
        day: "2",
        title: "Acclimatization Walk",
        details: [
          "Short acclimatization walk around Leh to get used to the altitude.",
          "Visit local sights and rest in the evening.",
        ],
      },
      {
        day: "3",
        title: "Leh to Skiu (Drive and Trek to Markha Village)",
        details: [
          "Drive from Leh to Skiu (about 2 hours).",
          "Begin the trek towards Markha (3-4 hours).",
          "Camp at Markha.",
        ],
      },
      {
        day: "4",
        title: "Markha to Hankar (6-7 hours)",
        details: [
          "Trek from Markha to Hankar.",
          "The trail will take around 6-7 hours, passing through scenic valleys and high-altitude terrain.",
          "Overnight camp in Hankar.",
        ],
      },
      {
        day: "5",
        title: "Hankar to Base Camp (7-8 hours)",
        details: [
          "Trek from Hankar to Base Camp (7-8 hours), gaining more altitude.",
          "Base camp is set up near the foot of Kangyatse 2.",
          "Rest and prepare for the next day.",
        ],
      },
      {
        day: "6",
        title: "Acclimatization Walk and Load Ferry to Advance Camp",
        details: [
          "Acclimatization walk around Base Camp.",
          "Ferry supplies to the Advance Camp at a higher elevation.",
          "Overnight at Base Camp.",
        ],
      },
      {
        day: "7",
        title: "Rest Day",
        details: [
          "Full rest day for recovery.",
          "Prepare gear and mentally prepare for the summit push.",
        ],
      },
      {
        day: "8",
        title: "Summit Kangyatse 2 (6050m) and Return to Base Camp",
        details: [
          "Early morning summit push towards Kangyatse 2 (6050m).",
          "Summit day can take 8-10 hours for ascent and descent.",
          "Return to Base Camp after summiting.",
        ],
      },
      {
        day: "9",
        title: "Base Camp to Chokdo via Kongmaru La, then Drive to Leh",
        details: [
          "Trek from Base Camp to Chokdo via Kongmaru La Pass (6-7 hours).",
          "Drive from Chokdo back to Leh (4-5 hours).",
          "Celebrate and rest in Leh.",
        ],
      },
      {
        day: "10-11",
        title: "Buffer Days",
        details: [
          "Reserved for weather or unforeseen circumstances.",
          "Can be used for resting or additional acclimatization.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/TrVP-533qdc?si=Mjkzm_8qPQt1QNZl",
    expeditionDate: [{ day: "05", month: "JUL" }],
  },
  {
    individualExpeditionType: "utKangri",
    individualExpeditionName: "Ut Kangri",
    carouselData: expedition6000MHeroSection,
    location: "Rumtse, Ladakh",
    duration: "8 Days",
    grade: "Beginners",
    maxAltitude: "6070 m or 19915 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience multiple challenging treks",
    mainExpeditionContent:
      "Nestled in the upper reaches of Rumtse village, the UT Ladakh Kangri peak stands as a majestic, snow-clad jewel, recently conquered by the local mountaineers of the Ladakh Mountain Guides Association in 2019. For those venturing into the world of mountaineering and eyeing their inaugural six-thousander conquest, UT Kangri stands with unparalleled allure.Beyond its aesthetic charm, the ascent to UT Kangri's summit is an interesting challenge, demanding a prerequisite understanding of high-altitude trekking. The summit day unfolds as a marathon lasting a formidable 10-11 hours. The ordeal is undeniably strenuous, yet reaching the top of Kangri is a reward beyond compare.The vista from the summit unfolds a canvas of breathtaking beauty, revealing the grandeur of the surrounding Himalayan ranges—the Karakoram, Zanskar, and Stok ranges—as if painted by the hand of nature herself.",
    aboutExpeditionExperienced:
      "Climb UT Kangri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Leh (3550 M)",
        details: [
          "Arrival at Leh.",
          "The day will be dedicated to acclimatizing to the pristine air of Ladakh. It is crucial to rest and stay hydrated during this period.",
        ],
      },
      {
        day: "2",
        title: "Small Trek Around Leh for Acclimatization",
        details: [
          "The morning sun beckons as you embark on a leisurely walk to the Shanti Stupa, a serenely perched monument that seems to touch the sky.",
          "Explore ancient monasteries, dive into the vibrant local market, and if the expedition checklist isn't complete, indulge in some last-minute shopping.",
          "Return to your hotel, letting the charm of Leh work its magic.",
        ],
      },
      {
        day: "3",
        title: "Leh to U.T Kangri 1st Camp",
        details: [
          "Depart from the guest house at 8 AM for U.T Kangri's first camp, located before Tanglangla Pass.",
          "The journey is a 3-hour drive, followed by a short trek from Rumtse Tea house to the 1st camp.",
        ],
      },
      {
        day: "4",
        title: "Camp One to Base Camp",
        details: [
          "Start the day with a picturesque mountain view. After breakfast, trek to the base camp, a gradual climb lasting 5-6 hours.",
          "The terrains are well-formed due to frequent visitors. Enjoy lunch with magnificent views, followed by a short walk around the camp for acclimatization.",
        ],
      },
      {
        day: "5",
        title: "Rest Day",
        details: [],
      },
      {
        day: "6",
        title: "U.T Kangri Summit",
        details: [
          "Prepare for an early morning start at 1 AM for the U.T Kangri Summit.",
          "Equip yourself with hiking essentials, and after a quick breakfast, begin the trek.",
          "The duration depends on snow conditions and endurance, likely taking 10 to 11 hours.",
          "The summit rewards you with breathtaking views of the Zanskar region. After descent, enjoy refreshments at the base camp.",
        ],
      },
      {
        day: "7",
        title: "Back to Leh via Thiksey",
        details: [
          "After breakfast, trek down to the road head camp (4 to 5 hours), where cars will pick you up and transfer you to the guest house.",
          "Optionally, explore the main market in the evening for gifts and souvenirs.",
        ],
      },
      {
        day: "8",
        title: "Airport Drop",
        details: [
          "After breakfast, you will be dropped off at the airport according to your flight's timing. Have a happy journey!",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "mentokKangri",
    individualExpeditionName: "Mentok Kangri",
    carouselData: expedition6000MHeroSection,
    location: "Karzok, Ladakh",
    duration: "11 Days",
    grade: "Intermediate",
    maxAltitude: "6250 m or 20505 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple challenging treks.",
    mainExpeditionContent:
      "The Mentok Kangri massif is situated on the western shores of Tso Moriri in eastern Ladakh, nestled in the Rupsu valley. Its three peaks stand tall at 6250 m, 6150 m, and 6210 m, linked by a gentle ridge. Dominating the western edge of Tso Moriri is Mentok Kangri II, soaring to an impressive height of 6,250 m. Your journey begins with a drive to Karzok, located on the picturesque banks of Tso Moriri, followed by establishing a base near the Rupshu plateau, home to the Changpa nomads. The name 'Mentok' originates from a Tibetan term linked to a wildflower native to this area.This summit provides a relatively straightforward snow/ice climb, rewarding climbers with breathtaking panoramic views of Tso Moriri and neighboring peaks, including the prominent Chamser and Lungser Kangri at 6600 m. The trek to Mentok Kangri guides adventurers through remote valleys, traditional villages, and stunning hilltop monasteries, showcasing some of the most remarkable vistas of pristine landscapes.",
    aboutExpeditionExperienced:
      "Climb Mentok Kangri 2 with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival at Leh (3500 m)",
        details: [
          "Your journey begins with the enchanting arrival at Leh, a gateway to the heart of Ladakh.",
          "Check into your cozy hotel and immerse yourself in the unique charm of this culturally rich town.",
          "As you explore the local markets, you'll not only acclimatize to the crisp mountain air but also discover the vibrant tapestry of Leh's monasteries.",
        ],
      },
      {
        day: "2",
        title: "Rest and Acclimatization in Leh",
        details: [
          "Take a day to rejuvenate and acclimatize in Leh.",
          "The nearby monasteries hold secrets waiting to be unraveled, adding a touch of mystique to your expedition.",
          "An overnight stay in the hotel relaxes you and gets you ready for the adventures ahead.",
        ],
      },
      {
        day: "3",
        title: "Leh - Korzok (4500 m) [Drive]",
        details: [
          "Embark on a thrilling 10-hour drive from Leh to Korzok, a journey that traverses breathtaking landscapes.",
          "You’ll be mesmerized seeing dark, crystal-clear blue Tso Morori (Tso means Lake). It is huge!",
          "Arrive at a guesthouse/camp nestled in the heart of the mountains, relax and enjoy.",
        ],
      },
      {
        day: "4",
        title: "Rest and Acclimatization at Korzok",
        details: [
          "With Korzok's high altitude, a day of rest is important.",
          "Allow your body to acclimatize while immersing yourself in the mesmerizing beauty of Tso Moriri Lake, a shimmering blue jewel in the rugged terrain.",
          "The guesthouse/camp offers a haven of comfort against the backdrop of towering peaks.",
        ],
      },
      {
        day: "5",
        title: "Karzok to Base camp (5250 M)",
        details: [
          "Embark on a captivating journey to Korzok Phu, a destination that promises solitude and serenity.",
          "The night unfolds against a canvas of starlit skies, creating a magical atmosphere.",
        ],
      },
      {
        day: "6",
        title: "Karzok to Base camp (5250 M)",
        details: [
          "Rise early to witness the breathtaking sunrise illuminating the majestic mountains and the sparkling beauty of Tso Moriri.",
          "The expedition takes a more serious turn as you ascend to the base camp and come closer to all 3 peaks of Mentok range.",
        ],
      },
      {
        day: "7",
        title: "Training and Acclimatization at BC. Trek to High Camp",
        details: [
          "Engage in invigorating hikes, training your body and soul for the impending challenge. The high-altitude air becomes your companion as you prepare for the summit push.",
        ],
      },
      {
        day: "8",
        title: "Summit Attempt and Return to Base Camp",
        details: [
          "Summit push starts at 3:00 am, a thrilling endeavor that rewards you with a panoramic view of Tso Moriri Lake, Parang La, and the majestic Chamser and Lungser Kangri.",
          "Descend to the base camp, the sense of accomplishment mingling with the crisp mountain air as you retire for the night.",
        ],
      },
      {
        day: "9",
        title: "Reserve Day",
        details: [
          "A day reserved for triumph or challenge, depending on the whims of weather. If needed, the summit climb fee on this day adds a dash of suspense to the adventure.",
        ],
      },
      {
        day: "10",
        title: "Base Camp - Korzok",
        details: [
          "Trace your steps back to Karzok, where the journey began. The guesthouse/camp welcomes you for a well-deserved rest.",
        ],
      },
      {
        day: "11",
        title: "Karzok - Leh",
        details: [
          "The final leg of the adventure unfolds as you embark on a 10-hour drive back to Leh.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/ypBh-TWqMdg?si=lQ2_bWWL6LCWDqBi",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "chauChauKangNilda",
    individualExpeditionName: "Chau Chau Kang Nilda",
    carouselData: expedition6000MHeroSection,
    location: "Spiti, Himachal Pradesh",
    duration: "11+2 Days",
    grade: "Intermediate",
    maxAltitude: "6303 m or 20679 ft",
    bestSeason: "June, July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Chau Chau Kang Nilda is one of most beautiful 6000-meter peak in Langza, Spiti. The shape and the setting of the peak will remind you of your childhood days when you tried drawing a peak. The gradual ascent rapidly rises after 5600 m and becomes almost a vertical wall leaving some room for negotiating the incline and reaching the top.Langza is a beautiful fossil village that will instill a sense of spirituality and calmness. History suggests that entire region was once submerged deep into The Tethys Ocean and this explains availability of fossils here. This high-altitude desert makes things cold and challenging.Chau Chau Kang Nilda is definitely a worthy challenge set in a very inspiring location. As you rise from camp to camp, getting closer to the summit, the beautiful views of neighboring ranges and high peaks will fire your flames to climb more. As a climber, this peak will polish and add one more achievement to your list of accomplishments. ",
    aboutExpeditionExperienced:
      "Climb Chau Chau Kiang Nilda with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Kaza (3800m)",
        details: [
          "Arrive in Kaza.",
          "Rest and acclimatization.",
          "Night stay at a homestay or hotel (double sharing).",
        ],
      },
      {
        day: "2",
        title: "Local Short Hike and Exploration",
        details: [
          "Short hike around Kaza to explore the region and acclimatize.",
          "Night stay at the same homestay or hotel.",
        ],
      },
      {
        day: "3",
        title: "Kaza to Komic (4500m)",
        details: [
          "Travel from Kaza to Komic by vehicle.",
          "Stay at a homestay in Komic.",
          "Rest and acclimatization.",
          "Night stay at the homestay.",
        ],
      },
      {
        day: "4",
        title: "Komic to Base Camp (5100m)",
        details: [
          "Trek from Komic to base camp (7-8 hours).",
          "Arrive at base camp.",
          "Night stay at base camp in tents (double sharing).",
        ],
      },
      {
        day: "5",
        title: "Rest at Base Camp",
        details: [
          "Rest day at base camp for acclimatization.",
          "Short hike around for acclimatization.",
          "Night stay at base camp in tents (double sharing).",
        ],
      },
      {
        day: "6",
        title: "Base Camp to Advance Camp (5500m)",
        details: [
          "Trek from base camp to advance camp (4-5 hours).",
          "Night stay at advance camp in tents (double sharing).",
        ],
      },
      {
        day: "7",
        title: "Rest at Advance Camp",
        details: [
          "Rest day at advance camp for acclimatization.",
          "Short hike for acclimatization.",
          "Night stay at advance camp in tents (double sharing).",
        ],
      },
      {
        day: "8",
        title: "Advance Camp to Summit Camp (5800m)",
        details: [
          "Trek from advance camp to summit camp (3 hours).",
          "Night stay at summit camp in tents (triple sharing).",
        ],
      },
      {
        day: "9",
        title: "Summit Day",
        details: [
          "Summit Chau Chau Kang Nilda (6309m).",
          "Return to advance camp (6-7 hours for summit and 3-4 hours back).",
          "Night stay at advance camp (5400m).",
        ],
      },
      {
        day: "10",
        title: "Rest at Advance Camp",
        details: [
          "Rest day at advance camp.",
          "Night stay at advance camp in tents.",
        ],
      },
      {
        day: "11",
        title: "Return to Kaza",
        details: [
          "Trek from advance camp to Komic (6-7 hours).",
          "Drive back to Kaza.",
          "Night stay at homestay or hotel.",
        ],
      },
      {
        day: "12-13",
        title: "Buffer Days",
        details: [
          "Two extra buffer days in case of bad weather or additional acclimatization.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali/Kaza upon arrival and during the expedition.",
          "Tented accommodation during the climb and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable climbing experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible climbing",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali & Kaza",
        content: [
          "Meals in Manali & Kaza on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the expedition organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the expedition itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the expedition.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/G12JKmSgy80?si=NxpxbmRALvWo0RLo",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "leoPurgyil",
    individualExpeditionName: "Leo Purgyil",
    carouselData: expedition6000MHeroSection,
    location: "Nako, Himachal Pradesh",
    duration: "10+2 Days",
    grade: "Advanced",
    maxAltitude: "6793 M or 22286 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple challenging treks.",
    mainExpeditionContent:
      "Just beside Reo Purgyil, the highest peak of Himachal Pradesh, you’ll find Leo Purgyil. Just 23 m shorter than Reo Purgyil, Mt. Leo is an interesting peak. It is an excellent peak if you wish to climb Mt. Reo later. The technical level of this peak is not as severe as of Reo. Both the mountains share the same base camp.You witness the distance valleys of Spiti and Kinnaur region on your way up the summit. Mt. Manirang is also visible from the summit. The dry region of Spiti and shortage of oxygen will be felt as you start to gain altitude. With right mindset, physical fitness and a good team you can brave this peak.",
    aboutExpeditionExperienced:
      "Climb Leo Purgyil with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Shimla to Nako",
        details: [
          "Duration: 9-10 hours drive.",
          "Activities: Scenic drive through Kinnaur, check-in at Nako, and rest.",
          "Altitude: 3662m.",
        ],
      },
      {
        day: 2,
        title: "Nako - Rest and Acclimatization",
        details: [
          "Activities: Explore the village and surroundings, hydrate, and acclimatize.",
        ],
      },
      {
        day: 3,
        title: "Nako to Transit Camp",
        details: [
          "Trek Duration: 6-7 hours.",
          "Altitude: 4700m.",
          "Activities: Trek through rugged terrain and set up transit camp.",
        ],
      },
      {
        day: 4,
        title: "Transit Camp to Base Camp",
        details: [
          "Trek Duration: 5-6 hours.",
          "Altitude: 5400m.",
          "Activities: Reach base camp, settle in, and rest.",
        ],
      },
      {
        day: 5,
        title: "Acclimatization at Base Camp",
        details: [
          "Activities: Short hikes around base camp to prepare for higher altitudes.",
        ],
      },
      {
        day: 6,
        title: "Base Camp to Camp 1",
        details: [
          "Trek Duration: 4-5 hours.",
          "Altitude: 5600m.",
          "Activities: Gradual ascent to Camp 1.",
        ],
      },
      {
        day: 7,
        title: "Rest Day at Camp 1",
        details: [
          "Activities: Acclimatize, hydrate, and prepare for summit camp.",
        ],
      },
      {
        day: 8,
        title: "Camp 1 to Summit Camp",
        details: [
          "Trek Duration: 3-4 hours.",
          "Altitude: 6000m.",
          "Activities: Set up summit camp and prepare for summit push.",
        ],
      },
      {
        day: 9,
        title: "Summit Day",
        details: [
          "Activities: Early morning start.",
          "Reach the summit of Leo Purgyil (6793m).",
          "Descend back to Camp 1.",
        ],
      },
      {
        day: 10,
        title: "Camp 1 to Base Camp",
        details: ["Activities: Descend to base camp and rest."],
      },
      {
        day: 11,
        title: "Base Camp to Nako",
        details: ["Trek Duration: Full-day trek back to Nako."],
      },
      {
        day: 12,
        title: "Buffer Days",
        details: ["Reserved for weather delays or additional rest."],
      },
      {
        day: 13,
        title: "Buffer Days",
        details: ["Reserved for weather delays or additional rest."],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Shimla/Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali/Shimla to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali/Shimla",
        content: [
          "Meals in Manali/Shimla on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "manirang",
    individualExpeditionName: "Manirang",
    carouselData: expedition6000MHeroSection,
    location: "Spiti, Himachal Pradesh",
    duration: "12 Days",
    grade: "Advanced",
    maxAltitude: "6593 m or 21625 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple challenging treks.",
    mainExpeditionContent:
      "One of the precious peaks, a crown jewel, of Himachal Pradesh is Mt. Manirang (6593 m). Lying on the border of Kinnaur and Lahaul & Spiti district, it is accessible from village Mane that is almost 20 kms from Kaza town, the administrative headquarters of Lahaul & Spiti region. Manirang pass, which is close to the peak stands tall at 5550 m and is very remote and one of the least explored passes.Manirang Pass was a part of an ancient trade route that connected the people of Spiti region with Kinnaur. As technology and vehicles start to ease the life of the villagers, this region remains remote and unexplored. One of the most interesting parts of the route is that it rises gradually. You cross some of the most beautiful scenery consisting of man-made wooden bridges, crystal clear streams, wild-life, wide plain field, variety of flowers and colorful shrubs.Mt. Manirang is a challenging peak where last 200 m require technical knowledge or understanding as you have negotiate icy and rocky section. It is rewarding to reach the top and get a sense of true adventure and accomplishment.",
    aboutExpeditionExperienced:
      "Climb Mt. Manerang with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Shimla/Manali to Kaza (3650 m) (9-10 hrs drive)",
        details: [
          "Depart from Manali for Kaza, enjoying scenic Himalayan landscapes.",
          "Plan breaks for meals and rest during the journey.",
          "Check into accommodation upon arrival in Kaza.",
        ],
      },
      {
        day: 2,
        title: "Kaza to Mane Village(3550 m) (2 hrs drive)",
        details: [
          "Travel from Kaza to Mane Village, a picturesque 2-hour drive.",
          "Marvel at the beautiful peaks and landscapes of Himachal Pradesh.",
          "Arrive at Mane Village and immerse yourself in the local surroundings.",
        ],
      },
      {
        day: 3,
        title: "Mane Village to Base Camp (4800 m) (7-8 hrs trek)",
        details: [
          "Embark on a trek from Mane Village to the Base Camp (7-8 hours).",
          "Set up camp at the Base Camp and rest for the night.",
        ],
      },
      {
        day: 4,
        title: "Rest Day and Acclimatize Walk",
        details: [
          "Take a day to rest at the Base Camp.",
          "Engage in acclimatization walks to adjust to the higher altitude.",
        ],
      },
      {
        day: 5,
        title: "Base Camp to Camp 1 (5200 m) (5-6 hrs trek)",
        details: [
          "Trek from the Base Camp to Camp 1 (5-6 hours).",
          "Settle into Camp 1, rest, and prepare for the upcoming days.",
        ],
      },
      {
        day: 6,
        title: "Camp 1 to Camp 2 (5600 m) (4 hrs trek)",
        details: [
          "Continue the ascent from Camp 1 to Camp 2 (4 hours).",
          "Reach Camp 2, rest, and have dinner in preparation for the summit push.",
        ],
      },
      {
        day: 7,
        title: "Camp 2 to Summit Camp (5900 m) (3 hrs trek)",
        details: [
          "Trek from Camp 2 to the Summit Camp (3 hours).",
          "Prepare for the summit push and rest for the upcoming challenge.",
        ],
      },
      {
        day: 8,
        title: "Summit Day",
        details: [
          "Undertake the summit push, dedicating approximately 8 hours to reach the summit of Mt. Manirang (6593 meters).",
          "Descend back to the Summit Camp after the successful summit.",
        ],
      },
      {
        day: 9,
        title: "Summit Camp to Base Camp",
        details: [
          "Descend from the Summit Camp to the Base Camp, relishing the memories of the summit.",
          "Reach the Base Camp, rest, and reflect on the expedition.",
        ],
      },
      {
        day: 10,
        title: "Base Camp to Mane Village",
        details: [
          "Trek from the Base Camp to Mane Village, appreciating the journey.",
          "Arrive in Mane Village and unwind.",
        ],
      },
      {
        day: 11,
        title: "Mane Village to Kaza",
        details: [
          "Travel from Mane Village to Kaza, taking in the sights and sounds.",
          "Rest in Kaza and celebrate the success of the summit.",
        ],
      },
      {
        day: 12,
        title: "Kaza to Shimla/Manali",
        details: [
          "Begin the journey back to Manali, completing the circuit.",
          "Allow for flexibility with two reserve days for any unforeseen weather conditions or other challenges.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Shimla/Manali and Kaza upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Shimla/Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Shimla/Manali",
        content: [
          "Meals in Shimla/Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/YORiX8_uCYM?si=I1vLU4mMQVFD0_dy",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "kanamo",
    individualExpeditionName: "Kanamo",
    carouselData: expedition5000MHeroSection,
    location: "Spiti, Himachal Pradesh",
    duration: "8 Days",
    grade: "Beginners",
    maxAltitude: "5974 M or 19553 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Trekking a high-altitude peak has its own raw appeal, especially when it reaches heights usually reserved for hardcore mountaineers. Kanamo is one such peak that you can trek. Unlike the technical climbs of traditional peaks, trekking this giant doesn't demand any fancy mountaineering skills—just a good acclimatization.Don't be fooled, though; tagging Kanamo as a mere trek would be a mistake. This is no cakewalk, you need serious fitness levels. The climb from Kanamo base camp to the summit is a relentless grind over scree and boulder moraine. And at that altitude, putting one foot in front of the other is no walk in the park. On summit day, you're not just hauling yourself up 4,500 ft; you've got to make it back to base camp on the same day. It's a brutal 12-14 hour trek, covering roughly 10 km through unforgiving terrain. This one's for the heavyweights—the toughest, fittest, and most seasoned trekkers out there!",
    aboutExpeditionExperienced:
      "Climb Kanamo Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Kaza (3,800m)",
        details: [
          "Arrive in Kaza, the administrative center of Spiti Valley.",
          "Check into a hotel/guesthouse and rest after the long journey.",
          "Briefing about the expedition, gear check, and acclimatization walk around Kaza.",
          "Overnight stay in Kaza.",
        ],
      },
      {
        day: 2,
        title: "Kaza to Kibber (4,200m)",
        details: [
          "Drive from Kaza to Kibber (approx. 1 hour).",
          "Explore Kibber, one of the highest inhabited villages in the world.",
          "Visit the Kibber Wildlife Sanctuary, home to snow leopards and Himalayan wildlife.",
          "Overnight stay in a guesthouse in Kibber.",
        ],
      },
      {
        day: 3,
        title: "Rest and Acclimatization in Kibber",
        details: [
          "Spend the day acclimatizing to the altitude.",
          "Take a short hike to surrounding areas like Chicham Bridge (world’s highest bridge) or local ridges.",
          "Prepare for the trek and double-check your gear.",
          "Overnight stay in Kibber.",
        ],
      },
      {
        day: 4,
        title: "Kibber to Kanamo Base Camp (4,800m)",
        details: [
          "Trek from Kibber to Kanamo Base Camp (approx. 5-6 hours).",
          "The trail offers stunning views of Spiti Valley and its rugged landscapes.",
          "Set up camp at the base camp and rest.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: 5,
        title: "Base Camp to Summit Camp (5,300m)",
        details: [
          "Trek to Summit Camp (approx. 4-5 hours) with gradual altitude gain.",
          "The trail passes through rocky terrain with breathtaking vistas.",
          "Set up camp and prepare for the summit push.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: 6,
        title: "Summit Day (6,000m) and Return to Base Camp",
        details: [
          "Start the summit attempt early (around 2-3 AM).",
          "Reach the summit of Kanamo Peak after a challenging climb (6-7 hours).",
          "Enjoy panoramic views of Spiti, Ladakh, and surrounding ranges from the top.",
          "Descend back to Base Camp (3-4 hours).",
          "Overnight stay in tents at Base Camp.",
        ],
      },
      {
        day: 7,
        title: "Base Camp to Kibber and Drive to Kaza",
        details: [
          "Trek back to Kibber (approx. 4-5 hours).",
          "Drive from Kibber to Kaza.",
          "Drop-off in Kaza.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Shimla/Manali and Kaza upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Shimla/Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Shimla/Manali",
        content: [
          "Meals in Shimla/Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/tqh2-NjBGzQ?si=psDqBtbSI4MI_EoG",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "20/12/2024",
        endDate: "22/12/2024",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "nun",
    individualExpeditionName: "Nun",
    carouselData: expedition7000MHeroSection,
    location: "Kargil, Ladakh",
    duration: "14+2 Days",
    grade: "Advanced",
    maxAltitude: "7135 m or 23,409 ft",
    bestSeason: "August & September",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs.",
    mainExpeditionContent:
      "Mount Nun, standing at an elevation of approximately 7,135 meters (23,409 feet) above sea level, is a prominent peak nestled within the stunning Nun-Kun massif in Himalayas. Located in the Suru Valley of the Kargil district in the Indian state of Jammu and Kashmir, Mt. Nun holds the distinction of being the highest mountain in this region. This majestic peak, along with its slightly lower counterpart, Mount Kun, forms the Nun Kun massif. Renowned for its challenging ascent, Mount Nun attracts adventurers and mountaineers seeking to conquer its formidable heights.The surrounding terrain is characterized by its remote and mountainous nature, necessitating careful consideration of weather conditions and logistical challenges for those attempting to explore or climb this iconic peak. The base camp in the Suru Valley serves as a crucial staging point for acclimatization before the ambitious summit attempt.This demanding climb requires specific mountaineering knowledge and skills, making it an expedition exclusively for experienced climbers. Mountaineering certification or equivalent high-altitude experience, with a summit of over 6,500M, is a prerequisite.",
    aboutExpeditionExperienced:
      "Climb Nun with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh (3500m)",
        details: [
          "Arrive at Leh airport and transfer to the hotel.",
          "Rest and acclimatize to the high altitude.",
          "Briefing about the expedition.",
          "Overnight Stay: Comfortable hotel in Leh.",
        ],
      },
      {
        day: 2,
        title: "Acclimatization Hike and Briefing",
        details: [
          "Short hike around Leh to adjust to the altitude.",
          "Explore local sights and get familiar with the surrounding terrain.",
          "Briefing session about the expedition, equipment check, and shopping for essentials.",
          "Overnight Stay: Hotel in Leh.",
        ],
      },
      {
        day: 3,
        title: "Drive from Leh to Kargil (2676m)",
        details: [
          "Distance: ~220 km (7-8 hours).",
          "Drive through scenic landscapes, including Lamayuru Monastery and Fotu La Pass.",
          "Enjoy breathtaking views of valleys and rugged mountains.",
          "Overnight Stay: Hotel in Kargil.",
        ],
      },
      {
        day: 4,
        title: "Drive from Kargil to Tangol (3700m)",
        details: [
          "Distance: ~80 km (3-4 hours).",
          "Continue the drive along Suru Valley, with stunning views of lush fields, rivers, and villages.",
          "Experience the charm of Himalayan villages and beautiful river crossings.",
          "Overnight Stay: Guesthouse or camp in Tangol.",
        ],
      },
      {
        day: 5,
        title: "Acclimatization in Tangol",
        details: [
          "Rest day for altitude adjustment. Walk around the village and interact with locals.",
          "Explore the serene surroundings and enjoy cultural experiences.",
          "Overnight Stay: Guesthouse or camp in Tangol.",
        ],
      },
      {
        day: 6,
        title: "Trek from Tangol to Base Camp (4500m)",
        details: [
          "Duration: 7-8 hours.",
          "Start the steep and challenging trek with stunning mountain views along the way.",
          "Cross streams and ascend through rugged terrain to reach base camp.",
          "Overnight Stay: Tent at base camp.",
        ],
      },
      {
        day: 7,
        title: "Rest Day at Base Camp",
        details: [
          "Rest and recovery day to prepare for higher altitudes.",
          "Enjoy the serene environment and review the climb strategy.",
          "Overnight Stay: Tent at base camp.",
        ],
      },
      {
        day: 8,
        title: "Load Ferry to Crampon Point and Return to Base Camp",
        details: [
          "Ferry equipment to the crampon point and return to base camp.",
          "Familiarize yourself with the route while acclimatizing.",
          "Overnight Stay: Tent at base camp.",
        ],
      },
      {
        day: 9,
        title: "Base Camp to Camp 1 (5400m)",
        details: [
          "Duration: 6-7 hours.",
          "Ascend over steep terrain using fixed ropes in some sections.",
          "Stunning views of glaciers and peaks.",
          "Overnight Stay: Tent at Camp 1.",
        ],
      },
      {
        day: 10,
        title: "Acclimatization Day at Camp 1",
        details: [
          "Rest and acclimatize to higher altitude.",
          "Short hikes around Camp 1 for active acclimatization.",
          "Overnight Stay: Tent at Camp 1.",
        ],
      },
      {
        day: 11,
        title: "Camp 1 to Camp 2 (6100m)",
        details: [
          "Duration: 6-7 hours.",
          "A technical climb over snow and ice using ropes and crampons.",
          "Crossing crevasses and experiencing the challenges of high-altitude climbing.",
          "Overnight Stay: Tent at Camp 2.",
        ],
      },
      {
        day: 12,
        title: "Camp 2 to Summit Camp (6400m)",
        details: [
          "Duration: 5-6 hours.",
          "Ascend to the summit camp, preparing for the final push.",
          "Spectacular views of the surrounding peaks.",
          "Overnight Stay: Tent at Summit Camp.",
        ],
      },
      {
        day: 13,
        title: "Summit Day (7135m)",
        details: [
          "Duration: 12-14 hours.",
          "Start early to ascend the peak and return to Camp 2.",
          "Conquer Mt. Nun and enjoy panoramic views of the Himalayan ranges.",
          "Overnight Stay: Tent at Camp 2.",
        ],
      },
      {
        day: 14,
        title: "Return to Base Camp",
        details: [
          "Duration: 6-8 hours.",
          "Descend from Camp 2 to Base Camp, retracing your steps.",
          "Celebrate the successful summit with the team.",
          "Overnight Stay: Tent at Base Camp.",
        ],
      },
      {
        day: 15,
        title: "Rest Day at Base Camp",
        details: [
          "Relax and recover after the summit climb.",
          "Share summit experiences with the team.",
          "Overnight Stay: Tent at Base Camp.",
        ],
      },
      {
        day: 16,
        title: "Base Camp to Tangol",
        details: [
          "Duration: 6-7 hours.",
          "Descend from Base Camp to Tangol.",
          "Relish the trek back with lighter loads and a sense of accomplishment.",
          "Overnight Stay: Guesthouse in Tangol.",
        ],
      },
      {
        day: 17,
        title: "Drive from Tangol to Leh",
        details: [
          "Duration: ~10 hours.",
          "Drive back to Leh through scenic routes, reliving the journey’s highlights.",
          "Overnight Stay: Hotel in Leh.",
        ],
      },
      {
        day: "Buffer Days",
        title: "Buffer Days for Weather or Delays",
        details: [
          "2 extra days included to account for bad weather or unforeseen delays.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/63bRSbdPsuo?si=27ZU2Vd3g25NQpOH",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "kun",
    individualExpeditionName: "Kun",
    carouselData: expedition7000MHeroSection,
    location: "Kargil, Ladakh",
    duration: "15 Days",
    grade: "Advanced",
    maxAltitude: "7077 m or 23,218 ft",
    bestSeason: "August, September",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m.",
    mainExpeditionContent:
      "Mount Kun is Ladakh's second-highest peak at 7,077 meters. Nestled in the Zanskar Valley, this majestic peak presents a challenge marked by imposing slopes, unpredictable weather, and a captivating hold on climbers. As part of the Nun-Kun massif, it proudly stands beside its taller twin, Mt. Nun, with a 4 km long snow plateau separating the two. Pinnacle Peak (6,930M), the third highest in the massif, adds to the allure of this challenging terrain located in the Suru Valley, Kargil district, approximately 250 km east of Srinagar.Mt. Kun, amidst the vast Himalayan expanse, challenges climbers seeking thrill, serving as a stepping stone or formidable goal for those aspiring to conquer the 8000ers. First conquered in 1913 and witnessing its second summit in 1971, the mountain demands a technical climb through treacherous terrain, battling violent winds and extreme cold.The nature of Mt. Kun, with its crevasse-riddled surfaces, changing faces, elaborate glacial formations, and high-gradient ice walls, sets it apart from other 7,000M peaks. This demanding climb requires specific mountaineering knowledge and skills, making it an expedition exclusively for experienced climbers. Mountaineering certification or equivalent high-altitude experience, with a summit of over 6,500M, is a prerequisite.",
    aboutExpeditionExperienced:
      "Climb Kun with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh (3500m)",
        details: [
          "Arrival at Leh airport and transfer to the hotel.",
          "Rest and allow your body to adjust to the high altitude.",
          "Hydration, relaxation, and preparation for the upcoming expedition.",
          "Overnight Stay: Comfortable hotel in Leh.",
        ],
      },
      {
        day: 2,
        title: "Acclimatization Hike and Expedition Briefing",
        details: [
          "Short acclimatization hike around Leh, followed by a team briefing.",
          "Visit nearby monasteries or viewpoints, ensuring gradual acclimatization.",
          "Equipment checks and final shopping for any missing essentials.",
          "Overnight Stay: Hotel in Leh.",
        ],
      },
      {
        day: 3,
        title: "Drive from Leh to Kargil (2676m)",
        details: [
          "Distance: ~220 km (7-8 hours).",
          "Scenic drive through the Indus Valley, visiting Lamayuru Monastery and Fotu La Pass.",
          "Experience the changing landscape, from desert-like mountains to greener valleys.",
          "Overnight Stay: Hotel in Kargil.",
        ],
      },
      {
        day: 4,
        title: "Drive from Kargil to Shafat Nala Base Camp (3700m)",
        details: [
          "Distance: ~80 km (3-4 hours).",
          "Drive through the Suru Valley, passing beautiful villages and farmlands.",
          "Set up base camp at the picturesque Shafat Nala.",
          "Enjoy the first glimpse of the glacier-fed river and Nun-Kun massif.",
          "Overnight Stay: Tent at Shafat Nala Base Camp.",
        ],
      },
      {
        day: 5,
        title: "Rest and Acclimatization at Base Camp",
        details: [
          "Spend the day acclimatizing to the altitude. Take light walks around the base camp.",
          "Stunning views of the surrounding peaks and interaction with the local environment.",
          "Overnight Stay: Tent at Base Camp.",
        ],
      },
      {
        day: 6,
        title: "Trek from Base Camp to Advance Camp (4500m)",
        details: [
          "Duration: 7-8 hours.",
          "Start the trek to Advance Camp through rugged terrain, crossing streams and moraines.",
          "The challenging yet rewarding trail offers breathtaking glacier views.",
          "Overnight Stay: Tent at Advance Camp.",
        ],
      },
      {
        day: 7,
        title: "Load Ferry to Camp 1 and Return to Advance Camp",
        details: [
          "Carry essential supplies and equipment to Camp 1, then return to Advance Camp.",
          "Familiarize yourself with the route and improve acclimatization.",
          "Overnight Stay: Tent at Advance Camp.",
        ],
      },
      {
        day: 8,
        title: "Rest Day at Advance Camp",
        details: [
          "A full day of rest to recover and prepare for the ascent to higher camps.",
          "Enjoy the serene mountain environment and review the climbing strategy.",
          "Overnight Stay: Tent at Advance Camp.",
        ],
      },
      {
        day: 9,
        title: "Trek from Advance Camp to Camp 1 (5400m)",
        details: [
          "Duration: 5-6 hours.",
          "Trek over glacier terrain, negotiating steep climbs and icefalls.",
          "Reach Camp 1 with incredible views of the glacier and surrounding peaks.",
          "Overnight Stay: Tent at Camp 1.",
        ],
      },
      {
        day: 10,
        title: "Camp 1 to Camp 2 (6100m)",
        details: [
          "Duration: 7-8 hours.",
          "Climb over technical glacier terrain, using fixed ropes and crampons.",
          "Navigate crevasses and enjoy stunning high-altitude scenery.",
          "Overnight Stay: Tent at Camp 2.",
        ],
      },
      {
        day: 11,
        title: "Rest and Acclimatization at Camp 2",
        details: [
          "Take a rest day to allow the body to adjust to the altitude.",
          "Practice rope techniques and ice climbing.",
          "Prepare mentally and physically for the summit push.",
          "Overnight Stay: Tent at Camp 2.",
        ],
      },
      {
        day: 12,
        title: "Trek from Camp 2 to Summit Camp (6300m)",
        details: [
          "Duration: 4-5 hours.",
          "Climb to the final camp, passing steep icy slopes and crevasses.",
          "The last stage before the summit offers thrilling views of the surrounding peaks.",
          "Overnight Stay: Tent at Summit Camp.",
        ],
      },
      {
        day: 13,
        title: "Summit Mt. Kun (7077m)",
        details: [
          "Duration: 10-12 hours (round trip).",
          "Begin the summit attempt early morning, navigating steep snow and icy terrain.",
          "Experience the incredible achievement of standing atop Mt. Kun, with panoramic views of the Himalayan range.",
          "Descend back to Camp 2.",
          "Overnight Stay: Tent at Camp 2.",
        ],
      },
      {
        day: 14,
        title: "Descend to Camp 1 or Advance Camp",
        details: [
          "Duration: 6-8 hours.",
          "Retrace the route to lower camps, moving through glaciers and moraines.",
          "Return to lower altitude with a sense of accomplishment.",
          "Overnight Stay: Tent at Camp 1 or Advance Camp.",
        ],
      },
      {
        day: 15,
        title: "Rest Day at Advance Camp",
        details: [
          "Rest and recover after the physically demanding summit attempt.",
          "Share summit stories and relax with the team.",
          "Overnight Stay: Tent at Advance Camp.",
        ],
      },
      {
        day: 16,
        title: "Trek from Advance Camp to Shafat Nala Base Camp",
        details: [
          "Duration: 6-7 hours.",
          "Descend from Advance Camp to Base Camp via the same route.",
          "Relish the return journey with lighter loads and fewer challenges.",
          "Overnight Stay: Tent at Shafat Nala Base Camp.",
        ],
      },
      {
        day: 17,
        title: "Drive from Shafat Nala to Leh",
        details: [
          "Duration: ~10 hours.",
          "Drive back to Leh, retracing the scenic route through Kargil and Suru Valley.",
          "Celebrate the successful expedition with your team.",
          "Overnight Stay: Hotel in Leh.",
        ],
      },
      {
        day: "Buffer Days",
        title: "Buffer Days for Weather or Delays",
        details: [
          "2 extra days included to account for bad weather or unforeseen delays.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/4up7D4axvCs?si=-IjmqMDS48cuV_g-",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "kiagarRi",
    individualExpeditionName: "Kiagar Ri",
    carouselData: expedition6000MHeroSection,
    location: "Karzok, Ladakh",
    duration: "6 Days",
    grade: "Beginners",
    maxAltitude: "6100 m or 20013 ft",
    bestSeason: "All",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Mt. Kiager Ri, located in the stunning Changthang region of Ladakh, offers a perfect blend of adventure and solitude. This lesser-known 6100m peak becomes even more challenging and rewarding in winter, with its pristine snow-covered landscape and sub-zero temperatures adding to the thrill of the climb",
    aboutExpeditionExperienced:
      "Climb Kiagar Ri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh (3500m)",
        details: [
          "Activities: Arrival in Leh, transfer to hotel, and rest for acclimatization.",
          "Accommodation: Heated room (double sharing).",
          "Meals: Breakfast and dinner provided.",
        ],
      },
      {
        day: 2,
        title: "Acclimatization & Sightseeing in Leh",
        details: [
          "Activities: Acclimatization hike around Leh and visit local sights.",
          "Accommodation: Heated room (double sharing).",
          "Meals: Breakfast and dinner provided.",
        ],
      },
      {
        day: 3,
        title: "Drive from Leh to Sumdho (4300m)",
        details: [
          "Duration: 4-hour drive.",
          "Activities: Arrive in Sumdho and rest. Light acclimatization walk in the area.",
          "Accommodation: homestay in Sumdho (all sharing).",
          "Meals: Breakfast lunch ,dinner provided.",
        ],
      },
      {
        day: 4,
        title: "Sightseeing and Acclimatization Hike",
        details: [
          "Activities: Visit Puga hot springs and Tsokar frozen lake, with chances to spot local wildlife.",
        ],
      },
      {
        day: 5,
        title: "Summit Push to Mt. Kiager Ri (6100m)",
        details: [
          "Start: Early morning at 5:00 am.",
          "Duration: 6 to 7 hours ascent and descent back to Sumdho.",
          "Activities: Summit Mt. Kiager Ri and return to base.",
        ],
      },
      {
        day: 6,
        title: "Return to Leh",
        details: [
          "Activities: Drive back from Sumdho to Leh and expedition ends.",
          "Duration: 4-hour drive.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Inclusion",
        content: [
          "Accommodation in Leh  (double sharing, heated rooms).",
          "Breakfast and dinner throughout the expedition.",
          "All climbing gear (harness, crampons, ice axe, etc.). If needed",
          "Professional guide for the summit.",
          "All necessary permits for the climb.",
          "Medical kit with an oxygen cylinder for emergencies.",
        ],
      },
      // {
      //   sectionTitle: "Meals",
      //   content: [
      //     "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
      //     "Special provisions for dietary preferences and requirements.",
      //   ],
      // },
      // {
      //   sectionTitle: "Experienced Guides",
      //   content: [
      //     "Expert local guides well-versed in the terrain and conditions of the region.",
      //     "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
      //   ],
      // },
      // {
      //   sectionTitle: "Transportation",
      //   content: [
      //     "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
      //     "Well-maintained vehicles and experienced drivers for safe travel.",
      //   ],
      // },
      // {
      //   sectionTitle: "Camping Equipment",
      //   content: [
      //     "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
      //     "Technical gear distribution and fitting for the summit attempt.",
      //   ],
      // },
      // {
      //   sectionTitle: "Permits and Paperwork",
      //   content: [
      //     "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
      //     "Coordination of permits required for the expedition.",
      //   ],
      // },
      // {
      //   sectionTitle: "Safety Measures",
      //   content: [
      //     "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
      //     "First aid provisions and medical assistance throughout the expedition.",
      //   ],
      // },
      // {
      //   sectionTitle: "Expedition Certificate",
      //   content: [
      //     "Commemorative certificates recognizing the successful completion Expedition",
      //   ],
      // },
      // {
      //   sectionTitle: "Communication",
      //   content: [
      //     "Communication equipment for emergencies",
      //     "Regular updates and briefings",
      //   ],
      // },
      // {
      //   sectionTitle: "Environment and Leave No Trace",
      //   content: [
      //     "Commitment to environmentally responsible trekking",
      //     "Practices to minimize environmental impact",
      //   ],
      // },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/GFPttnqiNN0?si=E-C3X9HqW744FDQv",
    expeditionDate: [
      { day: "15", month: "JAN" },
      { day: "05", month: "FEB" },
      { day: "15", month: "FEB" },
    ],
  },
  {
    individualExpeditionType: "spangnakRi",
    individualExpeditionName: "Spangnak Ri",
    carouselData: expedition6000MHeroSection,
    location: " Karzog, Ladakh",
    duration: "8 Days",
    grade: "Beginners",
    maxAltitude: "6393 m or 20,974 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of multiple challenging treks.",
    mainExpeditionContent:
      "Nestled in the mesmerizing landscapes of Ladakh, Spangnak Ri stands as an awe-inspiring peak, beckoning adventurers with its majestic allure. At an elevation of 6393 meters, this formidable summit promises not only breathtaking panoramic views but also an exhilarating journey through the heart of the Himalayas. The adventure begins in Leh, where travelers acclimate to the high altitude, immersing themselves in the local culture and exploring landmarks such as Shanti Stupa and Leh Palace.The expedition then takes an exciting turn as participants embark on a scenic drive from Leh to Karzok Village, positioned at an altitude of 4595 meters. From there, a trek unfolds, leading through the ethereal Tso Moriri Lake, allowing for acclimatization amidst the cold desert ambiance. The trek progresses to the Base Camp, strategically situated at 5000 meters, setting the stage for the ultimate ascent.The summit bid for Spangnak Ri begins with a challenging trek to the Summit Camp at 5400 meters, where adventurers rest and prepare for the pinnacle endeavor. Finally, the culmination of the journey occurs on Summit Day, as intrepid explorers conquer the peak at 6393 meters, reveling in the triumph of scaling this Himalayan giant. The descent brings a sense of accomplishment, concluding the expedition with a return to Karzok Village and a reflective drive back to Leh, marking the end of an unforgettable odyssey through Ladakh's stunning terrain and the triumphant conquest of Spangnak Ri.",
    aboutExpeditionExperienced:
      "Climb Spangnak Ri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh (3500m)",
        details: [
          "Arrival in Leh and transfer to the hotel.",
          "Rest for acclimatization and avoid strenuous activities.",
          "Evening exploration of Leh Bazaar (optional).",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 2,
        title: "Acclimatization in Leh (3500m)",
        details: [
          "Spend the day acclimatizing to the altitude.",
          "Visit nearby monasteries like Thiksey and Shey or explore Leh Palace and Shanti Stupa.",
          "Hydrate well and rest.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 3,
        title: "Leh to Tsokar (4500m)",
        details: [
          "Drive from Leh to Tsokar via Tanglang La (5328m), one of the world’s highest motorable passes.",
          "Set up camp near Tsokar Lake.",
          "Explore the lake and surrounding areas.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: 4,
        title: "Tsokar to Base Camp (5100m)",
        details: [
          "Morning drive (3 hours) to the Spangnak Ri base camp.",
          "Settle in and start preparing for the climb.",
          "Short acclimatization walk around the base camp.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: 5,
        title: "Base Camp to Advance Camp (5500m)",
        details: [
          "Trek for 3 hours to reach Advance Camp.",
          "Gradual ascent with stunning views of the surrounding peaks.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: 6,
        title: "Rest Day at Advance Camp (5500m)",
        details: [
          "Spend the day resting and acclimatizing at Advance Camp.",
          "Gear check and final preparation for the summit push.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: 7,
        title: "Advance Camp to Summit Camp (5900m)",
        details: [
          "Trek to Summit Camp (3-4 hours).",
          "Set up camp and prepare for the summit attempt.",
          "Early dinner and rest for the summit push.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: 8,
        title: "Summit Day (6309m)",
        details: [
          "Early morning start for the summit push (around midnight).",
          "Reach the summit of Spangnak Ri and enjoy panoramic views of the Ladakh region.",
          "Descend back to Advance Camp.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: 9,
        title: "Advance Camp to Base Camp and Drive to Leh",
        details: [
          "Descend to Base Camp in the morning.",
          "Drive back to Leh (5-6 hours).",
          "Celebrate the successful expedition.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 10,
        title: "Buffer Day",
        details: [
          "A contingency day in case of bad weather or delays during the climb.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "p6250",
    individualExpeditionName: "P6250",
    carouselData: expedition5000MHeroSection,
    location: "Manali",
    duration: "7 Days",
    grade: "Beginners",
    maxAltitude: "5289 m or 17, 349 ft",
    bestSeason: "May, June, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Friendship Peak, a mountain in Manali, Himachal Pradesh stands tall at 5,289 meters. It's like a magnet for folks who love trekking. Though it’s a trekking peak, the duration to reach the top, the gradient, and the efforts required, make it a worthy first climb for those who wish to enter the world of climbing. Picture this: snow all around, stunning views, and glaciers showing off. So, if you're into trekking with awesome scenery and good vibes, Friendship Peak in the Himalayas is your kind of adventure – no fancy words needed. Just a solid mountain and a great time!",
    aboutExpeditionExperienced:
      "Climb Friendship Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Leh (3500m)",
        details: [
          "Arrival in Leh, Ladakh.",
          "Acclimatization and rest.",
          "Briefing about the expedition and gear check.",
        ],
      },
      {
        day: "2",
        title: "Explore Leh and Acclimatization",
        details: [
          "Short acclimatization hike around Leh to adjust to the altitude.",
          "Explore local attractions like Shanti Stupa and Leh Palace.",
          "Final preparation for the expedition.",
        ],
      },
      {
        day: "3",
        title: "Drive from Leh to Tso Kar via Tanglang La Pass (4500m)",
        details: [
          "Distance: Approx. 150 km, 5-6 hours.",
          "Cross the stunning Tanglang La Pass, one of the highest motorable passes.",
          "Reach Tso Kar, a beautiful high-altitude saltwater lake.",
          "Overnight stay at Tso Kar.",
        ],
      },
      {
        day: "4",
        title: "Drive from Tso Kar to Base Camp (5200m)",
        details: [
          "Scenic drive through remote terrains of Ladakh.",
          "Set up Base Camp near the mountain.",
          "Short hike for acclimatization.",
        ],
      },
      {
        day: "5",
        title: "Rest and Acclimatization at Base Camp (5200m)",
        details: [
          "Spend the day acclimatizing to high altitude.",
          "Practice climbing techniques and gear familiarization.",
          "Rest and prepare for the summit push.",
        ],
      },
      {
        day: "6",
        title: "Summit Day (6250m) and Return to Base Camp",
        details: [
          "Early start (around midnight).",
          "Challenging climb to the summit with breathtaking views of the surrounding peaks.",
          "Descend safely back to Base Camp.",
        ],
      },
      {
        day: "7",
        title: "Drive from Base Camp to Leh",
        details: [
          "Pack up and bid farewell to Base Camp.",
          "Return to Leh via the scenic route.",
          "Celebrate the successful expedition and rest.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Accommodation in Manali on Day 1 and Day 6 (as applicable)",
          "Camping arrangements during the trek",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and hygienic meals throughout the expedition",
          "Breakfast, lunch, and dinner are provided during the expedition days",
          "Special arrangements for dietary preferences if communicated in advance",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: ["Transportation to and from climbing locations."],
      },
      {
        sectionTitle: "Guides and Support",
        content: [
          "Experienced trek leader and support staff",
          "Local guides familiar with the terrain",
          "Support staff for camping and logistics",
        ],
      },
      {
        sectionTitle: "Permits and Entrance Fees",
        content: [
          "All necessary permits and entry fees",
          "Permissions for climbing in the region",
          "Indian Mountaineering Foundation (IMF) registration",
        ],
      },
      {
        sectionTitle: "Safety and Medical Assistance",
        content: [
          "First aid kit and basic medical assistance",
          "Emergency evacuation plans in place",
          "Experienced guides trained in high-altitude rescue operations",
        ],
      },
      {
        sectionTitle: "Equipment",
        content: [
          "Tents, sleeping bags, and other camping equipment",
          "Safety equipment for the climb",
          "Personal gear check and assistance",
        ],
      },
      {
        sectionTitle: "Climbing Assistance",
        content: [
          "Guidance and support during the summit day",
          "Assistance with technical aspects of the climb",
        ],
      },
      {
        sectionTitle: "Documentation",
        content: [
          "Detailed itinerary and information before the climb",
          "Completion certificates for the participants",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking/climbing",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the climb organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the climb.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "chamserKangri",
    individualExpeditionName: "Chamser Kangri",
    carouselData: expedition6000MHeroSection,
    location: "Korzog, Ladakh",
    duration: "9 Days",
    grade: "Beginners",
    maxAltitude: "6620 m or 21,765 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Chamsher Kangri is a winter wonderland that captivates with its velvety snow, crisp air, and breathtaking vistas—a dreamlike adventure awaiting. Immerse yourself in the tale of this frosty escapade, a memory you'll cherish, an experience draped in nostalgia, and a journey you'll wish would linger.Embark on this exhilarating odyssey, commencing with a gradual trek through the renowned Markha Valley. The path leads to the majestic Chamsher Kangri, standing tall at 21,765 ft., overlooking the divine Tsomoriri Lake in the enchanting Changthang plateau. Markha Valley, a quintessential trek, unfolds the charm of living in Ladakh, one of the world's most exquisite locales.Traverse quaint villages, glimpsing the ethnic tapestry of Ladakh, and cross expansive grazing lands en route to the formidable Kongmaru La. Conquer the lower twin peaks of Chamsher and Lungsher in the Rupshu region, with Chamsher's lofty summit delivering an authentic mountaineering thrill. This journey is not just an ascent; it's a unique window into the nomadic life of Changpa Nomads, wandering the vast landscapes of Changthang.",
    aboutExpeditionExperienced:
      "Climb Chamaner Kangri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh",
        details: [
          "Arrive in Leh and check into your hotel.",
          "Rest and acclimate to the high altitude.",
        ],
      },
      {
        day: 2,
        title: "Explore Local Leh",
        details: [
          "Discover Leh's attractions: Shanti Stupa, Leh Palace, and Mall Road.",
          "Indulge in regional cuisine at local food cafes.",
          "Shop for expedition gear.",
        ],
      },
      {
        day: 3,
        title: "Drive from Leh to Karzok Village (4595 m)",
        details: [
          "Scenic drive to Karzok Village at 4595 meters.",
          "Trek for 5-6 hours to the village.",
          "Rest and enjoy a meal in homestays or camps.",
        ],
      },
      {
        day: 4,
        title: "Explore Tso Moriri Lake and Acclimatize",
        details: [
          "Explore the mesmerizing Tso Moriri Lake.",
          "Acclimatize to the cold desert environment.",
        ],
      },
      {
        day: 5,
        title: "Trek to Base Camp (5200 m)",
        details: [
          "Trek for 6-8 hours to reach the Base Camp at 5200 meters.",
          "Set up camp and rest in preparation for the summit.",
        ],
      },
      {
        day: 6,
        title: "Rest and Acclimatization at Base Camp (5200 m)",
        details: [
          "Take a day to rest and acclimate at the Base Camp.",
          "Engage in light activities and enjoy the surroundings.",
        ],
      },
      {
        day: 7,
        title: "Base Camp to Summit Camp (5700 m)",
        details: [
          "Trek for 4-5 hours to reach the Summit Camp at 5700 meters.",
          "Set up camp and prepare for the summit push.",
        ],
      },
      {
        day: 8,
        title: "Summit Day - Chamsar Kang Ri (6622 m)",
        details: [
          "Early morning trek to the summit, taking 5-6 hours.",
          "Summit Chamsar Kang Ri at 6622 meters.",
          "Descend back to the Base Camp after a successful summit.",
        ],
      },
      {
        day: 9,
        title: "Base Camp to Karzok and Drive Back to Leh",
        details: [
          "Trek from Base Camp to Karzok Village.",
          "Drive back to Leh, concluding the expedition.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "lungserKangri",
    individualExpeditionName: "Lungser Kangri",
    carouselData: expedition6000MHeroSection,
    location: "Korzog, Ladakh",
    duration: "9 Days",
    grade: "Beginners",
    maxAltitude: "6666 m or 21870 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Mount Lung Sar Kang Ri stands as an awe-inspiring peak, beckoning adventure enthusiasts to embark on a journey of a lifetime. With an elevation of 6666 meters, this formidable mountain offers an exhilarating challenge and a breathtaking panorama of the Himalayan region. The expedition commences in Leh, where travelers acclimate to the high altitude, explore local attractions, and indulge in Ladakhi cuisine.As the journey progresses, participants drive to Karzok Village, situated at 4595 meters, before undertaking a trek to the mesmerizing Tso Moriri Lake for acclimatization. The trek then leads to the Base Camp at 5200 meters, allowing for vital rest before the ascent to the Summit Camp at 5700 meters. The pinnacle of the adventure arrives on Summit Day, with an early morning trek culminating in the triumph of conquering Lung Sar Kang Ri at 6666 meters. This expedition seamlessly weaves cultural exploration, natural wonders, and the thrill of high-altitude trekking, making it an unforgettable odyssey in the heart of Ladakh's pristine beauty.",
    aboutExpeditionExperienced:
      "Climb Lungser Kangri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali (2050m)",
        details: [
          "Arrive in Manali and meet the expedition team.",
          "Orientation and gear check.",
          "Rest and acclimatization.",
          "Overnight stay in Manali (hotel).",
        ],
      },
      {
        day: "2",
        title: "Manali to Dhundi and Trek to Bakarthach (3300m)",
        details: [
          "Drive from Manali to Dhundi (approx. 2 hours).",
          "Trek to Bakarthach through beautiful alpine meadows (4–5 hours).",
          "Set up camp and spend the night in tents.",
        ],
      },
      {
        day: "3",
        title: "Trek to Beas Kund Base Camp (3700m)",
        details: [
          "Trek to Beas Kund, a sacred glacial lake (4–5 hours).",
          "Explore the surroundings and acclimatize.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "4",
        title: "Trek to Advanced Camp (4200m)",
        details: [
          "Ascend to Advanced Camp (approx. 4200m).",
          "Technical briefing and equipment preparation.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "5",
        title: "Load Ferry to Summit Camp (4800m)",
        details: [
          "Carry loads and establish Summit Camp.",
          "Return to Advanced Camp for the night.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "6",
        title: "Move to Summit Camp (4800m)",
        details: [
          "Trek to Summit Camp with full gear.",
          "Final briefing and preparation for the summit attempt.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "7",
        title: "Summit Day (5300m)",
        details: [
          "Early morning push for Mt. Ladkahi Peak summit.",
          "Enjoy panoramic views of the Himalayas.",
          "Descend to Summit Camp.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: "8",
        title: "Descend to Dhundi and Drive to Manali",
        details: [
          "Descend to Advanced Camp and further to Dhundi.",
          "Drive back to Manali.",
          "Celebrate the successful expedition.",
          "Overnight stay in Manali (hotel).",
        ],
      },
      {
        day: "Buffer Day",
        title: "Reserved for Unforeseen Delays",
        details: ["Reserved for unforeseen weather conditions or delays."],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "lagpoNorth",
    individualExpeditionName: "Lagpo North",
    carouselData: expedition6000MHeroSection,
    location: "Karzog, Ladakh",
    duration: "10 Days",
    grade: "Intermediate",
    maxAltitude: "6360 m or 20, 866 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Get ready for an adrenaline-pumping quest to Lagpo North, soaring at a jaw-dropping 6360 meters in Leh's heart. This 10-day thrill ride kicks off in Leh, where you'll acclimate to dizzying altitudes. The journey weaves through Karzok village, treating your eyes to Tso Moriri Lake's breathtaking vistas.Trek to the 5500-meter-high base camp, your launchpad for the ultimate challenge – a heart-pounding, 6360-meter summit attempt on Lagpo North. Celebrate amidst the Himalayan grandeur, crafting memories that echo with the thrill of scaling nature's towering heights. Get ready for a wild ride into the heart of adventure!",
    aboutExpeditionExperienced:
      "Climb Lagpo Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh (3,500 M)",
        details: [
          "Arrive in Leh and check into accommodation.",
          "Rest and acclimatize to the high altitude.",
        ],
      },
      {
        day: 2,
        title: "Local Sightseeing and Acclimatization Walk",
        details: [
          "Explore Leh's local attractions, market, and experience the high-mountain city's culture.",
          "Acclimatization walk to aid in adjusting to the altitude.",
          "Group debriefing session on upcoming days, safety measures, and mountain etiquette.",
        ],
      },
      {
        day: 3,
        title: "Drive from Leh to Karzok Village",
        details: [
          "Journey from Leh to Karzok village, enjoying scenic views.",
          "Rest upon reaching Karzok and prepare for further acclimatization.",
        ],
      },
      {
        day: 4,
        title: "Acclimatize and Explore Tso Moriri Lake (4,500 M)",
        details: [
          "Acclimatization activities in Karzok.",
          "Explore the breathtaking Tso Moriri Lake at an altitude of 4,500 meters.",
        ],
      },
      {
        day: 5,
        title: "Karzok to Base Camp Trek (5,500 M)",
        details: [
          "Begin trekking from Karzok to the base camp.",
          "Trek for 4-5 hours, reaching an altitude of 5,500 meters.",
          "Set up base camp and acclimatize.",
        ],
      },
      {
        day: 6,
        title: "Acclimatization Day at Base Camp",
        details: [
          "Rest and acclimatize at the base camp (5,500 meters).",
          "Short walks to maintain physical activity while adjusting to the altitude.",
        ],
      },
      {
        day: 7,
        title: "Base Camp to Summit Camp Trek (5,800 M)",
        details: [
          "Trek from the base camp to the summit camp.",
          "Trek for 3-4 hours, reaching an altitude of 5,800 meters.",
          "Prepare for the summit push.",
        ],
      },
      {
        day: 8,
        title: "Summit Day - Lagpo North (6360m)",
        details: [
          "Early morning trek from the summit camp to the peak of Lagpo North (6360m).",
          "Approximately 5 hours to summit and return to the base camp.",
          "Celebrate the achievement and rest at the base camp.",
        ],
      },
      {
        day: 9,
        title: "Trek from Base Camp to Karzok",
        details: [
          "Descend from the base camp to Karzok village.",
          "Reflect on the journey and enjoy the scenic landscapes.",
        ],
      },
      {
        day: 10,
        title: "Drive Back to Leh from Karzok",
        details: [
          "Drive back to Leh from Karzok.",
          "Conclude the expedition with fond memories and a sense of accomplishment.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the Deo Tibba expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Deo Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "chalung",
    individualExpeditionName: "Chalung",
    carouselData: expedition6000MHeroSection,
    location: "Ladakh",
    duration: "10 Days",
    grade: "Intermediate",
    maxAltitude: "6446 m or 21148 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Prepare for an exhilarating odyssey through Ladakh's untamed wilderness, where the imposing Chalung Peak (6,446 meters) stands as a formidable challenge for intrepid explorers. Over 10 pulse-pounding days, traverse the rugged landscapes of Leh and Karzok Village, immersing yourself in the vibrant high-mountain culture.Amidst the thin air and pristine beauty, acclimatize at the enchanting Tso Moriri Lake and establish a base camp at 5,500 meters. Chart an audacious course from Karzok to the summit camp at 5,800 meters, setting the stage for the ultimate conquest of Chalung Peak. This epic journey, pulsating with raw adventure, promises not just panoramic views but an indomitable test of resilience and an untold chapter in the high-altitude chronicles of Ladakh's allure. ",
    aboutExpeditionExperienced:
      "Climb Chalung Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh (3500m)",
        details: [
          "Arrive in Leh and transfer to your hotel.",
          "Rest and acclimatize to the altitude.",
          "Explore Leh’s markets and monasteries if feeling comfortable.",
        ],
      },
      {
        day: 2,
        title: "Acclimatization in Leh",
        details: [
          "Take a short hike to nearby spots like Shanti Stupa or Leh Palace.",
          "Optional visit to Shey and Thiksey monasteries for light activity.",
          "Focus on hydration and acclimatization.",
        ],
      },
      {
        day: 3,
        title: "Drive from Leh to Sumdho (4500m)",
        details: [
          "Drive along scenic routes with views of barren landscapes and valleys.",
          "Arrive at Sumdho village, the starting point for the trek.",
          "Overnight stay in tents.",
        ],
      },
      {
        day: 4,
        title: "Trek from Sumdho to Base Camp (5200m)",
        details: [
          "Trek duration: 7-8 hours.",
          "Traverse rocky trails and river crossings.",
          "Gradual ascent to base camp, surrounded by stunning Himalayan vistas.",
          "Set up camp and rest.",
        ],
      },
      {
        day: 5,
        title: "Rest and Acclimatization at Base Camp (5200m)",
        details: [
          "Spend the day acclimatizing to the high altitude.",
          "Optional short hikes around base camp to improve acclimatization.",
          "Final preparation for the summit push.",
        ],
      },
      {
        day: 6,
        title: "Trek from Base Camp to Summit Camp (5900m)",
        details: [
          "Trek duration: 4-5 hours.",
          "Steep ascent over rocky and glacial terrain.",
          "Set up camp near the summit route.",
          "Early dinner and rest for the summit attempt.",
        ],
      },
      {
        day: 7,
        title: "Summit Day (6446m) and Return to Base Camp (5200m)",
        details: [
          "Start the climb early (around midnight).",
          "Challenging ascent over snow and ice to the summit.",
          "Enjoy panoramic views of the Ladakh and Zanskar ranges from the top.",
          "Descend back to base camp for rest.",
        ],
      },
      {
        day: 8,
        title: "Trek from Base Camp to Sumdho",
        details: [
          "Retrace the route back to Sumdho.",
          "Trek duration: 6-7 hours.",
          "Overnight stay in Sumdho.",
        ],
      },
      {
        day: 9,
        title: "Drive from Sumdho to Leh",
        details: [
          "Scenic drive back to Leh.",
          "Relax and celebrate successful summit.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the Deo Tibba expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Deo Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "shukule",
    individualExpeditionName: "Shukule",
    carouselData: expedition6000MHeroSection,
    location: " Karzog, Ladakh",
    duration: "10 Days",
    grade: "Intermediate",
    maxAltitude: "6508 m or 21,351 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Nestled in the awe-inspiring landscapes of the Himalayas, Shukule Peak stands majestically at 6508 meters, offering a captivating challenge for seasoned mountaineers. This high-altitude expedition unfolds over ten exhilarating days, commencing in Leh at 3500 meters. The journey includes cultural immersion in Leh, a scenic drive to Karzok Village, and acclimatization activities along the pristine Tso Moriri Lake at 4500 meters.The ascent continues with a trek to the Base Camp at 5500 meters, allowing adventurers to acclimatize well. A dedicated acclimatization day precedes the trek to the Summit Camp at 5800 meters. The pinnacle of the expedition arrives on Summit Day, as climbers embark on an early morning trek to Shukule's apex, experiencing the triumph of reaching 6508 meters. The descent retraces the route through Karzok, culminating in the return to Leh, leaving wonderful memories of a challenging yet rewarding journey to the summit of Shukule Peak.",
    aboutExpeditionExperienced:
      "Climb Shukule Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrive in Leh (3,500 M)",
        details: [
          "Arrive in Leh and settle into accommodation.",
          "Rest and allow the body to acclimatize to the high altitude.",
        ],
      },
      {
        day: 2,
        title: "Local Sightseeing and Acclimatization Walk",
        details: [
          "Explore Leh's local attractions, market, and immerse in the high-mountain city's culture.",
          "Acclimatization walk to help the body adjust to the altitude.",
          "Group debriefing session to discuss the upcoming days, safety measures, and mountain etiquette.",
        ],
      },
      {
        day: 3,
        title: "Drive from Leh to Karzok Village",
        details: [
          "Journey from Leh to Karzok village.",
          "Take in the scenic views along the way.",
          "Rest upon reaching Karzok and prepare for further acclimatization.",
        ],
      },
      {
        day: 4,
        title: "Acclimatize and Explore Tso Moriri Lake (4,500 M)",
        details: [
          "Acclimatization activities in Karzok.",
          "Explore the breathtaking Tso Moriri Lake at an altitude of 4,500 meters.",
        ],
      },
      {
        day: 5,
        title: "Karzok to Base Camp Trek (5,500 M)",
        details: [
          "Begin trekking from Karzok to the base camp.",
          "Trek for 4-5 hours, reaching an altitude of 5,500 meters.",
          "Set up base camp and acclimatize.",
        ],
      },
      {
        day: 6,
        title: "Acclimatization Day at Base Camp",
        details: [
          "Rest and acclimatize at the base camp (5,500 meters).",
          "Short walks to maintain physical activity while allowing the body to adjust.",
        ],
      },
      {
        day: 7,
        title: "Base Camp to Summit Camp Trek (5,800 M)",
        details: [
          "Trek from the base camp to the summit camp.",
          "Trekking for 3-4 hours, reaching an altitude of 5,800 meters.",
          "Prepare for the summit push.",
        ],
      },
      {
        day: 8,
        title: "Summit Day - (Shukule 6508)",
        details: [
          "Early morning trek from summit camp to the peak of Mount Shukule 6508.",
          "Approximately 5 hours to summit and return to the base camp.",
          "Celebrate the achievement and rest at the base camp.",
        ],
      },
      {
        day: 9,
        title: "Trek from Base Camp to Karzok",
        details: [
          "Descend from the base camp to Karzok village.",
          "Reflect on the journey and enjoy the scenic landscapes.",
        ],
      },
      {
        day: 10,
        title: "Drive Back to Leh from Karzok",
        details: [
          "Drive back to Leh from Karzok.",
          "Conclude the expedition with fond memories and a sense of accomplishment.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the Deo Tibba expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Deo Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "cb13",
    individualExpeditionName: "CB13",
    carouselData: expedition6000MHeroSection,
    location: "Lahaul & Spiti",
    duration: "10 Days",
    grade: "Intermediate",
    maxAltitude: "6264 m or 20551 ft",
    bestSeason: "July, August, September, and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Welcome to the formidable challenge of conquering the Chandrabhaga-13 (CB13) peak, a relentless endeavor that beckons seasoned mountaineers. CB13, standing tall at 6,264 meters, unfolds a saga of treacherous terrain, unpredictable weather, and a demanding ascent that pushes the limits of human endurance. This summit, nestled in the Lahaul region of Himachal Pradesh, is renowned for its dynamic conditions, with climbers navigating through steep slopes, rocky outcrops, and snow-covered expanses. As climbers, you'll delve into the intricacies of this high-altitude expedition, grappling with nature's forces while strategically planning rotations between camps. Beyond the conventional, CB13 presents an authentic challenge, where resilience meets the raw grandeur of the mountains.",
    aboutExpeditionExperienced:
      "Climb CB 13 Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Manali (2,050 M)",
        details: [
          "Arrive in Manali, a high-altitude backpacking center.",
          "Participants settle into accommodation.",
          "Allow time for acclimatization and introduction to mountain rules.",
          "Briefing on route, terrain, weather, and CB13 expectations.",
        ],
      },
      {
        day: 2,
        title: "Manali (2,050M) to Batal (4,060M)",
        details: [
          "Depart between 9 and 10 AM after breakfast.",
          "Leisurely 5-6 hour drive to Batal, the expedition trailhead.",
          "Batal: Basecamp for Chandratal Lake; situated in Lahaul district, Himachal Pradesh.",
        ],
      },
      {
        day: 3,
        title: "Batal (4,060M) to Base Camp (4,440M)",
        details: [
          "Trekking distance: 10 kms; Duration: 7 hours.",
          "Commence trek after early breakfast at 7 AM.",
          "Encounter challenges like crossing Pagal Nalla and multiple streams.",
          "Steep ascent of 300M, followed by a climb through green rocky paths and boulders.",
          "Reach Base Camp (4,440M) after a mostly downhill last kilometer.",
        ],
      },
      {
        day: 4,
        title: "Acclimatization at Base Camp (4,440M)",
        details: [
          "Morning puja for safety and success.",
          "Set up gear, technical training, and basic technique practice.",
          "Full day for rest, acclimatization, and adjustment to base camp conditions.",
        ],
      },
      {
        day: "5 to 7",
        title: "Summit Attempt (Subject to Conditions)",
        details: [
          "Variable schedule due to unpredictable high-altitude conditions.",
          "Rotation rounds between camps for acclimatization.",
          "Advance Base Camp (4,860M) to Summit Camp (5,650M) with load ferry and occupy.",
          "Summit attempt from Summit Camp to Summit of CB13 (6,264M).",
          "Return to Advance Base Camp and further descent if conditions allow.",
          "Extra days for extreme snow conditions.",
        ],
      },
      {
        day: "8/9",
        title: "Descent - Advance Base Camp (4,900M) to Base Camp (4,440M)",
        details: [
          "Trekking distance: 3.5-4 kms; Duration: 2.5-3.5 hours.",
          "Retrace steps back to Base Camp along the ascent route.",
        ],
      },
      {
        day: 9,
        title: "Base Camp (4,440M) to Batal (4,060M)",
        details: [
          "Trekking distance: Same as ascent; Duration: Same as ascent.",
          "Return to Batal; camp overnight for early departure to Manali.",
        ],
      },
      {
        day: 10,
        title: "Batal (4,060M) to Manali (2,050M)",
        details: [
          "Return to Manali, plan next day's journey ahead for any unforeseen delays.",
          "Expect to reach Manali by evening.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the Deo Tibba expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Deo Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "menthosa",
    individualExpeditionName: "Menthosa",
    carouselData: expedition6000MHeroSection,
    location: "Lahaul & Spiti",
    duration: "9+3 Days",
    grade: "Intermediate",
    maxAltitude: "6443 m or 21138 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Mount Menthosa, standing tall at 6,443 meters, is a formidable peak in the Indian Himalayas, beckoning adventure enthusiasts to experience the raw, untamed beauty of its challenging slopes. Situated in the Pir Panjal range, this towering giant presents a thrilling ordeal for mountaineers seeking an authentic test of skill and endurance. The rugged terrain, with its unpredictable weather and demanding altitudes, adds an element of unpredictability to the ascent, making every step a true adventure. Menthosa's snow-capped peaks and ice-covered faces serve as a canvas for a daring narrative of exploration, where nature's forces and human determination converge in a high-altitude drama. This isn't just a climb; it's an immersive saga of resilience, where the mountain and the mountaineer engage in a dance of courage and tenacity.",
    aboutExpeditionExperienced:
      "Climb Menthosa Peak with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali and check into a hotel.",
          "Explore nearby sightseeing spots.",
          "Shop for personal gear in the local market.",
        ],
      },
      {
        day: 2,
        title: "Drive from Manali to Shukto",
        details: [
          "Begin the long drive from Manali to Shukto, approximately 3-4 hours.",
          "Enjoy the scenic journey through the beautiful landscapes.",
        ],
      },
      {
        day: 3,
        title: "Drive from Shukto to Urgos Nallah",
        details: [
          "Continue the drive to Urgos Nallah.",
          "Acclimatize and settle down in Urgos Nallah at an altitude of 3,200 meters.",
        ],
      },
      {
        day: 4,
        title: "Trek from Urgos to Base Camp (4,450 M)",
        details: [
          "Trek for 7 hours from Urgos to the base camp at an altitude of 4,450 meters.",
          "Enjoy the picturesque surroundings and set up camp.",
        ],
      },
      {
        day: 5,
        title: "Base Camp Acclimatization Day",
        details: [
          "Acclimatization day at the base camp.",
          "Rest in tents, take walks, and prepare for the upcoming trekking days.",
        ],
      },
      {
        day: 6,
        title: "Base Camp to Camp 1 (5,000 M)",
        details: [
          "Trek from the base camp to Camp 1 at an altitude of 5,000 meters.",
          "4-5 hours of trekking through challenging terrains.",
        ],
      },
      {
        day: 7,
        title: "Camp 1 to Camp 2 (5,700 M)",
        details: [
          "Trek from Camp 1 to Camp 2, gaining an altitude of 5,700 meters.",
          "A challenging 8-hour trek.",
        ],
      },
      {
        day: 8,
        title: "Camp 2 to Summit Camp (6,000 M)",
        details: [
          "Trek from Camp 2 to the summit camp at 6,000 meters.",
          "4-5 hours of trekking, rest, and preparation for the summit day.",
        ],
      },
      {
        day: 9,
        title: "Summit Day (6,443 M)",
        details: [
          "Summit push of 7-8 hours to reach the peak of Mount Menthosa (6,443 meters).",
          "Descend to the summit camp, rest, and celebrate the achievement.",
        ],
      },
      {
        day: 10,
        title: "Descend to Base Camp and Return to Urgos Village",
        details: [
          "Trek from the summit camp to the base camp.",
          "Descend to Urgos village and drive back to Manali.",
        ],
      },
      {
        day: "Buffer Days",
        title: "Buffer Days",
        details: [
          "Keep 3 buffer days in case of weather changes or unforeseen circumstances that may impact the itinerary.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the Deo Tibba expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Deo Tibba Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },

  // Pure adrenaline

  {
    individualExpeditionType: "papsura",
    individualExpeditionName: "Papsura",
    carouselData: expeditionPureAdrenalineHeroSection,
    location: "Parvati Valley, Himachal Pradesh",
    duration: "10 Days",
    grade: "Advanced",
    maxAltitude: "6450 M or 21160 ft",
    bestSeason: "May, June, September and October",
    experienceRequired: "For experienced climbers only.",
    mainExpeditionContent:
      "Papsura, known as the 'Peak of Evil', is a tough mountain in Himachal Pradesh, India, standing at about 6,450 meters (21,160 feet). Its distinct pyramid shape attracts experienced climbers. The climb involves tackling glaciers, rocks, and steep slopes, and climbers use specialized gear like ice axes and ropes.Reaching Papsura's summit is a challenging adventure, set against stunning landscapes of meadows and snow-covered peaks. The nickname 'Peak of Evil' hints at the difficulties faced by climbers due to unpredictable weather and technical challenges. Climbers need good preparation, including physical fitness and adjusting to high altitudes. Conquering Papsura offers a rewarding view from the top, showcasing the beauty of the Himalayas. Overall, Papsura is a tough but awe-inspiring mountain, inviting adventurers to test their skills in the heart of nature.",
    aboutExpeditionExperienced:
      "Climb Papsura with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Manali (2000m)",
        details: [
          "Arrive in Manali and check into a hotel.",
          "Meet the team for a briefing on the expedition, route, and safety measures.",
          "Gear check to ensure all equipment is in order.",
          "Explore the vibrant streets of Manali, visit local markets, or relax.",
          "Meals: Dinner.",
          "Accommodation: Hotel in Manali.",
        ],
      },
      {
        day: 2,
        title: "Manali to Chatru (3360m) | 3-4 Hours Drive",
        details: [
          "Drive via the iconic Rohtang Pass (3978m), descending into the Spiti Valley.",
          "Scenic journey with stunning views of the Chandra River and Himalayan landscapes.",
          "Set up camp at Chatru, a serene spot surrounded by mountains.",
          "Orientation session and an introduction to high-altitude trekking protocols.",
          "Meals: Breakfast, Lunch, Dinner.",
          "Accommodation: Tents at Chatru.",
        ],
      },
      {
        day: 3,
        title: "Chatru to Base Camp (Approx. 4000m) | 6-7 Hours Trek",
        details: [
          "Trek along riverbanks, through rocky terrain, and lush meadows.",
          "Begin the trek towards Base Camp, crossing streams and boulder fields.",
          "Gradual ascent while enjoying views of snow-clad peaks and glaciers.",
          "Reach Base Camp and set up tents.",
          "Rest and acclimatize at the campsite.",
          "Meals: Breakfast, Lunch, Dinner.",
          "Accommodation: Tents at Base Camp.",
        ],
      },
      {
        day: 4,
        title: "Base Camp to Advance Camp (Approx. 4800m) | 5-6 Hours Trek",
        details: [
          "Steady ascent through moraine fields and glacial ridges.",
          "Trek to Advance Camp while gaining altitude gradually.",
          "Stunning views of Papsura Peak and surrounding glaciers.",
          "Set up Advance Camp and rest.",
          "Meals: Breakfast, Lunch, Dinner.",
          "Accommodation: Tents at Advance Camp.",
        ],
      },
      {
        day: 5,
        title: "Rest and Acclimatization at Advance Camp (4800m)",
        details: [
          "Spend the day acclimatizing to the altitude and preparing for higher camps.",
          "Technical briefing on climbing techniques, use of ropes, and crampons.",
          "Practice climbing skills and review summit plans.",
          "Hydrate well and rest to conserve energy.",
          "Meals: Breakfast, Lunch, Dinner.",
          "Accommodation: Tents at Advance Camp.",
        ],
      },
      {
        day: 6,
        title: "Advance Camp to Summit Camp (Approx. 5500m) | 4-5 Hours Trek",
        details: [
          "Trek through steep icefields, rocky ridges, and challenging terrain.",
          "Move to Summit Camp with gradual ascent to minimize exertion.",
          "Set up a high-altitude camp and prepare for summit push.",
          "Review summit day plans and ensure all gear is ready.",
          "Meals: Breakfast, Lunch, Dinner.",
          "Accommodation: Tents at Summit Camp.",
        ],
      },
      {
        day: 7,
        title: "Rest and Acclimatization at Summit Camp (5500m)",
        details: [
          "Spend the day resting and acclimatizing to prepare for summit day.",
          "Final gear check and mental preparation for the summit attempt.",
          "Hydrate well and review safety protocols.",
          "Meals: Breakfast, Lunch, Dinner.",
          "Accommodation: Tents at Summit Camp.",
        ],
      },
      {
        day: 8,
        title: "Summit Day (6451m) and Return to Summit Camp | 10-12 Hours",
        details: [
          "Technical climb through snow, ice, and mixed terrain.",
          "Early alpine start (around midnight) for the summit push.",
          "Navigate technical sections using ropes, crampons, and ice axes.",
          "Reach the summit of Papsura Peak (6451m) and enjoy panoramic Himalayan views.",
          "Capture memories and descend carefully to Summit Camp.",
          "Meals: Breakfast, Packed Lunch, Dinner.",
          "Accommodation: Tents at Summit Camp.",
        ],
      },
      {
        day: 9,
        title: "Summit Camp to Base Camp | 6-7 Hours Trek",
        details: [
          "Descend through Advance Camp to Base Camp.",
          "Retrace the path, descending through challenging but rewarding terrain.",
          "Celebrate the success of the summit with the team at Base Camp.",
          "Meals: Breakfast, Lunch, Dinner.",
          "Accommodation: Tents at Base Camp.",
        ],
      },
      {
        day: 10,
        title: "Base Camp to Chatru and Drive to Manali | 7-8 Hours",
        details: [
          "Trek back to Chatru and drive to Manali.",
          "Complete the trek to the road head at Chatru.",
          "Drive back to Manali, enjoying the beautiful landscapes en route.",
          "Check into a hotel and enjoy a celebratory dinner.",
          "Meals: Breakfast, Lunch, Dinner.",
          "Accommodation: Hotel in Manali.",
        ],
      },
      {
        day: "Buffer Days",
        title: "Buffer Days (Day 11 & Day 12)",
        details: [
          "Reserved for weather delays or unexpected circumstances during the expedition.",
          "If unused, explore Manali or relax after the adventure.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali & Tosh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "reoPurgyil",
    individualExpeditionName: "Reo Purgyil",
    carouselData: expeditionPureAdrenalineHeroSection,
    location: "Nako, Himachal Pradesh",
    duration: "13+2 Days",
    grade: "Advanced",
    maxAltitude: "6816 m or 22362 ft",
    bestSeason: "May, June, September and October",
    experienceRequired: "For experienced climbers only.",
    mainExpeditionContent:
      "Reo Purgyil is the highest mountain in the Indian state of Himachal Pradesh and the second-highest peak in the Indian Himalayas. It is situated in the Kinnaur district near the Tibetan border. Reo Purgyil has an elevation of 6,816 meters (22,362 feet) above sea level. The mountain is known for its challenging terrain and climbing conditions. It is a popular destination for mountaineers and trekkers, offering stunning views of the surrounding landscapes. As soon as start trekking after the base camp, things start to get super challenging. The entire route is filled with moraines. Negotiating carefully, you manage to reach the advanced camp. From here on, the climb begins. As a climber, you need to have experience with tough and challenging peaks if you wish to ace Reo Purgyil. It will require negotiating sharp inclined slopes, and rocky and icy patches. The last few 100 mtrs are pretty demanding. All in all, you are in for an adrenaline-rich adventure of a lifetime.",
    aboutExpeditionExperienced:
      "Climb Reo Purgyil with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Shimla to Kalpa",
        details: [
          "Distance: Approximately 220 km (8–9 hours drive).",
          "Start early from Shimla, driving through scenic routes crossing Narkanda, Rampur, and Kinnaur.",
          "Reach Kalpa by evening. Overnight stay in Kalpa.",
        ],
      },
      {
        day: "2",
        title: "Kalpa to Nako",
        details: [
          "Distance: Approximately 110 km (3–4 hours drive).",
          "Complete permits and documentation in Kalpa in the morning.",
          "Drive towards Nako through picturesque Spiti Valley landscapes.",
          "Explore Nako village or relax by the serene Nako Lake. Overnight stay in Nako.",
        ],
      },
      {
        day: "3",
        title: "Rest and Acclimatization in Nako",
        details: [
          "Take the day to adapt to the altitude (~3,662m).",
          "Go for a short hike around Nako, visiting nearby monasteries or exploring local trails.",
          "Prepare gear for the expedition. Overnight stay in Nako.",
        ],
      },
      {
        day: "4",
        title: "Nako to Transit Camp (4,800m)",
        details: [
          "Drive and trek to reach the transit camp located at 4,800 meters.",
          "Set up tents and settle for the night. Start acclimatizing to higher altitude.",
        ],
      },
      {
        day: "5",
        title: "Transit Camp to Base Camp (5,500m)",
        details: [
          "Trek Duration: Approximately 6 hours.",
          "Trek to base camp, gradually ascending through rugged terrain.",
          "Set up base camp and enjoy breathtaking views of the surrounding peaks. Overnight at base camp.",
        ],
      },
      {
        day: "6",
        title: "Rest and Acclimatization at Base Camp",
        details: [
          "Take the day to rest and acclimatize at the base camp (~5,500m).",
          "Perform light activities, practice with climbing gear, and prepare for higher altitudes.",
        ],
      },
      {
        day: "7",
        title: "Base Camp to Camp 1 (5,800m)",
        details: [
          "Trek Duration: 4–5 hours.",
          "Gradual ascent to Camp 1, navigating through rocky terrain.",
          "Set up tents and spend the night at Camp 1.",
        ],
      },
      {
        day: "8",
        title: "Rest and Acclimatization at Camp 1",
        details: [
          "Rest day to acclimatize further and prepare for the steep climb ahead.",
        ],
      },
      {
        day: "9",
        title: "Camp 1 to Summit Camp (6,100m)",
        details: [
          "Trek Duration: 4–5 hours.",
          "A steep and rocky climb to the summit camp.",
          "Set up camp at approximately 6,100 meters and prepare for the summit push.",
        ],
      },
      {
        day: "10",
        title: "Summit Reo Purgyil (6,816m)",
        details: [
          "Climb Duration: 8–10 hours (round trip).",
          "Early morning summit attempt involving steep, rocky sections.",
          "Enjoy spectacular views from the summit and return to Summit Camp or Camp 1.",
        ],
      },
      {
        day: "11",
        title: "Return to Base Camp",
        details: [
          "Descend from Summit Camp or Camp 1 to Base Camp.",
          "Rest and celebrate your successful climb.",
        ],
      },
      {
        day: "12",
        title: "Base Camp to Nako",
        details: [
          "Trek and drive back to Nako.",
          "Relax in the village and enjoy local hospitality. Overnight stay in Nako.",
        ],
      },
      {
        day: "13",
        title: "Nako to Shimla",
        details: [
          "Distance: Approximately 330 km (10–12 hours drive).",
          "Embark on a long drive back to Shimla and conclude the expedition.",
        ],
      },
      {
        day: "Reserve Days",
        title: "Reserve Days",
        details: [
          "Keep two days reserved for unforeseen circumstances or extend stays as needed.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Shimla/Manali, Reckong Peo/Kalpa, and Nako upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Shimla/Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Shimla/Manali & Reckong Peo/Kalpa",
        content: [
          "Meals in Shimla & Reckong Peo/Kalpa on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/CTIFcnKzy7g?si=N4uYbdXzGu_vm00B",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "indrasan",
    individualExpeditionName: "Indrasan",
    carouselData: expeditionPureAdrenalineHeroSection,
    location: "Manali",
    duration: "14+2 Days",
    grade: "Advanced",
    maxAltitude: "6221 m or 20411 ft",
    bestSeason: "June and September",
    experienceRequired: "For experienced climbers only.",
    mainExpeditionContent:
      "Mount Indrasan boasts a rich climbing history, serving as a magnetic force for mountaineers globally. The pinnacle of its historical significance was reached in 1961 when an Indo-Japanese expedition, under the leadership of H. Chhibber, successfully achieved the first ascent of Mount Indrasan. Renowned for its formidable terrain, Mount Indrasan presents a formidable combination of rocky surfaces and icy slopes. The climb is characterized by intricate technical challenges, including steep ascents and demanding traverses that test the mettle of even the most seasoned climbers. Nestled within the Pir Panjal range, Mount Indrasan stands as one of the eminent peaks in the region. Its significance extends beyond its sheer elevation, contributing both culturally and geographically to the breathtaking panorama of the Himalayas. As a symbol of natural grandeur, Mount Indrasan holds a paramount place in the collective consciousness of those who are drawn to the allure of challenging and awe-inspiring mountain ascents.",
    aboutExpeditionExperienced:
      "Climb Indrasan with our seasoned climbing team with over a decade of experience in the Manali. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali and check into the hotel.",
          "Explore nearby sights and attractions.",
          "Check and organize equipment.",
          "Explore the local Manali market and shop for personal gear if needed.",
        ],
      },
      {
        day: 2,
        title: "Leisure Day in Manali",
        details: [
          "Free day to rest and make final preparations for the trek.",
          "Optional activities in and around Manali.",
        ],
      },
      {
        day: 3,
        title: "Manali to Chikha",
        details: [
          "Drive from Manali to Jagathsukh Power Plant (approx. 1 hour).",
          "Trek to Shikha (3 hours).",
          "Set up camp and rest in tents.",
        ],
      },
      {
        day: 4,
        title: "Chikha to Seiri",
        details: [
          "Trek from Chikha to Seiri (4-5 hours).",
          "Acclimatization and rest.",
        ],
      },
      {
        day: 5,
        title: "Seiri to Tainta",
        details: ["Trek from Seiri to Tainta (3-4 hours)."],
      },
      {
        day: 6,
        title: "Acclimatization Day in Tainta",
        details: [
          "Rest day for acclimatization.",
          "Short acclimatization walk and planning for the upcoming days.",
        ],
      },
      {
        day: 7,
        title: "Tainta to Camp 1 (Duhangan Col)",
        details: [
          "Trek from Tainta to Camp 1 (Duhangan Col) (4-5 hours).",
          "Steep climb.",
        ],
      },
      {
        day: 8,
        title: "Camp 1 to Camp 2",
        details: [
          "Trek from Camp 1 to Camp 2 (4-5 hours).",
          "Set up camp and rest.",
        ],
      },
      {
        day: 9,
        title: "Rest and Acclimatization in Camp 2",
        details: [],
      },
      {
        day: 10,
        title: "Camp 2 to Summit Camp",
        details: ["Trek from Camp 2 to Summit Camp (3 hours)."],
      },
      {
        day: 11,
        title: "Summit Day",
        details: ["Summit Mt. Indrasan (6221 m) and return to Summit Camp."],
      },
      {
        day: 12,
        title: "Descent from Summit Camp to Base Camp (Tainta)",
        details: [
          "Trek from Summit Camp to Base Camp (Tainta) via Duhangan Col.",
        ],
      },
      {
        day: 13,
        title: "Base Camp to Chikha",
        details: ["Trek from Base Camp to Chikha."],
      },
      {
        day: 14,
        title: "Chikha to Manali",
        details: ["Drive back to Manali.", "Depart for home."],
      },
      {
        day: "Reserved",
        title: "Reserved for Weather Changes",
        details: [
          "2 Extra Days: Reserved for weather changes or unforeseen circumstances.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "mukerBeh",
    individualExpeditionName: "Muker Beh",
    carouselData: expeditionPureAdrenalineHeroSection,
    location: "Manali",
    duration: "7+2 Days",
    grade: "Advanced",
    maxAltitude: "6221 m or 20411 ft",
    bestSeason: "June and September",
    experienceRequired: "For experienced climbers only.",
    mainExpeditionContent:
      "Mount Mukerbeh, an imposing peak in the Himalayan panorama, challenges the tough mountaineer to conquer its formidable 6069-meter peak. This ascent is a pinnacle of technical expertise, where the adept climber navigates through intricate terrains and grapples with the rigidity of high-altitude mountaineering. This expedition unfolds with strategic precision, starting from the base in Manali and progressing through diverse landscapes. From the scenic Beas Kund to the technical ascent of Shitidhar Summit, each day presents a unique test of skill. Expertise is not just encouraged but essential, as climbers tackle the complexities of the route with advanced techniques and physical resilience. For the seasoned mountaineer, Mount Mukerbeh is not merely a summit—it is an immersive encounter with the very essence of high-altitude mastery. Embark on this journey, where each step is a calculated move toward the zenith of expertise and triumph over the challenges that only a mountain of this caliber can present.",
    aboutExpeditionExperienced:
      "Climb Muker Beh with our seasoned climbing team with over a decade of experience in the Manali. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali and check into the hotel.",
          "Explore nearby sights and attractions.",
          "Check and organize equipment.",
          "Explore the local Manali market and shop for personal gear if needed.",
        ],
      },
      {
        day: 2,
        title: "Manali to Bakarthatch via Dhundi",
        details: [
          "Drive from Manali to Dhundi.",
          "Trek from Dhundi to Bakarthatch (2 hours).",
          "Set up camp at Bakarthatch.",
        ],
      },
      {
        day: 3,
        title: "Bakarthatch to Beas Kund",
        details: [
          "Trek from Bakarthatch to Beas Kund (3-4 hours).",
          "Enjoy the breathtaking view of Beas Kund and Mt. Hanuman Tibba.",
          "Rest and prepare for the next day.",
          "Overnight Stay: Tents at Lady Leg Camp.",
        ],
      },
      {
        day: 4,
        title: "Trek from Lady Leg Camp to Summit Camp (4500m)",
        details: [
          "Distance: ~3-4 km (~4-5 hours).",
          "Ascend to Summit Camp, navigating through moraine and snowfields.",
          "Set up camp at a higher altitude with stunning views.",
          "Rest and hydrate for the summit push.",
          "Overnight Stay: Tents at Summit Camp.",
        ],
      },
      {
        day: 5,
        title: "Rest and Acclimatization at Summit Camp",
        details: [
          "Take it easy to conserve energy for the summit attempt.",
          "Review technical gear and practice climbing techniques.",
          "Hydrate and mentally prepare for the summit day.",
          "Overnight Stay: Tents at Summit Camp.",
        ],
      },
      {
        day: 6,
        title: "Summit Shitidhar Peak (5250m) and Descend to Beas Kund (3690m)",
        details: [
          "Start Time: Around 2:00 AM.",
          "Begin the summit push under a star-lit sky.",
          "Reach the summit by sunrise, enjoy breathtaking views of the surrounding peaks.",
          "Descend carefully to Beas Kund and set up camp.",
          "Overnight Stay: Tents at Beas Kund Camp.",
        ],
      },
      {
        day: 7,
        title: "Trek from Beas Kund to Dhundi (3100m) and Drive to Manali",
        details: [
          "Distance: 6-7 km trek + 22 km drive (~3-4 hours).",
          "Trek down to Dhundi through alpine meadows.",
          "Drive back to Manali, marking the end of the expedition.",
          "Overnight Stay: Hotel in Manali (optional).",
        ],
      },
      {
        day: "Buffer (Day 8 and Day 9)",
        title: "Reserved for unforeseen circumstances or changes in weather.",
        details: [
          "Reserved for unforeseen circumstances or changes in weather.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },

  // Trekking

  {
    individualExpeditionType: "pinParvatiPassTrek",
    individualExpeditionName: "Pin Parvati Pass Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Parvati Valley to Spiti Valley",
    duration: "13 Days",
    grade: "Advanced",
    maxAltitude: "5319 M or 17450 ft",
    bestSeason: "June, July, August and September",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Pin Parvati is a mind-blowing and soul-testing trek through the rugged terrains of Himachal Pradesh, where nature truly overpowers the thoughts with which you started the trek and exposes the beauty and strength that lie within. Starting from the quaint village of Barsheni, the trail meanders through the Parvati Valley, revealing a mosaic of landscapes – from lush meadows to dense forests. The real challenge arises as trekkers ascend to the formidable Pin Parvati Pass, standing tall at 17,450 feet, where the air is thin, and panoramic views of snow-clad peaks unfold like a breathtaking tapestry.",
    aboutExpeditionExperienced:
      "Trek Pin-Parvati Pass with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Manali (6,726 ft)",
        details: [
          "Arrive in Manali, check-in, and rest.",
          "Attend trek briefing for acclimatization.",
          "Explore Manali's attractions.",
        ],
      },
      {
        day: 2,
        title: "Manali to Barsheni (7,677 ft) - Drive to Kheerganga (9,350 ft)",
        details: [
          "Drive to Barsheni from Manali.",
          "Trek to Kheerganga, camp overnight.",
        ],
      },
      {
        day: 3,
        title: "Kheerganga to Tunda Bhuj (10,076 ft)",
        details: [
          "Trek from Kheerganga to Tunda Bhuj through forests.",
          "Camp overnight at Tunda Bhuj.",
        ],
      },
      {
        day: 4,
        title: "Tunda Bhuj to Thakur Kuan (11,050 ft)",
        details: [
          "Trek to Thakur Kuan, surrounded by meadows.",
          "Camp overnight at Thakur Kuan.",
        ],
      },
      {
        day: 5,
        title: "Thakur Kuan to Odi Thach (11,712 ft)",
        details: [
          "Continue trek to Odi Thach, an alpine meadow.",
          "Camp overnight at Odi Thach.",
        ],
      },
      {
        day: 6,
        title: "Odi Thach to Mantalai Lake (13,451 ft)",
        details: [
          "Trek to Mantalai Lake, acclimatization day.",
          "Camp near the lake.",
        ],
      },
      {
        day: 7,
        title: "Mantalai Lake to Base Camp (14,500 ft)",
        details: [
          "Trek to Pin Parvati Pass Base Camp.",
          "Camp overnight for preparation.",
        ],
      },
      {
        day: 8,
        title:
          "Base Camp to Pin Parvati Pass (17,457 ft) to Pin Valley Camp (14,600 ft)",
        details: [
          "Early ascent to Pin Parvati Pass.",
          "Descend to Pin Valley Camp, camp overnight.",
        ],
      },
      {
        day: 9,
        title: "Pin Valley Camp to Mudh (12,800 ft)",
        details: [
          "Trek to Mudh, the last village in Pin Valley.",
          "Camp overnight at Mudh.",
        ],
      },
      {
        day: 10,
        title: "Mudh to Kaza (12,467 ft) - Drive",
        details: [
          "Drive to Kaza from Mudh.",
          "Explore Kaza and its monasteries.",
          "Overnight stay in Kaza.",
        ],
      },
      {
        day: 11,
        title: "Kaza to Manali - Drive",
        details: [
          "Drive back to Manali from Kaza. If you wish to leave from Kaza, you can do so.",
          "Check into accommodation in Manali.",
          "Celebrate the completion of the Pin Parvati trek.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali and Kaza upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali and Kaza",
        content: [
          "Meals in Manali and Kaza on arrival and departure days. You may order or explore the local cuisine.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "parangLaTrek",
    individualExpeditionName: "Parang La Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Spiti Valley To Tso Morori, Ladakh",
    duration: "14 Days",
    grade: "Advanced",
    maxAltitude: "5319 M or 17450 ft",
    bestSeason: "June, July, August and September",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "The Parang La Pass Trek is one of a kind. You start from a culturally rich Spiti and land across numerous mountains to reach the panoramic wonders of Ladakh. It is a challenging and awe-inspiring expedition that traverses the remote and rugged landscapes connecting the seekers of thril and adventure with stunning views and sublime feelings. This high-altitude adventure takes you through iconic monasteries, picturesque villages, and pristine high-altitude lakes.The journey reaches its peak while crossing the mighty Parang La Pass at 5,600m, offering breathtaking panoramic views of the surrounding peaks. This trek is a peek into the diverse terrains of the Himalayas, showcasing vast meadows, desert-like expanses, and serene lakes. It demands physical fitness, acclimatization, and a sense of adventure, promising an unforgettable experience for those seeking the thrill of exploration in the trans-Himalayan region. Ah, there’s a big blue lake as well!",
    aboutExpeditionExperienced:
      "Trek Parang La with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Manali",
        details: [
          "Arrive in Manali and relax.",
          "Explore the local cafes and markets and just take it easy.",
        ],
      },
      {
        day: 2,
        title: "Manali to Kaza",
        details: [
          "Drive from Manali to Kaza (202 km).",
          "Marvel at the majestic landscapes of the Himalayas.",
        ],
      },
      {
        day: 3,
        title: "Rest & Acclimatization Day at Chicham",
        details: [
          "Take a break at Chicham (18 km from Kaza).",
          "Immerse yourself in the serene surroundings for acclimatization.",
        ],
      },
      {
        day: 4,
        title: "Rest & Acclimatization Day at Chicham",
        details: [
          "Continue acclimatization at Chicham.",
          "Explore the beauty and culture of the region.",
        ],
      },
      {
        day: 5,
        title: "Chicham to Thaltak",
        details: [
          "Trek from Chicham to Thaltak (8 km).",
          "Experience stunning vistas of snow-clad peaks and alpine meadows.",
        ],
      },
      {
        day: 6,
        title: "Thaltak to Borochin",
        details: [
          "Trek from Thaltak to Borochin (12 km).",
          "Enjoy panoramic views of the rugged landscape.",
        ],
      },
      {
        day: 7,
        title: "Borochin to Kharsa Yongma via Parang La",
        details: [
          "Conquer Parang La pass (5580 m).",
          "Trek from Borochin to Kharsa Yongma (14 km).",
        ],
      },
      {
        day: 8,
        title: "Kharsa Yongma to Kapapuche",
        details: [
          "Descend to Kapapuche (20 km).",
          "Witness diverse landscapes and unique flora and fauna.",
        ],
      },
      {
        day: 9,
        title: "Kapapuche to Kitpodansa",
        details: [
          "Continue descending to Kitpodansa (20 km).",
          "Experience the transition in terrain and altitude.",
        ],
      },
      {
        day: 10,
        title: "Kitpodansa to Chumik Shilde",
        details: [
          "Traverse to Chumik Shilde (18 km).",
          "Witness stunning alpine landscapes and pristine lakes.",
        ],
      },
      {
        day: 11,
        title: "Chumik Shilde to Kiangdom",
        details: [
          "Journey to Kiangdom (14 km).",
          "Experience the rugged beauty of the mountains.",
        ],
      },
      {
        day: 12,
        title: "Kiangdom to Karzok",
        details: [
          "Trek to Karzok (21.5 km).",
          "Enjoy panoramic views of Tso Morori lake.",
        ],
      },
      {
        day: 13,
        title: "Karzok to Leh",
        details: [
          "Drive from Karzok to Leh (240 km).",
          "Reflect on the memorable journey through the Himalayas.",
        ],
      },
      {
        day: 14,
        title: "Departure",
        details: [
          "Conclude the journey and depart.",
          "Carry unforgettable memories of the adventure.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali/Kaza and Leh upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali/Kaza to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali/Kaza and Leh",
        content: [
          "Meals in Manali/Kaza and Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "audenColTrek",
    individualExpeditionName: "Auden's Col Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Gangotri, Uttarakhand",
    duration: "16 Days",
    grade: "Advanced",
    maxAltitude: "5490 m or 18,011 ft",
    bestSeason: "May, June, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Auden's Col, situated in the Garhwal region of the Indian Himalayas, is one of the most challenging treks. Named after the renowned British geologist and explorer John Bicknell Auden, this formidable mountain pass is at an elevation of 5,490 meters (18,011 feet), making it one of the highest and most challenging passes in the region. Auden's Col serves as a crucial link between the Gangotri and Kedarnath valleys, providing a dramatic traverse through a landscape characterized by huge glaciers, high peaks, and dense wilderness. Loved by expert-level adventurers, this route demands expertise, physical endurance, and an understanding of high-altitude environments. The journey to Auden's Col is of high-altitude trekking, and panoramic views of the Himalayas splendor that is capable of challenging even the most seasoned mountaineers with its rugged terrain and formidable altitudes.",
    aboutExpeditionExperienced:
      "Trek Auden’s Col with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Reach Dehradun and Arrival in Gangotri (3,415M)",
        details: [
          "Arrive in Gangotri and check into the hotel.",
          "Attend a briefing session with the trek leader.",
          "Evening at leisure to explore the beautiful surroundings.",
        ],
      },
      {
        day: 2,
        title: "Rest and Acclimatization in Gangotri",
        details: [
          "Acclimatization day in Gangotri.",
          "Explore the Gangotri town and visit the Gangotri Temple.",
          "Briefing on the upcoming trek and safety guidelines.",
        ],
      },
      {
        day: 3,
        title: "Gangotri (3,415M) to Nala Camp (3,760M)",
        details: [
          "Start the trek towards Nala Camp.",
          "Trek through lush green landscapes and gradually gain altitude.",
          "Camp overnight at Nala Camp.",
        ],
      },
      {
        day: 4,
        title: "Nala Camp (3,760M) to Rudugaira Camp (4,350M)",
        details: [
          "Continue the ascent towards Rudugaira Camp.",
          "Witness stunning views of the surrounding peaks.",
          "Camp overnight at Rudugaira Camp.",
        ],
      },
      {
        day: 5,
        title: "Rest and Acclimatization at Rudugaira Base Camp (4,350M)",
        details: [
          "Acclimatization day at Rudugaira Base Camp.",
          "Short hikes in the vicinity to adapt to the higher altitude.",
        ],
      },
      {
        day: 6,
        title:
          "Rudugaira Base Camp (4,350M) to Auden's Col Base Camp (4,720M) via Gangotri Base Camp (4,500M)",
        details: [
          "Trek to Auden's Col Base Camp via Gangotri Base Camp.",
          "Reach the base camp and prepare for the challenging days ahead.",
        ],
      },
      {
        day: 7,
        title:
          "Auden's Col Base Camp (4,720M) to Khatling Glacier Camp (5,000M) via Auden's Col (5,490M)",
        details: [
          "Cross Auden's Col, the highest point of the trek.",
          "Descend to Khatling Glacier Camp for an overnight stay.",
        ],
      },
      {
        day: 8,
        title: "Khatling Glacier Camp (5,000M) to Zero Point (3,870M)",
        details: [
          "Trek down to Zero Point through varied terrains.",
          "Camp overnight at Zero Point.",
        ],
      },
      {
        day: 9,
        title: "Rest Day at Zero Point (3,870M)",
        details: [
          "Rest and recovery day at Zero Point.",
          "Optional short hikes or activities.",
        ],
      },
      {
        day: 10,
        title: "Zero Point (3,870M) to Chowki (3,580M)",
        details: [
          "Descend further towards Chowki.",
          "Experience the changing landscapes along the way.",
        ],
      },
      {
        day: 11,
        title: "Chowki (3,580M) to Masar Tal (4,135M)",
        details: [
          "Trek to Masar Tal, a stunning high-altitude lake.",
          "Camp overnight near the lake.",
        ],
      },
      {
        day: 12,
        title:
          "Masar Tal (4,135M) to Vasuki Tal (4,135M) via Mayali Pass (5,000M)",
        details: [
          "Cross Mayali Pass and reach Vasuki Tal.",
          "Enjoy breathtaking views of the Himalayan peaks.",
        ],
      },
      {
        day: 13,
        title: "Vasuki Tal (4,135M) to Kedarnath (3,590M)",
        details: [
          "Trek to Kedarnath, a significant pilgrimage site.",
          "Visit the Kedarnath Temple.",
        ],
      },
      {
        day: 14,
        title: "Kedarnath (3,590M) to Gaurikund (1,985M)",
        details: ["Trek down to Gaurikund.", "Check into a hotel and relax."],
      },
      {
        day: 15,
        title: "Gaurikund (1,985M) to Dehradun (430M)",
        details: [
          "Drive from Gaurikund to Dehradun.",
          "Check into a hotel in Dehradun.",
        ],
      },
      {
        day: 16,
        title: "Reserve Day for Bad Weather",
        details: [
          "A reserve day in case of unfavorable weather conditions.",
          "Explore Dehradun or relax at the hotel.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Gangotri, Kedarnath and Gaurikund upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals from Day 2 lunch to Day 13, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Gangotri to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Gangotri, Dehradun and Gaurikund",
        content: [
          "Meals in Gangotri, Dehradun and Gaurikund on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "kalindiKhalTrek",
    individualExpeditionName: "Kalindi Khal Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Gangotri, Uttarakhand",
    duration: "15 Days",
    grade: "Advanced",
    maxAltitude: "5940 m or 19,488 ft",
    bestSeason: "September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Kalindi Khal Trek is a challenging expedition, traversing the daunting Kalindi Khal Pass amidst the breathtaking Garhwal Himalayas. The pass connects Gangotri and Badrinath. This challenging route entails navigating through vast glaciers, rocky slopes, and tough snowfields, often requiring the use of technical equipment such as ropes, ice axes, and crampons. As you journey through the Kalindi Khal, you'll traverse halfway around Garhwal, venturing from the Bhagirathi Valley to the Alaknanda Valley.This trek promises awe-inspiring scenery of the highest and most majestic peaks in the Indian Himalayan range. Marvel at Shivling standing tall at 6,543 m, the impressive Meru at 6,630 m, the commanding Kedar Dome at 6,808 m, the Bhagirathi group at 6,856 m, the regal Nilkantha at 6,506 m, the majestic Chandra Parvat at 6,728 m, the formidable Avalanche Peak at 6,413 m, the towering Kamet at 7,756 m, the iconic Mana at 7,272 m, and the imposing Abi Gamin at 7,355 m.",
    aboutExpeditionExperienced:
      "Trek Kalindi Khal with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrive in Gangotri",
        details: [
          "Arrive in Gangotri, a sacred town on the banks of the Ganges.",
          "Check into your accommodation and attend a briefing session.",
        ],
      },
      {
        day: 2,
        title: "Rest & Acclimatization in Gangotri",
        details: [
          "Acclimatization day in Gangotri.",
          "Explore the town, visit Gangotri Temple, and prepare for the trek.",
        ],
      },
      {
        day: 3,
        title: "Gangotri to Bhojwasa (12,467 ft) - 14 km",
        details: [
          "Begin the trek to Bhojwasa, the base for the journey ahead.",
          "Trek through scenic landscapes with the Bhagirathi River accompanying you.",
        ],
      },
      {
        day: 4,
        title: "Bhojwasa to Tapovan (14,632 ft) via Gomukh - 13 km",
        details: [
          "Trek to Tapovan via Gomukh, the source of the Ganges.",
          "Enjoy panoramic views of Shivling, Meru, and Bhagirathi group of peaks.",
        ],
      },
      {
        day: 5,
        title: "Tapovan to Nandanvan (14,271 ft) via Meru Glacier - 8 km",
        details: [
          "Trek to Nandanvan, passing through the captivating Meru Glacier.",
          "Marvel at the rugged beauty of the surroundings.",
        ],
      },
      {
        day: 6,
        title: "Nandanvan to Vasuki Tal (16,010 ft) - 6 km",
        details: [
          "Trek to Vasuki Tal, a stunning high-altitude lake.",
          "Enjoy the serene surroundings and acclimatize to the increasing altitude.",
        ],
      },
      {
        day: 7,
        title: "Rest & Acclimatization at Vasuki Tal",
        details: [
          "Rest day at Vasuki Tal for acclimatization.",
          "Short hikes around the lake to adapt to the altitude.",
        ],
      },
      {
        day: 8,
        title: "Vasuki Tal to Khara Patthar (17,060 ft) - 6 km",
        details: [
          "Ascend to Khara Patthar, a high-altitude plateau.",
          "Witness breathtaking views of the surrounding peaks.",
        ],
      },
      {
        day: 9,
        title: "Khara Patthar to Sweta Glacier (17,880 ft) - 8 km",
        details: [
          "Trek to Sweta Glacier, crossing challenging terrains.",
          "Set up camp amidst the snow-capped landscape.",
        ],
      },
      {
        day: 10,
        title: "Sweta Glacier to Kalindi Base Camp (18,569 ft) - 6 km",
        details: [
          "Reach the Kalindi Base Camp, your point of departure for the challenging Kalindi Khal pass.",
        ],
      },
      {
        day: 11,
        title:
          "Kalindi Base Camp to Rajaparav (16,076 ft) via Kalindi Khal (19,488 ft) - 13 km",
        details: [
          "Cross the formidable Kalindi Khal pass and descend to Rajaparav.",
          "A challenging day with spectacular mountain views.",
        ],
      },
      {
        day: 12,
        title: "Rajaparav to Arwa Tal (14,763 ft) - 13 km",
        details: [
          "Descend to Arwa Tal, a pristine high-altitude lake.",
          "Camp overnight amidst the serene surroundings.",
        ],
      },
      {
        day: 13,
        title:
          "Arwa Tal to Badrinath (9,842 ft) via Ghastoli (12,467 ft) - 12 km",
        details: [
          "Trek to Ghastoli and then drive to Badrinath.",
          "Visit the Badrinath Temple, a major pilgrimage site.",
        ],
      },
      {
        day: 14,
        title: "Badrinath to Dehradun - 295 km drive",
        details: [
          "Drive back to Dehradun, reflecting on the incredible journey.",
        ],
      },
      {
        day: 15,
        title: "Reserve Day for Climate Change",
        details: [
          "A reserve day to account for any unforeseen weather challenges.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Gangotri, Badrinath, and Dehradun upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals from Day 3 breakfast to Day 13 lunch, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Gangotri to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Gangotri, Dehradun and Badrinath",
        content: [
          "Meals in Gangotri, Dehradun and Badrinath on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "kangLaTrek",
    individualExpeditionName: "Kang La Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Manali to Zanskar",
    duration: "10 Days",
    grade: "Advanced",
    maxAltitude: "4600 M or 15100 ft",
    bestSeason: "September, October and November",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Kang La Pass trek, starting in Lahaul's Miyar Valley, is celebrated for its unspoiled alpine pastures and colourful wildflowers. This demanding expedition reaches the highest level at Kang La Pass, an elevation of 5465 meters, revealing the alpine beauty of Ladakh's Zanskar Valley—a rugged terrain dominated by rock. Serving as the border between Himachal and Jammu & Kashmir, Kang La boasts the central Himalaya's lengthiest glacial approach, spanning 24 km. Traverse through velvety green meadows, glacial lakes, snowfields, and moraines, leading to the secluded village of Padum in Zanskar. An undiscovered jewel in the Indian Himalayas, Kang La demands expertise from seasoned trekkers with robust physical fitness. This extensive journey, from Manali to Leh, guarantees an extraordinary tapestry of evolving landscapes, cultures, and languages—an all-encompassing adventure.",
    aboutExpeditionExperienced:
      "Trek Kang La with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Manali to Changut (10,500 ft) via Udaipur - 135 km Drive",
        details: [
          "Depart from Manali and drive to Changut via Udaipur.",
          "Enjoy the scenic journey through the Lahaul Valley.",
          "Reach Changut and set up the first camp.",
        ],
      },
      {
        day: 2,
        title: "Changut to Yolithang (12,300 ft) via Shukto - 10 km",
        details: [
          "Trek from Changut to Yolithang via Shukto.",
          "Experience the varied landscapes and pristine surroundings.",
          "Camp overnight at Yolithang.",
        ],
      },
      {
        day: 3,
        title: "Yolithang to Zardung (12,800 ft) - 10 km",
        details: [
          "Continue the trek to Zardung, surrounded by snow-capped peaks.",
          "Enjoy the serene beauty of the region.",
          "Camp overnight at Zardung.",
        ],
      },
      {
        day: 4,
        title: "Zardung to Kesar Yon Chhaap (13,100 ft) - 10 km",
        details: [
          "Trek towards Kesar Yon Chhaap, a high-altitude campsite.",
          "Enjoy breathtaking views of the surrounding mountains.",
          "Camp overnight at Kesar Yon Chhaap.",
        ],
      },
      {
        day: 5,
        title: "Rest and Acclimatization at Kesar Yon Chaap",
        details: [
          "Rest day for acclimatization and exploration.",
          "Short hikes around Kesar Yon Chaap to adapt to the altitude.",
        ],
      },
      {
        day: 6,
        title: "Kesar Yon Chaap to Miyar Glacier Camp (14,600 ft) - 12 km",
        details: [
          "Trek to Miyar Glacier Camp, passing through alpine meadows.",
          "Witness the stunning Miyar Glacier.",
          "Camp overnight at Miyar Glacier.",
        ],
      },
      {
        day: 7,
        title: "Miyar Glacier Camp to Kang La Base Camp (15,750 ft) - 9 km",
        details: [
          "Continue the trek to Kang La Base Camp.",
          "Marvel at the majestic Kang La Pass in the distance.",
          "Camp overnight at the base of Kang La.",
        ],
      },
      {
        day: 8,
        title:
          "Kang La Base Camp to Temasa Tongpo (14,500 ft) via Kang La (17,900 ft) - 17 km",
        details: [
          "Early morning ascent to Kang La Pass, the highest point of the trek.",
          "Descend to Temasa Tongpo through rocky terrains.",
          "Camp overnight at Temasa Tongpo.",
        ],
      },
      {
        day: 9,
        title:
          "Temasa Tongpo to Padam (11,800 ft) - 15 km Trek, 12 km Drive (Rest in Padam)",
        details: [
          "Trek to Padam, the endpoint of the trek.",
          "Drive to Padam for rest and relaxation.",
          "Overnight stay in Padum.",
        ],
      },
      {
        day: 10,
        title: "Drive from Padum to Leh",
        details: [
          "Depart from Padum and drive to Leh.",
          "Reach Leh and conclude the adventurous Kang La trek.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali, Padum, and Leh upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali and Leh",
        content: [
          "Meals in Manali and Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "goechaLaTrek",
    individualExpeditionName: "Goecha La Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Siliguri, West Bengal",
    duration: "10 Days",
    grade: "Intermediate",
    maxAltitude: "5465 M or 17929 ft",
    bestSeason: "June, July, August and September",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "The Goechala Trek in Sikkim India, calls the adventure enthusiasts to experience the beauty and challenges. This iconic trek is located against the backdrop of the mighty Kanchenjunga, the world's third-highest peak. The route offers a visual treat of snow-capped summits, rhododendron-rich slopes, and glacial lakes. Spanning approximately 90 kilometres, the journey winds through the heart of the Khangchendzonga National Park, a UNESCO World Heritage Site renowned for its diverse flora and fauna. Trekkers navigate through dense forests, traverse high-altitude meadows and reaching the Goechala Pass at 4,940 meters, where the panoramic views of the peaks awaits. The trek's allure lies not only in its challenging terrain but also in the profound connection it fosters with nature. With the rhythmic crunch of footsteps on mountain trails, the Goechala Trek promises an unforgettable adventure, blending physical endurance with the spiritual rejuvenation that comes from immersing oneself in the Himalayan wilderness.",
    aboutExpeditionExperienced:
      "Trek Goechla with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains-like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Yuksom (5,643 ft) to Sachen (7,200 ft)",
        details: [
          "Begin the trek from Yuksom, the trailhead for Goechala.",
          "Trek through dense forests and reach Sachen.",
          "Camp overnight at Sachen.",
        ],
      },
      {
        day: 2,
        title: "Sachen (7,200 ft) to Tshoka (9,650 ft)",
        details: [
          "Continue trekking through rhododendron forests.",
          "Reach Tshoka, a beautiful settlement with stunning mountain views.",
          "Camp overnight at Tshoka.",
        ],
      },
      {
        day: 3,
        title: "Tshoka (9,650 ft) to Dzongri (12,980 ft)",
        details: [
          "Trek to Dzongri, offering breathtaking panoramic views.",
          "Pass by Phedang, a scenic high-altitude meadow.",
          "Camp overnight at Dzongri.",
        ],
      },
      {
        day: 4,
        title: "Acclimatization Day at Dzongri (12,980 ft)",
        details: [
          "Rest day for acclimatization.",
          "Optional short hikes to nearby viewpoints.",
          "Camp overnight at Dzongri.",
        ],
      },
      {
        day: 5,
        title: "Dzongri (12,980 ft) to Lamuney (13,700 ft)",
        details: [
          "Trek to Lamuney, passing through meadows and rocky terrains.",
          "Enjoy views of Pandim, Tenzingkhang, and Jopuno.",
          "Camp overnight at Lamuney.",
        ],
      },
      {
        day: 6,
        title:
          "Lamuney (13,700 ft) to Goechala (16,000 ft) and Back to Kokchurang (12,000 ft)",
        details: [
          "Early morning trek to Goechala, the highest point of the trek.",
          "Witness stunning views of the Kanchenjunga massif.",
          "Descend to Kokchurang for an overnight stay.",
        ],
      },
      {
        day: 7,
        title: "Kokchurang (12,000 ft) to Tshoka",
        details: [
          "Trek back to Tshoka, retracing your steps.",
          "Enjoy the scenic beauty of the trail.",
          "Camp overnight at Tshoka.",
        ],
      },
      {
        day: 8,
        title: "Tshoka to Yuksom",
        details: [
          "Descend to Yuksom, concluding the trek.",
          "Celebrate the successful completion of Goechala trek.",
          "Overnight stay in Yuksom.",
        ],
      },
      {
        day: 9,
        title: "Departure",
        details: ["Explore Yuksom or depart for your onward journey."],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Yuksom upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Yuksom to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Yuksom",
        content: [
          "Meals in Yuksom on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "baraBangalTrek",
    individualExpeditionName: "Bara Bangal Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Manali",
    duration: "8 Days",
    grade: "Intermediate",
    maxAltitude: "4850 M or 15912 ft",
    bestSeason: "June, July, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Embark on a journey through the untamed beauty of the Himalayas with the Bara Bangal trek. This meticulously crafted eight-day itinerary promises an unforgettable adventure, winding through diverse landscapes and reaching heights that unveil panoramic vistas. Beginning in Manali, the gateway to the mountains, each day unfolds a new chapter of exploration and endurance.The trek kicks off with a drive to the trailhead at Dhundi, followed by a trek to Lama Dugh, where a scenic meadow becomes the first campsite under the starlit sky. As the journey progresses, participants traverse dense forests, ascending to the enchanting Riyali Thach and the challenging Kalihani Base Camp. The pinnacle of the trek is reached at Kalihani Pass (15,550 ft), offering a breathtaking panorama.Descending through alpine meadows, the trek weaves through remote villages like Bara Bhangal. The expedition concludes with a trek back to Manali, ensuring a seamless blend of adventure and cultural immersion. Throughout, participants are reminded to adapt to changing conditions, prioritize acclimatization, and savour the guidance of experienced leaders for a safe and rewarding journey.",
    aboutExpeditionExperienced:
      "Trek Bara Bangal with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Manali to Lama Dugh (9,842 ft)",
        details: [
          "Drive from Manali to the roadhead at Dhundi.",
          "Begin trekking to Lama Dugh, a picturesque meadow.",
          "Camp overnight at Lama Dugh.",
        ],
      },
      {
        day: 2,
        title: "Lama Dugh to Riyali Thach (11,150 ft)",
        details: [
          "Trek through birch and oak forests to Riyali Thach.",
          "Enjoy the scenic landscapes and camp overnight.",
        ],
      },
      {
        day: 3,
        title: "Riyali Thach to Kalihani Base Camp (12,700 ft)",
        details: [
          "Continue the ascent to Kalihani Base Camp.",
          "Experience the changing terrain and set up camp.",
        ],
      },
      {
        day: 4,
        title:
          "Kalihani Base Camp to Devi Ki Marhi (14,550 ft) via Kalihani Pass (15,994 ft)",
        details: [
          "Early morning trek to Kalihani Pass, the highest point.",
          "Descend to Devi Ki Marhi and camp overnight.",
        ],
      },
      {
        day: 5,
        title: "Devi Ki Marhi to Dal Marhi (13,780 ft)",
        details: [
          "Trek to Dal Marhi through alpine meadows.",
          "Enjoy the views of surrounding peaks.",
          "Camp overnight at Dal Marhi.",
        ],
      },
      {
        day: 6,
        title: "Dal Marhi to Bara Bhangal (8,775 ft)",
        details: [
          "Descend to Bara Bhangal, a remote village.",
          "Explore the unique culture of the village.",
          "Camp overnight in Bara Bhangal.",
        ],
      },
      {
        day: 7,
        title: "Bara Bhangal to Marhi (11,150 ft)",
        details: [
          "Trek to Marhi through lush green meadows.",
          "Enjoy the serene surroundings and camp overnight.",
        ],
      },
      {
        day: 8,
        title: "Marhi to Manali",
        details: [
          "Trek to the roadhead at Dhundi.",
          "Drive back to Manali, marking the end of the trek.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Manali upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Manali to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Manali on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "lehLamayuruTrek",
    individualExpeditionName: "Leh Lamayuru Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Leh",
    duration: "6 Days",
    grade: "Beginner",
    maxAltitude: "3850 M or 12631 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Embark on an exhilarating journey through the mesmerizing landscapes of Ladakh with our Ladakh Trekking Adventure. This six-day expedition offers a perfect blend of cultural exploration and physical challenge, taking you from the bustling town of Leh to the remote, picturesque villages nestled in the heart of the Himalayas. Witness the grandeur of Lamayuru Monastery, traverse scenic barley fields, and marvel at the ancient Alchi Monastery.As you trek through diverse terrains, including meadows and mountain passes, each day unfolds with breathtaking views of the Stok and Karakoram ranges. Immerse yourself in the rich culture of Ladakh, from traditional villages to renowned monasteries. With carefully planned acclimatization and expert guidance, this trek promises not only an adrenaline-filled adventure but also an intimate connection with the awe-inspiring beauty and spirituality of Ladakh.",
    aboutExpeditionExperienced:
      "Trek Leh-Lamayuru with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh",
        details: [
          "Arrive in Leh and acclimatize to the high altitude.",
          "Drive to Lamayuru, enjoying picturesque Ladakh landscapes.",
          "Visit Lamayuru Monastery for its stunning architecture and spiritual significance.",
          "Overnight stay at Lamayuru for further acclimatization.",
        ],
      },
      {
        day: 2,
        title: "Lamayuru to Wanla",
        details: [
          "Begin the trek from Lamayuru to Wanla.",
          "Trek through scenic barley fields, and small villages, and visit Alchi Monastery.",
          "Camp overnight at Wanla.",
        ],
      },
      {
        day: 3,
        title: "Wanla to Hinju",
        details: [
          "Trek towards Hinju, crossing beautiful meadows and small streams.",
          "Enjoy panoramic views of the Stok and Karakoram ranges.",
          "Camp overnight at Hinju.",
        ],
      },
      {
        day: 4,
        title: "Hinju to Sumdo",
        details: [
          "Continue trekking, crossing the Kanski La pass.",
          "Explore Kanski La valley, and interact with local shepherds.",
          "Camp overnight at Sumdo.",
        ],
      },
      {
        day: 5,
        title: "Sumdo to Chilling",
        details: [
          "Trek towards Chilling, passing through charming villages and landscapes.",
          "Explore Chilling Village, known for copper craftsmanship.",
          "Visit Chilling Monastery.",
          "Camp or stay in a local guesthouse.",
        ],
      },
      {
        day: 6,
        title: "Chilling to Leh (Drive)",
        details: [
          "Drive back to Leh from Chilling.",
          "Enjoy scenic beauty, and stop for photo opportunities en route.",
          "Arrive in Leh, explore local markets, and relax.",
          "Depart from Leh or extend your stay to explore more of Ladakh.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh and Chilling upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "markhaValleyTrek",
    individualExpeditionName: "Markha Valley Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Leh",
    duration: "7 Days",
    grade: "Intermediate",
    maxAltitude: "4750 M or 15583 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Welcome to an exhilarating journey through the enchanting landscapes of Ladakh! This meticulously crafted itinerary promises an unforgettable adventure, weaving through the majestic Himalayan terrain and providing a glimpse into the rich cultural tapestry of the region. Over the course of seven days, you will traverse from the ancient city of Leh, situated at an altitude of 3,500 meters, to the remote and awe-inspiring reaches of Nimaling at 4,750 meters.This trek is not merely a physical odyssey but a holistic experience, immersing you in the vibrant hues of Hemis National Park, the tranquility of quaint villages like Rumbak and Markha, and the spiritual aura of monasteries like Hemis and Markha. Each day unfolds a new chapter, taking you through diverse terrains, challenging mountain passes, and serene meadows, offering a perfect blend of adventure, cultural exploration, and natural beauty.Embark on this expedition that goes beyond the ordinary, leading you to discover the heart and soul of Ladakh, where every step echoes with the tales of ancient monasteries, nomadic life, and the resplendent grandeur of the Kang Yatse peaks. Join us as we traverse the trails less traveled, creating memories that will linger in your heart long after the journey concludes. Get ready to be captivated by the magic of Ladakh!",
    aboutExpeditionExperienced:
      "Trek Markha Valley with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Leh (3,500m) to Zingchen (3,400m) and Trek to Yurutse (3,550m)",
        details: [
          "Start the day with a 4-5 hours drive from Leh to Zingchen.",
          "Begin trekking towards Yurutse, passing through the Hemis National Park known for its diverse flora and fauna.",
          "Traverse the picturesque Rumbak village.",
          "Camp overnight at Yurutse.",
        ],
      },
      {
        day: 2,
        title: "Yurutse (3,550m) to Skiu (3,350m)",
        details: [
          "Embark on a 6-7 hours trek to Skiu, crossing the Ganda La pass.",
          "Enjoy breathtaking views of the Markha Valley.",
          "Pass through beautiful landscapes and the charming Hemis Shukpachan village.",
          "Camp overnight at Skiu.",
        ],
      },
      {
        day: 3,
        title: "Skiu (3,350m) to Markha (3,800m)",
        details: [
          "Trek for 6-7 hours towards Markha, crossing numerous streams and the Markha River.",
          "Explore the Markha village and the historic Markha Monastery.",
          "Camp overnight at Markha.",
        ],
      },
      {
        day: 4,
        title: "Markha (3,800m) to Thachungtse (4,050m)",
        details: [
          "Trek for 5-6 hours towards Thachungtse, passing through lush meadows.",
          "Enjoy views of Kang Yatse, a prominent peak in the region.",
          "Camp overnight at Thachungtse.",
        ],
      },
      {
        day: 5,
        title: "Thachungtse (4,050m) to Nimaling (4,750m)",
        details: [
          "Trek for 3-4 hours to Nimaling, a high-altitude grazing area with stunning views.",
          "Marvel at the grandeur of the Kang Yatse peaks.",
          "Camp overnight at Nimaling.",
        ],
      },
      {
        day: 6,
        title: "Nimaling (4,750m) to Shang Sumdo (3,750m)",
        details: [
          "Trek for 7-8 hours towards Shang Sumdo, descending through beautiful landscapes.",
          "Enjoy the last day of trekking with varied terrains.",
          "Camp overnight at Shang Sumdo.",
        ],
      },
      {
        day: 7,
        title: "Shang Sumdo (3,750m) to Hemis (3,600m) and Drive to Leh",
        details: [
          "Conclude the trek with a 2-3 hours trek to Hemis.",
          "Visit the Hemis Monastery, one of the largest and wealthiest monasteries in Ladakh.",
          "Drive back to Leh for an overnight stay.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "spitukToMathoTrek",
    individualExpeditionName: "Spituk To Matho Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Ladakh",
    duration: "5 Days",
    grade: "Beginner",
    maxAltitude: "4750 M or 15583 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Embark on an exhilarating Ladakh Trekking Expedition, a five-day journey through the pristine landscapes of the Himalayas, revealing the untamed beauty of the region. Begin your adventure with a scenic drive from Leh, the heart of Ladakh, to Zingchen, where the first steps of the trek lead you to the enchanting Yurutse. As you traverse the rugged terrains, experience the ever-changing scenery along the Indus River, leading you through gorges and alpine forests.Encounter diverse flora and fauna in Hemis National Park, keeping an eager eye out for elusive snow leopards, Tibetan wolves, and Bharal. Discover the rich cultural tapestry of Ladakh by exploring historic monasteries, such as Markha, and immersing yourself in the local way of life. Camp under the star-lit skies in picturesque locations like Skiu and Thachungtse, with breathtaking views of towering mountains. Conclude your trek with a final stretch to Matho and return to Leh, leaving you with indelible memories of Ladakh's majestic landscapes and vibrant cultural heritage.",
    aboutExpeditionExperienced:
      "Trek from Spituk to Matho with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title:
          "Leh (3,500m) to Zingchen (3-4 hours drive, 3,600m) and Trek to Yurutse (3,900m)",
        details: [
          "Morning: Scenic drive from Leh to Spituk (3-4 hours)",
          "Afternoon: Begin trek to Yurutse, passing through picturesque landscapes along the Indus River",
          "Evening: Enter the gorge leading to Zingchen, set up camp",
          "Night: Overnight stay at Yurutse surrounded by natural beauty",
        ],
      },
      {
        day: 2,
        title: "Yurutse (3,900m) to Skiu (3,980m)",
        details: [
          "Morning: Trek towards Skiu (5-6 hours)",
          "Afternoon: Cross Ganda La pass, explore Hemis National Park",
          "Evening: Camp at Skiu, a charming village with a scenic backdrop",
          "Night: Overnight stay in Skiu, surrounded by diverse flora and fauna",
        ],
      },
      {
        day: 3,
        title: "Skiu (3,980m) to Markha (3,800m)",
        details: [
          "Morning: Continue trek to Markha (6-7 hours)",
          "Afternoon: Explore Markha Monastery, immerse in local culture",
          "Evening: Camp at Markha, enjoy the serene atmosphere of the valley",
          "Night: Overnight stay in Markha",
        ],
      },
      {
        day: 4,
        title: "Markha (3,800m) to Thachungtse (4,050m)",
        details: [
          "Morning: Trek towards Thachungtse (5-6 hours) through alpine trees and dry forests",
          "Afternoon: Enjoy the tranquility and changing landscapes",
          "Evening: Camp at Thachungtse, with views of majestic mountains",
          "Night: Overnight stay in Thachungtse",
        ],
      },
      {
        day: 5,
        title: "Thachungtse (4,050m) to Matho (4,300m) and Drive to Leh",
        details: [
          "Morning: Final trek stretch to Matho (5-6 hours)",
          "Afternoon: Drive back to Leh, reflecting on the beautiful landscapes and experiences",
          "Evening: Arrive in Leh, explore local markets or relax",
          "Night: Spend the evening at leisure in Leh",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "padumToDarchaTrek",
    individualExpeditionName: "Padum To Darcha Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Ladakh",
    duration: "13 Days",
    grade: "Advanced",
    maxAltitude: "5100 M or 16700 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Embark on an unforgettable journey through the remote and rugged landscapes of Zanskar with our 10-day trekking expedition. This adventure takes you through picturesque villages, ancient monasteries, and challenging mountain passes, offering a rare glimpse into the rich culture and breathtaking natural beauty of the Himalayan region.Starting from the historic capital of Zanskar, Padum, each day brings new landscapes and cultural encounters as you traverse from Karsha to Pishu, Hanumil, Snertse, Lingshed, Singge La Base, Photoksar, Zanskar Sumdo, Chuminakpo, and finally concluding in Darcha in the Lahaul region. Immerse yourself in the tranquillity of high-altitude campsites, cross mountain passes, and connect with the warm hospitality of the local communities. This expedition promises not only a physical challenge but also a soul-stirring experience in one of the most awe-inspiring corners of the world.",
    aboutExpeditionExperienced:
      "Trek from Padum to Darcha with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrive in Leh",
        details: [],
      },
      {
        day: 2,
        title: "Reach Padum",
        details: [],
      },
      {
        day: 3,
        title: "Padum to Karsha (4-5 hours trek)",
        details: [
          "Start the trek from Padum, the capital of Zanskar.",
          "Hike to Karsha Monastery, one of the largest monastic complexes in the region.",
          "Camp overnight near Karsha, enjoying the scenic surroundings.",
        ],
      },
      {
        day: 4,
        title: "Karsha to Pishu (5-6 hours trek)",
        details: [
          "Trek to Pishu, passing through picturesque landscapes and small villages.",
          "Explore Pishu village and interact with the locals.",
          "Camp overnight at Pishu.",
        ],
      },
      {
        day: 5,
        title: "Pishu to Hanumil (6-7 hours trek)",
        details: [
          "Continue the trek towards Hanumil, navigating through rocky terrain.",
          "Cross the Zanskar River on a bridge.",
          "Camp overnight at Hanumil, surrounded by mountains.",
        ],
      },
      {
        day: 6,
        title: "Hanumil to Snertse (7-8 hours trek)",
        details: [
          "Trek towards Snertse, crossing the Purfi La pass.",
          "Enjoy breathtaking views of the surrounding peaks.",
          "Camp overnight at Snertse.",
        ],
      },
      {
        day: 7,
        title: "Snertse to Lingshed (6-7 hours trek)",
        details: [
          "Hike to Lingshed, a remote village with a beautiful monastery.",
          "Explore Lingshed Monastery and immerse in the local culture.",
          "Camp overnight at Lingshed.",
        ],
      },
      {
        day: 8,
        title: "Lingshed to Singge La Base (6-7 hours trek)",
        details: [
          "Trek to the base of Singge La pass, ascending gradually.",
          "Camp overnight at Singge La Base, preparing for the pass crossing.",
        ],
      },
      {
        day: 9,
        title: "Singge La Base to Photoksar (8-9 hours trek)",
        details: [
          "Cross Singge La pass (5,090m) and descend towards Photoksar.",
          "Admire the stunning landscapes and mountain views.",
          "Camp overnight at Photoksar.",
        ],
      },
      {
        day: 10,
        title: "Photoksar to Zanskar Sumdo (6-7 hours trek)",
        details: [
          "Trek to Zanskar Sumdo, passing through high-altitude landscapes.",
          "Camp overnight at Zanskar Sumdo, relishing the tranquility.",
        ],
      },
      {
        day: 11,
        title: "Zanskar Sumdo to Chuminakpo (6-7 hours trek)",
        details: [
          "Continue the trek to Chuminakpo, crossing a suspension bridge.",
          "Enjoy the scenic beauty and peaceful surroundings.",
          "Camp overnight at Chuminakpo.",
        ],
      },
      {
        day: 12,
        title: "Chuminakpo to Darcha (7-8 hours trek)",
        details: [
          "Conclude the trek by hiking to Darcha in the Lahaul region.",
          "Drive to Keylong or continue to Manali, depending on your travel plans.",
        ],
      },
      {
        day: 13,
        title: "Departure",
        details: [],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh and Padum upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "lehToTsoMororiTrek",
    individualExpeditionName: "Leh To Tso Morori Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Ladakh",
    duration: "10 Days",
    grade: "Advanced",
    maxAltitude: "3100 M or 11560 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Embark on an exhilarating 10-day trek through the mesmerizing landscapes of Ladakh, India, with altitudes ranging from 3,500 to 4,900 meters. Begin in Leh, acclimatizing to the high altitude, exploring local attractions, and gearing up for the adventure. Traverse from Rumtse to Kyamar, witnessing scenic beauty and camping at 4,050 meters. Challenge yourself on day four with a trek to Tisaling, crossing the Mandalchan La pass at 4,900 meters, and continue to Pangunagu on day five. Day six leads to Nuruchan, crossing the Nuruchan La pass at 4,800 meters, and day seven brings you to Gyama. Trek to the pristine Tso Moriri Lake on day eight, camping at 4,595 meters, followed by a rest day to absorb the tranquil surroundings. Conclude the journey with a short hike to Karzok and a drive back to Leh, reflecting on the awe-inspiring beauty and cultural richness experienced along the way.",
    aboutExpeditionExperienced:
      "Trek from Leh to Tso Morori with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh - Acclimatization",
        details: [
          "Arrive in Leh and spend the day acclimatizing to the high altitude.",
          "Take it easy, rest, and hydrate to minimize the effects of altitude sickness.",
          "Explore Leh town in the evening and visit local attractions like Shanti Stupa or Leh Palace.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 2,
        title: "Leh - Acclimatization and Gear Shopping",
        details: [
          "Continue acclimatizing in Leh.",
          "Visit the Leh market to shop for any personal trekking gear or supplies you may need.",
          "Attend a briefing session with your trekking guide, where you'll receive information about the trek ahead.",
          "Rest and hydrate for the upcoming journey.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 3,
        title: "Leh to Rumtse and Trek to Kyamar",
        details: [
          "Drive from Leh to Rumtse (about 2-3 hours' drive).",
          "Start trekking from Rumtse to Kyamar, passing through scenic landscapes.",
          "Begin your trek with gradual ascents and enjoy the picturesque surroundings.",
          "Camp overnight at Kyamar.",
        ],
      },
      {
        day: 4,
        title: "Kyamar to Tisaling",
        details: [
          "Trek from Kyamar to Tisaling, crossing the Mandalchan La pass.",
          "Enjoy breathtaking views of the surrounding mountains.",
          "Camp overnight at Tisaling.",
        ],
      },
      {
        day: 5,
        title: "Tisaling to Pangunagu",
        details: [
          "Continue the trek to Pangunagu, crossing the Shibuk La pass.",
          "Descend into a beautiful valley and camp near Pangunagu.",
          "Embrace the serenity of the region.",
          "Camp overnight at Pangunagu.",
        ],
      },
      {
        day: 6,
        title: "Pangunagu to Nuruchan",
        details: [
          "Trek to Nuruchan, crossing the Nuruchan La pass.",
          "Witness stunning landscapes and high mountain peaks.",
          "Camp overnight at Nuruchan.",
        ],
      },
      {
        day: 7,
        title: "Nuruchan to Gyama",
        details: [
          "Hike to Gyama, passing through scenic landscapes and river streams.",
          "Enjoy the beauty of the region.",
          "Camp overnight at Gyama.",
        ],
      },
      {
        day: 8,
        title: "Gyama to Korzok (Tso Moriri Lake)",
        details: [
          "Trek towards Tso Moriri Lake and the village of Korzok.",
          "Enjoy breathtaking views of the lake and the surrounding mountains.",
          "Explore the Korzok Monastery and camp near Tso Moriri Lake.",
        ],
      },
      {
        day: 9,
        title: "Rest Day at Tso Moriri",
        details: [
          "Spend a day at Tso Moriri to rest and enjoy the peaceful ambiance.",
          "Explore the area around the lake and capture the beauty.",
          "Camp overnight at Tso Moriri.",
        ],
      },
      {
        day: 10,
        title: "Tso Moriri to Karzok and Drive to Leh",
        details: [
          "Conclude the trek with a short hike to Karzok.",
          "Drive back to Leh, reflecting on the beautiful landscapes and experiences.",
          "Arrive in Leh and spend the evening exploring the local markets or relaxing.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh and Padum upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "nubraValleyTrek",
    individualExpeditionName: "Nubra Valley Trek",
    carouselData: expeditionTrekkingHeroSection,
    location: "Ladakh",
    duration: "6 Days",
    grade: "Intermediate",
    maxAltitude: "3048 M or 10430 ft",
    bestSeason: "May, June, July, August, September and October",
    experienceRequired: "A participant should’ve trekked before.",
    mainExpeditionContent:
      "Embark on an exhilarating journey through the rugged landscapes of Ladakh with our carefully curated trekking itinerary. Starting with an acclimatization day in Leh, you'll gradually adapt to the breathtaking high altitudes that characterize this Himalayan region. Immerse yourself in the rich cultural tapestry of Leh town, exploring iconic sites like Shanti Stupa and Leh Palace, while receiving crucial briefings from experienced trekking guides.The trek unfolds with a scenic drive to Phyang, setting the stage for an awe-inspiring adventure. Traverse from Phyang to Lasermo La Base, camping amidst the pristine wilderness. As you ascend, relish panoramic views of the Phyang Monastery and surrounding vistas. Journey through the dramatic landscapes of Nubra Valley, crossing the Lasermo La pass to reach Hundar Dok, where you'll camp beneath the star-lit sky. Trek onwards to Hunder, discovering the charm of the village and its famed dunes, and concluding with a memorable visit to Diskit Monastery.",
    aboutExpeditionExperienced:
      "Trek from Nubra Valley from Phyang to Hunder with us, a team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh - Acclimatization",
        details: [
          "Arrive in Leh.",
          "Acclimatize to high altitude.",
          "Rest and hydrate to minimize altitude sickness.",
          "Evening exploration of Leh town (Shanti Stupa, Leh Palace).",
          "Briefing session with trekking guide.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 2,
        title: "Leh - Acclimatization and Gear Shopping",
        details: [
          "Continue acclimatizing in Leh.",
          "Visit Leh market for trekking gear and supplies.",
          "Briefing session with trekking guide.",
          "Rest and hydrate.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 3,
        title: "Leh to Phyang and Trek to Lasermo La Base",
        details: [
          "Drive to Phyang (30 minutes).",
          "Trek from Phyang to Lasermo La Base (ascending).",
          "Enjoy views of Phyang Monastery and surroundings.",
          "Camp overnight at Lasermo La Base.",
        ],
      },
      {
        day: 4,
        title: "Lasermo La Base to Hundar Dok",
        details: [
          "Trek from Lasermo La Base to Hundar Dok.",
          "Cross Lasermo La pass.",
          "Descend into Nubra Valley with stunning views.",
          "Camp overnight at Hundar Dok.",
        ],
      },
      {
        day: 5,
        title: "Hundar Dok to Hunder",
        details: [
          "Trek to Hunder through picturesque landscapes.",
          "Explore Hunder village and visit sand dunes.",
          "Witness Bactrian camels in Nubra's cold desert.",
          "Camp overnight at Hunder.",
        ],
      },
      {
        day: 6,
        title: "Hunder to Diskit and Drive to Leh",
        details: [
          "Short hike to Diskit.",
          "Visit Diskit Monastery perched on a hilltop.",
          "Drive back to Leh, reflecting on landscapes and experiences.",
          "Arrive in Leh, explore local markets or relax in the evening.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the trek.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: ["Coordination of permits required for the trek."],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion of the trek",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "Kangyatse_2x6000M",
    individualExpeditionName: "Mt. Kangyatse 1 & 2",
    carouselData: expedition6000MHeroSection,
    location: "Markha Valley, Ladakh",
    duration: "14+1 Days",
    grade: "Intermediate",
    maxAltitude: "6401 M & 6250 M",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple treks",
    mainExpeditionContent:
      "Kangyatse is a prominent mountain located in the Indian union territory of Ladakh. It is part of the Himalayas and specifically situated in the Markha Valley region. Kangyatse is a popular peak among mountaineers and trekkers due to its challenging ascent and stunning surrounding landscapes.Kangyatse has two main summits: Kangyatse I (6,401 meters or 21,000 feet) and Kangyatse II (6,250 meters or 20,505 feet). Climbers often aim to reach the higher Kangyatse I. The ascent involves technical climbing, and the trek to the base camp provides breathtaking views of the Himalayan range.Ladakh's harsh yet captivating terrain, with its high-altitude desert and snow-capped peaks, adds to the allure of Mount Kangyatse for adventure enthusiasts seeking a challenging climb and an immersive experience in the stunning landscapes of the region.",
    aboutExpeditionExperienced:
      "Climb Kangyatse 1 & 2 with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Leh (3500m)",
        details: [
          "Arrival in Leh.",
          "Acclimatization and rest day.",
          "Explore the local market or nearby monasteries.",
          "Overnight stay: Hotel in Leh.",
        ],
      },
      {
        day: "2",
        title: "Leh Acclimatization",
        details: [
          "Acclimatization hike to nearby points such as Shanti Stupa or Khardung La.",
          "Prepare gear and supplies for the trek.",
          "Overnight stay: Hotel in Leh.",
        ],
      },
      {
        day: "3",
        title: "Drive from Leh to Skiu (3400m) and Trek to Markha (3700m)",
        details: [
          "Early morning drive to Skiu via the scenic Zanskar Valley.",
          "Trek (~6-7 hours) through beautiful landscapes to reach Markha.",
          "Overnight stay: Campsite in Markha.",
        ],
      },
      {
        day: "4",
        title: "Trek from Markha to Hankar (4000m)",
        details: [
          "Trek (~6-7 hours) through pastures, small villages, and beautiful views of Kangyatse in the distance.",
          "Overnight stay: Campsite in Hankar.",
        ],
      },
      {
        day: "5",
        title: "Trek from Hankar to Kangyatse Base Camp (4900m)",
        details: [
          "Trek (~5-6 hours) through rocky terrain and meadows to base camp.",
          "Set up camp and rest.",
          "Overnight stay: Campsite at Kangyatse Base Camp.",
        ],
      },
      {
        day: "6",
        title: "Rest Day at Base Camp",
        details: [
          "Acclimatization and training day.",
          "Practice using climbing gear and discuss summit plans.",
          "Overnight stay: Base Camp.",
        ],
      },
      {
        day: "7",
        title: "Trek from Base Camp to Advanced Camp (5300m)",
        details: [
          "Trek (~3-4 hours) to Advanced Camp, crossing glacial and moraine terrain.",
          "Set up camp closer to the summit route.",
          "Overnight stay: Advanced Camp.",
        ],
      },
      {
        day: "8",
        title: "Summit Kangyatse 1 (6400m) and Return to Base Camp",
        details: [
          "Start the climb early (midnight or 1 AM).",
          "Summit Kangyatse 1 after 6-8 hours of challenging ascent.",
          "Descend back to Base Camp (~4-5 hours).",
          "Overnight stay: Base Camp.",
        ],
      },
      {
        day: "9",
        title: "Rest Day at Base Camp",
        details: [
          "Recuperate and relax at Base Camp.",
          "Overnight stay: Base Camp.",
        ],
      },
      {
        day: "10",
        title: "Optional Rest Day",
        details: [
          "If required, take another rest day or prepare for Kangyatse 2.",
          "Overnight stay: Base Camp or Summit Camp.",
        ],
      },
      {
        day: "11",
        title: "Summit Kangyatse 2 (6200m) and Return to Base Camp",
        details: [
          "Begin early (~1-2 AM) for a ~5-7 hour ascent.",
          "Summit Kangyatse 2 and descend back to Base Camp (~3-4 hours).",
          "Overnight stay: Base Camp.",
        ],
      },
      {
        day: "12",
        title:
          "Trek from Base Camp to Chokdo via Kongmaru La Pass (5200m) then Drive to Leh",
        details: [
          "Descend to Chokdo (~6-7 hours).",
          "Drive back to Leh (~2 hours).",
          "Overnight stay: Hotel in Leh.",
        ],
      },
      {
        day: "13-14",
        title: "Extra Buffer Days",
        details: ["Reserved for bad weather conditions or delays."],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/TrVP-533qdc?si=Mjkzm_8qPQt1QNZl",
    expeditionDate: [{ day: "05", month: "JULY" }],
  },
  {
    individualExpeditionType: "MentokKangri_3x6000M",
    individualExpeditionName: "Mt. Mentok Kangri",
    carouselData: expedition6000MHeroSection,
    location: "Karzok, Ladakh",
    duration: "12 Days",
    grade: "Intermediate",
    maxAltitude: "6250 m or 20505 ft",
    bestSeason: "July, August, September and October",
    experienceRequired:
      "An enthusiast should have prior experience of 5000m/6000m climbs or multiple challenging treks.",
    mainExpeditionContent:
      "The Mentok Kangri massif is situated on the western shores of Tso Moriri in eastern Ladakh, nestled in the Rupsu valley. Its three peaks stand tall at 6250 m, 6150 m, and 6210 m, linked by a gentle ridge. Dominating the western edge of Tso Moriri is Mentok Kangri II, soaring to an impressive height of 6,250 m. Your journey begins with a drive to Karzok, located on the picturesque banks of Tso Moriri, followed by establishing a base near the Rupshu plateau, home to the Changpa nomads. The name 'Mentok' originates from a Tibetan term linked to a wildflower native to this area.This summit provides a relatively straightforward snow/ice climb, rewarding climbers with breathtaking panoramic views of Tso Moriri and neighboring peaks, including the prominent Chamser and Lungser Kangri at 6600 m. The trek to Mentok Kangri guides adventurers through remote valleys, traditional villages, and stunning hilltop monasteries, showcasing some of the most remarkable vistas of pristine landscapes.",
    aboutExpeditionExperienced:
      "Climb Mentok Kangri 2 with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Leh (3500m)",
        details: [
          "Arrive in Leh, Ladakh, and transfer to your accommodation.",
          "Spend the day resting and acclimatizing to the altitude. Avoid physical exertion.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: "2",
        title: "Acclimatization in Leh",
        details: [
          "Explore local attractions like Shanti Stupa, Leh Palace, and the local market.",
          "Optional acclimatization hike to a nearby viewpoint.",
          "Focus on hydration and light activity to aid acclimatization.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: "3",
        title: "Leh to Korzok (Tsomoriri Lake, 4595m)",
        details: [
          "Drive from Leh to Korzok village (5–6 hours, approx. 210 km).",
          "Enjoy stunning landscapes en route, including high-altitude passes like Tanglang La.",
          "Arrive at the picturesque Tsomoriri Lake, nestled amidst rugged mountains.",
          "Overnight stay in Korzok.",
        ],
      },
      {
        day: "4",
        title: "Rest and Acclimatization in Korzok",
        details: [
          "Take a leisurely day to acclimatize to the higher altitude.",
          "Optional walk around Tsomoriri Lake or a short hike to nearby ridges.",
          "Overnight stay in Korzok.",
        ],
      },
      {
        day: "5",
        title: "Trek to Base Camp (5200m)",
        details: [
          "Begin the trek to Mentok Kangri Base Camp (7–8 hours).",
          "The trail ascends gradually, crossing rocky terrain and moraine fields.",
          "Set up camp and rest for the night.",
          "Overnight stay at Base Camp.",
        ],
      },
      {
        day: "6",
        title: "Rest and Acclimatization at Base Camp",
        details: [
          "Spend the day resting and preparing for the summit push.",
          "Optional acclimatization hike to higher altitudes for better preparation.",
          "Check and organize climbing gear.",
          "Overnight stay at Base Camp.",
        ],
      },
      {
        day: "7",
        title: "Summit Day (Mentok Kangri, 6220m)",
        details: [
          "Start the climb early (around midnight) for a long summit day (10–12 hours).",
          "The ascent involves snow slopes, rocky ridges, and steep inclines.",
          "Reach the summit and enjoy breathtaking views of the Himalayas and surrounding peaks.",
          "Descend carefully to Base Camp.",
          "Overnight stay at Base Camp.",
        ],
      },
      {
        day: "8",
        title: "Base Camp to Korzok",
        details: [
          "Trek back to Korzok (6–7 hours).",
          "Celebrate the successful expedition with your team.",
          "Overnight stay in Korzok.",
        ],
      },
      {
        day: "9",
        title: "Korzok to Leh",
        details: [
          "Drive back to Leh (5–6 hours).",
          "Relax and enjoy your last evening in Leh.",
          "Overnight stay in Leh.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Manali",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/k3ArNz91cH0?si=X0ANwJ1PE8hKxXdX",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "kiagarRi_3x6000M",
    individualExpeditionName:
      "Mt. Kiager Ri (6100m), Mt. Spangnak Ri (6390m), and Mt. Zes Ri (6320m)",
    carouselData: expedition6000MHeroSection,
    location: "Karzok, Ladakh",
    duration: "9 Days",
    grade: "Advanced",
    maxAltitude: "6100 m or 20013 ft",
    bestSeason: "June, July, August, and September",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Mt. Kiager Ri, located in the stunning Changthang region of Ladakh, offers a perfect blend of adventure and solitude. This lesser-known 6100m peak becomes even more challenging and rewarding in winter, with its pristine snow-covered landscape and sub-zero temperatures adding to the thrill of the climb",
    aboutExpeditionExperienced:
      "Climb Kiagar Ri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: 1,
        title: "Arrival in Leh (3500m)",
        details: [
          "Arrive in Leh and transfer to the hotel.",
          "Rest and acclimatize to the altitude.",
          "Briefing about the expedition.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 2,
        title: "Acclimatization in Leh",
        details: [
          "Morning acclimatization walk.",
          "Visit Khardung La, the world’s second-highest motorable road (5359m), and return to Leh.",
          "Explore local sights, shop for expedition gear, and enjoy the vibrant markets of Leh.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 3,
        title: "Leh to Tso Kar (4500m)",
        details: [
          "Drive to Tso Kar Lake (4–5 hours).",
          "Set up camp near the lake, surrounded by scenic beauty and wildlife.",
          "Evening acclimatization walk.",
          "Overnight camping at Tso Kar.",
        ],
      },
      {
        day: 4,
        title: "Tso Kar to Base Camp (5000m)",
        details: [
          "Drive for 3 hours to the base camp of Mt. Kiager Ri.",
          "Set up the base camp and check expedition equipment.",
          "Relax and prepare for the climb.",
          "Overnight stay at base camp.",
        ],
      },
      {
        day: 5,
        title: "Summit Mt. Kiager Ri (6100m) and Move to Advance Camp (5400m)",
        details: [
          "Early morning ascent to the summit of Mt. Kiager Ri (6100m).",
          "Enjoy panoramic views from the summit.",
          "Descend and move to advance camp (5400m).",
          "Overnight at advance camp.",
        ],
      },
      {
        day: 6,
        title: "Rest Day at Advance Camp (5400m)",
        details: [
          "A full rest day to recover and acclimatize.",
          "Gear check and preparation for the next climbs.",
          "Overnight at advance camp.",
        ],
      },
      {
        day: 7,
        title: "Move to Summit Camp",
        details: [
          "Trek to the summit camp positioned strategically for Mt. Spangnak Ri and Mt. Zes Ri.",
          "Rest and prepare for the dual summits.",
          "Overnight at summit camp.",
        ],
      },
      {
        day: 8,
        title:
          "Summit Mt. Spangnak Ri (6390m) & Mt. Zes Ri (6320m) and Return to Base Camp",
        details: [
          "Early start to summit both peaks in a single push.",
          "Enjoy breathtaking views from the summits of Mt. Spangnak Ri and Mt. Zes Ri.",
          "Descend to base camp and drive back to Leh.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 9,
        title: "Rest Day and Celebration in Leh",
        details: [
          "Celebrate the success of the expedition with your team.",
          "Relax, explore Leh, and enjoy the local culture.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: 10,
        title: "Departure from Leh",
        details: [
          "Transfer to Leh airport for your journey back home with unforgettable memories.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/gINtMRf2QvI?si=PdGnHxECKwd8zSrG",
    expeditionDate: [
      { day: "15", month: "JUN" },
      { day: "13", month: "JUL" },
      { day: "10", month: "AUG" },
      { day: "07", month: "SEP" },
      { day: "28", month: "SEP" },
      { day: "12", month: "OCT" },
    ],
  },
  {
    individualExpeditionType: "skiDownExped",
    individualExpeditionName: "Ski Down Expedition",
    carouselData: expedition6000MHeroSection,
    location: "Himachal Pradesh Lahaul valley",
    duration: "9 Days",
    grade: "Advanced",
    maxAltitude: "6111 m",
    bestSeason: "Early June",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "At 6111m atop Mt. Yunam in Lahaul, Himachal Pradesh, you take in breathtaking Himalayan views before starting your daring ski descent. The untouched snow, biting air, and steep slopes test your skill and focus. Each turn carries a rush of adrenaline, blending serenity and thrill. By the base, you’ve conquered both the peak and an unforgettable high-altitude adventure.",
    aboutExpeditionExperienced:
      "Climb Kiagar Ri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali (2050m)",
        details: [
          "Arrive in Manali and check into your hotel.",
          "Spend the day exploring the town, shopping for last-minute gear, and preparing for the expedition.",
          "Overnight stay in Manali.",
        ],
      },
      {
        day: "2",
        title: "Acclimatization Walk",
        details: [
          "Take a leisurely walk around Old Manali and nearby trails to acclimatize.",
          "Explore Solang Valley or the nearby Naggar village if time permits.",
          "Overnight stay in Manali.",
        ],
      },
      {
        day: "3",
        title: "Drive from Manali to Bhartpur Base Camp (4500m)",
        details: [
          "Drive approximately 6–7 hours to Bhartpur along the Leh-Manali highway.",
          "Set up camp at Bhartpur and rest.",
          "Overnight stay at Bhartpur Base Camp.",
        ],
      },
      {
        day: "4",
        title: "Acclimatization and Load Ferry to High Camp",
        details: [
          "Spend the day acclimatizing to the altitude.",
          "Carry a portion of your gear and supplies to the high camp (~5000m) and return to base camp.",
          "Overnight stay at base camp.",
        ],
      },
      {
        day: "5",
        title: "Move to Summit Camp",
        details: [
          "Trek from base camp to summit camp (~5300m).",
          "Set up the camp and prepare for the summit attempt.",
          "Rest and hydrate well.",
          "Overnight stay at summit camp.",
        ],
      },
      {
        day: "6",
        title: "Summit Day (6111m)",
        details: [
          "Begin the summit push early in the morning.",
          "Ascend Mt. Yunam and enjoy breathtaking views from the peak.",
          "Ski down from the summit to the base camp, experiencing an exhilarating descent.",
          "Overnight stay at base camp.",
        ],
      },
      {
        day: "7",
        title: "Rest or Additional Ascent and Ski Down",
        details: [
          "Use this day for rest or to attempt another ascent on Mt. Yunam or a nearby peak.",
          "Enjoy skiing down again.",
          "Overnight stay at base camp.",
        ],
      },
      {
        day: "8",
        title: "Reserve Day for Ascent or Skiing",
        details: [
          "Use this day as a buffer for weather or for further exploration and skiing.",
          "Overnight stay at base camp.",
        ],
      },
      {
        day: "9",
        title: "Return to Manali",
        details: [
          "Drive back to Manali from Bhartpur (6–7 hours).",
          "Check into your hotel and relax after the expedition.",
          "Celebrate your successful ski expedition in Manali.",
          "Overnight stay in Manali.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "10", month: "JUN" }],
  },
  {
    individualExpeditionType: "ramjak",
    individualExpeditionName: "Mt Ramjak",
    carouselData: expedition6000MHeroSection,
    location: "Himachal Lahaul valley",
    duration: "12 Days",
    grade: "Extreme",
    maxAltitude: "6318 m",
    bestSeason: "June September October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Ramjak Peak, standing at 6,318 meters in Himachal Pradesh’s Lahaul and Spiti District, is a challenging yet accessible summit on the trekking route from Lahaul to Zanskar in Leh Ladakh. The peak was first ascended by the Indian Mountaineering Foundation expedition led by Sangay Dorjee Sherpa. To reach it, trekkers follow the Manali-Leh Military Road to Darcha, then hike along the Kado Tokpo via Jankar Sumdo to Chuminako. Known for its sharp icy summit, the climb is considered moderate. Notable climbers include Gary Murray, the first to summit in 2002, and Rumpa Das, the first Indian woman to do so.",
    aboutExpeditionExperienced:
      "Climb Kiagar Ri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali (2050m)",
        details: [
          "Arrive in Manali and check into your hotel.",
          "Rest and prepare for the trek. Ensure all gear and permits are in place.",
          "Overnight stay in Manali.",
        ],
      },
      {
        day: "2",
        title: "Manali to Jispa (3200m)",
        details: [
          "Drive approximately 3 hours from Manali to Jispa.",
          "Enjoy scenic views of the mountains along the route.",
          "Arrive in Jispa and check into a guesthouse or camp.",
          "Rest for acclimatization at this altitude.",
          "Overnight stay in Jispa.",
        ],
      },
      {
        day: "3",
        title: "Jispa to Base Camp (4600m)",
        details: [
          "Drive approximately 2 hours to the Base Camp.",
          "Set up camp and begin acclimatizing to the higher altitude.",
          "Overnight stay at Base Camp.",
        ],
      },
      {
        day: "4",
        title: "Acclimatization Walk at Base Camp",
        details: [
          "Spend the day acclimatizing by taking a short, easy trek around Base Camp.",
          "Ensure proper hydration and rest.",
          "Enjoy the views of the surrounding peaks.",
          "Overnight stay at Base Camp.",
        ],
      },
      {
        day: "5",
        title: "Base Camp to Camp 1 (5200m)",
        details: [
          "Trek Duration: Approximately 6 hours.",
          "Begin the trek from Base Camp to Camp 1, involving a gradual ascent.",
          "Set up camp at Camp 1 and rest for the night.",
        ],
      },
      {
        day: "6",
        title: "Rest and Acclimatization at Camp 1",
        details: [
          "Take a full rest day at Camp 1 to further acclimatize.",
          "Engage in a short acclimatization hike if needed.",
          "Focus on hydration and food intake.",
          "Overnight stay at Camp 1.",
        ],
      },
      {
        day: "7",
        title: "Camp 1 to Summit Camp (5800m)",
        details: [
          "Trek Duration: A few hours, depending on pace.",
          "Begin the trek from Camp 1 to Summit Camp.",
          "Set up camp at Summit Camp and prepare for the summit attempt.",
          "Rest and ensure all gear is ready for the summit push.",
          "Overnight stay at Summit Camp.",
        ],
      },
      {
        day: "8",
        title: "Summit Rmajak Peak (6318m) and Return to Summit Camp",
        details: [
          "Early morning departure for the summit of Rmajak Peak (6318m).",
          "After reaching the summit, enjoy panoramic views and take photos.",
          "Return to Summit Camp for rest.",
          "Overnight stay at Summit Camp.",
        ],
      },
      {
        day: "9",
        title: "Camp 1 to Base Camp and Drive Back to Manali",
        details: [
          "Descend from Summit Camp to Camp 1, and then continue to Base Camp.",
          "Drive from Base Camp to Manali after packing up camp.",
          "Arrive in Manali in the evening.",
          "Overnight stay in Manali.",
        ],
      },
      {
        day: "10-11",
        title: "Buffer Days",
        details: [
          "Reserved for unforeseen delays, weather conditions, or acclimatization needs.",
          "Can be used to rest at higher camps or for additional trekking.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "kangjuKangri",
    individualExpeditionName: "Mt Kangju Kangri",
    carouselData: expedition6000MHeroSection,
    location: "Ladakh Pangong Karakoram range",
    duration: "13 Days",
    grade: "Extreme",
    maxAltitude: "6725 m",
    bestSeason: "July September",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "Kangju Kangri is a mountain in the Karakoram Range, located in Ladakh, India, with an elevation of 6,725 meters (22,064 feet). It is the highest peak in the Pangong Range, featuring the three-faced Kangju Glacier and overlooking Pangong Tso lake to the east. The peak was first ascended in 1983 by an Indo-Tibet Border Police and local army expedition, with subsequent ascents in 1987, 1995, and 2001 by the ITBP and Indian Army.",
    aboutExpeditionExperienced:
      "Climb Kiagar Ri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Leh (3500m)",
        details: [
          "Arrive in Leh, Ladakh, and transfer to your accommodation.",
          "Rest and acclimatize to the high altitude.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: "2",
        title: "Leh Acclimatization",
        details: [
          "Explore Leh and its surroundings to aid acclimatization.",
          "Visit local sites like monasteries, markets, and other cultural landmarks.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: "3",
        title: "Leh to Chushul (4300m)",
        details: [
          "Drive to Chushul, approximately 6–7 hours.",
          "Enjoy the scenic drive through the Ladakh region.",
          "Overnight stay in Chushul.",
        ],
      },
      {
        day: "4",
        title: "Acclimatization and Rest in Chushul",
        details: [
          "Rest day for acclimatization in Chushul.",
          "Take short hikes in the area if needed to assist with altitude adjustment.",
          "Overnight stay in Chushul.",
        ],
      },
      {
        day: "5",
        title: "Chushul to Base Camp (5000m)",
        details: [
          "Trek from Chushul to Base Camp, approximately 5 hours.",
          "Set up camp at the base for the summit push.",
          "Overnight stay at Base Camp.",
        ],
      },
      {
        day: "6",
        title: "Base Camp to Camp 1 (5500m)",
        details: [
          "Trek Duration: Approximately 5 hours.",
          "Trek from Base Camp to Camp 1.",
          "Set up camp and prepare for higher altitude trekking.",
          "Overnight stay at Camp 1.",
        ],
      },
      {
        day: "7",
        title: "Rest and Acclimatization at Camp 1",
        details: [
          "Rest day to acclimatize at Camp 1.",
          "Perform short hikes or exercises to adjust to the altitude.",
          "Overnight stay at Camp 1.",
        ],
      },
      {
        day: "8",
        title: "Camp 1 to Summit Camp (6100m)",
        details: [
          "Trek Duration: A few hours.",
          "Trek from Camp 1 to Summit Camp.",
          "Set up camp at a higher altitude for the final summit push.",
          "Overnight stay at Summit Camp.",
        ],
      },
      {
        day: "9",
        title: "Summit Kangju Kangri (6725m) and Return to Base Camp",
        details: [
          "Early morning summit push to Kangju Kangri.",
          "Enjoy panoramic views from the summit.",
          "Return to Base Camp after summiting.",
          "Overnight stay at Base Camp.",
        ],
      },
      {
        day: "10",
        title: "Return to Chushul",
        details: [
          "Trek back from Base Camp to Chushul.",
          "Celebrate the successful expedition and rest.",
          "Overnight stay in Chushul.",
        ],
      },
      {
        day: "11",
        title: "Chushul to Leh (Drive)",
        details: [
          "Drive back from Chushul to Leh.",
          "Rest and relax in Leh after the trek.",
          "Overnight stay in Leh.",
        ],
      },
      {
        day: "12-13",
        title: "Buffer Days",
        details: [
          "Reserved for unforeseen delays due to weather, health issues, or other emergencies.",
          "Can be used for additional acclimatization or extra rest days.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "2x5000",
    individualExpeditionName: "Friendship Peak & Shitidhar",
    carouselData: expedition6000MHeroSection,
    location: "Manali",
    duration: "11+2 Days",
    grade: "Advanced",
    maxAltitude: "5289M and 5250M",
    bestSeason: "May June September October",
    experienceRequired:
      "An enthusiast should have trekked between 4000 m to 5000 m at least once.",
    mainExpeditionContent:
      "An expedition combining the ascent of Friendship Peak (5,289 meters) and Shitidhar Peak (5,250 meters) is an exciting and challenging mountaineering adventure, located in the Manali region of Himachal Pradesh, India. Both peaks are known for their remarkable beauty and technical difficulty, offering an excellent experience for climbers with prior mountaineering skills.",
    aboutExpeditionExperienced:
      "Climb Kiagar Ri with our seasoned climbing team with over a decade of experience in the Indian Himalayas. We pride ourselves on upholding the highest safety standards throughout our operations. With a track record of more than 10 years, we've honed our skills and know these mountains like the back of our hands. Our commitment to safety goes beyond words—we have meticulous rescue plans in place, ready to be executed if the need arises. When you embark on an adventure with us, rest assured that you're in the hands of professionals who prioritize your safety above all else. Welcome to a team that's as prepared as it gets.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Manali (2000m)",
        details: [
          "Reach Manali and check into the hotel.",
          "Briefing, gear check, and preparation for the expedition.",
          "Overnight stay in Manali.",
        ],
      },
      {
        day: "2",
        title: "Manali to Lady Leg Base Camp",
        details: [
          "Drive to Dhundi and trek to Lady Leg Base Camp (3500m).",
          "Approximate trek duration: 5-6 hours.",
          "Overnight stay in tents at Lady Leg Base Camp.",
        ],
      },
      {
        day: "3",
        title: "Acclimatization and Rest Day at Lady Leg",
        details: [
          "Explore the surrounding areas to acclimatize.",
          "Short hikes and training sessions on the use of climbing gear.",
          "Overnight stay in tents at Lady Leg Base Camp.",
        ],
      },
      {
        day: "4",
        title: "Rest and Preparation at Lady Leg",
        details: [
          "Additional rest and acclimatization.",
          "Final preparations and gear adjustments for the summit push.",
          "Overnight stay in tents at Lady Leg Base Camp.",
        ],
      },
      {
        day: "5",
        title: "Lady Leg to Summit Camp (Friendship Peak)",
        details: [
          "Trek to the Summit Camp of Friendship Peak (4200m).",
          "Approximate trek duration: 3-4 hours.",
          "Overnight stay in tents at Summit Camp.",
        ],
      },
      {
        day: "6",
        title: "Summit Day (Friendship Peak)",
        details: [
          "Early morning start for the summit push (5289m).",
          "Enjoy panoramic views from the top.",
          "Descend back to Lady Leg Base Camp.",
          "Approximate duration: 10-12 hours.",
          "Overnight stay in tents at Lady Leg Base Camp.",
        ],
      },
      {
        day: "7",
        title: "Rest Day at Lady Leg Base Camp",
        details: [
          "Relax and recover from the summit climb.",
          "Overnight stay in tents at Lady Leg Base Camp.",
        ],
      },
      {
        day: "8",
        title: "Lady Leg to Summit Camp (Shitidhar)",
        details: [
          "Trek to Shitidhar Summit Camp (4200m).",
          "Approximate trek duration: 5-6 hours.",
          "Overnight stay in tents at Summit Camp.",
        ],
      },
      {
        day: "9",
        title: "Summit Day (Shitidhar)",
        details: [
          "Early morning summit attempt of Shitidhar Peak (5250m).",
          "Return to Lady Leg Base Camp.",
          "Approximate duration: 10-12 hours.",
          "Overnight stay in tents at Lady Leg Base Camp.",
        ],
      },
      {
        day: "10",
        title: "Trek Back to Manali",
        details: [
          "Descend from Lady Leg to Dhundi.",
          "Drive back to Manali and check into the hotel.",
          "Overnight stay in Manali.",
        ],
      },
      {
        day: "11",
        title: "Departure",
        details: ["Depart from Manali to your respective destinations."],
      },
      {
        day: "12-13",
        title: "Buffer Days",
        details: [
          "These extra days will be used in case of unfavorable weather or delays during the summit attempts.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Comfortable lodging in Leh upon arrival and during the expedition.",
          "Tented accommodation during the trek and at various campsites.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: [
          "Nutritious and delicious meals throughout the expedition, designed to meet the energy demands of high-altitude trekking.",
          "Special provisions for dietary preferences and requirements.",
        ],
      },
      {
        sectionTitle: "Experienced Guides",
        content: [
          "Expert local guides well-versed in the terrain and conditions of the region.",
          "Assistance and guidance to ensure a safe and enjoyable trekking experience.",
        ],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Transport from Leh to the trek starting points and back, ensuring a seamless and comfortable journey.",
          "Well-maintained vehicles and experienced drivers for safe travel.",
        ],
      },
      {
        sectionTitle: "Camping Equipment",
        content: [
          "High-quality camping gear, including tents, sleeping bags, and other essentials for a comfortable night's rest.",
          "Technical gear distribution and fitting for the summit attempt.",
        ],
      },
      {
        sectionTitle: "Permits and Paperwork",
        content: [
          "Assistance in completing necessary paperwork with the Indian Mountaineering Foundation (IMF).",
          "Coordination of permits required for the expedition.",
        ],
      },
      {
        sectionTitle: "Safety Measures",
        content: [
          "Emergency evacuation plans and access to experienced high-altitude rescue teams.",
          "First aid provisions and medical assistance throughout the expedition.",
        ],
      },
      {
        sectionTitle: "Expedition Certificate",
        content: [
          "Commemorative certificates recognizing the successful completion Expedition",
        ],
      },
      {
        sectionTitle: "Communication",
        content: [
          "Communication equipment for emergencies",
          "Regular updates and briefings",
        ],
      },
      {
        sectionTitle: "Environment and Leave No Trace",
        content: [
          "Commitment to environmentally responsible trekking",
          "Practices to minimize environmental impact",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Travel Insurance",
        content: [
          "Personal travel insurance covering medical expenses, evacuation, and other unforeseen circumstances.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Individual trekking equipment and personal clothing.",
          "Any additional gear required for specific preferences or comfort.",
        ],
      },
      {
        sectionTitle: "Meals in Leh",
        content: [
          "Meals in Leh on arrival and departure days.",
          "Expenses for additional meals beyond the provided trek meals.",
        ],
      },
      {
        sectionTitle: "Beverages",
        content: [
          "Expenses for additional beverages or snacks not included in the provided meals.",
        ],
      },
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Any expenses of a personal nature, including souvenirs, phone calls, laundry, and tips.",
        ],
      },
      {
        sectionTitle: "Transport beyond Itinerary",
        content: [
          "Transportation or expenses beyond the specified itinerary or additional travel arrangements.",
        ],
      },
      {
        sectionTitle: "Medical Expenses",
        content: [
          "Costs related to pre-existing medical conditions or personal medication.",
        ],
      },
      {
        sectionTitle: "Changes in Itinerary",
        content: [
          "Any additional costs incurred due to changes in the itinerary caused by factors beyond the control of the trek organizers.",
        ],
      },
      {
        sectionTitle: "Unforeseen Circumstances",
        content: [
          "Expenses arising from unforeseen circumstances such as natural disasters, political unrest, or flight cancellations.",
        ],
      },
      {
        sectionTitle: "Additional Services",
        content: [
          "Any services or facilities not explicitly mentioned in the inclusions.",
        ],
      },
      {
        sectionTitle: "Porter Services",
        content: [
          "Personal porter services for carrying individual backpacks, if required.",
        ],
      },
      {
        sectionTitle: "Optional Activities",
        content: [
          "Costs associated with optional activities or excursions not included in the trek itinerary.",
        ],
      },
      {
        sectionTitle: "Government Fees",
        content: [
          "Any additional fees imposed by government authorities during the trek.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "25", month: "MAY" }],
  },
];

// 5.1.1 2X5000M
// Friendship peak & Shitidhar
// 5.2.1   2X6000M
// KY 1 & KY 2
// 5.3.1  3x6000M
// MT KIAGER RI - MT SPANGNAK RI - MT ZES RI
// 5.4.1 Ski Down expedition 6100M
// 5.5.1 UNCLIMBED PEAK 6000M
// 6.5.1 Mt RAMJAK 6318M
// 6.6.1  MT CHAKULA 6534M
