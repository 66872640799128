import React from "react";
import RickyMountaineer from "../../assets/home/Members/Ricky-2.webp";
import SampleImage from "../../assets/home/Members/Ricky-2.webp"; // Add your image path here
import Footer from "../../components/Footer";
import expedition from "../../assets/home/hero/expedition.webp";
import NavigationBar from "../../components/NavigationBar";

const BlogTemplate = ({
  heading,
  summary,
  coverImage,
  authorName,
  date,
  children,
}) => {
  return (
    <>
      <section className="center">
        <NavigationBar />
      </section>
      <div className="w-full h-auto">
        {/* Dynamic Black Section */}
        <div className="w-full bg-black pt-28 relative">
          <section className="center">
            <div className="container">
              <div className="grid grid-cols-12 gap-2">
                <h1 className="newHeader col-start-1 col-span-12 text-center text-primary">
                  {heading}
                </h1>
                <h1 className="newContent col-start-1 col-span-12 text-center margin">
                  {summary}
                </h1>
              </div>
              {/* Author Section */}
              <div className="col-start-1 col-span-12 flex justify-center items-center pb-8 margin">
                <div className="flex items-center gap-4 margin">
                  <img
                    src={RickyMountaineer}
                    alt="Olivia Rhye"
                    className="w-14 h-14 rounded-full"
                  />
                  <div>
                    <h4 className="text-white newL2SubHeader">{authorName}</h4>
                    <p className="text-white newL2Content">{date}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* Overlapping Image */}
        <div className="relative z-10 md:-mt-24 lg:-mt-44 -mt-12">
          <section className="center">
            <div className="container">
              <div className="grid grid-cols-12 gap-2">
                <div className="col-start-1 col-span-12 flex justify-center">
                  <img
                    src={coverImage}
                    alt="Overlay"
                    className="w-full h-[400px] md:h-[600px] lg:h-[800px] rounded-lg shadow-lg object-cover"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* Content Section */}
        <div className="w-full bg-white md:-mt-24 lg:-mt-44 -mt-10">
          <section className="center">
            <div className="container">
              <div className="grid grid-cols-12 gap-2">
                <div className="col-start-1 col-span-12 lg:col-start-3 lg:col-span-8">
                  {children}
                </div>
              </div>
            </div>
          </section>
        </div>
        <section
          className="relative bg-center bg-cover"
          style={{ backgroundImage: `url(${expedition})` }}
        >
          <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>
          <div className="center">
            <div className="container relative z-20">
              <div className="grid grid-cols-12 gap-2">
                <div className="col-start-1 col-span-12">
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BlogTemplate;
