import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { individualExpedition } from "./dataStorage";
import HeroSection from "../../components/HeroSection";
import NavigationBar from "../../components/NavigationBar";

import Footer from "../../components/Footer";
import expedition from "../../assets/home/hero/expedition.webp";

import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";

import AOS from "aos";
import "aos/dist/aos.css";
import Whatsapp from "../../components/Whatsapp";
import { Helmet } from "react-helmet";
import UpcomingTours from "../../components/UpcomingTours";
import UpcomingToursIcon from "../../components/UpcomingToursIcon";

const IndividualTeamMember = () => {
  const { individualLeader } = useParams();

  const FilterIndividualExpedition = individualExpedition.find(
    (exp) => exp.individualExpeditionType === individualLeader
  );
  useEffect(() => {
    AOS.init();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  return (
    <div>
      <Helmet>
        <title>White Expedition - Team Member</title>
        <meta
          name="description"
          content="Meet a White Expedition guide! Explore the profiles of our passionate and experienced climbing professionals leading adventures in Himachal Pradesh."
        />
      </Helmet>

      {/* Hero Section  */}
      <HeroSection carouselData={FilterIndividualExpedition.carouselData} />

      {/* Navigation Bar Section  */}
      <section className="center">
        <NavigationBar />
      </section>

      {/* About Leader Section  */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-primary text-center">
              {FilterIndividualExpedition.leaderName}
            </h1>
            <div className="col-start-1 col-span-12 flex justify-center items-center space-x-3">
              {FilterIndividualExpedition.facebookLink !== "" && (
                <a
                  href={FilterIndividualExpedition.facebookLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookSquare
                    className="text-black cursor-pointer"
                    size={25}
                  />
                </a>
              )}
              {FilterIndividualExpedition.twitterLink !== "" && (
                <a
                  href={FilterIndividualExpedition.twitterLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaSquareXTwitter
                    className="text-black cursor-pointer"
                    size={25}
                  />
                </a>
              )}
              {FilterIndividualExpedition.instagramLink !== "" && (
                <a
                  href={FilterIndividualExpedition.instagramLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram
                    className="text-black cursor-pointer"
                    size={25}
                  />
                </a>
              )}
              {FilterIndividualExpedition.youtubeLink !== "" && (
                <a
                  href={FilterIndividualExpedition.youtubeLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube className="text-black cursor-pointer" size={25} />
                </a>
              )}
            </div>
            <h1 className="newContent col-start-1 col-span-12 text-center margin">
              {FilterIndividualExpedition.mainExpeditionContent}
            </h1>
            <h1 className="newContent col-start-1 col-span-12 text-center margin">
              {FilterIndividualExpedition.aboutExpeditionExperienced}
            </h1>
          </div>
        </div>
      </section>

      {/* Photo Gallery Section  */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-primary text-center">
              Photo Gallery
            </h1>
            {/* Member 1  */}
            <div className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-1 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-1 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer">
              <img
                src={FilterIndividualExpedition.image1}
                alt="Expedition Leader"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Member 2  */}
            <div className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-7 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-5 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer">
              <img
                src={FilterIndividualExpedition.image2}
                alt="Expedition Leader"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Member 3  */}
            <div className="relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-4 md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-9 xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer">
              <img
                src={FilterIndividualExpedition.image3}
                alt="Expedition Leader"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Member Youtube Section   */}
      {FilterIndividualExpedition.youtubeVideoLink !== "" && (
        <section className="center">
          <div className="container bg-black">
            <div className="grid grid-cols-12 gap-2">
              <div className="col-start-1 col-span-12 aspect-video rounded-lg overflow-hidden">
                <iframe
                  className="w-full h-full"
                  src={FilterIndividualExpedition.youtubeVideoLink}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Footer section  */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>
        <div className="center">
          <div className="container relative z-20">
            <div className="grid grid-cols-12 gap-2">
              <div className="col-start-1 col-span-12">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Whatsapp />

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </div>
  );
};

export default IndividualTeamMember;
