import { wildllifePhotographyHeroSection } from "../../data/Home/dataStorage";

export const thingstocarray = [
  {
    sectionTitle: "Layered Clothing",
    content: [
      "Thermal innerwear: Base layers to keep you warm.",
      "Insulating layers: Fleece or down jackets for insulation.",
      "Waterproof and windproof outer shell: Protects against snow and wind.",
    ],
  },
  {
    sectionTitle: "Headgear",
    content: [
      "Insulated hat or balaclava: Covers the head and ears.",
      "Sunglasses with UV protection: Protects eyes from snow glare.",
    ],
  },
  {
    sectionTitle: "Hand Protection",
    content: [
      "Insulated gloves or mittens: Keep hands warm and dry.",
      "Hand warmers: Disposable heat packs for extra warmth.",
    ],
  },
  {
    sectionTitle: "Footwear",
    content: [
      "Insulated and waterproof boots: Provide warmth and protection.",
      "Gaiters: Cover the lower leg and prevent snow from entering the boots.",
      "Extra pairs of moisture-wicking socks: Keep feet dry and warm.",
    ],
  },
  {
    sectionTitle: "Backpack",
    content: [
      "Large, sturdy backpack: To carry all your essentials.",
      "Rain cover or waterproof liners: Protects gear from snow and moisture.",
      "Day bag (20-30 ltr)",
    ],
  },
  {
    sectionTitle: "Nutrition",
    content: [
      "High-energy snacks: Nuts, energy bars, and chocolate.",
      "Hydration system: Insulated water bottles or a hydration pack.",
    ],
  },
  {
    sectionTitle: "Emergency Equipment",
    content: ["Personal medicines if required."],
  },
  {
    sectionTitle: "Lighting",
    content: [
      "Headlamp or flashlight: Essential for visibility in the dark.",
      "Extra batteries: Ensure you have enough power.",
    ],
  },
  {
    sectionTitle: "Communication",
    content: [
      "Fully charged cell phone: Keep it in a warm place to preserve battery life.",
      "Portable charger: Extra power for electronic devices.",
    ],
  },
  {
    sectionTitle: "Personal Items",
    content: [
      "Sunscreen: Even in winter, UV rays can be intense at high altitudes.",
      "Lip balm: Prevents chapped lips in cold, dry conditions.",
      "Personal hygiene items: Toothbrush, toothpaste, etc.",
    ],
  },
  {
    sectionTitle: "Documentation & Cash",
    content: [
      "ID, permits, and emergency contact information.",
      "Remote areas don’t have ATMs, so please carry enough cash.",
    ],
  },
];

export const individualExpedition = [
  {
    individualExpeditionType: "snowLeopardWinterSpiti",
    individualExpeditionName: "Snow Leopard Winter Spiti",
    carouselData: wildllifePhotographyHeroSection,
    location: "Spiti Himachal Pradesh",
    duration: "8 Days",
    grade: "Any",
    maxAltitude: "4587m (above)",
    bestSeason: "Jan, Feb and March",
    experienceRequired: "Don’t want to do it in a group? Get it customised.",
    mainExpeditionContent:
      "Snow leopards—one of the world’s most elusive cats—are perfectly equipped to thrive in extreme, high-elevation habitats. Snow leopards can be found throughout high mountain ranges, including the Himalayas and the southern Siberian mountains in Russia. They can also be found in the Tibetan Plateau and across a range that stretches from China to the mountains of Central Asia. They prefer steep, rugged terrain with rocky outcrops where prey can be hard to come by. That’s why these carnivores require an enormous amount of space to roam: Male leopards require up to 80 square miles while females have ranges of up to 48 square miles.",
    aboutExpeditionExperienced:
      "The Spiti River, which originates from the Kunzum Range, flows through the valley, and the region is known for its stark landscapes, barren mountains, and vast expanses of barren land. The region is also home to several rare and endangered wildlife species, including the snow leopard, ibex, and blue sheep.It's not easy to spot these enigmatic cats. It is important to be at the right place at the right time!",
    itineraryData: [
      {
        day: "1",
        title: "Shimla to Kalpa",
        details: [
          "Distance: Approx. 225 km",
          "Altitude: Shimla (2,276 m) to Kalpa (2,960 m)",
          "Driving Time: Around 8-9 hours",
          "Activities: Depart from Shimla early in the morning, embark on a scenic drive to Kalpa.",
          "Arrival in Kalpa by late afternoon or early evening.",
          "Evening free for relaxation and acclimatization to the high-altitude environment.",
          "Overnight Stay: Kalpa.",
        ],
      },
      {
        day: "2",
        title: "Kalpa to Tabo",
        details: [
          "Distance: Approx. 150 km",
          "Altitude: Kalpa (2,960 m) to Tabo (3,280 m)",
          "Driving Time: 6-7 hours",
          "Activities: After breakfast, continue the journey towards Tabo, a remote village in Spiti Valley.",
          "Explore the historic Tabo Monastery, known for its 1,000-year-old murals and spiritual significance.",
          "Overnight Stay: Tabo.",
        ],
      },
      {
        day: "3",
        title: "Tabo to Kaza",
        details: [
          "Distance: Approx. 50 km",
          "Altitude: Tabo (3,280 m) to Kaza (3,650 m)",
          "Driving Time: 2-3 hours",
          "Activities: Depart for Kaza, the largest town in the Spiti Valley.",
          "Arrive in Kaza and check into your accommodations.",
          "Rest for the day to adapt to the altitude.",
          "Evening at leisure to explore the local village.",
          "Overnight Stay: Kaza.",
        ],
      },
      {
        day: "4",
        title: "Rest and Acclimatization in Kaza",
        details: [
          "Activities: Spend the day resting and adjusting to the high-altitude environment.",
          "Optional: Take a short walk around Kaza and its surroundings to familiarize yourself with the terrain and begin looking for wildlife.",
          "Visit local monasteries, such as the Key Monastery, and enjoy the stunning views of the Spiti Valley.",
          "Overnight Stay: Kaza.",
        ],
      },
      {
        day: "5",
        title: "Kaza to Kibber",
        details: [
          "Distance: Approx. 18 km",
          "Altitude: Kaza (3,650 m) to Kibber (4,205 m)",
          "Driving Time: 1-2 hours",
          "Activities: After breakfast, head towards Kibber, a picturesque village at a higher altitude.",
          "Arrive in Kibber and settle into your accommodation.",
          "Begin scouting the area for potential wildlife sightings.",
          "Explore the village and the surrounding landscapes.",
          "Overnight Stay: Kibber.",
        ],
      },
      {
        day: "6-8",
        title: "Snow Leopard Spotting in Kibber and Surrounding Areas",
        details: [
          "Activities: Over the next three days, embark on early morning and late afternoon wildlife excursions, aiming to spot the elusive Snow Leopard in the rugged terrain.",
          "Focus on key locations known for frequent Snow Leopard sightings, including the Kibber Wildlife Sanctuary, the high-altitude areas around Kibber, and remote cliffs.",
          "Capture videos and photos of the Snow Leopard and other wildlife such as Himalayan Blue Sheep, Ibex, and various bird species.",
          "Local guides and trackers will assist you in locating the Snow Leopards.",
          "Overnight Stays: Kibber.",
        ],
      },
      {
        day: "9",
        title: "Kibber to Nako",
        details: [
          "Distance: Approx. 70 km",
          "Altitude: Kibber (4,205 m) to Nako (3,662 m)",
          "Driving Time: 3-4 hours",
          "Activities: After breakfast, drive from Kibber to Nako, a quiet village located by a beautiful high-altitude lake.",
          "Explore Nako and its surroundings, including Nako Lake and the Nako Monastery.",
          "Take some time to relax and enjoy the serene atmosphere.",
          "Overnight Stay: Nako.",
        ],
      },
      {
        day: "10",
        title: "Nako to Shimla",
        details: [
          "Distance: Approx. 250 km",
          "Altitude: Nako (3,662 m) to Shimla (2,276 m)",
          "Driving Time: 8-9 hours",
          "Activities: Begin your return journey to Shimla after breakfast.",
          "Arrive in Shimla by late evening, marking the end of your Snow Leopard expedition.",
          "Overnight Stay: Shimla or onward departure.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Transportation as per program for 8 days.",
        content: [],
      },
      {
        sectionTitle: "4x4 vehicle",
        content: [],
      },
      {
        sectionTitle: "Team Leader(s)",
        content: [],
      },
      {
        sectionTitle: "Accommodation will be a hotel for days 1 & 7.",
        content: [],
      },
      {
        sectionTitle: "Accommodation will be Homestay for days 2, 3, 4,  5 & 6",
        content: [],
      },
      {
        sectionTitle: "Meals as mentioned",
        content: [],
      },
      {
        sectionTitle: "Highly-Experienced driver cum guide",
        content: [],
      },
      {
        sectionTitle: "Sightseeing as per itinerary",
        content: [],
      },
      {
        sectionTitle: "Inner Line permit",
        content: [],
      },
    ],
    exclusion: [
      {
        sectionTitle:
          "The cost incurred due to change in itinerary due to bad weather, road closure/block, heavy snowfall, or factors beyond control.",
        content: [],
      },
      {
        sectionTitle: "Tips to guides/drivers.",
        content: [],
      },
      {
        sectionTitle: "Camera and Time Village Fees",
        content: [],
      },
      {
        sectionTitle: "Personal Gear/Specialized clothing for cold weather.",
        content: [],
      },
      {
        sectionTitle: "Medical or any other emergency evacuation costs.",
        content: [],
      },
      {
        sectionTitle:
          "Cameras/batteries any other props Like Binoculars, Lenses, etc.",
        content: [],
      },
      {
        sectionTitle: "Sanctuary and Monastery fees.",
        content: [],
      },
      {
        sectionTitle: "Any item not mentioned in the “Inclusions” list.",
        content: [],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
];
