import React, { useEffect, useState } from "react";
import { MdOutlineExplore } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import {
  expedition5000M,
  expedition6000M,
  expedition7000M,
  pureAdrenaline,
  trekking,
} from "../data/Expeditions/dataStorage";
import logoSmall2 from "../assets/White_Exped_Logo 2.webp";

import { FaChevronCircleRight } from "react-icons/fa";
import { FaChevronCircleLeft } from "react-icons/fa";
import { FaChevronCircleDown } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

import { useUserAuth } from "../utils/UserAuthContext";
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import { authentication } from "../utils/Firebase";
import Popup from "./Popup";

const NavigationBar = () => {
  const [smallDeviceNavbarVisible, setSmallDeviceNavbarVisible] =
    useState(false);

  const navigate = useNavigate();

  const [expeditionCount, setExpeditionCount] = useState(0);

  const [firstExpedition, setFirstExpedition] = useState([]);
  const [secondExpedition, setSecondExpedition] = useState([]);
  const [thirdExpedition, setThirdExpedition] = useState([]);
  const [fourthExpedition, setFourthExpedition] = useState([]);
  const [fifthExpedition, setFifthExpedition] = useState([]);
  const [sixthExpedition, setSixthExpedition] = useState([]);
  const [seventhExpedition, setSeventhExpedition] = useState([]);
  const [eighthExpedition, setEighthExpedition] = useState([]);

  const [expeditionArray, setExpeditionArray] = useState(expedition5000M);
  const [expeditionType, setExpeditionType] = useState("expedition5000M");

  const [isActive, setIsActive] = useState({
    aboutUs: false,
    expeditions: false,
    adventureTours: false,
    subAdventureTours: false,
  });

  // Function to handle toggling of menu items
  const handleToggle = (id) => {
    setIsActive((prev) => {
      const newState = {};

      // Set all states to false
      Object.keys(prev).forEach((key) => {
        newState[key] = false;
      });

      // Set the specified state to true
      newState[id] = !prev[id];

      return newState;
    });
  };

  const subHandleToggle = (id) => {
    setIsActive((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleClose = (id) => {
    setIsActive((prev) => {
      const newState = {};

      // Set all states to false
      Object.keys(prev).forEach((key) => {
        newState[key] = false;
      });

      return newState;
    });
  };

  const expeditionHandler = (chosenExpedition) => {
    setExpeditionArray(chosenExpedition);
    setExpeditionCount(0);
  };

  const expeditionTypeHandler = (chosenExpeditionName) => {
    setExpeditionType(chosenExpeditionName);
  };

  useEffect(() => {
    setFirstExpedition(
      expeditionArray.filter((item) => item.id === 8 * expeditionCount + 0)
    );
    setSecondExpedition(
      expeditionArray.filter((item) => item.id === 8 * expeditionCount + 1)
    );
    setThirdExpedition(
      expeditionArray.filter((item) => item.id === 8 * expeditionCount + 2)
    );
    setFourthExpedition(
      expeditionArray.filter((item) => item.id === 8 * expeditionCount + 3)
    );
    setFifthExpedition(
      expeditionArray.filter((item) => item.id === 8 * expeditionCount + 4)
    );
    setSixthExpedition(
      expeditionArray.filter((item) => item.id === 8 * expeditionCount + 5)
    );
    setSeventhExpedition(
      expeditionArray.filter((item) => item.id === 8 * expeditionCount + 6)
    );
    setEighthExpedition(
      expeditionArray.filter((item) => item.id === 8 * expeditionCount + 7)
    );
  }, [expeditionCount, expeditionArray]);

  // Sign in sign up logic

  const [auth, setAuth] = useState({
    signIn: false,
    signUp: false,
  });

  const handleSignInClick = () => {
    setSmallDeviceNavbarVisible(false);
    setAuth({ signIn: true, signUp: false });
  };

  const handleSignUpClick = () => {
    setSmallDeviceNavbarVisible(false);
    setAuth({ signIn: false, signUp: true });
  };

  const authHandler = () => {
    setAuth({ signIn: false, signUp: false });
    setSignInEmail("");
    setSignInPassword("");
    setSignUpEmail("");
    setSignUpPassword("");
  };

  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");
  const [isSignInDisable, setIsSignInDisable] = useState(true);
  const [signUpEmail, setSignUpEmail] = useState("");
  const [signUpPassword, setSignUpPassword] = useState("");
  const [passwordMessasge, setPasswordMessage] = useState({
    noErrorMessage: true,
    successMessage: false,
    errorMessage: false,
  });
  const [isSignUpDisable, setIsSignUpDisable] = useState(true);

  const [isSignIn, setIsSignIn] = useState(false);

  const { logIn, googleSignIn, signUp, logOut } = useUserAuth();

  // Sign in logic

  useEffect(() => {
    if (signInPassword.length >= 1 && validateEmail(signInEmail)) {
      setIsSignInDisable(false);
    } else {
      setIsSignInDisable(true);
    }
  }, [signInEmail, signInPassword]);

  const validateEmail = (signInEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(signInEmail);
  };

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const toggleErrorPopup = () => setShowErrorPopup(false);

  const handleSignIn = async () => {
    try {
      await logIn(signInEmail, signInPassword);
      setAuth({ signIn: false, signUp: false });
      setSignInEmail("");
      setSignInPassword("");
      setSignUpEmail("");
      setSignUpPassword("");
      setIsSignIn(true);
    } catch (error) {
      if (error.code === "auth/invalid-credential") {
        // Show popup for new account creation
        setShowErrorPopup(true);
      } else {
        // Handle other potential errors (e.g., network issues)
        console.error("Error signing in:", error);
      }
    }
  };

  const signInWithGoogle = async () => {
    try {
      await googleSignIn(signInEmail, signInPassword);
      setAuth({ signIn: false, signUp: false });
      setSignInEmail("");
      setSignInPassword("");
      setSignUpEmail("");
      setSignUpPassword("");
      setIsSignIn(true);
    } catch (err) {
      console.log(err);
    }
  };

  // Sign up logic

  useEffect(() => {
    // Check password strength and update UI accordingly
    if (signUpPassword.length >= 8) {
      // Strong password
      setPasswordMessage({
        noErrorMessage: false,
        successMessage: true,
        errorMessage: false,
      });
    } else if (signUpPassword.length > 0 && signUpPassword.length < 8) {
      // Weak password
      setPasswordMessage({
        noErrorMessage: false,
        successMessage: false,
        errorMessage: true,
      });
    } else {
      // Password too short
      setPasswordMessage({
        noErrorMessage: true,
        successMessage: false,
        errorMessage: false,
      });
    }
  }, [signUpPassword]);

  useEffect(() => {
    if (signUpPassword.length >= 8 && validateEmail(signUpEmail)) {
      setIsSignUpDisable(false);
    } else {
      setIsSignUpDisable(true);
    }
  }, [signUpPassword, signUpEmail]);

  const handleSignUp = async () => {
    try {
      await signUp(signUpEmail, signUpPassword);
      setAuth({ signIn: false, signUp: false });
      setSignInEmail("");
      setSignInPassword("");
      setSignUpEmail("");
      setSignUpPassword("");
      setIsSignIn(true);
    } catch (err) {
      console.error("Error during signup:", err);
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(authentication, provider);
      const user = result.user; // Access the Google user object

      console.log("Google User Object:", user); // Log the entire Google user object for debugging

      // await googleSignIn();
      setAuth({ signIn: false, signUp: false });
      setSignInEmail("");
      setSignInPassword("");
      setSignUpEmail("");
      setSignUpPassword("");
      setIsSignIn(true);
    } catch (error) {
      console.log(error.message);
    }
  };

  // Logout logic

  const handleLogout = async () => {
    try {
      await logOut();
      setIsSignIn(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  // Get currently log in user

  const [activeUser, setActiveUser] = useState("");

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(
      auth,
      (currentUser) => {
        if (currentUser) {
          // User is logged in
          setActiveUser(currentUser);
        } else {
          // User is not logged in (or error occurred)
          setActiveUser(null);
        }
      },
      [isSignIn]
    );

    // Cleanup function to prevent memory leaks
    return () => unsubscribe();
  }, [isSignIn]);

  // Reset Password Logic

  const [showResetPopup, setShowResetPopup] = useState(false);
  const toggleResetPopup = () => setShowResetPopup(false);

  function handlePasswordReset(email) {
    // firebase
    //   .auth()
    //   .sendPasswordResetEmail(email)
    //   .then(() => {
    //     // Password reset email sent successfully
    //     // alert("A password reset link has been sent to your email address.");
    //     setShowResetPopup(true);
    //   })
    //   .catch((error) => {
    //     // Handle errors (e.g., invalid email, network issues)
    //     console.error("Error sending password reset email:", error);
    //     alert(
    //       "There was an error sending the password reset email. Please try again."
    //     );
    //   });
  }

  return (
    <>
      {/* Lg onwards navigationbar  */}
      <div className="absolute w-full top-0 left-0 z-10 flex-col hidden invisible lg:flex lg:visible">
        <div className="w-full h-12 bg-black">
          <div className="w-full h-full grid grid-cols-12 gap-2 text-white">
            <div className="col-start-3 col-span-3 flex justify-center items-center space-x-3">
              <a
                href="https://www.facebook.com/Whiteexpeditions?mibextid=LQQJ4d"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our facebook page
                </h1>
              </a>
              <a
                href="https://www.twitter.com/@WhiteExped79673"
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareXTwitter
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our twitter page
                </h1>
              </a>
              <a
                href="https://www.instagram.com/white.expedition?igsh=dHZiNXZwOGtyMTN0"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our instagram page
                </h1>
              </a>
              <a
                href="https://www.youtube.com/@White.Expeditions"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="text-primary cursor-pointer" size={25} />
                <h1 className="invisible hidden">
                  Click here to see our youtube page
                </h1>
              </a>
            </div>
            <div className="col-start-9 col-span-3 flex justify-center items-center space-x-2">
              {isSignIn || activeUser ? (
                <>
                  <button
                    className="newL2Content px-6 py-0.5 border border-primary rounded-lg bg-primary text-black cursor-pointer"
                    onClick={handleSignUpClick}
                  >
                    Dashboard
                  </button>
                  <button
                    className="newL2Content px-6 py-0.5 border border-primary rounded-lg text-primary hover:bg-primary hover:text-black cursor-pointer"
                    onClick={handleLogout}
                  >
                    Log Out
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="newL2Content px-6 py-0.5 border border-primary rounded-lg text-primary hover:bg-primary hover:text-black cursor-pointer"
                    onClick={handleSignInClick}
                  >
                    Sign in
                  </button>
                  <button
                    className="newL2Content px-6 py-0.5 border border-primary rounded-lg bg-primary text-black cursor-pointer"
                    onClick={handleSignUpClick}
                  >
                    Sign up
                  </button>
                </>
              )}
            </div>
            {/* <div className="col-start-10 col-span-2 flex justify-center items-center">
            </div> */}
          </div>
        </div>
        <div className="w-full h-16 bg-black bg-opacity-40 ps-36 flex items-center">
          <div className=" flex space-x-4 xl:space-x-6 center">
            <div className="relative z-30">
              <h1
                className="newContent text-white cursor-pointer hover:text-primary"
                onClick={() => handleToggle("aboutUs")}
              >
                About us
              </h1>
              <div
                className={`absolute bg-[#262626] z-50 w-40 2xl:w-44 h-auto flex flex-col space-y-2 py-3 rounded-md mt-5 -mx-3 px-3 transform origin-top ${
                  isActive.aboutUs ? "scale-y-100" : "scale-y-0"
                } transition-transform duration-300 ease-in-out`}
              >
                <h1
                  className="newContent text-white hover:text-primary hover:cursor-pointer"
                  onClick={() => {
                    navigate("/whoWeAre");
                  }}
                >
                  Who We are ?
                </h1>
                <h1
                  className="newContent text-white hover:text-primary hover:cursor-pointer"
                  onClick={() => {
                    navigate("/whyWithUs");
                  }}
                >
                  Why With Us ?
                </h1>
                <h1
                  className="newContent text-white hover:text-primary hover:cursor-pointer"
                  onClick={() => {
                    navigate("/ourTeam");
                  }}
                >
                  Our Team
                </h1>
                <h1
                  className="newContent text-white hover:text-primary hover:cursor-pointer"
                  onClick={() => {
                    navigate("/certificates");
                  }}
                >
                  Certificates
                </h1>
              </div>
            </div>
            <div className="relative z-30">
              <h1
                className="newContent text-white cursor-pointer hover:text-primary"
                onClick={() => handleToggle("expeditions")}
              >
                Expeditions
              </h1>
            </div>
            <div>
              <h1
                className="newContent text-white cursor-pointer hover:text-primary"
                onClick={() => {
                  navigate("/wildlifePhotography/snowLeopardWinterSpiti");
                }}
              >
                Wildlife Photography
              </h1>
            </div>
            <div>
              <div className="relative z-30">
                <h1
                  className="newContent text-white cursor-pointer hover:text-primary"
                  onClick={() => handleToggle("adventureTours")}
                >
                  Adventure Tours
                </h1>
                <div
                  className={`absolute bg-[#262626] z-50 w-60 xl:w-72 2xl:w-80 h-auto flex flex-col space-y-2 py-3 rounded-md mt-5 -mx-3 px-3 transform origin-top ${
                    isActive.adventureTours ? "scale-y-100" : "scale-y-0"
                  } transition-transform duration-300 ease-in-out`}
                >
                  <h1
                    className="newContent text-white hover:text-primary hover:cursor-pointer"
                    onClick={() => {
                      handleClose();
                      navigate("/adventureTours/spitiValleyOverlanding");
                    }}
                  >
                    Spiti Valley Adventure
                  </h1>
                  <h1
                    className="newContent text-white hover:text-primary hover:cursor-pointer"
                    onClick={() => {
                      handleClose();
                      navigate("/adventureTours/ladakhOverlanding");
                    }}
                  >
                    Ladakh Adventure
                  </h1>
                  <h1
                    className="newContent text-white hover:text-primary hover:cursor-pointer"
                    onClick={() => subHandleToggle("subAdventureTours")}
                  >
                    4x4 Self - Drive Overlanding
                  </h1>
                </div>
                <div
                  className={`absolute bg-[#262626] z-50 w-56 xl:w-72 2xl:w-80 h-auto flex flex-col space-y-2 py-3 rounded-md mt-24 mx-60 xl:mx-72 2xl:mx-80 px-3 transform origin-top ${
                    isActive.subAdventureTours ? "scale-y-100" : "scale-y-0"
                  } transition-transform duration-300 ease-in-out`}
                >
                  <h1
                    className="newContent text-white hover:text-primary hover:cursor-pointer"
                    onClick={() => {
                      handleClose();
                      navigate("/adventureTour/sachPasKishtwarOverlanding");
                    }}
                  >
                    Sach Pass - Kishtwar world Dangerous Road
                  </h1>
                  <h1
                    className="newContent text-white hover:text-primary hover:cursor-pointer"
                    onClick={() => {
                      handleClose();
                      navigate("/adventureTours/zanskarOverlanding");
                    }}
                  >
                    Zanskar Ladakh Umling La World highest Motorable Road
                  </h1>
                  <h1
                    className="newContent text-white hover:text-primary hover:cursor-pointer"
                    onClick={() => {
                      handleClose();
                      navigate("/adventureTour/winterSpitiOverlanding");
                    }}
                  >
                    Winter Spiti Expedition
                  </h1>
                </div>
              </div>
            </div>
            <div>
              <h1
                className="newContent text-white cursor-pointer hover:text-primary"
                onClick={() => {
                  navigate("/getInTouch");
                }}
              >
                Get In Touch
              </h1>
            </div>
            <div>
              <h1
                className="newContent text-white cursor-pointer hover:text-primary"
                onClick={() => {
                  navigate("/AchievementsRecords");
                }}
              >
                Achievements & Records
              </h1>
            </div>
          </div>
        </div>

        {/* Large screen expedition sub-navigation  */}
        {isActive.expeditions && (
          <div
            className={`absolute w-full lg:h-[500px] xl:h-[550px] bg-[#262626] mt-28 left-0 flex transform origin-top ${
              isActive.expeditions ? "scale-y-100" : "scale-y-0"
            } transition-transform duration-300 ease-in-out`}
          >
            <div className="w-1/6 pt-5">
              <div className="flex flex-col space-y-4">
                <div className="flex flex-col ps-7">
                  <h1
                    className="newL2Content text-white hover:text-primary hover:cursor-pointer duration-300 ease-in-out"
                    onClick={() => {
                      expeditionHandler(expedition5000M);
                      expeditionTypeHandler("expedition5000M");
                    }}
                  >
                    5000 M
                  </h1>
                </div>
                <div
                  className="flex flex-col ps-7"
                  onClick={() => {
                    expeditionHandler(expedition6000M);
                    expeditionTypeHandler("expedition6000M");
                  }}
                >
                  <h1 className="newL2Content text-white hover:text-primary hover:cursor-pointer duration-300 ease-in-out">
                    6000 M
                  </h1>
                </div>
                <div
                  className="flex flex-col ps-7"
                  onClick={() => {
                    expeditionHandler(expedition7000M);
                    expeditionTypeHandler("expedition7000M");
                  }}
                >
                  <h1 className="newL2Content text-white hover:text-primary hover:cursor-pointer duration-300 ease-in-out">
                    7000 M
                  </h1>
                </div>
                <div
                  className="flex flex-col ps-7"
                  onClick={() => {
                    expeditionHandler(pureAdrenaline);
                    expeditionTypeHandler("pureAdrenaline");
                  }}
                >
                  <h1 className="newL2Content text-white hover:text-primary hover:cursor-pointer duration-300 ease-in-out ">
                    Pure Adrenaline
                  </h1>
                </div>
                <div
                  className="flex flex-col ps-7"
                  onClick={() => {
                    expeditionHandler(trekking);
                    expeditionTypeHandler("trekking");
                  }}
                >
                  <h1 className="newL2Content text-white hover:text-primary hover:cursor-pointer duration-300 ease-in-out ">
                    Trekking
                  </h1>
                </div>
              </div>
            </div>
            <div className="w-5/6 h-full border-l-2 border-gray-600">
              <div className="center h-full">
                <div className="px-10 py-5 xl:py-7 2xl:py-3 w-full h-full">
                  <div className="grid grid-cols-12 gap-1 overflow-hidden">
                    {/* First Expedition */}
                    {firstExpedition.length > 0 ? (
                      <div className="col-start-1 col-span-3 relative h-52 xl:h-56 2xl:h-60 bg-primary rounded-md overflow-hidden group">
                        <a
                          href={
                            firstExpedition.length > 0
                              ? firstExpedition[0].link
                              : "/"
                          }
                        >
                          <img
                            src={
                              firstExpedition.length > 0
                                ? firstExpedition[0].img
                                : ""
                            }
                            alt="Expeditions"
                            className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-105"
                          />
                          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
                          <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4 group-hover:cursor-pointer text-center">
                            <h1 className="newContent text-primary">
                              {firstExpedition.length > 0
                                ? firstExpedition[0].groupBy
                                : ""}
                            </h1>
                            <h1 className="newL2SubHeader text-center text-white lg:text-[20px]">
                              {firstExpedition.length > 0
                                ? firstExpedition[0].title
                                : ""}
                            </h1>
                          </div>
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* Second Expedition  */}
                    {secondExpedition.length > 0 ? (
                      <div className="col-start-4 col-span-3 relative h-52 xl:h-56 2xl:h-60 bg-primary rounded-md overflow-hidden group">
                        <a
                          href={
                            secondExpedition.length > 0
                              ? secondExpedition[0].link
                              : "/"
                          }
                        >
                          <img
                            src={
                              secondExpedition.length > 0
                                ? secondExpedition[0].img
                                : ""
                            }
                            alt="Expeditions"
                            className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-105"
                          />
                          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
                          <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4 group-hover:cursor-pointer">
                            <h1 className="newContent text-primary">
                              {secondExpedition.length > 0
                                ? secondExpedition[0].groupBy
                                : ""}
                            </h1>
                            <h1 className="newL2SubHeader text-center text-white lg:text-[20px]">
                              {secondExpedition.length > 0
                                ? secondExpedition[0].title
                                : ""}
                            </h1>
                          </div>
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* Third Expedition  */}
                    {thirdExpedition.length > 0 ? (
                      <div className="col-start-7 col-span-3 relative h-52 xl:h-56 2xl:h-60 bg-primary rounded-md overflow-hidden group">
                        <a
                          href={
                            thirdExpedition.length > 0
                              ? thirdExpedition[0].link
                              : "/"
                          }
                        >
                          <img
                            src={
                              thirdExpedition.length > 0
                                ? thirdExpedition[0].img
                                : ""
                            }
                            alt="Expeditions"
                            className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-105"
                          />
                          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
                          <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4 group-hover:cursor-pointer">
                            <h1 className="newContent text-primary">
                              {thirdExpedition.length > 0
                                ? thirdExpedition[0].groupBy
                                : ""}
                            </h1>
                            <h1 className="newL2SubHeader text-center text-white lg:text-[20px]">
                              {thirdExpedition.length > 0
                                ? thirdExpedition[0].title
                                : ""}
                            </h1>
                          </div>
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* Fourth Expedition  */}
                    {fourthExpedition.length > 0 ? (
                      <div className="col-start-10 col-span-3 relative h-52 xl:h-56 2xl:h-60 bg-primary rounded-md overflow-hidden group">
                        <a
                          href={
                            fourthExpedition.length > 0
                              ? fourthExpedition[0].link
                              : "/"
                          }
                        >
                          <img
                            src={
                              fourthExpedition.length > 0
                                ? fourthExpedition[0].img
                                : ""
                            }
                            alt="Expeditions"
                            className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-105"
                          />
                          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
                          <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4 group-hover:cursor-pointer">
                            <h1 className="newContent text-primary">
                              {fourthExpedition.length > 0
                                ? fourthExpedition[0].groupBy
                                : ""}
                            </h1>
                            <h1 className="newL2SubHeader text-center text-white lg:text-[20px]">
                              {fourthExpedition.length > 0
                                ? fourthExpedition[0].title
                                : ""}
                            </h1>
                          </div>
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* Fifth Expedition  */}
                    {fifthExpedition.length > 0 ? (
                      <div className="col-start-1 col-span-3 relative h-52 xl:h-56 2xl:h-60 bg-primary rounded-md overflow-hidden group">
                        <a
                          href={
                            fifthExpedition.length > 0
                              ? fifthExpedition[0].link
                              : "/"
                          }
                        >
                          <img
                            src={
                              fifthExpedition.length > 0
                                ? fifthExpedition[0].img
                                : ""
                            }
                            alt="Expeditions"
                            className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-105"
                          />
                          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
                          <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4 group-hover:cursor-pointer">
                            <h1 className="newContent text-primary">
                              {fifthExpedition.length > 0
                                ? fifthExpedition[0].groupBy
                                : ""}
                            </h1>
                            <h1 className="newL2SubHeader text-center text-white lg:text-[20px]">
                              {fifthExpedition.length > 0
                                ? fifthExpedition[0].title
                                : ""}
                            </h1>
                          </div>
                        </a>
                      </div>
                    ) : (
                      <>
                        <div className="col-start-1 col-span-3 relative h-52 xl:h-56 2xl:h-60 bg-primary rounded-md overflow-hidden group bg-transparent"></div>
                      </>
                    )}
                    {/* Sixth Expedition  */}
                    {sixthExpedition.length > 0 ? (
                      <div className="col-start-4 col-span-3 relative h-52 xl:h-56 2xl:h-60 bg-primary rounded-md overflow-hidden group">
                        <a
                          href={
                            sixthExpedition.length > 0
                              ? sixthExpedition[0].link
                              : "/"
                          }
                        >
                          <img
                            src={
                              sixthExpedition.length > 0
                                ? sixthExpedition[0].img
                                : ""
                            }
                            alt="Expeditions"
                            className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-105"
                          />
                          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
                          <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4 group-hover:cursor-pointer">
                            <h1 className="newContent text-primary">
                              {sixthExpedition.length > 0
                                ? sixthExpedition[0].groupBy
                                : ""}
                            </h1>
                            <h1 className="newL2SubHeader text-center text-white lg:text-[20px]">
                              {sixthExpedition.length > 0
                                ? sixthExpedition[0].title
                                : ""}
                            </h1>
                          </div>
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* Seven Expedition  */}
                    {seventhExpedition.length > 0 ? (
                      <div className="col-start-7 col-span-3 relative h-52 xl:h-56 2xl:h-60 bg-primary rounded-md overflow-hidden group">
                        <a
                          href={
                            seventhExpedition.length > 0
                              ? seventhExpedition[0].link
                              : "/"
                          }
                        >
                          <img
                            src={
                              seventhExpedition.length > 0
                                ? seventhExpedition[0].img
                                : ""
                            }
                            alt="Expeditions"
                            className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-105"
                          />
                          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
                          <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4 group-hover:cursor-pointer">
                            <h1 className="newContent text-primary">
                              {seventhExpedition.length > 0
                                ? seventhExpedition[0].groupBy
                                : ""}
                            </h1>
                            <h1 className="newL2SubHeader text-center text-white lg:text-[20px]">
                              {seventhExpedition.length > 0
                                ? seventhExpedition[0].title
                                : ""}
                            </h1>
                          </div>
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* Eight Expedition  */}
                    {eighthExpedition.length > 0 ? (
                      <div className="col-start-10 col-span-3 relative h-52 xl:h-56 2xl:h-60 bg-primary rounded-md overflow-hidden group">
                        <a
                          href={
                            eighthExpedition.length > 0
                              ? eighthExpedition[0].link
                              : "/"
                          }
                        >
                          <img
                            src={
                              eighthExpedition.length > 0
                                ? eighthExpedition[0].img
                                : ""
                            }
                            alt="Expeditions"
                            className="w-full h-full object-cover transform transition-transform duration-300 hover:scale-105"
                          />
                          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
                          <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4 group-hover:cursor-pointer">
                            <h1 className="newContent text-primary">
                              {eighthExpedition.length > 0
                                ? eighthExpedition[0].groupBy
                                : ""}
                            </h1>
                            <h1 className="newL2SubHeader text-center text-white lg:text-[20px]">
                              {eighthExpedition.length > 0
                                ? eighthExpedition[0].title
                                : ""}
                            </h1>
                          </div>
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="grid grid-cols-12 gap-1 mt-2 xl:mt-4 2xl:mt-2 overflow-hidden">
                    {expeditionCount > 0 && (
                      <div className="col-start-1 col-span-1 flex justify-center items-center cursor-pointer">
                        <FaChevronCircleLeft
                          size={25}
                          className="text-white"
                          onClick={() =>
                            setExpeditionCount((prevCount) =>
                              prevCount > 0 ? prevCount - 1 : 0
                            )
                          }
                        />
                      </div>
                    )}

                    <div className="lg:col-start-5 lg:col-span-4 xl:col-start-6 xl:col-span-2  border-2 rounded-full cursor-pointer">
                      {/* eslint-disable-next-line */}
                      <a
                        className="flex space-x-2 justify-center items-center"
                        href={
                          expeditionType === "expedition5000M"
                            ? "/5000m-expedition"
                            : expeditionType === "expedition6000M"
                            ? "/6000m-expedition"
                            : expeditionType === "expedition7000M"
                            ? "/7000m-expedition"
                            : expeditionType === "pureAdrenaline"
                            ? "/pure-adrenaline-expedition"
                            : expeditionType === "trekking"
                            ? "/trekking-expedition"
                            : ""
                        }
                      >
                        <h1 className="newContent text-white">View All</h1>
                        <FaChevronCircleDown
                          size={25}
                          className=" text-white"
                        />
                      </a>
                    </div>
                    {expeditionCount <
                      Math.ceil(expeditionArray.length / 8) - 1 && (
                      <div className="col-start-12 col-span-1 flex justify-center items-center cursor-pointer">
                        <FaChevronCircleRight
                          size={25}
                          className="text-white"
                          onClick={() =>
                            setExpeditionCount((prevCount) => prevCount + 1)
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {auth.signIn && (
        <div
          className="absolute top-0 right-0 z-50 w-screen h-screen bg-black bg-opacity-60 flex justify-center items-center"
          // onClick={authHandler}
        >
          <div className="center">
            <div className="container">
              <div className="grid grid-cols-12 gap-2">
                <div className="relative col-start-1 col-span-12 sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-4 lg:col-span-6 xl:col-start-5 xl:col-span-4 bg-white rounded-md h-96 md:h-[400px] xl:h-[420px] 2xl:h-[430px]">
                  <IoClose
                    size={30}
                    className="absolute top-3 right-4 hover:cursor-pointer"
                    onClick={authHandler}
                  />
                  <h1 className="newHeader md:newContent lg:newSubHeader text-center border-b border-textColor">
                    Sign In
                  </h1>
                  <div className="w-full h-20 2xl:h-24 flex justify-center items-center">
                    <button className="relative w-5/6 h-10 bg-black rounded-full flex overflow-hidden">
                      <div className="w-auto px-1 h-10 flex justify-center items-center">
                        <FcGoogle size={30} />
                      </div>
                      <div className="w-[calc(100%-40px)] h-full flex justify-center items-center">
                        <h1
                          className="newContent xl:newL2Content text-white xl:text-white"
                          onClick={signInWithGoogle}
                        >
                          Sign in with Google
                        </h1>
                      </div>
                    </button>
                  </div>
                  <div className="relative w-full h-52 xl:h-56 flex flex-col items-center ">
                    <span className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-2">
                      <h1 className="newContent">OR</h1>
                    </span>
                    <div className="w-5/6 h-3/4 flex flex-col  space-y-2 xl:space-y-3 justify-center items-center border-t border-textColor">
                      <input
                        type="email"
                        placeholder="Email"
                        className="w-full h-10 border newContent xl:newL2Content border-textColor rounded-md px-2 py-0.5"
                        value={signInEmail}
                        onChange={(e) => setSignInEmail(e.currentTarget.value)}
                      />
                      <div className="w-full h-10">
                        <input
                          type="password"
                          placeholder="Password"
                          className="w-full h-full border newContent xl:newL2Content border-textColor rounded-md px-2 py-0.5"
                          value={signInPassword}
                          onChange={(e) =>
                            setSignInPassword(e.currentTarget.value)
                          }
                        />
                        <h1
                          className="newL2Content text-primary text-bold lg:text-primary lg:font-bold underline lg:newL2Content p-1"
                          onClick={handlePasswordReset(signInEmail)}
                        >
                          Reset Password
                        </h1>
                      </div>
                    </div>
                    <div className="w-full h-1/4 flex justify-center items-center">
                      <button
                        className={`w-5/6 h-10 bg-primary rounded-full ${
                          isSignInDisable
                            ? "disable bg-opacity-40 cursor-not-allowed"
                            : ""
                        }`}
                        onClick={handleSignIn}
                      >
                        <h1
                          className={`newContent xl:newL2Content text-black xl:text-black`}
                        >
                          Sign in
                        </h1>
                      </button>
                    </div>
                  </div>
                  <h1 className="newContent xl:newL2Content text-center mt-2 md:mt-4 lg:mt-3">
                    Don't have account?{" "}
                    <span
                      className="newContent xl:newL2Content text-primary xl:text-primary xl:font-bold font-bold underline hover:cursor-pointer"
                      onClick={handleSignUpClick}
                    >
                      Create account
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {auth.signUp && (
        <div
          className="absolute top-0 right-0 z-50 w-screen h-screen bg-black bg-opacity-60 flex justify-center items-center"
          // onClick={authHandler}
        >
          <div className="center">
            <div className="container">
              <div className="grid grid-cols-12 gap-2">
                <div className="relative col-start-1 col-span-12 sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-4 lg:col-span-6 xl:col-start-5 xl:col-span-4 bg-white rounded-md h-96 md:h-[400px] xl:h-[420px] 2xl:h-[430px]">
                  <IoClose
                    size={30}
                    className="absolute top-3 right-4 hover:cursor-pointer"
                    onClick={authHandler}
                  />
                  <h1 className="newHeader md:newContent lg:newSubHeader text-center border-b border-textColor">
                    Sign Up
                  </h1>
                  <div className="w-full h-20 2xl:h-24 flex justify-center items-center">
                    <button className="relative w-5/6 h-10 bg-black rounded-full flex overflow-hidden">
                      <div className="w-auto px-1 h-10 flex justify-center items-center">
                        <FcGoogle size={30} />
                      </div>
                      <div className="w-[calc(100%-40px)] h-full flex justify-center items-center">
                        <h1
                          className="newContent xl:newL2Content text-white xl:text-white"
                          onClick={handleGoogleSignUp}
                        >
                          Sign up with Google
                        </h1>
                      </div>
                    </button>
                  </div>
                  <div className="relative w-full h-52 xl:h-56 flex flex-col items-center ">
                    <span className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-2">
                      <h1 className="newContent">OR</h1>
                    </span>
                    <div className="w-5/6 h-3/4 flex flex-col  space-y-2 xl:space-y-3 justify-center items-center border-t border-textColor">
                      <input
                        type="email"
                        placeholder="Email"
                        className="w-full h-10 border newContent xl:newL2Content border-textColor rounded-md px-2 py-0.5"
                        value={signUpEmail}
                        onChange={(e) => setSignUpEmail(e.currentTarget.value)}
                      />
                      <div className="w-full h-10">
                        <input
                          type="password"
                          placeholder="Password"
                          className="w-full h-full border newContent xl:newL2Content border-textColor rounded-md px-2 py-0.5"
                          value={signUpPassword}
                          onChange={(e) =>
                            setSignUpPassword(e.currentTarget.value)
                          }
                        />
                        {/* <h1 className="newContent lg:newL2Content p-1">
                          Atleast 8 character.
                        </h1> */}
                        {passwordMessasge.noErrorMessage && (
                          <h1 className="newContent lg:newL2Content p-1 mt-1">
                            At least 8 characters.
                          </h1>
                        )}
                        {passwordMessasge.errorMessage && (
                          <h1 className="newContent lg:newL2Content text-red-600 lg:text-red-600 p-1 mt-1">
                            Pwd isn't strong enough.
                          </h1>
                        )}
                        {passwordMessasge.successMessage && (
                          <h1 className="newContent lg:newL2Content text-green-600 lg:text-green-600 p-1 mt-1">
                            This is very strong pwd!
                          </h1>
                        )}
                      </div>
                    </div>
                    <div className="w-full h-1/4 flex justify-center items-center">
                      <button
                        className={`w-5/6 h-10 bg-primary rounded-full ${
                          isSignUpDisable
                            ? "disable bg-opacity-40 cursor-not-allowed"
                            : ""
                        }`}
                        onClick={handleSignUp}
                      >
                        <h1 className="newContent xl:newL2Content text-black xl:text-black">
                          Sign up
                        </h1>
                      </button>
                    </div>
                  </div>
                  <h1 className="newContent xl:newL2Content text-center mt-2 md:mt-4 lg:mt-3">
                    Already have account?{" "}
                    <span
                      className="newContent xl:newL2Content text-primary xl:text-primary xl:font-bold font-bold underline hover:cursor-pointer"
                      onClick={handleSignInClick}
                    >
                      Sign in
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* All screen logo  */}
      <a href="/">
        <div className=" bg-transperant rounded-md absolute top-4 lg:top-0 left-6 sm:left-8 z-20 justify-start items-center text-white invisible hidden lg:visible lg:flex">
          <img
            src={logoSmall2}
            alt="Brand Logo"
            className="aspect-auto"
            width={150}
          />
        </div>
        <div className=" bg-transperant rounded-md absolute top-4 lg:top-0 left-6 sm:left-8 z-20 flex justify-start items-center text-white lg:invisible lg:hidden">
          <img
            src={logoSmall2}
            alt="Brand Logo"
            className="aspect-auto"
            width={120}
          />
        </div>
      </a>

      {/* Default, sm and md explore button  */}
      <div
        className="absolute top-10 right-8 sm:right-16 flex space-x-1 items-center lg:invisible lg:hidden"
        onClick={() =>
          setSmallDeviceNavbarVisible((prevVisibility) => !prevVisibility)
        }
      >
        <h1 className="newL2SubHeader text-white">Explore</h1>
        <MdOutlineExplore className="text-white" size={25} />
      </div>

      {/* Default, sm and md overlay  */}
      {smallDeviceNavbarVisible && (
        <div className="absolute z-50 top-0 left-0 w-screen h-screen bg-[#262626] flex flex-col  overflow-y-scroll hidden-scrollbar lg:invisible lg:hidden">
          <div className="flex justify-between items-center px-10 pe-14 mt-3">
            <div className="w-20 h-20 bg-transperant rounded-md flex justify-center items-center">
              <img src={logoSmall2} alt="Brand's Logo" />
            </div>
            <IoClose
              className="text-white"
              size={25}
              onClick={() =>
                setSmallDeviceNavbarVisible((prevVisibility) => !prevVisibility)
              }
            />
          </div>
          <div className=" my-10 px-10 flex flex-col space-y-8">
            {/* About us  */}
            <div
              className="flex justify-between sm:pe-6 md:pe-8"
              onClick={() => handleToggle("aboutUs")}
            >
              <h1 className="newL3SubHeader text-white hover:text-primary ">
                About us
              </h1>
              <RiArrowDropDownLine
                className="text-white hover:text-primary"
                size={35}
              />
            </div>

            {isActive.aboutUs && (
              <div className="flex flex-col space-y-2">
                <div className="flex flex-col ps-3">
                  <h1
                    className="newContent text-white hover:text-primary duration-300 ease-in-out"
                    onClick={() => {
                      setSmallDeviceNavbarVisible(false);
                      navigate("/whoWeAre");
                    }}
                  >
                    Who We are ?
                  </h1>
                </div>
                <div className="flex flex-col ps-3">
                  <h1
                    className="newContent text-white hover:text-primary duration-300 ease-in-out"
                    onClick={() => {
                      setSmallDeviceNavbarVisible(false);
                      navigate("/whyWithUs");
                    }}
                  >
                    Why With Us ?
                  </h1>
                </div>
                <div className="flex flex-col ps-3">
                  <h1
                    className="newContent text-white hover:text-primary duration-300 ease-in-out"
                    onClick={() => {
                      setSmallDeviceNavbarVisible(false);
                      navigate("/ourTeam");
                    }}
                  >
                    Our Team
                  </h1>
                </div>
                <div className="flex flex-col ps-3">
                  <h1
                    className="newContent text-white hover:text-primary duration-300 ease-in-out "
                    onClick={() => {
                      setSmallDeviceNavbarVisible(false);
                      navigate("/certificates");
                    }}
                  >
                    Certificates
                  </h1>
                </div>
              </div>
            )}

            {/* Expeditions  */}
            <div
              className="flex justify-between sm:pe-6 md:pe-8"
              onClick={() => handleToggle("expeditions")}
            >
              <h1 className="newL3SubHeader text-white hover:text-primary">
                Expeditions
              </h1>
              <RiArrowDropDownLine
                className="text-white hover:text-primary"
                size={35}
              />
            </div>

            {isActive.expeditions && (
              <div className="flex flex-col space-y-2">
                <div className="flex flex-col ps-3">
                  <h1
                    className="newContent text-white hover:text-primary"
                    onClick={() => {
                      setSmallDeviceNavbarVisible(false);
                      navigate("/5000m-expedition");
                    }}
                  >
                    5000 M
                  </h1>
                </div>
                <div className="flex flex-col ps-3">
                  <h1
                    className="newContent text-white hover:text-primary"
                    onClick={() => {
                      setSmallDeviceNavbarVisible(false);
                      navigate("/6000m-expedition");
                    }}
                  >
                    6000 M
                  </h1>
                </div>
                <div className="flex flex-col ps-3">
                  <h1
                    className="newContent text-white hover:text-primary"
                    onClick={() => {
                      setSmallDeviceNavbarVisible(false);
                      navigate("/7000m-expedition");
                    }}
                  >
                    7000 M
                  </h1>
                </div>
                <div className="flex flex-col ps-3">
                  <h1
                    className="newContent text-white hover:text-primary"
                    onClick={() => {
                      setSmallDeviceNavbarVisible(false);
                      navigate("/pure-adrenaline-expedition");
                    }}
                  >
                    Pure Adrenaline
                  </h1>
                </div>
                <div className="flex flex-col ps-3">
                  <h1
                    className="newContent text-white hover:text-primary"
                    onClick={() => {
                      setSmallDeviceNavbarVisible(false);
                      navigate("/trekking-expedition");
                    }}
                  >
                    Trekking
                  </h1>
                </div>
              </div>
            )}

            {/* Wildlife Photography  */}
            <div className="flex justify-between sm:pe-6 md:pe-8">
              <h1
                className="newL3SubHeader text-white hover:text-primary"
                onClick={() => {
                  navigate("/wildlifePhotography/snowLeopardWinterSpiti");
                }}
              >
                Wildlife Photography
              </h1>
            </div>

            {/* Adventure Tours  */}
            <div
              className="flex justify-between sm:pe-6 md:pe-8"
              onClick={() => handleToggle("adventureTours")}
            >
              <h1 className="newL3SubHeader text-white hover:text-primary">
                Adventure Tours
              </h1>
              <RiArrowDropDownLine
                className="text-white hover:text-primary"
                size={35}
              />
            </div>

            {isActive.adventureTours && (
              <div
                className={`transform scale-y-${
                  isActive.adventureTours ? "100" : "0"
                } origin-top transition-all duration-300 ease-in-out overflow-hidden`}
              >
                <div className="flex flex-col space-y-2">
                  <div className="flex flex-col ps-3">
                    <h1
                      className="newContent text-white hover:text-primary"
                      onClick={() => {
                        setSmallDeviceNavbarVisible(false);
                        navigate("/adventureTours/spitiValleyOverlanding");
                      }}
                    >
                      Spiti Valley Adventure
                    </h1>
                  </div>
                  <div className="flex flex-col ps-3">
                    <h1
                      className="newContent text-white hover:text-primary"
                      onClick={() => {
                        setSmallDeviceNavbarVisible(false);
                        navigate("/adventureTours/ladakhOverlanding");
                      }}
                    >
                      Ladakh Adventure
                    </h1>
                  </div>
                  <div className="flex flex-col ps-3">
                    <h1
                      className="newContent text-white hover:text-primary"
                      onClick={() => subHandleToggle("subAdventureTours")}
                    >
                      4x4 Self - Drive Overlanding
                    </h1>
                  </div>
                  <div
                    className={`transform scale-y-${
                      isActive.subAdventureTours ? "100" : "0"
                    } ${isActive.subAdventureTours ? "" : "hidden"}
                     origin-top transition-all duration-300 ease-in-out overflow-hidden`}
                  >
                    <div className="flex flex-col space-y-2">
                      <div className="flex flex-col ps-7">
                        <h1
                          className="newContent text-white hover:text-primary"
                          onClick={() => {
                            setSmallDeviceNavbarVisible(false);
                            navigate(
                              "/adventureTour/sachPasKishtwarOverlanding"
                            );
                          }}
                        >
                          Sach Pass - Kishtwar world Dangerous Road
                        </h1>
                      </div>
                      <div className="flex flex-col ps-7">
                        <h1
                          className="newContent text-white hover:text-primary"
                          onClick={() => {
                            setSmallDeviceNavbarVisible(false);
                            navigate("/adventureTours/zanskarOverlanding");
                          }}
                        >
                          Zanskar Ladakh Umling La World highest Motorable Road
                        </h1>
                      </div>
                      <div className="flex flex-col ps-7">
                        <h1
                          className="newContent text-white hover:text-primary"
                          onClick={() => {
                            setSmallDeviceNavbarVisible(false);
                            navigate("/adventureTour/winterSpitiOverlanding");
                          }}
                        >
                          Winter Spiti Expedition
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Get in touch  */}
            <div className="flex justify-between sm:pe-6 md:pe-8">
              <h1
                className="newL3SubHeader text-white hover:text-primary"
                onClick={() => {
                  setSmallDeviceNavbarVisible(false);
                  navigate("/getInTouch");
                }}
              >
                Get in Touch
              </h1>
            </div>

            {/* Achivement & Record  */}
            <div className="flex justify-between sm:pe-6 md:pe-8">
              <h1
                className="newL3SubHeader text-white hover:text-primary"
                onClick={() => {
                  setSmallDeviceNavbarVisible(false);
                  navigate("/AchievementsRecords");
                }}
              >
                Achievements & Records
              </h1>
            </div>

            {/* Personalise your trip  */}
            <div className="flex justify-between sm:pe-6 md:pe-8">
              <h1
                className="newL3SubHeader text-white hover:text-primary"
                onClick={() => {
                  setSmallDeviceNavbarVisible(false);
                  navigate("/planYourTrip");
                }}
              >
                Personalise Your Trip
              </h1>
            </div>

            {/* Social Media Icons  */}
            <div className="flex space-x-2">
              <a
                href="https://www.facebook.com/Whiteexpeditions?mibextid=LQQJ4d"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare
                  className="text-primary cursor-pointer"
                  size={25}
                />
              </a>
              <a
                href="https://www.twitter.com/@WhiteExped79673"
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareXTwitter
                  className="text-primary cursor-pointer"
                  size={25}
                />
              </a>
              <a
                href="https://www.instagram.com/white.expedition?igsh=dHZiNXZwOGtyMTN0"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  className="text-primary cursor-pointer"
                  size={25}
                />
              </a>
              <a
                href="https://www.youtube.com/@White.Expeditions"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="text-primary cursor-pointer" size={25} />
              </a>
            </div>

            {/* Sign in & Sign up button  */}
            <div className="flex space-x-2">
              {isSignIn || activeUser ? (
                <>
                  <button
                    className="newContent px-6 py-0.5 border border-primary rounded-lg bg-primary text-black cursor-pointer"
                    onClick={handleSignUpClick}
                  >
                    Dashboard
                  </button>
                  <button
                    className="newContent px-6 py-0.5 border border-primary rounded-lg text-primary hover:bg-primary hover:text-black cursor-pointer"
                    onClick={handleLogout}
                  >
                    Log Out
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="newContent px-6 py-0.5 border border-primary rounded-lg text-primary hover:bg-primary hover:text-black cursor-pointer"
                    onClick={handleSignInClick}
                  >
                    Sign in
                  </button>
                  <button
                    className="newContent px-6 py-0.5 border border-primary rounded-lg bg-primary text-black cursor-pointer"
                    onClick={handleSignUpClick}
                  >
                    Sign up
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {showErrorPopup && (
        <Popup
          error={true}
          success={false}
          title={"Sign Up First"}
          content={"You don't have an account yet. Create your account first."}
          onClose={toggleErrorPopup}
        />
      )}

      {showResetPopup && (
        <Popup
          error={false}
          success={true}
          title={"Password Reset Link"}
          content={"A password reset link has been sent to your email address."}
          onClose={toggleResetPopup}
        />
      )}
    </>
  );
};

export default NavigationBar;
