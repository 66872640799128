import React, { useState } from "react";
import "../index.css";
import Image1 from "../assets/home/Photo Gallery/Image1.jpeg";
import Image2 from "../assets/home/Photo Gallery/Image2.jpg";
import Image3 from "../assets/home/Photo Gallery/Image3.jpg";
import Image4 from "../assets/home/Photo Gallery/Image4.jpg";
import Image5 from "../assets/home/Photo Gallery/Image5.jpg";
import Image6 from "../assets/home/Photo Gallery/Image6.jpg";
import Image7 from "../assets/home/Photo Gallery/Image7.jpg";
import Image8 from "../assets/home/Photo Gallery/Image8.jpg";
import Image9 from "../assets/home/Photo Gallery/Image9.jpg";
import Image10 from "../assets/home/Photo Gallery/Image10.jpg";
import Image11 from "../assets/home/Photo Gallery/Image11.jpg";
import Image12 from "../assets/home/Photo Gallery/Image12.jpg";
import Image13 from "../assets/home/Photo Gallery/Image13.jpg";
import Image14 from "../assets/home/Photo Gallery/Image14.jpg";
import Image15 from "../assets/home/Photo Gallery/Image15.jpg";
import Image16 from "../assets/home/Photo Gallery/Image16.jpg";
import Image17 from "../assets/home/Photo Gallery/Image17.jpg";
import Image18 from "../assets/home/Photo Gallery/Image18.jpg";
import Image19 from "../assets/home/Photo Gallery/Image19.jpg";
import Image20 from "../assets/home/Photo Gallery/Image20.jpg";
import Image21 from "../assets/home/Photo Gallery/Image21.jpg";
import Image22 from "../assets/home/Photo Gallery/Image22.jpg";
import Image23 from "../assets/home/Photo Gallery/Image23.jpg";
import Image24 from "../assets/home/Photo Gallery/Image24.jpg";
import Image25 from "../assets/home/Photo Gallery/Image25.jpg";
import Image26 from "../assets/home/Photo Gallery/Image26.jpg";
import Image27 from "../assets/home/Photo Gallery/Image27.jpg";
import Image28 from "../assets/home/Photo Gallery/Image28.jpg";
import Image29 from "../assets/home/Photo Gallery/Image29.jpg";
import Image30 from "../assets/home/Photo Gallery/Image30.jpg";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const photos = [
  { id: 1, src: Image1 },
  { id: 2, src: Image2 },
  { id: 3, src: Image3 },
  { id: 4, src: Image4 },
  { id: 5, src: Image5 },
  { id: 6, src: Image6 },
  { id: 7, src: Image7 },
  { id: 8, src: Image8 },
  { id: 9, src: Image9 },
  { id: 10, src: Image10 },
  { id: 11, src: Image11 },
  { id: 12, src: Image12 },
  { id: 13, src: Image13 },
  { id: 14, src: Image14 },
  { id: 15, src: Image15 },
  { id: 16, src: Image16 },
  { id: 17, src: Image17 },
  { id: 18, src: Image18 },
  { id: 19, src: Image19 },
  { id: 20, src: Image20 },
  { id: 21, src: Image21 },
  { id: 22, src: Image22 },
  { id: 23, src: Image23 },
  { id: 24, src: Image24 },
  { id: 25, src: Image25 },
  { id: 26, src: Image26 },
  { id: 27, src: Image27 },
  { id: 28, src: Image28 },
  { id: 29, src: Image29 },
  { id: 30, src: Image30 },
];

const InstaFeeds = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = {
    lg: 6, // 3x3 for laptops
    sm: 4, // 2x2 for tablets
    base: 1, // 1x1 for mobile
  };

  const getItemsPerPage = () => {
    if (window.innerWidth >= 1024) return itemsPerPage.lg; // Laptop
    if (window.innerWidth >= 640) return itemsPerPage.sm; // Tablet
    return itemsPerPage.base; // Mobile
  };

  const itemsToShow = getItemsPerPage();
  const totalPages = Math.ceil(photos.length / itemsToShow);

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const startIndex = (currentPage - 1) * itemsToShow;
  const currentPhotos = photos.slice(startIndex, startIndex + itemsToShow);

  return (
    <div className="w-full flex flex-col items-center">
      {/* Photo Grid */}
      <div
        className={`grid ${
          itemsToShow === 6
            ? "grid-cols-3"
            : itemsToShow === 4
            ? "grid-cols-2"
            : "grid-cols-1"
        } gap-1`}
      >
        {currentPhotos.map((photo) => (
          <LazyLoadImage key={photo.id} src={photo.src} />
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="col-start-1 col-span-12 margin flex justify-center items-center space-x-2 hover:cursor-pointer">
        <FaArrowLeftLong
          className={`text-primary w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 ${
            currentPage === 1
              ? "opacity-50 cursor-not-allowed"
              : "hover:text-primary-dark"
          }`}
          onClick={currentPage > 1 ? handlePrev : null}
        />
        <FaArrowRightLong
          className={`text-primary w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 ${
            currentPage === totalPages
              ? "opacity-50 cursor-not-allowed"
              : "hover:text-primary-dark"
          }`}
          onClick={currentPage < totalPages ? handleNext : null}
        />
      </div>
    </div>
  );
};

const LazyLoadImage = ({ src }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  return (
    <div className="h-[450px] w-full rounded-md overflow-hidden relative">
      {loading && !error && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
          <span>Loading...</span>
        </div>
      )}
      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
          <span>Failed to load</span>
        </div>
      )}
      <img
        src={src}
        alt="Gallery"
        className={`w-full h-full object-cover object-center ${
          loading ? "hidden" : ""
        }`}
        onLoad={() => setLoading(false)}
        onError={() => {
          setLoading(false);
          setError(true);
        }}
      />
    </div>
  );
};

export default InstaFeeds;
