import expedition from "../../assets/home/hero/Climb_with_us.webp";
import trekking from "../../assets/home/hero/Trek_with_us.webp";
import adventureTours from "../../assets/home/hero/Travel_with_us.webp";
import wildlifePhotography from "../../assets/home/hero/Capture_with_us.webp";

import affiliation1 from "../../assets/Footer/Affiliation/1.webp";
import affiliation2 from "../../assets/Footer/Affiliation/2.webp";
import affiliation3 from "../../assets/Footer/Affiliation/3.webp";
import affiliation4 from "../../assets/Footer/Affiliation/4.webp";
import affiliation5 from "../../assets/Footer/Affiliation/5.webp";
import affiliation6 from "../../assets/Footer/Affiliation/6.webp";
import affiliation7 from "../../assets/Footer/Affiliation/7.webp";
import affiliation8 from "../../assets/Footer/Affiliation/8.webp";
import affiliation9 from "../../assets/Footer/Affiliation/9.webp";
import affiliation10 from "../../assets/Footer/Affiliation/10.webp";
import affiliation11 from "../../assets/Footer/Affiliation/11.webp";
import affiliation12 from "../../assets/Footer/Affiliation/12.webp";
import affiliation13 from "../../assets/Footer/Affiliation/13.webp";
import affiliation14 from "../../assets/Footer/Affiliation/14.webp";
import affiliation15 from "../../assets/Footer/Affiliation/15.JPG";

import WhoWeAre1 from "../../assets/who-we-are/Who_We_Are_1.webp";
import WhoWeAre2 from "../../assets/who-we-are/Who_We_Are_2.webp";

import whyWithUs from "../../assets/why-with-us/Why_With_Us.webp";

export const heroSectionCarousel = [
  {
    image: expedition,
    bookingInfo: "BOOKING OPEN FOR 2025",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: trekking,
    bookingInfo: "BOOKING OPEN FOR 2025",
    title: "Walk the trails, Live the thrills",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: adventureTours,
    bookingInfo: "BOOKING OPEN FOR 2025",
    title: "Go beyond the map , Discover the magic",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: wildlifePhotography,
    bookingInfo: "BOOKING OPEN FOR 2025",
    title: "In the Snow, They glow",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const affiliation = [
  {
    index: 1,
    image: affiliation1,
  },
  {
    index: 2,
    image: affiliation2,
  },
  {
    index: 3,
    image: affiliation3,
  },
  {
    index: 4,
    image: affiliation4,
  },
  {
    index: 5,
    image: affiliation5,
  },
  {
    index: 6,
    image: affiliation6,
  },
  {
    index: 7,
    image: affiliation7,
  },
  {
    index: 8,
    image: affiliation8,
  },
  {
    index: 9,
    image: affiliation9,
  },
  {
    index: 10,
    image: affiliation10,
  },
  {
    index: 11,
    image: affiliation11,
  },
  {
    index: 12,
    image: affiliation12,
  },
  {
    index: 13,
    image: affiliation13,
  },
  {
    index: 14,
    image: affiliation14,
  },
  {
    index: 15,
    image: affiliation15,
  },
];

export const expedition5000MHeroSection = [
  {
    image: expedition,
    bookingInfo: "5000 M",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "5000 M",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "5000 M",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "5000 M",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const expedition6000MHeroSection = [
  {
    image: expedition,
    bookingInfo: "6000 M",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "6000 M",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "6000 M",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "6000 M",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const expedition7000MHeroSection = [
  {
    image: expedition,
    bookingInfo: "7000 M",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "7000 M",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "7000 M",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "7000 M",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const expeditionPureAdrenalineHeroSection = [
  {
    image: expedition,
    bookingInfo: "Pure Adrenalines",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "Pure Adrenalines",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "Pure Adrenalines",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "Pure Adrenalines",
    title: "Climb Higher, Live Bolder",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const expeditionTrekkingHeroSection = [
  {
    image: trekking,
    bookingInfo: "Trekkings",
    title: "Walk the trails, Live the thrills",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: trekking,
    bookingInfo: "Trekkings",
    title: "Walk the trails, Live the thrills",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: trekking,
    bookingInfo: "Trekkings",
    title: "Walk the trails, Live the thrills",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: trekking,
    bookingInfo: "Trekkings",
    title: "Walk the trails, Live the thrills",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const wildllifePhotographyHeroSection = [
  {
    image: wildlifePhotography,
    bookingInfo: "Wildlife Photography",
    title: "In the Snow, They glow",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: wildlifePhotography,
    bookingInfo: "Wildlife Photography",
    title: "In the Snow, They glow",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: wildlifePhotography,
    bookingInfo: "Wildlife Photography",
    title: "In the Snow, They glow",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: wildlifePhotography,
    bookingInfo: "Wildlife Photography",
    title: "In the Snow, They glow",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const adventureToursHeroSection = [
  {
    image: adventureTours,
    bookingInfo: "Adventure Tours",
    title: "Go beyond the map , Discover the magic",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: adventureTours,
    bookingInfo: "Adventure Tours",
    title: "Go beyond the map , Discover the magic",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: adventureTours,
    bookingInfo: "Adventure Tours",
    title: "Go beyond the map , Discover the magic",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: adventureTours,
    bookingInfo: "Adventure Tours",
    title: "Go beyond the map , Discover the magic",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const leaderHeroSection = [
  {
    image: expedition,
    bookingInfo: "Leadership",
    title: "White Exped Leadership",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "Leadership",
    title: "White Exped Leadership",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "Leadership",
    title: "White Exped Leadership",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: expedition,
    bookingInfo: "Leadership",
    title: "White Exped Leadership",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const WhoWeAreHeroSection = [
  {
    image: WhoWeAre1,
    bookingInfo: "KNOW MORE",
    title: "Who We Are ?",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: WhoWeAre2,
    bookingInfo: "KNOW MORE",
    title: "Who We Are ?",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const TermsConditionsHeroSection = [
  {
    image: expedition,
    bookingInfo: "KNOW MORE",
    title: "Terms & Conditions",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const PrivacyPolicyHeroSection = [
  {
    image: expedition,
    bookingInfo: "KNOW MORE",
    title: "Privacy Policy",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const CancellationPolicyHeroSection = [
  {
    image: expedition,
    bookingInfo: "KNOW MORE",
    title: "Cancellation Policy",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const WhyWithUsHeroSection = [
  {
    image: whyWithUs,
    bookingInfo: "KNOW MORE",
    title: "Why With Us ?",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const OurTeamHeroSection = [
  {
    image: WhoWeAre1,
    bookingInfo: "KNOW MORE",
    title: "Our Team",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
  {
    image: WhoWeAre2,
    bookingInfo: "KNOW MORE",
    title: "Our Team",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const CertificatesHeroSection = [
  {
    image: expedition,
    bookingInfo: "KNOW MORE",
    title: "Certificates",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const AchievementsRecordsHeroSection = [
  {
    image: expedition,
    bookingInfo: "KNOW MORE",
    title: "Achievements & Records",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const GetInTouchHeroSection = [
  {
    image: expedition,
    bookingInfo: "KNOW MORE",
    title: "Get In Touch",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];

export const PlanYourTripHeroSection = [
  {
    image: expedition,
    bookingInfo: "Customize",
    title: "Plan Your Trip",
    buttonText: "Exploring the Unseen, Conquering the Exterme",
  },
];
