import React, { useEffect, useRef, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import NavigationBar from "../components/NavigationBar";
import HeroSection from "../components/HeroSection";
import { heroSectionCarousel } from "../data/Home/dataStorage";
import Popup from "../components/Popup";
import InstaFeeds from "../components/InstaFeeds";
import Footer from "../components/Footer";
import Whatsapp from "../components/Whatsapp";
import expedition from "../assets/home/hero/expedition.webp";
import { FaFacebookSquare, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const WriteReview = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    ratingStar: "",
    selectedTrip: "",
    message: "",
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const requiredFields = [
      "fullName",
      "email",
      "ratingStar",
      "tripType",
      "country",
      "selectedTrip",
      "message",
    ];

    console.log(formData);

    // Check if all required fields are filled (excluding message)
    const isAllRequiredFilled = requiredFields.every(
      (field) => formData[field] !== ""
    );

    setIsDisabled(!isAllRequiredFilled); // Enable button only if all required fields are filled
  }, [formData]);

  const formRef = useRef(null);

  async function handleSubmit(event) {
    event.preventDefault();
    console.log("Form submitted:", formData);
    // Submit form data to your backend here

    try {
      const db = getFirestore();

      await addDoc(collection(db, "reviews"), {
        ...formData,
      });

      setShowSuccessPopup(true);

      console.log(formData);

      // Clear form fields after successful submission
      setFormData({
        fullName: "",
        email: "",
        ratingStar: "",
        tripType: "",
        country: "",
        selectedTrip: "",
        message: "",
      });
    } catch (error) {
      console.error("Error saving review details:", error);
    }

    // if (activeUser != null) {
    //   try {
    //     const db = getFirestore();

    //     await addDoc(collection(db, "reviews"), {
    //       ...formData,
    //     });

    //     setShowSuccessPopup(true);

    //     console.log(formData);

    //     // Clear form fields after successful submission
    //     setFormData({
    //       fullName: "",
    //       email: "",
    //       ratingStar: "",
    //       tripType: "",
    //       country: "",
    //       selectedTrip: "",
    //       message: "",
    //     });
    //   } catch (error) {
    //     console.error("Error saving review details:", error);
    //   }
    // } else {
    //   setShowErrorPopup(true);
    // }
    sendDataToGoogleSheet();
  }

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const toggleSuccessPopup = () => setShowSuccessPopup(false);

  const sendDataToGoogleSheet = () => {
    //   if (activeUser != null) {
    //   }
    fetch(
      "https://script.google.com/macros/s/AKfycbx7tlmyPC58iTj-AjQwcI0HWQCWAf2MfNQ-FpdFzlTw1iLlMwdlLNKGhlxhA1Eds35G/exec",
      {
        method: "POST",
        body: new FormData(formRef.current),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        alert(data.msg);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <HeroSection carouselData={heroSectionCarousel} />

      <section className="center">
        <NavigationBar />
      </section>

      {/* Write a review */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-center margin">
              Add Your Review
            </h1>
            <form ref={formRef} className="col-start-1 col-span-12">
              <div className="grid grid-cols-12 gap-2">
                {/* Full Name */}
                <div className="col-start-1 col-span-12 lg:col-start-1 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Full Name :</span>
                    <input
                      required
                      type="text"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div>
                {/* Email Address */}
                {/* <div className="col-start-1 col-span-12 lg:col-start-7 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Email Address :</span>
                    <input
                      required
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div> */}
                {/* Star Rating */}
                <div className="col-start-1 col-span-12 lg:col-start-7 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Star Rating :</span>
                    <select
                      required
                      name="ratingStar"
                      value={formData.ratingStar}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 h-12 newContent rounded-md hover:outline-primary"
                    >
                      <option value="">-- Please Select --</option>
                      <option value="5">5</option>
                      <option value="4.5">4.5</option>
                      <option value="4">4</option>
                      <option value="3.5">3.5</option>
                      <option value="3">3</option>
                      <option value="2.5">2.5</option>
                      <option value="2">2</option>
                      <option value="1.5">1.5</option>
                      <option value="1">1</option>
                    </select>
                  </div>
                </div>
                {/* Select Trip Type */}
                {/* <div className="col-start-1 col-span-12 lg:col-start-7 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Select Trip Type :</span>
                    <select
                      required
                      name="tripType"
                      value={formData.tripType}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 h-12 newContent rounded-md hover:outline-primary"
                    >
                      <option value="">-- Please Select --</option>
                      <option value="expedition">Expedition</option>
                      <option value="pure_adrenaline">Pure Adrenaline</option>
                      <option value="trekking">Trekking</option>
                      <option value="wildlife_photography">
                        Wildlife Photography
                      </option>
                      <option value="adventure_tour">Adventure Tours</option>
                    </select>
                  </div>
                </div> */}
                {/* Country */}
                {/* <div className="col-start-1 col-span-12 lg:col-start-1 lg:col-span-6 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Country :</span>
                    <input
                      required
                      type="text"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                    />
                  </div>
                </div> */}
                <div className="col-start-1 col-span-12 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Select Trip :</span>
                    <select
                      required
                      name="selectedTrip"
                      value={formData.selectedTrip}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 h-12 newContent rounded-md hover:outline-primary"
                    >
                      <option value="">
                        -- Please Select Trip Type First --
                      </option>
                      <option value="Winter Expedition Kiagar Ri">
                        Winter Expedition Kiagar Ri
                      </option>
                      <option value="Winter Spiti 4x4">Winter Spiti 4x4</option>
                      <option value="2x5000 (Firendship Peak & Shitidhar)">
                        2x5000 (Firendship Peak & Shitidhar)
                      </option>
                      <option value="2x6000 (Kangyatse 1 & Kangyatse 2)">
                        2x6000 (Kangyatse 1 & Kangyatse 2)
                      </option>
                      <option value="3x6000M (Kiager Ri, Spangnak Ri & Zes Ri)">
                        3x6000M (Kiager Ri, Spangnak Ri & Zes Ri)
                      </option>
                      <option value="Ski Down">Ski Down</option>
                      <option value="Ramjak">Ramjak</option>
                      <option value="Kangju Kangri">Kangju Kangri</option>
                      <option value="Friendship Peak">Friendship Peak</option>
                      <option value="Yunum Peak">Yunum Peak</option>
                      <option value="Hanuman Tibba">Hanuman Tibba</option>
                      <option value="Deo Tibba">Deo Tibba</option>
                      <option value="Shitidhar">Shitidhar</option>
                      <option value="Ladakhi">Ladakhi</option>
                      <option value="Lady Of Keylong">Lady Of Keylong</option>
                      <option value="Kangyatse 1">Kangyatse 1</option>
                      <option value="Kangyatse 2">Kangyatse 2</option>
                      <option value="Ut Kangri">Ut Kangri</option>
                      <option value="Mentok Kangri">Mentok Kangri</option>
                      <option value="Chau Chau Kang Nilda">
                        Chau Chau Kang Nilda
                      </option>
                      <option value="Leo Purgyil">Leo Purgyil</option>
                      <option value="Manirang">Manirang</option>
                      <option value="Kanamo">Kanamo</option>
                      <option value="Nun">Nun</option>
                      <option value="Kun">Kun</option>
                      <option value="Kiagar Ri">Kiagar Ri</option>
                      <option value="Spangnak Ri">Spangnak Ri</option>
                      <option value="P6250">P6250</option>
                      <option value="Chamaer Kangri">Chamaer Kangri</option>
                      <option value="Lungser Kangri">Lungser Kangri</option>
                      <option value="Lagpo">Lagpo</option>
                      <option value="Chalung">Chalung</option>
                      <option value="Shukule">Shukule</option>
                      <option value="CB 13">CB 13</option>
                      <option value="Menthosa">Menthosa</option>
                      <option value="Papsura">Papsura</option>
                      <option value="Reo Purgyil">Reo Purgyil</option>
                      <option value="Indrasan">Indrasan</option>
                      <option value="Makar Beh">Makar Beh</option>
                      <option value="Pin Parvati Pass Trek">
                        Pin Parvati Pass Trek
                      </option>
                      <option value="Parang La Trek">Parang La Trek</option>
                      <option value="Auden's Col Trek">Auden's Col Trek</option>
                      <option value="Kalindi Khal Trek">
                        Kalindi Khal Trek
                      </option>
                      <option value="Kang La Trek">Kang La Trek</option>
                      <option value="Goecha La Trek">Goecha La Trek</option>
                      <option value="Bara Bangal Trek">Bara Bangal Trek</option>
                      <option value="Leh Lamayuru Trek">
                        Leh Lamayuru Trek
                      </option>
                      <option value="Markha Valley Trek">
                        Markha Valley Trek
                      </option>
                      <option value="Spituk To Matho Trek">
                        Spituk To Matho Trek
                      </option>
                      <option value="Padum To Darcha Trek">
                        Padum To Darcha Trek
                      </option>
                      <option value="Leh To Tso Morori Trek">
                        Leh To Tso Morori Trek
                      </option>
                      <option value="Nubra Valley Trek">
                        Nubra Valley Trek
                      </option>
                      <option value="Snow Leopard Winter Spiti">
                        Snow Leopard Winter Spiti
                      </option>
                      <option value="Spiti Valley Overlanding">
                        Spiti Valley Overlanding
                      </option>
                      <option value="Ladakh Overlanding">
                        ladakh Overlanding
                      </option>
                      <option value="Zanskar Overlanding">
                        Zanskar Overlanding
                      </option>
                      <option value="Sach Pas Kishtwar Overlanding">
                        Sach Pas Kishtwar Overlanding
                      </option>
                      {/* Populate options based on selected tripType (optional) */}
                      {/* {formData.tripType === "expedition" && (
                        <>
                          <option value="Friendship Peak">
                            Friendship Peak
                          </option>
                          <option value="Yunum Peak">Yunum Peak</option>
                          <option value="Hanuman Tibba">Hanuman Tibba</option>
                          <option value="Deo Tibba">Deo Tibba</option>
                          <option value="Shitidhar">Shitidhar</option>
                          <option value="Ladakhi">Ladakhi</option>
                          <option value="Lady Of Keylong">
                            Lady Of Keylong
                          </option>
                          <option value="Kangyatse 1">Kangyatse 1</option>
                          <option value="Kangyatse 2">Kangyatse 2</option>
                          <option value="Ut Kangri">Ut Kangri</option>
                          <option value="Mentok Kangri">Mentok Kangri</option>
                          <option value="Chau Chau Kang Nilda">
                            Chau Chau Kang Nilda
                          </option>
                          <option value="Leo Purgyil">Leo Purgyil</option>
                          <option value="Manirang">Manirang</option>
                          <option value="Kanamo">Kanamo</option>
                          <option value="Nun">Nun</option>
                          <option value="Kun">Kun</option>
                          <option value="Kiagar Ri">Kiagar Ri</option>
                          <option value="Spangnak Ri">Spangnak Ri</option>
                          <option value="P6250">P6250</option>
                          <option value="Chamaer Kangri">Chamaer Kangri</option>
                          <option value="Lungser Kangri">Lungser Kangri</option>
                          <option value="Lagpo">Lagpo</option>
                          <option value="Chalung">Chalung</option>
                          <option value="Shukule">Shukule</option>
                          <option value="CB 13">CB 13</option>
                          <option value="Menthosa">Menthosa</option>
                        </>
                      )}
                      {formData.tripType === "pure_adrenaline" && (
                        <>
                          <option value="Papsura">Papsura</option>
                          <option value="Reo Purgyil">Reo Purgyil</option>
                          <option value="Indrasan">Indrasan</option>
                          <option value="Makar Beh">Makar Beh</option>
                        </>
                      )}
                      {formData.tripType === "trekking" && (
                        <>
                          <option value="Pin Parvati Pass Trek">
                            Pin Parvati Pass Trek
                          </option>
                          <option value="Parang La Trek">Parang La Trek</option>
                          <option value="Auden's Col Trek">
                            Auden's Col Trek
                          </option>
                          <option value="Kalindi Khal Trek">
                            Kalindi Khal Trek
                          </option>
                          <option value="Kang La Trek">Kang La Trek</option>
                          <option value="Goecha La Trek">Goecha La Trek</option>
                          <option value="Bara Bangal Trek">
                            Bara Bangal Trek
                          </option>
                          <option value="Leh Lamayuru Trek">
                            Leh Lamayuru Trek
                          </option>
                          <option value="Markha Valley Trek">
                            Markha Valley Trek
                          </option>
                          <option value="Spituk To Matho Trek">
                            Spituk To Matho Trek
                          </option>
                          <option value="Padum To Darcha Trek">
                            Padum To Darcha Trek
                          </option>
                          <option value="Leh To Tso Morori Trek">
                            Leh To Tso Morori Trek
                          </option>
                          <option value="Nubra Valley Trek">
                            Nubra Valley Trek
                          </option>
                        </>
                      )}
                      {formData.tripType === "wildlife_photography" && (
                        <>
                          <option value="Snow Leopard Winter Spiti">
                            Snow Leopard Winter Spiti
                          </option>
                        </>
                      )}
                      {formData.tripType === "adventure_tour" && (
                        <>
                          <option value="Spiti Valley Overlanding">
                            Spiti Valley Overlanding
                          </option>
                          <option value="Ladakh Overlanding">
                            ladakh Overlanding
                          </option>
                          <option value="Zanskar Overlanding">
                            Zanskar Overlanding
                          </option>
                          <option value="Sach Pas Kishtwar Overlanding">
                            Sach Pas Kishtwar Overlanding
                          </option>
                        </>
                      )} */}
                    </select>
                  </div>
                </div>
                {/* Review */}
                <div className="col-start-1 col-span-12 mt-2">
                  <div className="flex flex-col">
                    <span className="newL3SubHeader">Message :</span>
                    <textarea
                      type="text"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="bg-gray-200 p-2 newContent rounded-md hover:outline-primary"
                      rows={4}
                    />
                  </div>
                </div>
                {/* Submit */}
                <div className="col-start-1 col-span-12 mt-4">
                  <div className="flex flex-col">
                    <button
                      className={`bg-primary p-2 newL3SubHeader font-bold text-black hover:cursor-pointer rounded-md hover:outline-primary ${
                        isDisabled
                          ? "disabled opacity-50 hover:cursor-not-allowed"
                          : ""
                      }`}
                      disabled={isDisabled}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      {showSuccessPopup && (
        <Popup
          error={false}
          success={true}
          title={"Review Submitted"}
          content={
            "Thank you for your review! It has been submitted successfully."
          }
          onClose={toggleSuccessPopup}
        />
      )}

      {/* Instagram Section */}
      <section className="center bg-black">
        <div className="container ">
          <div className="grid grid-cols-12 gap-2 ">
            <h1 className="newHeader col-start-1 col-span-12 text-center">
              Photo Gallery
            </h1>
            {/* <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="newL3SubHeader  text-primary">
                @white.expedition
              </h1>
            </a> */}
            <div className="col-span-12 flex justify-center items-center space-x-3">
              <a
                href="https://www.facebook.com/share/19E5pajnVe/?mibextid=wwXIfr"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our facebook page
                </h1>
              </a>
              <a
                href="https://www.twitter.com/@WhiteExped79673"
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareXTwitter
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our twitter page
                </h1>
              </a>
              <a
                href="https://www.instagram.com/white.expedition?igsh=dHZiNXZwOGtyMTN0"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our instagram page
                </h1>
              </a>
              <a
                href="https://www.youtube.com/@White.Expeditions"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="text-primary cursor-pointer" size={25} />
                <h1 className="invisible hidden">
                  Click here to see our youtube page
                </h1>
              </a>
            </div>
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
            </div>
          </div>
        </div>
      </section>

      {/* Footer section */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>
        <div className="center">
          <div className="container relative z-20">
            <div className="grid grid-cols-12 gap-2">
              <div className="col-start-1 col-span-12">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* WhatsApp Section */}
      <Whatsapp />
    </>
  );
};

export default WriteReview;
