import React, { useState, useEffect, useRef } from "react";
import { FaChevronCircleRight } from "react-icons/fa";

const HeroSection = ({ carouselData, showContent = true }) => {
  // Added showContent prop
  const [activeIndex, setActiveIndex] = useState(0);
  const [loadedIndexes, setLoadedIndexes] = useState([]); // Track which images are loaded
  const slideRefs = useRef([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
    }, 7000);

    return () => clearInterval(intervalId);
  }, [carouselData]);

  useEffect(() => {
    // Lazy load images using IntersectionObserver
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.dataset.index, 10);
            if (!loadedIndexes.includes(index)) {
              setLoadedIndexes((prevIndexes) => [...prevIndexes, index]);
            }
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    slideRefs.current.forEach((slide) => {
      if (slide) observer.observe(slide);
    });

    return () => observer.disconnect();
  }, [loadedIndexes]);

  return (
    <>
      <div className="relative w-full h-screen overflow-hidden">
        <div
          className="w-screen h-screen flex transition-transform duration-1000 relative"
          style={{
            transform: `translateX(-${
              activeIndex * (100 / carouselData.length)
            }%)`,
            width: `${carouselData.length * 100}%`,
          }}
        >
          {carouselData.map((image, index) => (
            <div
              key={index}
              ref={(el) => (slideRefs.current[index] = el)} // Reference for IntersectionObserver
              data-index={index}
              className="w-full flex-shrink-0 relative"
              style={{ flexBasis: `${100 / carouselData.length}%` }}
            >
              <div
                className="relative bg-cover bg-center h-screen flex items-center px-6 sm:px-10 md:px-14 lg:px-20 xl:px-24"
                style={{
                  backgroundImage: loadedIndexes.includes(index)
                    ? `url(${image.image})`
                    : "none", // Only load the background image when it's in view
                }}
              >
                {/* Overlay */}
                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60"></div>

                {/* Content */}
                {showContent && ( // Conditional rendering based on showContent prop
                  <div className="z-10 relative flex flex-col space-y-3 text-white">
                    <h1 className="text-sm sm:text-base md:text-lg px-2 py-1 w-fit bg-primary text-black font-bold font-sintony">
                      {image.bookingInfo}
                    </h1>
                    <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl lg:max-w-5xl font-sintony text-white font-bold">
                      {image.title}
                    </h1>
                    <div className="flex items-center space-x-2">
                      {/* <FaChevronCircleRight className="text-primary w-8 h-8 sm:w-9 sm:h-9 md:w-10 md:h-10" /> */}
                      <h2 className="font-sintony text-primary tracking-wide font-medium text-xl sm:text-2xl hover:text-primary hover:cursor-pointer">
                        {image.buttonText}
                      </h2>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default HeroSection;
