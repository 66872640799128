import React, { useState, useEffect } from "react";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const YouTubeGallery = ({ videos }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [videosPerPage, setVideosPerPage] = useState(9); // Default to 3x3 for laptops

  useEffect(() => {
    const updateVideosPerPage = () => {
      if (window.innerWidth >= 1024) {
        setVideosPerPage(9); // 3x3 for laptops
      } else if (window.innerWidth >= 640) {
        setVideosPerPage(4); // 2x2 for tablets
      } else {
        setVideosPerPage(2); // 1x2 for mobile
      }
    };

    // Set initial state
    updateVideosPerPage();

    // Update state on window resize
    window.addEventListener("resize", updateVideosPerPage);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", updateVideosPerPage);
  }, []);

  const totalPages = Math.ceil(videos.length / videosPerPage);

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const startIndex = (currentPage - 1) * videosPerPage;
  const currentVideos = videos.slice(startIndex, startIndex + videosPerPage);

  return (
    <section className="center">
      <div className="container">
        <h1 className="newHeader col-start-1 col-span-12 text-center">
          YouTube Gallery
        </h1>
        {/* Video Grid */}
        <div
          className={`grid margin ${
            videosPerPage === 9
              ? "grid-cols-3"
              : videosPerPage === 4
              ? "grid-cols-2"
              : "grid-cols-1"
          } gap-4`}
        >
          {currentVideos.map((video) => (
            <div key={video.id} className="h-60 rounded-md overflow-hidden">
              <iframe
                className="w-full h-full"
                src={video.link}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="col-start-1 col-span-12 margin flex justify-center items-center space-x-2 hover:cursor-pointer">
          <FaArrowLeftLong
            className={`text-primary w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 ${
              currentPage === 1
                ? "opacity-50 cursor-not-allowed"
                : "hover:text-primary-dark"
            }`}
            onClick={currentPage > 1 ? handlePrev : null}
          />
          <FaArrowRightLong
            className={`text-primary w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 ${
              currentPage === totalPages
                ? "opacity-50 cursor-not-allowed"
                : "hover:text-primary-dark"
            }`}
            onClick={currentPage < totalPages ? handleNext : null}
          />
        </div>
      </div>
    </section>
  );
};

export default YouTubeGallery;
