import { adventureToursHeroSection } from "../../data/Home/dataStorage";

export const thingstocarray = [
  {
    sectionTitle: "Clothing",
    content: [
      "Warm layers (thermals, fleece, down jacket)",
      "Waterproof and windproof outer layers",
      "Headgear: hat, scarf, sunglasses, sun hat/cap",
      "Insulated, waterproof boots and woolen socks",
    ],
  },
  {
    sectionTitle: "Health and Safety",
    content: [
      "Altitude sickness medication (consult a doctor)",
      "First aid kit and personal medications",
    ],
  },
  {
    sectionTitle: "Miscellaneous",
    content: [
      "Camera with accessories, power bank, and phone",
      "Personal identification, permits, and passport size photos",
      "Sufficient cash",
      "High-energy snacks",
      "Toiletries and quick-dry travel towel",
      "Sunscreen, lip balm with UV protection",
      "Reusable water bottle and thermos for hot water",
    ],
  },
];

export const individualExpedition = [
  {
    individualExpeditionType: "spitiValleyOverlanding",
    individualExpeditionName: "Spiti Valley Adventure",
    carouselData: adventureToursHeroSection,
    location: "Spiti Valley, Himachal Pradesh",
    duration: "8 Days",
    grade: "Any",
    maxAltitude: "4700 m",
    bestSeason: "June, July, August, September and October",
    experienceRequired: "Don’t want to do it in a group? Get it customised.",
    mainExpeditionContent:
      "Nestled amid the Himalayas in Himachal Pradesh, Spiti Valley, at an altitude of 3,650 to 4500 metres, captivates with its stark beauty. The high-altitude desert, crisscrossed by the Spiti River, boasts ancient monasteries like Ki and Tabo, resonating with Buddhist spirituality.",
    aboutExpeditionExperienced:
      "Accessible via challenging roads from Manali or Shimla, the region reveals remote villages like Kibber and Dhankar. With snow leopards and Himalayan blue sheep inhabiting its rugged terrain, Spiti offers a unique ecosystem. Despite harsh winters limiting accessibility, adventurous souls are drawn to its serene landscapes and the mesmerising Chandratal Lake, making Spiti a haven for cultural exploration and high-altitude escapades.",
    itineraryData: [
      {
        day: "1",
        title: "Shimla to Chitkul (Stay at Rakcham)",
        details: [
          "Distance: ~250 km | Travel Time: 8–10 hours",
          "Route: Shimla – Kufri – Narkanda – Rampur – Chitkul",
          "Activities: Start early from Shimla, drive along scenic routes following the Sutlej River.",
          "Explore Chitkul, the last inhabited village near the Indo-China border.",
          "Accommodation: Stay overnight in Rakcham amidst lush green meadows.",
          "Meals: Dinner included.",
        ],
      },
      {
        day: "2",
        title: "Rakcham to Kalpa",
        details: [
          "Distance: ~60 km | Travel Time: 3–4 hours",
          "Route: Rakcham – Sangla – Kalpa",
          "Activities: Begin the day with a leisurely breakfast.",
          "Visit Kamru Fort and Reckong Peo en route.",
          "At Kalpa, explore Suicide Point and enjoy panoramic views of the Kinner Kailash range.",
          "Accommodation: Stay overnight in Kalpa.",
          "Meals: Breakfast and dinner included.",
        ],
      },
      {
        day: "3",
        title: "Kalpa to Tabo",
        details: [
          "Distance: ~170 km | Travel Time: 7–8 hours",
          "Route: Kalpa – Khab Sangam – Nako – Tabo",
          "Activities: Leave early for Tabo via Nako, stopping at Khab Sangam to see the confluence of Spiti and Sutlej Rivers.",
          "Explore Nako Lake and its monastery before reaching Tabo.",
          "Visit the ancient Tabo Monastery, renowned for its murals and historical significance.",
          "Accommodation: Stay overnight in Tabo.",
          "Meals: Breakfast and dinner included.",
        ],
      },
      {
        day: "4",
        title: "Tabo to Kaza",
        details: [
          "Distance: ~50 km | Travel Time: 2–3 hours",
          "Route: Tabo – Dhankar – Kaza",
          "Activities: Visit Dhankar Monastery, perched on a cliff with breathtaking views.",
          "Optional trek to Dhankar Lake if time permits.",
          "Arrive in Kaza, the cultural hub of Spiti, and spend the evening exploring.",
          "Accommodation: Stay overnight in Kaza.",
          "Meals: Breakfast and dinner included.",
        ],
      },
      {
        day: "5",
        title: "Kaza to Komic (World’s Highest Village)",
        details: [
          "Distance: ~20 km | Travel Time: 1 hour",
          "Route: Kaza – Langza – Hikkim – Komic",
          "Activities: Visit Langza for its giant Buddha statue and fossil-rich terrain.",
          "Stop at Hikkim, home to the world’s highest post office.",
          "Proceed to Komic for a unique high-altitude experience.",
          "Accommodation: Stay overnight in Komic.",
          "Meals: Breakfast and dinner included.",
        ],
      },
      {
        day: "6",
        title: "Komic to Chandertal Lake (via Chicham & Hikkim)",
        details: [
          "Distance: ~120 km | Travel Time: 6–7 hours",
          "Route: Komic – Chicham Bridge – Kunzum Pass – Chandertal Lake",
          "Activities: Drive through Chicham Bridge, Asia’s highest suspension bridge.",
          "Trek (~1.5 km) to Chandertal Lake, a crescent-shaped high-altitude lake.",
          "Enjoy the serene beauty and stay overnight in camps near the lake.",
          "Meals: Breakfast and dinner included.",
        ],
      },
      {
        day: "7",
        title: "Chandertal Lake to Manali",
        details: [
          "Distance: ~125 km | Travel Time: 6–8 hours",
          "Route: Chandertal – Batal – Gramphu – Rohtang Pass – Manali",
          "Activities: Drive back to Manali, transitioning from Spiti’s barren landscapes to lush greenery.",
          "Relax in Manali after a long day of travel.",
          "Accommodation: Stay overnight in Manali.",
          "Meals: Breakfast and dinner included.",
        ],
      },
      {
        day: "8",
        title: "Drop from Manali",
        details: [
          "Activities: Explore Manali (if time permits) before departure.",
          "Visit Hadimba Temple, Vashisht Hot Springs, or stroll through the Mall Road.",
          "Meals: Breakfast included.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Inclusion",
        content: [
          "Self-Drive Option: Vehicles like Thar, Jimny, Fortuner, Isuzu, Hilux, Yodha.",
          "Backup Team: 4x4 experienced drivers and guides.",
          "Medical Assistance: Oxygen cylinders for high altitude support.",
          "Accommodation: Luxury hotels, guest houses, and homestays.",
          "Meals: Breakfast and dinner provided daily.",
          "Permits: All required permits for the Spiti region.",
          "Special Stops: Offbeat locations for unique experiences.",
        ],
      },
      //   {
      //     sectionTitle: "Meals",
      //     content: ["Dinner and Breakfast all days"],
      //   },
      //   {
      //     sectionTitle: "Transportation",
      //     content: [
      //       "The cost of the vehicle (either self-driven or with a driver) for the entire road trip.",
      //     ],
      //   },
      //   {
      //     sectionTitle: "Permits",
      //     content: [
      //       "Any necessary permits for travelling through restricted or protected areas.",
      //     ],
      //   },
      // ],
      // exclusion: [
      //   {
      //     sectionTitle: "Personal Expenses",
      //     content: [
      //       "Expenses such as personal shopping, additional meals, or any personal activities not specified in the itinerary.",
      //     ],
      //   },
      //   {
      //     sectionTitle: "Lunch on any day",
      //     content: [],
      //   },
      //   {
      //     sectionTitle: "Tips and Gratuities",
      //     content: [
      //       "Tips for guides, drivers, and other service staff are usually not included.",
      //     ],
      //   },
      //   {
      //     sectionTitle: "Transportation to the Starting Point",
      //     content: [
      //       "The journey often starts from a specific location. Participants are responsible for reaching that location.",
      //     ],
      //   },
      //   {
      //     sectionTitle: "Additional Activities",
      //     content: [
      //       "Any optional activities or excursions not mentioned in the itinerary.",
      //     ],
      //   },
      //   {
      //     sectionTitle: "Emergency Expenses",
      //     content: [
      //       "Any expenses related to medical emergencies or unexpected events not covered by insurance.",
      //     ],
      //   },
      //   {
      //     sectionTitle: "Personal Gear",
      //     content: [
      //       "Participants are responsible for their personal travel gear, clothing, and equipment.",
      //     ],
      //   },
      //   {
      //     sectionTitle: "Vehicle Maintenance",
      //     content: [
      //       "If participants are using a self-driven vehicle, they may be responsible for vehicle maintenance and repairs.",
      //     ],
      //   },
    ],
    youtubeLink:
      "https://www.youtube.com/embed/Ywdyh0g2wTE?si=7ttcgL7GiK4zcbMO",
    expeditionDate: [
      { day: "08", month: "FEB" },
      { day: "22", month: "FEB" },
      { day: "15", month: "MAR" },
    ],
  },
  {
    individualExpeditionType: "ladakhOverlanding",
    individualExpeditionName: "Ladakh Adventure",
    carouselData: adventureToursHeroSection,
    location: "Ladakh",
    duration: "9 Days",
    grade: "Any",
    maxAltitude: "5883 m",
    bestSeason: "June, July, August, September and October",
    experienceRequired: "Don’t want to do it in a group? Get it customised.",
    mainExpeditionContent:
      "Ladakh, a high-altitude region in northern India, captivates with its stark beauty and rugged landscapes. Nestled amidst the Himalayas, Ladakh boasts breathtaking mountain vistas, serene lakes like Pangong and Tso Moriri, and ancient monasteries such as Thiksey and Hemis. Known as the 'Land of High Passes,' it offers adventurous souls thrilling journeys through passes like Khardung La. ",
    aboutExpeditionExperienced:
      "The cultural tapestry is woven with Tibetan Buddhism, evident in prayer flags fluttering in the wind and monks chanting in monasteries. With a unique blend of tradition and tranquility, and numerous high altitude lakes, wild-life and mind boggling places, Ladakh remains an enchanting destination for those seeking awe-inspiring natural wonders and spiritual solace.",
    itineraryData: [
      {
        day: "1",
        title: "Arrival in Leh",
        details: [
          "Altitude: 3,500m (11,500 feet).",
          "Activities: Arrive in Leh, transfer to the hotel, and rest for acclimatization.",
          "Evening: Stroll around Leh Market to explore local shops and culture.",
          "Tips: Avoid strenuous activities, stay hydrated, and avoid alcohol.",
          "Stay: Overnight in Leh.",
        ],
      },
      {
        day: "2",
        title: "Acclimatization & Leh Local Sightseeing",
        details: [
          "Places to Visit: Shanti Stupa, Leh Palace, Hall of Fame, Pathar Sahib Gurudwara, Magnetic Hill, and Sangam (Indus & Zanskar Rivers).",
          "Tips: Hydrate well and engage in light activities to aid acclimatization.",
          "Stay: Overnight in Leh.",
        ],
      },
      {
        day: "3",
        title: "Leh to Nubra Valley (via Khardung La)",
        details: [
          "Route: Leh – Khardung La (5,359m) – Nubra Valley.",
          "Activities: Stop at Khardung La Pass for breathtaking views. Visit Diskit Monastery and the Maitreya Buddha statue. Explore Hunder Sand Dunes and enjoy a double-humped camel ride.",
          "Stay: Overnight in Nubra (Hunder or Diskit).",
        ],
      },
      {
        day: "4",
        title: "Nubra Valley to Turtuk",
        details: [
          "Route: Hunder – Turtuk (approx. 3-4 hours).",
          "Activities: Explore Turtuk village, its apricot orchards, and Balti culture. Visit the Indo-Pak border village for a unique cultural experience.",
          "Stay: Overnight in Turtuk.",
        ],
      },
      {
        day: "5",
        title: "Turtuk to Pangong Lake (via Shyok)",
        details: [
          "Route: Turtuk – Shyok – Pangong Lake (4,350m).",
          "Activities: Drive through stunning landscapes, enjoy the scenic beauty of Pangong Lake, and relax by its changing hues.",
          "Stay: Overnight in Mirak (near Pangong Lake).",
        ],
      },
      {
        day: "6",
        title: "Mirak to Hanle",
        details: [
          "Route: Mirak – Chushul – Hanle (4,500m).",
          "Activities: Visit Rezang La Memorial en route. Explore Hanle Observatory, one of the world's highest astronomical observatories, and enjoy the serenity of this remote village.",
          "Stay: Overnight in Hanle.",
        ],
      },
      {
        day: "7",
        title: "Hanle to Tso Moriri",
        details: [
          "Route: Hanle – Nyoma – Tso Moriri (4,522m).",
          "Activities: Reach Tso Moriri, a pristine high-altitude lake surrounded by mountains. Visit Korzok Monastery and enjoy a leisurely stroll along the lake.",
          "Stay: Overnight at Tso Moriri.",
        ],
      },
      {
        day: "8",
        title: "Tso Moriri to Leh (via Tso Kar & Puga)",
        details: [
          "Route: Tso Moriri – Tso Kar – Puga – Leh.",
          "Activities: Explore Tso Kar Lake, known for salt deposits and birdlife. Visit Puga Hot Springs and pass through Tanglang La, one of the world’s highest motorable passes.",
          "Stay: Overnight in Leh.",
        ],
      },
      {
        day: "9",
        title: "Departure",
        details: [
          "Activities: After breakfast, transfer to Leh airport for departure.",
          "Tips: Cherish the memories of your Ladakh adventure.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Enjoy comfortable stays in selected hotels/ camps/home-stay/guest houses throughout the journey.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: ["Dinner from Day 1 to Breakfast on Day 9"],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Experience hassle-free travel with included transportation for all planned excursions and transfers.",
        ],
      },
      {
        sectionTitle: "Permits",
        content: [
          "Assistance in obtaining necessary permits for restricted areas, ensuring a smooth travel experience.",
        ],
      },
      {
        sectionTitle: "Sightseeing Tours",
        content: [
          "Explore Ladakh's cultural and natural wonders with guided tours wherever possible.",
        ],
      },
      {
        sectionTitle: "Oxygen Support",
        content: [
          "As Ladakh boasts high altitudes, oxygen support is provided as needed to ensure the well-being of travellers.",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Expenses such as personal shopping, additional meals, or any personal activities not specified in the itinerary.",
        ],
      },
      {
        sectionTitle: "Tips and Gratuities",
        content: [
          "Tips for guides, drivers, and other service staff are usually not included.",
        ],
      },
      {
        sectionTitle: "Transportation to the Starting Point",
        content: [
          "The journey often starts from a specific location. Participants are responsible for reaching that location.",
        ],
      },
      {
        sectionTitle: "Additional Activities",
        content: [
          "Any optional activities or excursions not mentioned in the itinerary.",
        ],
      },
      {
        sectionTitle: "Emergency Expenses",
        content: [
          "Any expenses related to medical emergencies or unexpected events not covered by insurance.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Participants are responsible for their personal travel gear, clothing, and equipment.",
        ],
      },
      {
        sectionTitle: "Vehicle Maintenance",
        content: [
          "If participants are using a self-driven vehicle, they may be responsible for vehicle maintenance and repairs.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "zanskarOverlanding",
    individualExpeditionName: "Zanskar Overlanding",
    carouselData: adventureToursHeroSection,
    location: "Zanskar, Ladakh",
    duration: "8 Days",
    grade: "Any",
    maxAltitude: "5000 m",
    bestSeason: "June, July, August, September and October",
    experienceRequired: "Don’t want to do it in a group? Get it customised.",
    mainExpeditionContent:
      "Zanskar, nestled within the Union Territory of Ladakh in northern India, is a captivating region celebrated for its breathtaking landscapes and rich cultural heritage. Surrounded by the towering peaks of the Himalayas and the Zanskar Range, it stands as one of the most isolated and challenging-to-reach areas on the Indian subcontinent. The Zanskar River, flowing through this high-altitude desert, freezes during winter, creating the renowned Chadar Trek—an adventurous journey across its icy expanse.",
    aboutExpeditionExperienced:
      "The cultural tapestry of Zanskar is woven with the threads of Tibetan Buddhism, evident in its ancient monasteries. Despite its remoteness, Zanskar has become a destination for adventure enthusiasts, offering trekking, river rafting, and an exploration of its unique monastic heritage. However, the region faces challenges due to its harsh climate, and its residents, primarily of Tibetan descent, have adapted their lives to the demanding conditions, relying on agriculture and livestock for sustenance. Zanskar, with its stunning natural beauty and resilient local culture, provides a truly distinctive and immersive experience for those who venture into its remote embrace.",
    itineraryData: [
      {
        day: "1",
        title: "Manali",
        details: [
          "Begin your adventure in the enchanting town of Manali, nestled in the lap of the Himalayas. The air is crisp, and the altitude sets the stage for the journey ahead.",
        ],
      },
      {
        day: "2",
        title: "Manali to Jispa",
        details: [
          "Depart from Manali, winding through the Atal Tunnel and engineering marvel, as the altitude gradually rises. Marvel at the panoramic views of snow-capped peaks. Arrive in Jispa, a serene Himalayan hamlet, where the altitude offers a taste of the high-altitude charm.",
        ],
      },
      {
        day: "3",
        title: "Jispa to Mt. Gonbo Rangjon",
        details: [
          "Continue the journey, ascending towards the majestic Mt. Gonbo Rangjon via challenging and desolate Shinkula Pass. The altitude adds a sense of awe to the rugged terrain. Experience the sublime beauty of the Himalayas at this elevated vantage point.",
          "Mt. Gonbo Rangjon is unlike any other peak you’d ever seen. It is considered a holy peak by the locals.",
        ],
      },
      {
        day: "4",
        title: "Mt. Gonbo Rangjon to Phugtal Monastery to Padum",
        details: [
          "After breakfast in the morning start your drive toward Purne.",
          "Two hour trek through the winding  Tsarap river will lead you on a spiritual exploration as you descend and head towards the ancient Phugtal Monastery. Perched on cliffs, the monastery's altitude mirrors the devotion embedded in its history.",
          "Come back and board your vehicle and ride towards Padum.",
          "Traverse the landscape from the remote monastery to the ancient town of Padum. The journey encompasses both distance and the tales of ancient trade routes, and the increasing altitude provides a backdrop of solitude and grandeur.",
        ],
      },
      {
        day: "5",
        title: "Padum to Lamayuru",
        details: [
          "Early start as one has to navigate through unpredictable water streams that take over the road and make it difficult for motorists to cross the tough patches.",
          "Venture towards Lamayuru, one of the most beautiful places that you’ll ever visit.",
          "Known as a “Moonland”, it really is very charming giving off a laid back vibes. Altitude becomes a companion, enhancing the mystical aura of the surroundings.",
        ],
      },
      {
        day: "6",
        title: "Lamayuru to Leh",
        details: [
          "Start a little late today, ascend towards Leh, a bustling town surrounded by lofty peaks. The changing altitude signals the transition from remote landscapes to a more inhabited, yet still elevated, environment.",
          "Enjoy sights such as Sangam, Gurudwara Pathar Sahib and Magnetic Hill on the way.",
          "Welcome back to civilization. Even at Leh Market.",
        ],
      },
      {
        day: "7",
        title: "Leh Town",
        details: [
          "Immerse yourself in Leh's local attractions.Visit Leh Palace and Hemis monastery. Spend some time in the market if you wish to. Visit Shanti Stupa in the evening. Enjoy cafe’s and culture of Leh.",
        ],
      },
      {
        day: "8",
        title: "Departure",
        details: [
          "After breakfast in the morning, bid adieu to this high-altitude expedition, carrying with you the memories of a journey intricately woven through altitude, spanning distances, and unveiling the rich tapestry of the Himalayan landscape.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Enjoy comfortable stays in selected hotels/ camps/home-stay/guest houses throughout the journey.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: ["Dinner from Day 1 to Breakfast on Day 9"],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Experience hassle-free travel with included transportation for all planned excursions and transfers.",
        ],
      },
      {
        sectionTitle: "Permits",
        content: [
          "Assistance in obtaining necessary permits for restricted areas, ensuring a smooth travel experience.",
        ],
      },
      {
        sectionTitle: "Sightseeing Tours",
        content: [
          "Explore Zanskars cultural and natural wonders with guided tours wherever possible.",
        ],
      },
      {
        sectionTitle: "Oxygen Support",
        content: [
          "As Ladakh boasts high altitudes, oxygen support is provided as needed to ensure the well-being of travellers.",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Expenses such as personal shopping, additional meals, or any personal activities not specified in the itinerary.",
        ],
      },
      {
        sectionTitle: "Lunch on any day",
        content: [],
      },
      {
        sectionTitle: "Tips and Gratuities",
        content: [
          "Tips for guides, drivers, and other service staff are usually not included.",
        ],
      },
      {
        sectionTitle: "Transportation to the Starting Point",
        content: [
          "The journey often starts from a specific location. Participants are responsible for reaching that location.",
        ],
      },
      {
        sectionTitle: "Additional Activities",
        content: [
          "Any optional activities or excursions not mentioned in the itinerary.",
        ],
      },
      {
        sectionTitle: "Emergency Expenses",
        content: [
          "Any expenses related to medical emergencies or unexpected events not covered by insurance.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Participants are responsible for their personal travel gear, clothing, and equipment.",
        ],
      },
      {
        sectionTitle: "Vehicle Maintenance",
        content: [
          "If participants are using a self-driven vehicle, they may be responsible for vehicle maintenance and repairs.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
  {
    individualExpeditionType: "sachPasKishtwarOverlanding",
    individualExpeditionName: "Zanskar Overlanding",
    carouselData: adventureToursHeroSection,
    location: "Zanskar, Ladakh",
    duration: "8 Days",
    grade: "Any",
    maxAltitude: "5000 m",
    bestSeason: "June, July, August, September and October",
    experienceRequired: "Don’t want to do it in a group? Get it customised.",
    mainExpeditionContent:
      "Zanskar, nestled within the Union Territory of Ladakh in northern India, is a captivating region celebrated for its breathtaking landscapes and rich cultural heritage. Surrounded by the towering peaks of the Himalayas and the Zanskar Range, it stands as one of the most isolated and challenging-to-reach areas on the Indian subcontinent. The Zanskar River, flowing through this high-altitude desert, freezes during winter, creating the renowned Chadar Trek—an adventurous journey across its icy expanse.",
    aboutExpeditionExperienced:
      "The cultural tapestry of Zanskar is woven with the threads of Tibetan Buddhism, evident in its ancient monasteries. Despite its remoteness, Zanskar has become a destination for adventure enthusiasts, offering trekking, river rafting, and an exploration of its unique monastic heritage. However, the region faces challenges due to its harsh climate, and its residents, primarily of Tibetan descent, have adapted their lives to the demanding conditions, relying on agriculture and livestock for sustenance. Zanskar, with its stunning natural beauty and resilient local culture, provides a truly distinctive and immersive experience for those who venture into its remote embrace.",
    itineraryData: [
      {
        day: "1",
        title: "Manali",
        details: [
          "Begin your adventure in the enchanting town of Manali, nestled in the lap of the Himalayas. The air is crisp, and the altitude sets the stage for the journey ahead.",
        ],
      },
      {
        day: "2",
        title: "Manali to Jispa",
        details: [
          "Depart from Manali, winding through the Atal Tunnel and engineering marvel, as the altitude gradually rises. Marvel at the panoramic views of snow-capped peaks. Arrive in Jispa, a serene Himalayan hamlet, where the altitude offers a taste of the high-altitude charm.",
        ],
      },
      {
        day: "3",
        title: "Jispa to Mt. Gonbo Rangjon",
        details: [
          "Continue the journey, ascending towards the majestic Mt. Gonbo Rangjon via challenging and desolate Shinkula Pass. The altitude adds a sense of awe to the rugged terrain. Experience the sublime beauty of the Himalayas at this elevated vantage point.",
          "Mt. Gonbo Rangjon is unlike any other peak you’d ever seen. It is considered a holy peak by the locals.",
        ],
      },
      {
        day: "4",
        title: "Mt. Gonbo Rangjon to Phugtal Monastery to Padum",
        details: [
          "After breakfast in the morning start your drive toward Purne.",
          "Two hour trek through the winding  Tsarap river will lead you on a spiritual exploration as you descend and head towards the ancient Phugtal Monastery. Perched on cliffs, the monastery's altitude mirrors the devotion embedded in its history.",
          "Come back and board your vehicle and ride towards Padum.",
          "Traverse the landscape from the remote monastery to the ancient town of Padum. The journey encompasses both distance and the tales of ancient trade routes, and the increasing altitude provides a backdrop of solitude and grandeur.",
        ],
      },
      {
        day: "5",
        title: "Padum to Lamayuru",
        details: [
          "Early start as one has to navigate through unpredictable water streams that take over the road and make it difficult for motorists to cross the tough patches.",
          "Venture towards Lamayuru, one of the most beautiful places that you’ll ever visit.",
          "Known as a “Moonland”, it really is very charming giving off a laid back vibes. Altitude becomes a companion, enhancing the mystical aura of the surroundings.",
        ],
      },
      {
        day: "6",
        title: "Lamayuru to Leh",
        details: [
          "Start a little late today, ascend towards Leh, a bustling town surrounded by lofty peaks. The changing altitude signals the transition from remote landscapes to a more inhabited, yet still elevated, environment.",
          "Enjoy sights such as Sangam, Gurudwara Pathar Sahib and Magnetic Hill on the way.",
          "Welcome back to civilization. Even at Leh Market.",
        ],
      },
      {
        day: "7",
        title: "Leh Town",
        details: [
          "Immerse yourself in Leh's local attractions.Visit Leh Palace and Hemis monastery. Spend some time in the market if you wish to. Visit Shanti Stupa in the evening. Enjoy cafe’s and culture of Leh.",
        ],
      },
      {
        day: "8",
        title: "Departure",
        details: [
          "After breakfast in the morning, bid adieu to this high-altitude expedition, carrying with you the memories of a journey intricately woven through altitude, spanning distances, and unveiling the rich tapestry of the Himalayan landscape.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Enjoy comfortable stays in selected hotels/ camps/home-stay/guest houses throughout the journey.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: ["Dinner from Day 1 to Breakfast on Day 9"],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Experience hassle-free travel with included transportation for all planned excursions and transfers.",
        ],
      },
      {
        sectionTitle: "Permits",
        content: [
          "Assistance in obtaining necessary permits for restricted areas, ensuring a smooth travel experience.",
        ],
      },
      {
        sectionTitle: "Sightseeing Tours",
        content: [
          "Explore Zanskars cultural and natural wonders with guided tours wherever possible.",
        ],
      },
      {
        sectionTitle: "Oxygen Support",
        content: [
          "As Ladakh boasts high altitudes, oxygen support is provided as needed to ensure the well-being of travellers.",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Expenses such as personal shopping, additional meals, or any personal activities not specified in the itinerary.",
        ],
      },
      {
        sectionTitle: "Lunch on any day",
        content: [],
      },
      {
        sectionTitle: "Tips and Gratuities",
        content: [
          "Tips for guides, drivers, and other service staff are usually not included.",
        ],
      },
      {
        sectionTitle: "Transportation to the Starting Point",
        content: [
          "The journey often starts from a specific location. Participants are responsible for reaching that location.",
        ],
      },
      {
        sectionTitle: "Additional Activities",
        content: [
          "Any optional activities or excursions not mentioned in the itinerary.",
        ],
      },
      {
        sectionTitle: "Emergency Expenses",
        content: [
          "Any expenses related to medical emergencies or unexpected events not covered by insurance.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Participants are responsible for their personal travel gear, clothing, and equipment.",
        ],
      },
      {
        sectionTitle: "Vehicle Maintenance",
        content: [
          "If participants are using a self-driven vehicle, they may be responsible for vehicle maintenance and repairs.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [{ day: "On", month: "Demand" }],
  },
];
