import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Navigation hook for routing
import AOS from "aos"; // AOS library for animations
import "aos/dist/aos.css"; // AOS stylesheet import
import Modal from "react-modal"; // Import react-modal

// Components
import NavigationBar from "../components/NavigationBar";
import HeroSection from "../components/HeroSection";
import InstaFeeds from "../components/InstaFeeds";
import Footer from "../components/Footer";
import Whatsapp from "../components/Whatsapp";

// Data
import { heroSectionCarousel } from "../data/Home/dataStorage";

// Assets (grouped for better organization)
import logoMedium from "../assets/WHITEEXPED LOGO - Medium.webp";
import expedition from "../assets/home/hero/expedition.webp";
import rickyMountaineer from "../assets/home/Members/Ricky-2.webp";
import nikitaThakur from "../assets/home/Members/Nikita_Thakur.webp";
import shubhamBisht from "../assets/home/Members/Shubham.webp";
import m5000 from "../assets/home/Cover Photo/5000M.webp";
import m6000 from "../assets/home/Cover Photo/6000M.webp";
import m7000 from "../assets/home/Cover Photo/7000M.webp";
import pureAdrenaline from "../assets/home/Cover Photo/Pure_Adrenaline.webp";
import wildlifePhotography from "../assets/home/Other Adventures/Wildlife_Photography.webp";
import adventureTour from "../assets/home/Other Adventures/Adventure_Tours.avif";
import upcomingMtKiagri from "../assets/home/Upcoming Expedition/MT_KIAGER_RI.webp";
import upcomingMtDeoTibba from "../assets/home/Upcoming Expedition/Mt Deo Tibba.webp";
import upcomingMtFriendshipPeak from "../assets/home/Upcoming Expedition/Mt Friendship peak.webp";
import upcomingMtKangyatse2 from "../assets/home/Upcoming Expedition/Mt kangyatse 2.webp";
import upcomingMtYunam from "../assets/home/Upcoming Expedition/Mt yunam-2.webp";
import upcomingWinterSpiti from "../assets/home/Upcoming Expedition/Winter Spiti 4x4 Expedition.webp";
import upcomingUtKangri from "../assets/home/Upcoming Expedition/UT KANGRI.webp";
import upcomingMentokKangri from "../assets/home/Upcoming Expedition/MENTOK KANGRI.webp";
import special2X5000 from "../assets/home/Special Expedition/2X5000M Friendship peak & Shitidhar.webp";
import special2X6000 from "../assets/home/Special Expedition/2X6000M KY 1 & KY 2.webp";
import special3X6000 from "../assets/home/Special Expedition/3x6000M MT KIAGER RI - MT SPANGNAK RI - MT ZES RI.webp";
import specialSkiDown from "../assets/home/Special Expedition/Ski Down expedition.webp";
import specialunClimbed from "../assets/home/Special Expedition/UNCLIMBED PEAK.webp";
import extremeReoPurgyil from "../assets/home/Extreme Expedition/Reo Purgyil.webp";
import extremePapsura from "../assets/home/Extreme Expedition/Papsura.webp";
import extremeNun from "../assets/home/Extreme Expedition/NUN.jpeg";
import extremeIndrasan from "../assets/home/Extreme Expedition/Indrasan.webp";
import extremeRamjak from "../assets/home/Extreme Expedition/RAMJAK.webp";
import extremeKangjuKangri from "../assets/home/Extreme Expedition/Kangju_Kangri.webp";
import googleIcon from "../assets/home/Social Media Icons/Platform=Google, Style=Brand, State=Default.png";
import fullStar from "../assets/home/Social Media Icons/Fill=100%, Color=Yellow.png";
import halfStar from "../assets/home/Social Media Icons/Fill=50%, Color=Yellow.png";
import emptyStar from "../assets/home/Social Media Icons/Fill=0%, Color=Yellow.png";

import blog1 from "../assets/blogs/blog1_1.webp";
import Blog2 from "../assets/blogs/blog2_1.webp";

// Icons (grouped for better organization)
import { MdAddAPhoto } from "react-icons/md";
import { GiBarracksTent } from "react-icons/gi";
import {
  FaFacebookSquare,
  FaInstagram,
  FaQuoteLeft,
  FaYoutube,
} from "react-icons/fa";
import { FaArrowLeftLong, FaSquareXTwitter } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { Helmet } from "react-helmet";
import UpcomingTours from "../components/UpcomingTours";
import UpcomingToursIcon from "../components/UpcomingToursIcon";
import ExpeditionSection from "../components/ExpeditionSection";
import YouTubeGallery from "../components/YouTubeGallery";

const Home = () => {
  const navigate = useNavigate();

  const [testimonialsArray, setTestimonialArray] = useState([
    {
      fullName: "Prit Shah",
      selectedTrip: "Friendship Peak",
      message:
        "I recently embarked on an unforgettable mountain expedition organized by Ricky's team. The level of professionalism and attention to detail was exceptional. From the breathtaking views to the well-planned itinerary, every moment felt like an adventure. The guides were knowledgeable and made the experience truly enriching.",
    },
  ]);

  const [visibleCount, setVisibleCount] = useState(3); // Default to 3 for laptops

  useEffect(() => {
    const updateVisibleCount = () => {
      if (window.innerWidth >= 1024) {
        setVisibleCount(3); // 3 for laptops
      } else if (window.innerWidth >= 640) {
        setVisibleCount(2); // 2 for tablets
      } else {
        setVisibleCount(1); // 1 for mobile
      }
    };

    // Initial check
    updateVisibleCount();

    // Update on resize
    window.addEventListener("resize", updateVisibleCount);

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", updateVisibleCount);
  }, []);

  const totalTestimonials = testimonialsArray.length;

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + visibleCount >= totalTestimonials
        ? 0
        : prevIndex + visibleCount
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - visibleCount < 0
        ? Math.max(0, totalTestimonials - visibleCount)
        : prevIndex - visibleCount
    );
  };

  const [averageRating, setAverageRating] = useState(0); // To store the average rating
  const [totalReviews, setTotalReviews] = useState(0); // To store the total number of reviews

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const db = getFirestore();
        const testimonialQuery = collection(db, "reviews");
        const querySnapshot = await getDocs(testimonialQuery);

        const fetchedTestimonials = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTestimonialArray(fetchedTestimonials);

        // Filter out invalid ratings and convert to number
        const validRatings = fetchedTestimonials.filter((review) => {
          const rating = parseFloat(review.ratingStar); // Convert to number
          return !isNaN(rating) && rating >= 0 && rating <= 5; // Validate range
        });

        // Calculate the total rating and count of reviews
        const totalRating = validRatings.reduce((sum, review) => {
          return sum + parseFloat(review.ratingStar); // Add numeric value
        }, 0);
        const reviewCount = validRatings.length;

        // Update state
        setAverageRating(
          reviewCount > 0 ? (totalRating / reviewCount).toFixed(1) : 0
        );
        setTotalReviews(reviewCount);
      } catch (error) {
        console.log("Error fetching testimonials:", error);
      }
    };
    fetchTestimonials();
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  // const handleNext = () => {
  //   // Wrap around to the beginning if reaching the end
  //   setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsArray.length);
  // };

  // const handlePrev = () => {
  //   // Handle negative index by adding testimonials length
  //   setCurrentIndex(
  //     (prevIndex) =>
  //       (prevIndex - 1 + testimonialsArray.length) % testimonialsArray.length
  //   );
  // };

  // useEffect(() => {
  //   // Initialize AOS library for animations
  //   AOS.init();
  // }, []);

  const leaders = [
    {
      name: "Ricky Mountaineer",
      linkName: "Ricky",
      title: "Director and Exped Leader",
      imageUrl: rickyMountaineer, // Replace with your image path
      link: "/whiteExpeditionLeader/rickyMountaineer",
      mdColStart: 1,
      xlColStart: 1,
      index: 0,
    },
    {
      name: "Nikita Thakur",
      linkName: "Nikita",
      title: "Auditor & Exped Leader",
      imageUrl: nikitaThakur, // Replace with your image path
      link: "/whiteExpeditionLeader/nikitaThakur",
      mdColStart: 7,
      xlColStart: 5,
      index: 1,
    },
    {
      name: "Shubham Bisht",
      linkName: "Shubham",
      title: "Managing Director & Exped leader",
      imageUrl: shubhamBisht, // Replace with your image path
      link: "/whiteExpeditionLeader/shubhamBisht",
      mdColStart: 4,
      xlColStart: 9,
      index: 2,
    },
  ];

  const upcomingExpeditions = [
    {
      title: "5000 M",
      imageSrc: m5000,
      description: "Begin Your High Altitude Journey",
      link: "/5000m-expedition",
      mdColStart: 1,
      lgColStart: 1,
      index: 0,
    },
    {
      title: "6000 M",
      imageSrc: m6000,
      description: "Aim Higher, Transcend The Limitations",
      link: "/6000m-expedition",
      mdColStart: 7,
      lgColStart: 4,
      index: 1,
    },
    {
      title: "7000 M", // Replace with your expedition title
      imageSrc: m7000, // Replace with image source
      description: "Prepare For The Mighty 8000ers", // Replace with description
      link: "/7000m-expedition", // Replace with link
      mdColStart: 7,
      lgColStart: 7,
      index: 2,
    },
    {
      title: "Pure Adrenaline", // Replace with your expedition title
      imageSrc: pureAdrenaline, // Replace with image source
      description: "Become One With The Mountains, Find The Flow", // Replace with description
      link: "/pure-adrenaline-expedition", // Replace with link
      mdColStart: 1,
      lgColStart: 10,
      index: 3,
    },
  ];

  const [isVisible, setIsVisible] = useState(false);

  // Expedition Date Logic
  const highlightDatesLogic = (expedition) => {
    const today = new Date();

    const updatedDates = expedition.dates.map((date) => {
      const fullDate = new Date(
        today.getFullYear(),
        new Date(`${date.month} 1, 2000`).getMonth(),
        parseInt(date.day, 10)
      );

      // If the date is in the past (considering year), move it to next year
      if (fullDate < today) {
        fullDate.setFullYear(today.getFullYear() + 1);
      }

      return { ...date, fullDate };
    });

    const firstUpcomingDate = updatedDates
      .filter((date) => date.fullDate >= today)
      .sort((a, b) => a.fullDate - b.fullDate)[0];

    return updatedDates.map((date) => ({
      ...date,
      isHighlighted:
        date.fullDate.getTime() === firstUpcomingDate?.fullDate.getTime(),
    }));
  };

  const expeditions = [
    {
      title: "Winter Expedition 6000M",
      image: upcomingMtKiagri,
      name: "Kiagar Ri",
      dates: [
        { day: "15", month: "JAN" },
        { day: "05", month: "FEB" },
        { day: "15", month: "FEB" },
      ],
      link: "expedition/kiagarRi",
    },
    {
      title: "Winter Spiti 4x4 Exped",
      image: upcomingWinterSpiti,
      name: "Winter Spiti Overlanding",
      dates: [
        { day: "08", month: "FEB" },
        { day: "22", month: "FEB" },
        { day: "15", month: "MAR" },
      ],
      link: "adventureTour/winterSpitiOverlanding",
    },
    {
      title: "Mt Friendship peak",
      image: upcomingMtFriendshipPeak,
      name: "Friendship Peak",
      dates: [
        { day: "10", month: "MAY" },
        { day: "20", month: "MAY" },
        { day: "05", month: "JUN" },
        { day: "15", month: "JUN" },
        { day: "05", month: "SEP" },
        { day: "15", month: "SEP" },
        { day: "05", month: "OCT" },
        { day: "15", month: "OCT" },
      ],
      link: "expedition/friendshipPeak",
    },
    {
      title: "Mt yunam",
      image: upcomingMtYunam,
      name: "Yunam",
      dates: [
        { day: "10", month: "JUN" },
        { day: "25", month: "JUN" },
        { day: "10", month: "JUL" },
        { day: "25", month: "JUL" },
        { day: "11", month: "AUG" },
        { day: "10", month: "SEP" },
        { day: "20", month: "OCT" },
        { day: "10", month: "OCT" },
      ],
      link: "expedition/yunamPeak",
    },
    {
      title: "Mt Deo Tibba",
      image: upcomingMtDeoTibba,
      name: "Deo Tibba",
      dates: [
        { day: "10", month: "MAY" },
        { day: "15", month: "SEP" },
      ],
      link: "expedition/deoTibba",
    },
    {
      title: "Mt kangyatse 2",
      image: upcomingMtKangyatse2,
      name: "Kangyatse 2",
      dates: [{ day: "15", month: "JUN" }],
      link: "expedition/kangyatse2",
    },
    {
      title: "Mt Ut Kangri",
      image: upcomingUtKangri,
      name: "Ut Kangri",
      dates: [{ day: "On", month: "Demand" }],
      link: "expedition/utKangri",
    },
    {
      title: "Mt Mentok Kangri",
      image: upcomingMentokKangri,
      name: "Mentok Kangri",
      dates: [{ day: "On", month: "Demand" }],
      link: "expedition/mentokKangri",
    },
  ];

  const specialExpeditions = [
    {
      title: "2X5000M",
      image: special2X5000,
      name: "Friendship Peak & Shitidhar",
      dates: [{ day: "25", month: "MAY" }],
      link: "expedition/2x5000",
    },
    {
      title: "2X6000M",
      image: special2X6000,
      name: "Kangyatse 1 & Kangyatsae 2",
      dates: [{ day: "20", month: "JUN" }],
      link: "expedition/Kangyatse_2x6000M",
    },
    {
      title: "3X6000M",
      image: special3X6000,
      name: "Kiager Ri, Spangnak Ri & Zes Ri",
      dates: [
        { day: "15", month: "JUN" },
        { day: "13", month: "JUL" },
        { day: "10", month: "AUG" },
        { day: "07", month: "SEP" },
        { day: "28", month: "SEP" },
        { day: "12", month: "OCT" },
      ],
      link: "expedition/kiagarRi_3x6000M",
    },
    {
      title: "Ski Down exped",
      image: specialSkiDown,
      name: "Ski Down",
      dates: [{ day: "10", month: "JUN" }],
      link: "expedition/skiDownExped",
    },
  ];

  const extremeExpeditions = [
    {
      title: "Mt Reo Purgyil",
      image: extremeReoPurgyil,
      name: "Reo Purgyil",
      dates: [{ day: "On", month: "Demand" }],
      link: "expedition/reoPurgyil",
    },
    {
      title: "Mt Papsura",
      image: extremePapsura,
      name: "Papsura",
      dates: [{ day: "15", month: "SEP" }],
      link: "expedition/papsura",
    },
    {
      title: "Mt Nun",
      image: extremeNun,
      name: "Nun",
      dates: [{ day: "10", month: "JUL" }],
      link: "expedition/nun",
    },
    {
      title: "Mt Indrasan",
      image: extremeIndrasan,
      name: "Indrasan",
      dates: [{ day: "01", month: "OCT" }],
      link: "expedition/indrasan",
    },
    {
      title: "Mt Ramjak",
      image: extremeRamjak,
      name: "Ramjak",
      dates: [{ day: "01", month: "SEP" }],
      link: "expedition/ramjak",
    },
    {
      title: "Mt Kangju Kangri",
      image: extremeKangjuKangri,
      name: "Kangju Kangri",
      dates: [{ day: "On", month: "Demand" }],
      link: "expedition/kangjuKangri",
    },
  ];

  const youtubeVideos = [
    {
      id: 1,
      link: "https://www.youtube.com/embed/0gS6ut-63MQ?si=PHEGMIZVeBkyX-sI",
    },
    {
      id: 2,
      link: "https://www.youtube.com/embed/K4HvXCnjUvo?si=YKw9H9PoSYxNVtQv",
    },
    {
      id: 3,
      link: "https://www.youtube.com/embed/G12JKmSgy80?si=rg4MDB-4HiKLGDDj",
    },
    {
      id: 4,
      link: "https://www.youtube.com/embed/umPrbQ_X8To?si=eKPoJKJOkvk6Mztg",
    },
    {
      id: 5,
      link: "https://www.youtube.com/embed/i74upB2Jt3g?si=yuN9vOfQZtYn0l3p",
    },
    {
      id: 6,
      link: "https://www.youtube.com/embed/KVWiQLM_Akc?si=AnLYXWdhVdu2iXCC",
    },
    {
      id: 7,
      link: "https://www.youtube.com/embed/k3ArNz91cH0?si=zcUszPsyJfHMQIxn",
    },
    {
      id: 8,
      link: "https://www.youtube.com/embed/CTIFcnKzy7g?si=WTO4M2CtXZa_YVOB",
    },
    {
      id: 9,
      link: "https://www.youtube.com/embed/gINtMRf2QvI?si=DoPsBgZMLMS74b0a",
    },
    {
      id: 10,
      link: "https://www.youtube.com/embed/utL3Z6RQCk8?si=45lWi3zkZjoZB3GG",
    },
    {
      id: 11,
      link: "https://www.youtube.com/embed/63bRSbdPsuo?si=J5jhRkRtsbsg5s78",
    },
    {
      id: 12,
      link: "https://www.youtube.com/embed/KYbUv4NhYpw?si=WK2X2m60uSc0jCpM",
    },
    {
      id: 13,
      link: "https://www.youtube.com/embed/csCpWrakV-M?si=XRITY1YzMIov9J4P",
    },
    {
      id: 14,
      link: "https://www.youtube.com/embed/Ws5YuCQ1IcU?si=gxF1vgQ6bNhinK9W",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal visibility
  const [selectedReview, setSelectedReview] = useState(""); // Store the full review content

  const openModal = (content) => {
    setSelectedReview(content);
    setIsModalOpen(true);
    console.log("isModalOpen state:", isModalOpen); // Should show `true` after update
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedReview(""); // Clear the review content
  };

  return (
    <>
      <Helmet>
        <title>White Expedition - Home</title>
        <meta
          name="description"
          content="Scale new heights with White Expedition! Experience the thrill of mountain climbing in Himachal Pradesh. Explore our guided climbs and reach your summit dreams."
        />
      </Helmet>

      <HeroSection carouselData={heroSectionCarousel} />

      <section className="center">
        <NavigationBar />
      </section>

      {/* About Company Section */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2 overflow-hidden">
            <div className="invisible hidden lg:flex lg:visible lg:col-start-1 lg:col-span-4 justify-center items-center">
              <img
                src={logoMedium}
                alt="White Expeditions Logo"
                className="aspect-auto"
                width={480}
              />
            </div>
            <h1 className="newL3SubHeader col-span-full lg:col-start-5 lg:col-span-8 flex items-center">
              White Expedition is a team of professional mountaineers dedicated
              to exploring and climbing unclimbed, challenging peaks. With over
              a decade of experience, we specialize in bringing new and exciting
              adventures to the climbing world. Whether you’re a beginner
              looking for your first summit or an experienced climber seeking
              extreme challenges, we offer expeditions tailored to all levels.
              Join us in our journey to discover new heights and create
              unforgettable memories!
            </h1>
          </div>
        </div>
      </section>

      {/* Upcoming Expedition Section */}
      <ExpeditionSection
        title="Upcoming Expeditions for 2025"
        expeditions={expeditions}
        highlightDatesLogic={highlightDatesLogic}
      />

      {/* Special Expeditions */}
      <ExpeditionSection
        title="Special Expeditions for 2025"
        expeditions={specialExpeditions}
        highlightDatesLogic={highlightDatesLogic}
      />

      {/* Extreme Expeditions */}
      <ExpeditionSection
        title="Extreme Expeditions for 2025"
        expeditions={extremeExpeditions}
        highlightDatesLogic={highlightDatesLogic}
      />

      {/* Leadership Section  */}
      {/* <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
           
            <h1
              className="newHeader col-start-1 col-span-12 text-primary text-center"
              
            >
              White Expedition: Exemplary Leadership Driving Adventure to New
              Heights
            </h1>

            

            <h1
              className="newContent col-start-1 col-span-12 text-center margin"
              
            >
              At the heart of White Expedition lies a team of visionary leaders
              dedicated to creating unforgettable adventures for climbers and
              explorers. Their unmatched expertise, passion for the outdoors,
              and relentless commitment to safety and excellence have
              established White Expedition as a leader in the adventure
              community.
            </h1>

          
            {leaders.map((leader) => (
              <div
                className={`relative col-start-1 col-span-12 h-[400px] sm:col-start-3 sm:col-span-8 sm:h-[450px] md:col-start-${leader.mdColStart} md:col-span-6 md:h-[450px] lg:h-[500px] xl:col-start-${leader.xlColStart} xl:col-span-4 xl:h-[530px] 2xl:h-[600px] overflow-hidden bg-white rounded-md margin group hover:cursor-pointer`}
                
                data-aos-offset="400"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(leader.link);
                }}
                key={leader.index}
              >
                <img
                  src={leader.imageUrl}
                  alt="Director"
                  className="w-full h-full object-cover"
                />
                <div className="absolute left-0 bottom-0 w-full h-36 transition-transform duration-300 transform translate-y-12 group-hover:translate-y-0 bg-[#2C2C2C] flex flex-col justify-center items-start px-4 py-2 text-textColor">
                  <h1 className="newL2SubHeader text-primary">{leader.name}</h1>
                  <h1 className="paragrph xl:newL2Content">{leader.title}</h1>
                  <a
                    href={leader.link}
                    className="text-primary font-bold underline mt-2 tracking-wide"
                  >
                    Learn more about {leader.linkName}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-center">
              White Expedition: Exemplary Leadership Driving Adventure to New
              Heights
            </h1>
            <h1 className="newContent col-start-1 col-span-12 text-center margin">
              At the heart of White Expedition lies a team of visionary leaders
              dedicated to creating unforgettable adventures for climbers and
              explorers. Their unmatched expertise, passion for the outdoors,
              and relentless commitment to safety and excellence have
              established White Expedition as a leader in the adventure
              community.
            </h1>
            <div className="col-span-12 lg:col-span-4 h-[450px] md:h-[600px] lg:h-[550px] rounded-lg overflow-hidden margin">
              <img
                src={rickyMountaineer}
                alt="Director"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="col-span-12 lg:col-span-8 flex flex-col gap-8 margin">
              <h1 className="newL2SubHeader px-4">
                Ricky Mountaineer – Redefining Limits in Mountaineering
              </h1>
              <div className="flex flex-col gap-4">
                <h1 className="newContent px-4">
                  Born in Shimla, Himachal Pradesh, Ricky Mountaineer is a
                  passionate climber with over a decade of experience in the
                  mountains. Having completed all advanced mountaineering
                  courses, including specialized training in Search and Rescue,
                  Ricky is not just a skilled climber but also a leader in the
                  field of adventure sports.
                </h1>
                <h1 className="newContent px-4">
                  In his 10-year journey, Ricky has climbed 25 peaks above 6000m
                  and two 7000m peaks, including remarkable solo ascents of Mt.
                  Reo Purgyil (6816m)—the highest peak in Himachal Pradesh—and
                  Mt. Manirang (6593m). Known for his speed and endurance, Ricky
                  holds several records, including the fastest ascent of a 6000m
                  peak.
                </h1>
                <h1 className="newContent px-4">
                  Beyond personal achievements, Ricky is dedicated to promoting
                  mountaineering in India. He has guided numerous expeditions
                  and inspired many climbers to chase their dreams. Now
                  preparing for his biggest challenge yet—climbing Mt.
                  Everest—Ricky seeks to push his boundaries further and inspire
                  others to do the same.
                </h1>
                <h1 className="newContent px-4">
                  Ricky’s story is one of perseverance, courage, and a
                  relentless pursuit of excellence, making him a true icon in
                  Indian mountaineering.
                </h1>
              </div>
            </div>
            <div className="col-span-12 h-auto center margin">
              <button
                className="px-8 md:px-12 py-4 newContent rounded-md text-black bg-primary cursor-pointer"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/ourTeam");
                }}
              >
                Meet My Team
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Upcoming Expedition Section */}
      {/* <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1
              className="newHeader col-start-1 col-span-12 text-center"
              
            >
              Upcoming Expeditions
            </h1>
            {upcomingExpeditions.map((upcomingExpedition) => (
              <div
                className={`relative col-start-1 col-span-12 h-96 sm:col-start-3 sm:col-span-8 sm:h-[400px] md:col-start-${upcomingExpedition.mdColStart} md:col-span-6 md:h-[420px] lg:col-start-${upcomingExpedition.lgColStart} lg:col-span-3 lg:h-[300px] xl:col-span-3 xl:h-[350px] 2xl:h-[400px] overflow-hidden bg-slate-300 rounded-md margin group hover:cursor-pointer`}
                
                data-aos-offset="400"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate(upcomingExpedition.link);
                }}
                key={upcomingExpedition.index}
              >
                <img
                  src={upcomingExpedition.imageSrc}
                  alt="Upcoming Expedition"
                  className="w-full h-full object-cover"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
                <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4">
                  <h1 className="newL2SubHeader text-primary">
                    {upcomingExpedition.title}
                  </h1>
                  <h1 className="newContent text-center text-white lg:text-[15px]">
                    {upcomingExpedition.description}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* Why With Us Section */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-center text-primary">
              Why With Us?
            </h1>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center text-primary margin">
                Expertise and Experience
              </h1>
              <h1 className="newL2Content text-center mt-1">
                Our team comprises seasoned mountaineers, adventure leaders, and
                filmmakers who have explored the most challenging terrains. From
                unclimbed peaks to iconic summits, we’ve achieved milestones
                that reflect our unmatched expertise.
              </h1>
            </div>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center text-primary margin">
                Personalized Adventures
              </h1>
              <h1 className="newL2Content text-center mt-1">
                Every expedition is thoughtfully planned to ensure it aligns
                with your dreams and abilities. Whether you’re a beginner or a
                seasoned climber, our itineraries are crafted to offer the right
                mix of challenge, safety, and fulfillment.
              </h1>
            </div>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center text-primary margin">
                Safety First, Always
              </h1>
              <h1 className="newL2Content text-center mt-1">
                We prioritize your safety above all. With certified guides,
                medical assistance, and top-notch equipment, you can focus on
                the journey while we ensure your well-being.
              </h1>
            </div>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center text-primary margin">
                Local Knowledge, Global Standards
              </h1>
              <h1 className="newL2Content text-center mt-1">
                Our deep connection with the Himalayas and our team’s local
                expertise give us an edge. We combine this with international
                standards of planning, professionalism, and service to offer you
                the best of both worlds.
              </h1>
            </div>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center text-primary margin">
                Sustainable Adventure
              </h1>
              <h1 className="newL2Content text-center mt-1">
                We believe in leaving no trace. Our expeditions are designed to
                minimize environmental impact and support local communities,
                ensuring your adventure is meaningful and eco-friendly.
              </h1>
            </div>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center text-primary margin">
                Unforgettable Experiences
              </h1>
              <h1 className="newL2Content text-center mt-1">
                From breathtaking views and thrilling climbs to stories shared
                around a campfire, we promise memories that last a lifetime.
                With us, it’s more than just an expedition—it’s an experience
                that transforms.
              </h1>
            </div>
            <div className="col-span-12 md:col-start-2 md:col-span-10 lg:col-start-5 lg:col-span-4 px-2">
              <h1 className="newL3SubHeader text-center text-primary margin">
                Recognized Excellence
              </h1>
              <h1 className="newL2Content text-center mt-1">
                We’ve been part of record-breaking climbs, pioneering
                expeditions, and successful group adventures. Our track record
                speaks for itself, and our clients’ stories of satisfaction say
                it all.
              </h1>
            </div>
          </div>
        </div>
      </section>

      {/* Other Activities Section */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Section newHeader */}
            <h1 className="newHeader text-center col-start-1 col-span-12">
              Explore Other Adventures
            </h1>

            {/* Activity Card: Wildlife Photography */}
            <div
              className="relative col-start-1 col-span-12 h-96 sm:col-start-3 sm:col-span-8 sm:h-[400px] md:col-start-1 md:col-span-6 md:h-[420px] lg:col-start-3 lg:col-span-4 lg:h-[300px] xl:col-start-3 xl:col-span-4 xl:h-[350px] 2xl:h-[400px] overflow-hidden bg-slate-300 rounded-md margin group hover:cursor-pointer"
              data-aos-offset="400"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/wildlifePhotography/snowLeopardWinterSpiti");
              }}
            >
              <img
                src={wildlifePhotography}
                alt="Other Activities"
                className="w-full h-full object-cover"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
              <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4">
                <MdAddAPhoto className="text-primary w-10 h-10 sm:w-12 sm:h-12" />
                <h1 className="newL2SubHeader text-center text-white">
                  Wildlife Photography
                </h1>
              </div>
            </div>

            {/* Activity Card: Adventure Tours */}
            <div
              className="relative col-start-1 col-span-12 h-96 sm:col-start-3 sm:col-span-8 sm:h-[400px] md:col-start-7 md:col-span-6 md:h-[420px] lg:col-start-7 lg:col-span-4 lg:h-[300px] xl:col-start-7 xl:col-span-4 xl:h-[350px] 2xl:h-[400px] overflow-hidden bg-slate-300 rounded-md margin group hover:cursor-pointer"
              // data-aos-delay="300"
              data-aos-offset="400"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/adventure-tours");
              }}
            >
              <img
                src={adventureTour}
                alt="Director"
                className="w-full h-full object-cover"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
              <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-center items-center px-4">
                <GiBarracksTent className="text-primary w-10 h-10 sm:w-12 sm:h-12" />
                <h1 className="newL2SubHeader text-center text-white">
                  Adventure Tours
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Personalize Your Trip Section */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newL2SubHeader col-start-1 col-span-12 lg:col-start-1 lg:col-span-8 lg:flex lg:justify-center lg:items-center text-center">
              Want to <span className="text-primary lg:mx-2">Personalize</span>{" "}
              Your Own Adventure?
            </h1>
            <h1
              className="newContent col-start-2 col-span-10 sm:col-start-4 sm:col-span-6 lg:col-start-9 lg:col-span-4 2xl:col-start-9 2xl:col-span-4 flex justify-center items-center px-2 py-2 rounded-md text-black font-bold text-center bg-primary margin lg:m-0 hover:cursor-pointer"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/planYourTrip");
              }}
            >
              PERSONALIZE YOUR TRIP
            </h1>
          </div>
        </div>
      </section>

      {/* Achievements & Records Section */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-center">
              Achievements & Records
            </h1>
            <h1 className="newContent col-start-1 col-span-12 text-center margin">
              At White Expedition, we take immense pride in our achievements.
              We’ve successfully led over 100 expeditions, with our team
              climbing 30 different 6000m peaks, including 10 unclimbed and
              unnamed peaks. Our dedication to pushing boundaries has also led
              us to set numerous records, including solo ascents and the fastest
              climbs. These milestones reflect our expertise, commitment, and
              relentless passion for mountaineering, and we continue to strive
              for excellence with every adventure we embark on.
            </h1>
            <h1
              className="px-4 py-2 bg-primary newContent col-start-2 col-span-10 sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 xl:col-start-5 xl:col-span-4 margin rounded-lg flex justify-center items-center text-black hover:cursor-pointer"
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/AchievementsRecords");
              }}
            >
              See Achievements & Records
            </h1>
          </div>
        </div>
      </section>

      {/* Our Blogs Section */}
      <section className="center bg-black">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-center text-primary">
              Our Blogs
            </h1>

            {/* <h1 className="newContent col-start-1 col-span-12 text-center  margin">
              Stay tune for upcoming blogs
            </h1> */}

            {/* Blog 1  */}
            <div className="relative col-start-1 col-span-12 h-96 sm:col-start-3 sm:col-span-8 sm:h-[400px] md:col-start-1 md:col-span-6 md:h-[420px] lg:col-start-1 lg:col-span-6 lg:h-[300px] xl:col-start-1 xl:col-span-6 xl:h-[350px] 2xl:h-[400px] overflow-hidden bg-slate-300 rounded-md margin group hover:cursor-pointer">
              <Link
                to="/blogs/how-to-start-mountaineering-in-india-step-by-step-guide"
                onClick={() => {
                  window.scrollTo(0, 0); // Scroll to the top of the page
                }}
              >
                <img
                  src={blog1}
                  alt="Director"
                  className="w-full h-full object-cover"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
                <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-end items-start px-4 pb-8">
                  <h1 className="newContent text-start text-white">
                    January 10, 2025
                  </h1>
                  <h1 className="newL2SubHeader text-start margin text-primary group-hover:text-primary">
                    How to Start Mountaineering in India: A Step-by-Step Guide
                  </h1>
                </div>
              </Link>
            </div>

            {/* Blog 2  */}
            <div
              className="relative col-start-1 col-span-12 h-96 sm:col-start-3 sm:col-span-8 sm:h-[400px] md:col-start-7 md:col-span-6 md:h-[420px] lg:col-start-7 lg:col-span-6 lg:h-[300px] xl:col-start-7 xl:col-span-6 xl:h-[350px] 2xl:h-[400px] overflow-hidden bg-slate-300 rounded-md margin group hover:cursor-pointer"
              data-aos-offset="400"
            >
              <Link
                to="/blogs/beginner-friendly-6000m-peaks-high-altitude-mountaineering"
                onClick={() => {
                  window.scrollTo(0, 0); // Scroll to the top of the page
                }}
              >
                <img
                  src={Blog2}
                  alt="Director"
                  className="w-full h-full object-cover"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 transition-all duration-300 ease-in-out group-hover:opacity-75"></div>
                <div className="absolute w-full h-full top-0 left-0 flex flex-col justify-end items-start px-4 pb-8">
                  <h1 className="newContent text-start text-white">
                    January 20, 2025
                  </h1>
                  <h1 className="newL2SubHeader text-start text-primary margin group-hover:text-primary">
                    Beginner-Friendly 6000m Peaks to Start Your High-Altitude
                    Mountaineering Journey
                  </h1>
                </div>
              </Link>
            </div>
            {/* <div
              className="col-start-1 col-span-12 margin flex justify-center items-center space-x-2 hover:cursor-pointer"
              
            >
              <FaArrowCircleRight className="text-primary w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8" />
              <h1 className="newL3SubHeader text-center text-primary">View all</h1>
            </div> */}
          </div>
        </div>
      </section>

      {/* Youtube Gallery Section */}
      <YouTubeGallery videos={youtubeVideos} />

      {/* Testimonials Section */}
      {/* <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1
              className="newHeader col-start-1 col-span-12 text-center"
              
            >
              Testimonials
            </h1>
            <div
              className="relative col-start-1 col-span-12 sm:col-start-3 sm:col-span-8 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 2xl:col-start-2 2xl:col-span-10 rounded-md border border-textColor overflow-hidden h-96 margin"
              
            >
              <FaQuoteLeft className="mt-2 ms-3 text-primary w-8 h-8 sm:w-10 sm:h-10 md:w-11 md:h-11 lg:w-12 lg:h-12 xl:w-12 xl:h-12 2xl:w-13 2xl:h-13" />
              <h1 className="newContent mx-3 my-1 w-auto h-[250px] sm:h-[230px] lg:h-[210px] 2xl:h-[195px] overflow-y-scroll custom-scrollbar pe-3">
                {testimonialsArray[currentIndex].message}
              </h1>
              <div className="bottom-2 absolute w-full">
                <hr className="my-2 mx-3 border-t-2 border-gray-300" />
                <h1 className="newL2SubHeader mx-3">
                  {testimonialsArray[currentIndex].fullName}
                </h1>
                <h1 className="newL3SubHeader mx-3">
                  {testimonialsArray[currentIndex].selectedTrip}
                </h1>
              </div>
            </div>

            <div
              className="col-start-1 col-span-12 margin flex justify-center items-center space-x-2 hover:cursor-pointer"
              
            >
              <FaArrowLeftLong
                className="text-primary w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8"
                onClick={handlePrev}
              />
              <FaArrowRightLong
                className="text-primary w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8"
                onClick={handleNext}
              />
            </div>
          </div>
        </div>
      </section> */}

      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <h1 className="newHeader col-start-1 col-span-12 text-center">
              Testimonials
            </h1>

            {/* Responsive Testimonials Grid */}
            <div className="col-span-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {testimonialsArray
                .slice(currentIndex, currentIndex + visibleCount)
                .map((testimonial, index) => (
                  <div
                    key={index}
                    className="border h-96 rounded-md margin flex flex-col"
                  >
                    {/* First Child - Fixed Height */}
                    <div className="flex items-center border-b h-20 px-4">
                      {/* Initial of the name */}
                      <div
                        className="w-14 h-14 rounded-full flex justify-center items-center"
                        style={{ backgroundColor: getRandomColor() }}
                      >
                        <h1 className="text-white text-xl font-bold leading-none">
                          {testimonial.fullName.charAt(0)}
                        </h1>
                      </div>

                      {/* Name and Trip Name */}
                      <div className="flex flex-col flex-1 ml-4 relative group">
                        {/* Full Name with Tooltip */}
                        <div className="relative">
                          <h1 className="newL2SubHeader font-semibold text-black max-w-[200px] md:max-w-[180px] lg:max-w-[280px] truncate cursor-pointer">
                            {testimonial.fullName}
                          </h1>
                          <div className="absolute hidden group-hover:flex top-full left-0 mt-2 bg-black text-white text-sm rounded p-2 shadow-lg z-10 w-max max-w-xs">
                            {testimonial.fullName}
                          </div>
                        </div>

                        {/* Selected Trip with Tooltip */}
                        <div className="relative mt-2">
                          <h1 className="newContent text-gray-600 max-w-[200px] md:max-w-[180px] lg:max-w-[280px] truncate cursor-pointer">
                            {testimonial.selectedTrip}
                          </h1>
                          <div className="absolute hidden group-hover:flex top-full left-0 mt-2 bg-black text-white text-sm rounded p-2 shadow-lg z-10 w-max max-w-xs">
                            {testimonial.selectedTrip}
                          </div>
                        </div>
                      </div>

                      {/* Google Logo */}
                      <div className="w-14 h-14 rounded-full flex justify-center items-center">
                        <img
                          src={googleIcon}
                          alt="Google Logo"
                          className="w-10 h-10 object-contain"
                        />
                      </div>
                    </div>

                    {/* Second Child - Fixed Height */}
                    {/* Star Rating Section */}
                    <div className="h-14 flex items-center justify-center gap-1">
                      {[...Array(5)].map((_, index) => {
                        const starValue = index + 1;
                        if (testimonial.ratingStar >= starValue) {
                          // Full Star
                          return (
                            <img
                              key={index}
                              src={fullStar}
                              alt="Full Star"
                              className="w-6 h-6 object-contain"
                            />
                          );
                        } else if (testimonial.ratingStar > starValue - 1) {
                          // Half Star
                          return (
                            <img
                              key={index}
                              src={halfStar}
                              alt="Half Star"
                              className="w-6 h-6 object-contain"
                            />
                          );
                        } else {
                          // Empty Star (Optional)
                          return (
                            <img
                              key={index}
                              src={emptyStar} // Ensure you have an empty star image asset
                              alt="Empty Star"
                              className="w-6 h-6 object-contain"
                            />
                          );
                        }
                      })}
                    </div>

                    {/* Third Child - Flexible Height */}
                    <div className="flex-grow overflow-hidden">
                      {/* Truncated Content */}
                      <p className="text-start px-6 pt-2 newContent line-clamp-6">
                        {testimonial.message}
                      </p>
                      <h1
                        className="newL3SubHeader mt-3 text-center cursor-pointer text-primary"
                        onClick={() => openModal(testimonial.message)}
                      >
                        Read More
                      </h1>
                    </div>

                    {/* Modal for Full Review */}
                    <Modal
                      isOpen={isModalOpen}
                      onRequestClose={closeModal}
                      contentLabel="Full Review"
                      className="modal-content"
                      overlayClassName="modal-overlay"
                      ariaHideApp={false}
                    >
                      <div className="relative p-6 bg-white rounded-lg max-w-lg mx-auto">
                        {/* Close Button */}
                        <button
                          className="absolute top-2 right-2 text-gray-600 hover:text-black newL2SubHeader"
                          onClick={closeModal}
                        >
                          ✖
                        </button>

                        {/* Review Content */}
                        <h1 className="font-bold mb-4 newL2SubHeader">
                          Full Review
                        </h1>
                        <p className=" text-gray-700 overflow-y-auto max-h-[300px] newContent">
                          {selectedReview}
                        </p>
                      </div>
                    </Modal>
                  </div>
                ))}
            </div>

            <h1 className="newContent col-start-1 col-span-12 text-center margin">
              Google rating score:{" "}
              <span className="font-bold">{averageRating}</span> of 5, based on{" "}
              <span className="font-bold">{totalReviews}</span> reviews
            </h1>

            {/* Navigation Buttons */}
            <div className="col-start-1 col-span-12 margin flex justify-center items-center space-x-2 hover:cursor-pointer">
              <FaArrowLeftLong
                className={`text-primary w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 ${
                  currentIndex === 0
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:text-primary-dark"
                }`}
                onClick={currentIndex > 0 ? handlePrev : null}
              />
              <FaArrowRightLong
                className={`text-primary w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-8 lg:h-8 ${
                  currentIndex + visibleCount >= testimonialsArray.length
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:text-primary-dark"
                }`}
                onClick={
                  currentIndex + visibleCount < testimonialsArray.length
                    ? handleNext
                    : null
                }
              />
            </div>
            <div className="col-start-1 col-span-12 flex justify-center items-center margin">
              <button
                className="px-8 md:px-12 py-4 newContent rounded-md text-black bg-primary cursor-pointer"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate("/WriteReview");
                }}
              >
                Write Review
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Instagram Section */}
      <section className="center bg-black">
        <div className="container ">
          <div className="grid grid-cols-12 gap-2 ">
            <h1 className="newHeader col-start-1 col-span-12 text-center">
              Photo Gallery
            </h1>
            {/* <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="newL3SubHeader text-primary">@white.expedition</h1>
            </a> */}
            <div className="col-span-12 flex justify-center items-center space-x-3">
              <a
                href="https://www.facebook.com/share/19E5pajnVe/?mibextid=wwXIfr"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our facebook page
                </h1>
              </a>
              <a
                href="https://www.twitter.com/@WhiteExped79673"
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareXTwitter
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our twitter page
                </h1>
              </a>
              <a
                href="https://www.instagram.com/white.expedition?igsh=dHZiNXZwOGtyMTN0"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our instagram page
                </h1>
              </a>
              <a
                href="https://www.youtube.com/@White.Expeditions"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="text-primary cursor-pointer" size={25} />
                <h1 className="invisible hidden">
                  Click here to see our youtube page
                </h1>
              </a>
            </div>
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
            </div>
          </div>
        </div>
      </section>

      {/* Footer section */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>
        <div className="center">
          <div className="container relative z-20">
            <div className="grid grid-cols-12 gap-2">
              <div className="col-start-1 col-span-12">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* WhatsApp Section */}
      <Whatsapp />

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </>
  );
};

export default Home;

const getRandomColor = () => {
  const colors = [
    "#4E73DF", // Soft Blue
    "#1CC88A", // Calm Green
    "#36B9CC", // Muted Teal
    "#F6C23E", // Golden Yellow
    "#858796", // Neutral Gray
    "#E74A3B", // Professional Red
    "#5A5C69", // Dark Neutral
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};
