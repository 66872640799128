import React, { useEffect, useState } from "react";

// Components for page structure
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import InstaFeeds from "../components/InstaFeeds";
import NavigationBar from "../components/NavigationBar";
import Whatsapp from "../components/Whatsapp";

// Data for HeroSection
import { PrivacyPolicyHeroSection } from "../data/Home/dataStorage";

// Asset for footer background
import expedition from "../assets/home/hero/expedition.webp";

// Animation library (if actively used)
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import UpcomingTours from "../components/UpcomingTours";
import UpcomingToursIcon from "../components/UpcomingToursIcon";
import { FaFacebookSquare, FaInstagram, FaYoutube } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const PrivacyPolicy = () => {
  useEffect(() => {
    // Initialize AOS library for animations
    AOS.init();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Helmet>
        <title>White Expedition - Privacy Policy</title>
        <meta
          name="description"
          content="We respect your privacy! Learn how we handle your data in our Privacy Policy. Gain transparency about data collection and usage."
        />
      </Helmet>

      {/* Hero Section with Carousel Data from WhoWeAreHeroSection */}
      <HeroSection carouselData={PrivacyPolicyHeroSection} />

      {/* Navigation Bar */}
      <section className="center">
        <NavigationBar />
      </section>

      {/* Privacy Policy Section  */}
      <section className="center">
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start text-primary font-bold margin">
                Effective Date: March 1, 2024
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                White Expedition ("we," "us," or "our") is committed to
                protecting your privacy and the security of your personal
                information. This Privacy Policy outlines how we collect, use,
                disclose, and store your personal information when you use our
                website white-Expeditions.com.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start text-primary font-bold margin">
                Information We Collect
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                We only collect personal information when it's necessary to
                provide you with a service on our website. We collect
                information by fair and lawful means, with your knowledge and
                consent. We will always explain why we are collecting your
                information and how it will be used.
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1 className="newContent text-start text-primary font-bold margin">
                The types of personal information we may collect include:
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1 className="newContent text-start margin">
                Contact information (e.g., name, email address, phone number)
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1 className="newContent text-start margin">
                Information necessary to fulfill your requests (e.g., booking
                inquiries)
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start text-primary font-bold margin">
                Use of Your Information
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                We only retain collected information for as long as necessary to
                provide you with the requested service. We use your personal
                information to:
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1 className="newContent text-start margin">
                Respond to your inquiries and requests
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1 className="newContent text-start margin">
                Process your booking requests
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1 className="newContent text-start margin">
                Improve the functionality and content of our website
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start text-primary font-bold margin">
                Data Security
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                We take reasonable steps to protect your personal information
                from loss, theft, unauthorized access, disclosure, copying, use,
                or modification. We employ commercially acceptable means to
                safeguard your information, but please note that no website or
                internet transmission is completely secure.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start text-primary font-bold margin">
                Disclosure of Your Information
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                We do not share any personally identifiable information publicly
                or with third-parties except in the following situations:
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1 className="newContent text-start margin">
                When required by law or legal process
              </h1>
            </div>

            <div className="col-start-2 col-span-12">
              <h1 className="newContent text-start margin">
                To protect the rights or safety of ourselves or others
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start text-primary font-bold margin">
                Third-Party Links
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                Our website may contain links to external websites that are not
                operated by us. We are not responsible for the content or
                practices of these third-party sites and do not endorse them. We
                recommend you review the privacy policies of any external sites
                you visit.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start text-primary font-bold margin">
                Your Choices
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                You are free to refuse our request for your personal
                information, with the understanding that we may be unable to
                provide you with some of your desired services on the website.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start text-primary font-bold margin">
                Changes to Our Privacy Policy
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                our website. Your continued use of the website after the revised
                Privacy Policy is posted constitutes your acceptance of the
                revised Privacy Policy.
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start text-primary font-bold margin">
                Contact Us
              </h1>
            </div>

            <div className="col-start-1 col-span-12">
              <h1 className="newContent text-start margin">
                If you have any questions about this Privacy Policy or our
                handling of your personal information, please feel free to
                <span className="newContent text-primary hover:cursor-pointer">
                  <a href="/getInTouch"> contact us</a>
                </span>
              </h1>
            </div>
          </div>
        </div>
      </section>

      {/* Instagram Section  */}
      <section className="center bg-black">
        {/* Container for Instagram feed section */}
        <div className="container">
          <div className="grid grid-cols-12 gap-2">
            {/* Section newHeader (remove commented-out animations) */}
            <h1 className="newHeader col-start-1 col-span-12 text-center">
              Photo Gallery
            </h1>

            {/* Instagram Username Link */}
            {/* <a
              href="https://www.instagram.com/white.expedition/"
              target="_blank"
              rel="noopener noreferrer"
              className="col-start-1 col-span-12 text-center"
            >
              <h1 className="newL3SubHeader text-primary">@white.expedition</h1>
            </a> */}
            <div className="col-span-12 flex justify-center items-center space-x-3">
              <a
                href="https://www.facebook.com/share/19E5pajnVe/?mibextid=wwXIfr"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our facebook page
                </h1>
              </a>
              <a
                href="https://www.twitter.com/@WhiteExped79673"
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareXTwitter
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our twitter page
                </h1>
              </a>
              <a
                href="https://www.instagram.com/white.expedition?igsh=dHZiNXZwOGtyMTN0"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  className="text-primary cursor-pointer"
                  size={25}
                />
                <h1 className="invisible hidden">
                  Click here to see our instagram page
                </h1>
              </a>
              <a
                href="https://www.youtube.com/@White.Expeditions"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="text-primary cursor-pointer" size={25} />
                <h1 className="invisible hidden">
                  Click here to see our youtube page
                </h1>
              </a>
            </div>

            {/* Instagram Feed (replace with your actual Instagram feed component) */}
            <div className="col-start-1 col-span-12 rounded-md overflow-hidden margin">
              <InstaFeeds />
              {/* Comment: Replace InstaFeeds with your actual Instagram feed component */}
            </div>
          </div>
        </div>
      </section>

      {/* Footer section with background image */}
      <section
        className="relative bg-center bg-cover"
        style={{ backgroundImage: `url(${expedition})` }}
      >
        {/* Dark overlay on top of background image */}
        <div className="absolute inset-0 bg-primary opacity-80 z-10"></div>

        {/* Container for footer content */}
        <div className="container relative z-20">
          <div className="grid grid-cols-12 gap-2">
            {/* Footer component (replace with your actual footer component) */}
            <div className="col-start-1 col-span-12">
              <Footer />
              {/* Comment: Replace Footer with your actual footer component */}
            </div>
          </div>
        </div>
      </section>

      {/* WhatsApp integration (replace with your implementation) */}
      <Whatsapp />
      {/* Comment: Replace Whatsapp with your actual WhatsApp integration */}

      {isVisible && <UpcomingTours close={() => setIsVisible(false)} />}

      <UpcomingToursIcon
        open={() => {
          setIsVisible(true);
        }}
      />
    </>
  );
};

export default PrivacyPolicy;
